import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LanguageSelectionDialog from "./LanguageSelectionDialog";

import localStorageService from "services/localStorageService";

import { setLanguage } from "actions";
import reduceLanguages from "lang/reduceLanguages";
import urlServices from "services/urlServices";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseLanguageSelectionDialog: PropTypes.func.isRequired
};

const defaultProps = {
  showModal: false
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLanguage: language => {
      dispatch(setLanguage(language));
    }
  };
};

export class LanguageSelectionDialogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
      language: reduceLanguages(props.language)
    };

    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = event => {
    event.preventDefault();

    urlServices.addParam("lang", this.state.language);
    localStorageService.setItem("language", this.state.language);
    this.props.setLanguage(this.state.language);
  };

  componentDidMount() {
    this.setState({ language: this.setSelectedValue() });
  }

  setSelectedValue() {
    let language = this.props.language;
    let lang;

    lang = reduceLanguages(language);

    return lang.toLowerCase();
  }

  onLanguageChange(value) {
    this.setState({ language: value });
  }

  render() {
    return (
      <LanguageSelectionDialog
        showModal={this.props.showModal}
        handleCloseLanguageSelectionDialog={
          this.props.handleCloseLanguageSelectionDialog
        }
        handleSubmit={this.handleSubmit}
        selectedValue={this.state.language}
        onLanguageChange={this.onLanguageChange}
        language={this.props.language}
      />
    );
  }
}

LanguageSelectionDialogContainer.propTypes = propTypes;
LanguageSelectionDialogContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelectionDialogContainer);
