import React, { Component } from "react";
import PropTypes from "prop-types";

import "./ProjectCard.css";
import { PROJECT_HOME } from "App/Routes";
import placeholder_project from "images/core/placeholders/placeholder_project.png";
import Link from "components/shared/Link/Link";

import Dotdotdot from "react-dotdotdot";

const propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  description: PropTypes.string
};
const defaultProps = {
  img: placeholder_project
};

class ProjectCard extends Component {
  render() {
    let description = this.props.description;
    let img = this.props.img;
    let text = this.props.text;

    return (
      <div className="project-card-container">
        <Link
          to={PROJECT_HOME.format(this.props.id)}
          className="project-card project-card-bg pure-g"
        >
          <div className="pure-u-5-24">
            <span className="square-image-wrapper">
              <span className="square-image circle">
                <img src={img} alt={text} />
              </span>
            </span>
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-3-4">
            <Dotdotdot clamp={2}>
              <h6 className="title">{text}</h6>
            </Dotdotdot>
            {this.props.description && description.trim() !== "" && (
              <Dotdotdot clamp={3}>
                <p dangerouslySetInnerHTML={{ __html: description }} />
              </Dotdotdot>
            )}
          </div>
        </Link>
      </div>
    );
  }
}

ProjectCard.propTypes = propTypes;
ProjectCard.defaultProps = defaultProps;

export default ProjectCard;
