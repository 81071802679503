import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getApiGenerator from "services/getApiGenerator";
import { UNJOIN_PROJECT } from "services/api";

import { showAlertWithTimeout } from "actions";

import UnjoinProjectDialog from "./UnjoinProjectDialog";
import localize from "lang/localize";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  handleCloseUnjoinProjectDialog: PropTypes.func.isRequired,
  removeProject: PropTypes.func.isRequired,
  private: PropTypes.bool.isRequired
};

const defaultProps = {
  showModal: false
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

class UnjoinProjectDialogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal
    };

    this.handleClickDelete = this.handleClickDelete.bind(this);
  }

  unjoinProject(typeId) {
    getApiGenerator(
      UNJOIN_PROJECT,
      {
        type_id: typeId
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error"
            });
          }
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("alert_unjoin_project_success", this.props.language),
          type: "success"
        });
      }
    });
  }

  handleClickDelete() {
    this.unjoinProject(this.props.projectId);
    this.props.removeProject(this.props.projectId);
    this.props.handleCloseUnjoinProjectDialog();
  }

  render() {
    return (
      <UnjoinProjectDialog
        showModal={this.props.showModal}
        projectId={this.props.projectId}
        handleCloseUnjoinProjectDialog={
          this.props.handleCloseUnjoinProjectDialog
        }
        handleClickDelete={this.handleClickDelete}
        language={this.props.language}
        private={this.props.private}
      />
    );
  }
}

UnjoinProjectDialogContainer.propTypes = propTypes;
UnjoinProjectDialogContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnjoinProjectDialogContainer);
