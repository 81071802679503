import React from "react";
import PropTypes from "prop-types";

import "./Alert.css";

const propTypes = {
  alerts: PropTypes.array.isRequired,
  dismissable: PropTypes.bool,
  clearAlert: PropTypes.func
};

const defaultProps = {
  dismissable: true
};

const Alert = props => {
  if (props.alerts.length > 0) {
    const displayAlert = props.alerts[0];
    return (
      <div className="alert-wrapper">
        <div
          className={
            "alert " + (displayAlert.alert.type ? displayAlert.alert.type : "")
          }
        >
          <span className="pure-u-1 pure-u-lg-3-4">
            {displayAlert.alert.text}
          </span>
          {props.dismissable && (
            <button
              className="button clear close floatright"
              aria-label="Close"
              onClick={() => props.clearAlert(displayAlert.id)}
            >
              &times;
            </button>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
