import React, { Component } from "react";
import { connect } from "react-redux";

import { HOME } from "App/Routes";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import ProjectReferAFriendPage from "./ProjectReferAFriendPage";

import { LOAD_MORE_COUNT, SINGLE_PROJECT_APP } from "config";
import { setProject, setButtons } from "actions";
import getApiGenerator from "services/getApiGenerator";
import { GET_TOPICS } from "services/api";
import localize from "lang/localize";

import sessionStorageService from "services/sessionStorageService";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    project: state.project,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    }
  };
};

/*
  In Project Container, this.props.id refers to category ID
  while this.props.projectId refers to projectId.
*/
export class ProjectReferAFriendContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: null,
      code: null,
      error: null
    };
  }

  componentDidMount() {
    this.getTopics(1);
  }

  componentDidUpdate(prevProps, prevState) {
    // mitigating race conditions in joining user into game
    // checks: (1) if this.state.error and prevState.error are different,
    //         (2) error message check (search for 'join' keyword),
    //         (3) prevState.error is not string (null check)
    // then: call api to get topics again
    // won't go into loop because this.state.error will only be null (otherwise it is a string) before api is called
    if (
      this.state.error !== prevState.error &&
      this.state.error &&
      this.state.error.indexOf("join") !== -1 &&
      typeof prevState.error !== "string"
    ) {
      this.getTopics(1);
    }

    /* when switching projects */
    if (
      this.props.projectId !== prevProps.projectId &&
      typeof this.props.projectId === "number" &&
      typeof prevProps.projectId === "number"
    ) {
      /* reset states */
      this.setState(
        {
          topics: null,
          code: null,
          error: null
        },
        () => {
          /* reinitialize project */
          this.getTopics(1);
        }
      );
    }
  }

  getTopics(page, category_id = "", limit = LOAD_MORE_COUNT) {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: page,
        category_id: category_id,
        limit: limit
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error
          });
        }
      } else {
        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        let compiledProject = res.body.game;
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: ""
        });
        this.props.setProject(compiledProject);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  render() {
    const UNAVAILABLE_GAME_ERROR_LIST_LOGGEDIN = [
      localize("unavailable_game_private_loggedin", this.props.language),
      localize("unavailable_game_unpublished", this.props.language),
      localize("unavailable_game_deleted", this.props.language),
      localize("unavailable_game_no_topics", this.props.language),
      localize("unavailable_game_invalid", this.props.language)
    ];
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    if (!this.props.sessionKey) {
      /* Not logged in */
      return (
        <NotLoggedInContainer
          showLogin={false}
          language={this.props.language}
        />
      );
    }
    // project exists, referrals open
    else if (
      this.state.topics &&
      this.state.topics.length > 0 &&
      this.props.project &&
      this.props.project.projectReferralLink
    ) {
      /*
        Setting a key prop for ProjectReferAFriendPage will allow the interface to be reset,
        whenever you switch projectIds
      */
      return (
        <ProjectReferAFriendPage
          key={this.props.projectId}
          sessionKey={this.props.sessionKey}
          projectReferralLink={this.props.project.projectReferralLink}
          language={this.props.language}
        />
      );
    }
    // project exists, referrals are closed
    else if (
      this.state.topics &&
      this.state.topics.length > 0 &&
      this.props.project
    ) {
      return (
        <GenericErrorPage
          message={localize("unavailable_game_referral", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (this.state.topics) {
      /* No information is returned, but logged in */
      return (
        <GenericErrorPage
          routeUrl={SINGLE_PROJECT_APP || IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_game_long", this.props.language)}
          messageList={UNAVAILABLE_GAME_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectReferAFriendContainer);
