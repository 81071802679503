import React, { Component } from "react";
import PropTypes from "prop-types";

import ReactModal from "react-modal";

import localize from "lang/localize";

import "./UnjoinProjectDialog.css";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  handleCloseUnjoinProjectDialog: PropTypes.func.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
  language: PropTypes.string,
  private: PropTypes.bool.isRequired
};

class UnjoinProjectDialog extends Component {
  renderBodyCopywriting() {
    return (
      <p>{localize("bundle_alert_remove_message", this.props.language)}</p>
    );
  }

  renderPrivateBodyCopywriting() {
    return (
      <p>
        {localize("dialog_unjoin_project_private_text", this.props.language)}
      </p>
    );
  }

  renderLeaveGameButton() {
    return (
      <button
        className="button inline danger"
        onClick={this.props.handleClickDelete}
        id="unjoinProjectButton"
      >
        {localize("web_confirm_yes_text", this.props.language)}
      </button>
    );
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.showModal}
        contentLabel={localize(
          "bundle_alert_remove_title",
          this.props.language
        )}
        onRequestClose={this.props.handleCloseUnjoinProjectDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog unjoin-project-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize("bundle_alert_remove_title", this.props.language)}
            </h5>
          </div>
          <div className="dialog-body">
            {this.props.private
              ? this.renderPrivateBodyCopywriting()
              : this.renderBodyCopywriting()}
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseUnjoinProjectDialog}
                id="cancelButton"
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              {this.props.private ? null : this.renderLeaveGameButton()}
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

UnjoinProjectDialog.propTypes = propTypes;

export default UnjoinProjectDialog;
