import React, { Component } from "react";
import PropTypes from "prop-types";
import Countdown, { zeroPad } from "react-countdown";
import localize from "lang/localize";

const propTypes = {
  timerRef: PropTypes.object,
  setTimerActive: PropTypes.func,
  countdownTime: PropTypes.number,
  language: PropTypes.string
};

class CountdownButton extends Component {
  constructor() {
    super();
    this.timerRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.countdownTime !== this.props.countdownTime ||
      nextProps.language !== this.props.language
    ) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Countdown
        ref={this.timerRef}
        date={Date.now() + this.props.countdownTime}
        onComplete={() => this.props.setTimerActive(false)}
        renderer={({ minutes, seconds }) => {
          if (minutes === 0) {
            return localize("button_timer_seconds", this.props.language).format(
              seconds
            );
          } else {
            return localize("button_timer_minutes", this.props.language).format(
              minutes,
              zeroPad(seconds)
            );
          }
        }}
      />
    );
  }
}

CountdownButton.propTypes = propTypes;

export default CountdownButton;
