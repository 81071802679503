import React, { Fragment } from "react";
import PropTypes from "prop-types";
import LeaderboardPage from "components/Project/Leaderboard/LeaderboardPage";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "components/shared/Loading";
import SelectInput from "components/shared/Inputs/SelectInput";

import localize from "lang/localize";

import "./LeaderboardPage.css";

const propTypes = {
  projectId: PropTypes.number.isRequired,
  mainBoard: PropTypes.array,
  userBoard: PropTypes.array,
  moreBoardData: PropTypes.bool,
  fullBoard: PropTypes.array,
  more: PropTypes.bool,
  topicLeaderboards: PropTypes.array,
  type: PropTypes.oneOf(["overall", "monthly", "daily"]).isRequired,
  board: PropTypes.oneOf(["individual", "team"]).isRequired,
  teamAvailable: PropTypes.bool.isRequired,
  handleToggleType: PropTypes.func.isRequired,
  handleToggleBoard: PropTypes.func.isRequired,
  handleToggleOverview: PropTypes.func.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  overview: PropTypes.bool.isRequired,
  userId: PropTypes.number,
  teamId: PropTypes.number,
  indivReqStatus: PropTypes.array.isRequired,
  teamReqStatus: PropTypes.array.isRequired,
  language: PropTypes.string,
  /* CUSTOM FOR ASM */
  handleToggleCountry: PropTypes.func.isRequired,
  countryLeaderboards: PropTypes.array
};

/**
 * CUSTOM FOR ASM:
 * - Header
 * - 30 day filter
 * - Country filter for player leaderboard
 * - Scrollbar for reward list instead of whole page
 */
class ASMLeaderboardPage extends LeaderboardPage {
  constructor(props) {
    super(props);
    this.asmContainerRef = React.createRef();
  }
  /*
   * CUSTOM FOR ASM: 30 day filter
   */
  renderTimeButtons() {
    return (
      <div className="textcenter asm-leaderboard-filters">
        <button
          id="overall"
          className={
            "button inline toggle " +
            (this.props.type === "overall" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleType}
        >
          <span>
            {localize("score_summary_segment_all_time", this.props.language)}
          </span>
        </button>
        <button
          id="monthly"
          className={
            "button inline toggle " +
            (this.props.type === "monthly" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleType}
        >
          <span>Last 30 Days</span>
        </button>
        <button
          id="daily"
          className={
            "button inline toggle " +
            (this.props.type === "daily" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleType}
        >
          <span>
            {localize("score_summary_segment_daily", this.props.language)}
          </span>
        </button>
      </div>
    );
  }

  /*
   * CUSTOM FOR ASM: Country filters (using custom country API)
   */
  renderCountryToggle() {
    if (
      this.props.countryLeaderboards &&
      Array.isArray(this.props.countryLeaderboards) &&
      this.props.countryLeaderboards.length > 0
    ) {
      return (
        <form
          id="countryLeaderboardForm"
          className="pure-form flex justify-content-center"
        >
          <SelectInput
            id="countryLeaderboardFilterOption"
            name="countryLeaderboardFilter"
            className="select-text-align-center"
            options={this.props.countryLeaderboards}
            onChange={this.props.handleToggleCountry}
            placeholder={localize("category_all_text", this.props.language)}
          />
        </form>
      );
    } else {
      return null;
    }
  }

  /*
   * CUSTOM FOR ASM: Switch position between the 2 buttons
   */
  renderBoardNav() {
    return (
      <nav className="nav-buttons">
        <button
          id="individual"
          className={
            "button " +
            (this.props.board === "individual" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleBoard}
        >
          <span>
            <i className="fas fa-user rightmargin-10"></i>
            {localize("icon_leaderboard_mode_user", this.props.language)}
          </span>
        </button>
        <button
          id="team"
          className={
            "button " + (this.props.board === "team" ? "active" : "inactive")
          }
          onClick={this.props.handleToggleBoard}
        >
          <span>
            <i className="fas fa-users rightmargin-10"></i>
            {localize("icon_leaderboard_mode_team", this.props.language)}
          </span>
        </button>
      </nav>
    );
  }

  renderBoards(empty) {
    if (empty) {
      return this.renderEmpty();
    } else if (this.props.overview && this.props.mainBoard) {
      return (
        <Fragment>
          {this.renderMainBoard()}
          {this.renderUserBoard(this.props.userBoard)}
        </Fragment>
      );
    } else if (!this.props.overview && this.props.fullBoard) {
      return (
        <InfiniteScroll
          dataLength={this.props.fullBoard.length}
          next={this.props.handleFetchData}
          hasMore={this.props.more}
          loader={<Loading />}
          style={{ overflow: "visible" }}
          scrollableTarget={this.asmContainerRef.current}
        >
          {this.renderBoard(this.props.fullBoard)}
        </InfiniteScroll>
      );
    } else {
      return <Loading />;
    }
  }

  render() {
    /* empty only if API call has been made, and main board is an array of at least 1 item */
    let empty;
    if (this.props.mainBoard) {
      empty =
        Array.isArray(this.props.mainBoard) &&
        this.props.mainBoard.length === 0;
    } else {
      empty =
        this.props.fullBoard &&
        Array.isArray(this.props.fullBoard) &&
        this.props.fullBoard.length === 0;
    }

    return (
      <React.Fragment>
        {/*
          If teams are available, show Team/Player as tabs.
          Otherwise, show Time (All-Time / Monthly / Daily).
        */}
        <div className="asm-leaderboard-container">
          <div className="asm-page-header">Leaderboard</div>
          {this.props.teamAvailable && this.renderBoardNav()}
          <div className="leaderboard verticalpadding">
            {/*
              When teams are available, Team/Player are the navtabs.
              Use Time (All-Time, Monthly, Daily) as buttons.
            */}
            {this.renderTimeButtons()}
            {this.renderCountryToggle()}

            <div
              className="asm-leaderboard-list-container"
              ref={this.asmContainerRef}
            >
              {this.renderBoards(empty)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ASMLeaderboardPage.propTypes = propTypes;

export default ASMLeaderboardPage;
