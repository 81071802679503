import React, { Component } from "react";
import PropTypes from "prop-types";

import ReactModal from "react-modal";

import localize from "lang/localize";

import "./ResetActivityDialog.css";

const propTypes = {
  showDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  topicTitle: PropTypes.string.isRequired,
  topicId: PropTypes.number.isRequired,
  language: PropTypes.string
};

/**
 * Pop-up Modal to confirm activity reset
 */
class ResetActivityDialog extends Component {
  renderBodyCopywriting() {
    return (
      <div>
        <p className="nomargin">
          {localize("topic_alert_reset_message", this.props.language)}
        </p>
      </div>
    );
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.showDialog}
        contentLabel={localize("topic_alert_reset_title", this.props.language)}
        onRequestClose={this.props.handleCloseDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog reset-activity-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize("topic_alert_reset_title", this.props.language)}
            </h5>
          </div>
          <div className="dialog-body">{this.renderBodyCopywriting()}</div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseDialog}
                id="cancelButton"
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              <button
                className="button inline danger"
                onClick={this.props.handleConfirm}
                id="resetActivityButton"
              >
                {localize("web_confirm_yes_text", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

ResetActivityDialog.propTypes = propTypes;

export default ResetActivityDialog;
