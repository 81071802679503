/* This getApiGenerator2 is for use with API2 */

import request from "superagent";
import { ENDPOINT2 } from "config";

const getApiGenerator2 = (route, query = {}, sessionKey) =>
  request
    .get(ENDPOINT2 + route)
    .query(query)
    .set("Authorization", `Bearer ${sessionKey}`);

export default getApiGenerator2;
