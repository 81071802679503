import React, { Component } from "react";
import PropTypes from "prop-types";

import "./EmbedDialog.css";

import deviceServices from "services/deviceServices";

import Modal from "react-modal";

const propTypes = {
  showDialog: PropTypes.bool.isRequired,
  handleCloseEmbedDialog: PropTypes.func.isRequired,
  url: PropTypes.string,
  title: PropTypes.string.isRequired
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Pop-up Modal to render a generic embedded web view
 */
class EmbedDialog extends Component {
  render() {
    let title = this.props.title;
    let url = this.props.url;

    return (
      <Modal
        isOpen={this.props.showDialog}
        contentLabel={this.props.title && title.trim() !== "" ? title : " "}
        onRequestClose={this.props.handleCloseEmbedDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog embed-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {this.props.title && title.trim() !== "" ? title : " "}
            </h5>
            <button
              onClick={this.props.handleCloseEmbedDialog}
              className="close-button-container"
            >
              <i className="fa close-button" aria-hidden="true" />
            </button>
          </div>
          <div
            className={
              "dialog-body" +
              (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
            }
          >
            <iframe
              title={url}
              src={url}
              allow="geolocation"
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

EmbedDialog.propTypes = propTypes;

export default EmbedDialog;
