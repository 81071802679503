import React, { Component } from "react";
import { connect } from "react-redux";

import Error404Page from "./Error404Page";

export const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language
  };
};

export class Error404Container extends Component {
  render() {
    return <Error404Page language={this.props.language} />;
  }
}

export default connect(mapStateToProps)(Error404Container);
