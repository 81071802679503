import {
  SINGLE_PROJECT_APP,
  ENABLE_PROJECT_SPECIFIC_ACCOUNT_PAGES
} from "config";

/*
  Makes use of format method in src/lib/js/string.js
  USAGE: USER.format(1234) => "/user/1234"
*/

/* Multi-Project App */
let HOME = "/home",
  LOGIN_HOME = "/loginhome",
  LOGIN = "/login",
  LOGIN_SSO = "/loginsso",
  POST_LOGIN_INTERCEPT = "/postloginintercept",
  LOGIN_TOUR = "/tour",
  FRAME_LOGIN_SUCCESS = "/frameloginsuccess",
  SSO_LOGIN_SUCCESS = "/ssologinsuccess",
  PROJECT_LIST = "/home",
  PROFILE = "/profile",
  USER = "/user/{0}",
  USER_ACHIEVEMENTS = "/user/{0}/achievements",
  USER_FOLLOWS = "/user/{0}/follows",
  ACHIEVEMENTS = "/achievements",
  BOOKMARKS = "/bookmarks",
  NOTIFICATIONS = "/notifications",
  INBOX = "/inbox",
  ITEM = "/item/{0}",
  TEAM = "/team/{0}",
  TEAM_INVITE = "/team/{0}/invite",
  TEAM_UPDATE = "/team/{0}/update",
  ERROR = "ERROR",
  SETTINGS = "/settings",
  REGISTER = "/register",
  RESET_PASSWORD = "/resetpassword",
  UPDATE_DEVICE_TOKEN = "/updateDeviceToken?deviceToken={0}",
  // enquiry
  CHALLENGE_ENQUIRY = "/challenge/{0}/enquiry",
  PROJECT_USER_ENQUIRY = "/project/{0}/user/{1}/enquiry",
  CLAIM_ENQUIRY = "/claim/{0}/enquiry",
  // project
  PROJECT_HOME = "/project/{0}",
  TOPIC_CATEGORY = "/project/{0}/category/{1}",
  TOPIC = "/topic/{0}",
  CHALLENGE_CATEGORY = "/topic/{0}/category/{1}",
  TOPIC_COMMENTS = "/topic/{0}/comments",
  TOPIC_COMMENTS_THREAD = "/topic/{0}/comments/{1}/thread",
  CHALLENGE = "/challenge/{0}",
  CHALLENGE_A_FRIEND = "/challenge/{0}/refer",
  CHALLENGE_COMMENTS = "/challenge/{0}/comments",
  CHALLENGE_COMMENTS_THREAD = "/challenge/{0}/comments/{1}/thread",
  CHALLENGE_ACTIVITY = "/challenge/{0}/activity",
  CHALLENGE_MY_ACTIVITY = "/challenge/{0}/my-activity",
  CLAIM = "/claim/{0}",
  CLAIM_COMMENTS = "/claim/{0}/comments",
  CLAIM_COMMENTS_THREAD = "/claim/{0}/comments/{1}/thread",
  FRAME = "/frame?url={0}",
  PROJECT_ACHIEVEMENTS = "/project/{0}/achievements",
  PROJECT_USER_ACHIEVEMENTS = "/project/{0}/user/{1}/achievements",
  PROJECT_USER_FOLLOWS = "/project/{0}/user/{1}/follows",
  PROJECT_ACTIVITY = "/project/{0}/activity",
  PROJECT_BOOKMARKS = "/project/{0}/bookmarks",
  PROJECT_LEADERBOARD = "/project/{0}/leaderboard",
  PROJECT_REFER_A_FRIEND = "/project/{0}/refer",
  PROJECT_NOTIFICATIONS = "/project/{0}/notifications",
  PROJECT_INBOX = "/project/{0}/inbox",
  PROJECT_PROFILE = "/project/{0}/profile",
  PROJECT_REWARDS = "/project/{0}/rewards",
  PROJECT_TEAMS = "/project/{0}/teams",
  PROJECT_TEAM_CREATE = "/project/{0}/teams/create",
  PROJECT_SEARCH = "/project/{0}?search={1}",
  PROJECT_USER = "/project/{0}/user/{1}",
  PROJECT_USERS = "/project/{0}/users",
  PROJECT_LOGIN_HOME = "/project/{0}/loginhome",
  PROJECT_LOGIN = "/project/{0}/login",
  PROJECT_POST_LOGIN_INTERCEPT = "/project/{0}/postloginintercept",
  PROJECT_LOGIN_SSO = "/project/{0}/loginsso",
  PROJECT_FRAME_LOGIN_SUCCESS = "/project/{0}/frameloginsuccess",
  PROJECT_SSO_LOGIN_SUCCESS = "/project/{0}/ssologinsuccess",
  PROJECT_REGISTER = "/project/{0}/register",
  PROJECT_RESET_PASSWORD = "/project/{0}/resetpassword",
  PROJECT_SETTINGS = "/project/{0}/settings",
  PROJECT_TEAM = "/project/{0}/team/{1}",
  PROJECT_TEAM_INVITE = "/project/{0}/team/{1}/invite",
  PROJECT_TEAM_UPDATE = "/project/{0}/team/{1}/update",
  PROJECT_ITEM = "/project/{0}/item/{1}",
  PROJECT_FRAME = "/project/{0}/frame?url={1}";

/* Pseudo-links */
let LOGOUT = "/logout",
  PROJECT_LOGOUT = "/project/{0}/logout",
  PROJECT_SEARCH_CHALLENGES = "/project/{0}/searchchallenges",
  PROJECT_SELECT_CATEGORY = "/project/{0}/selectcategory",
  TOPIC_SELECT_CATEGORY_NO_ID =
    "/topic/selectcategory" /* No ID variation, in case Topic ID is not populated quickly from props/Redux */,
  PROJECT_SHARE = "/project/{0}/share";

/* Multiple-Project Apps with disabled Project-specific Account pages */
if (!SINGLE_PROJECT_APP && !ENABLE_PROJECT_SPECIFIC_ACCOUNT_PAGES) {
  PROJECT_LOGIN_HOME = "/loginhome";
  PROJECT_LOGIN = "/login";
  PROJECT_POST_LOGIN_INTERCEPT = "/postloginintercept";
  PROJECT_LOGIN_SSO = "/loginsso";
  PROJECT_FRAME_LOGIN_SUCCESS = "/frameloginsuccess";
  PROJECT_SSO_LOGIN_SUCCESS = "/ssologinsuccess";
  PROJECT_REGISTER = "/register";
  PROJECT_RESET_PASSWORD = "/resetpassword";
  PROJECT_SETTINGS = "/settings";
  PROJECT_FRAME = "/frame?url={1}";

  /* Pseudo-links */
  PROJECT_LOGOUT = "/logout";
  PROJECT_SEARCH_CHALLENGES = "/searchchallenges";
  PROJECT_SELECT_CATEGORY = "/selectcategory";
  PROJECT_SHARE = "/share";
}

/* Single-Project App */
// routes specifically use {1} to maintain ease of use in the application
// the first argument supplied in format will be ignored if {0} is not in string
if (SINGLE_PROJECT_APP) {
  PROJECT_HOME = "/home";
  TOPIC_CATEGORY = "/category/{1}";
  PROJECT_ACHIEVEMENTS = "/achievements";
  PROJECT_USER_ACHIEVEMENTS = "/user/{1}/achievements";
  PROJECT_USER_FOLLOWS = "/user/{1}/follows";
  PROJECT_USER_ENQUIRY = "/user/{1}/enquiry";
  PROJECT_ACTIVITY = "/activity";
  PROJECT_BOOKMARKS = "/bookmarks";
  PROJECT_LEADERBOARD = "/leaderboard";
  PROJECT_REFER_A_FRIEND = "/refer";
  PROJECT_NOTIFICATIONS = "/notifications";
  PROJECT_INBOX = "/inbox";
  PROJECT_PROFILE = "/profile";
  PROJECT_REWARDS = "/rewards";
  PROJECT_TEAMS = "/teams";
  PROJECT_TEAM_CREATE = "/teams/create";
  PROJECT_TEAM_INVITE = "/team/{1}/invite";
  PROJECT_TEAM_UPDATE = "/team/{1}/update";
  PROJECT_SEARCH = "?search={1}";
  PROJECT_USER = "/user/{1}";
  PROJECT_USERS = "/users";
  PROJECT_LOGIN_HOME = "/loginhome";
  PROJECT_LOGIN = "/login";
  PROJECT_POST_LOGIN_INTERCEPT = "/postloginintercept";
  PROJECT_LOGIN_SSO = "/loginsso";
  PROJECT_FRAME_LOGIN_SUCCESS = "/frameloginsuccess";
  PROJECT_SSO_LOGIN_SUCCESS = "/ssologinsuccess";
  PROJECT_REGISTER = "/register";
  PROJECT_RESET_PASSWORD = "/resetpassword";
  PROJECT_SETTINGS = "/settings";
  PROJECT_TEAM = "/team/{1}";
  PROJECT_TEAM_INVITE = "/team/{1}/invite";
  PROJECT_TEAM_UPDATE = "/team/{1}/update";
  PROJECT_ITEM = "/item/{0}";
  PROJECT_FRAME = "/frame?url={1}";

  /* Pseudo-links */
  PROJECT_LOGOUT = "/logout";
  PROJECT_SEARCH_CHALLENGES = "/searchchallenges";
  PROJECT_SELECT_CATEGORY = "/selectcategory";
  PROJECT_SHARE = "/share";
}

export {
  HOME,
  LOGIN_HOME,
  LOGIN,
  POST_LOGIN_INTERCEPT,
  LOGIN_SSO,
  LOGIN_TOUR,
  FRAME_LOGIN_SUCCESS,
  SSO_LOGIN_SUCCESS,
  PROJECT_LIST,
  PROFILE,
  USER,
  USER_FOLLOWS,
  USER_ACHIEVEMENTS,
  ACHIEVEMENTS,
  BOOKMARKS,
  NOTIFICATIONS,
  INBOX,
  ITEM,
  TEAM,
  TEAM_INVITE,
  TEAM_UPDATE,
  ERROR,
  UPDATE_DEVICE_TOKEN,
  SETTINGS,
  REGISTER,
  RESET_PASSWORD,
  PROJECT_HOME,
  TOPIC_CATEGORY,
  TOPIC,
  CHALLENGE_CATEGORY,
  TOPIC_COMMENTS,
  TOPIC_COMMENTS_THREAD,
  CHALLENGE,
  CHALLENGE_A_FRIEND,
  CHALLENGE_ACTIVITY,
  CHALLENGE_MY_ACTIVITY,
  CHALLENGE_COMMENTS,
  CHALLENGE_COMMENTS_THREAD,
  CLAIM,
  CLAIM_COMMENTS,
  CLAIM_COMMENTS_THREAD,
  FRAME,
  PROJECT_ACHIEVEMENTS,
  PROJECT_USER_ACHIEVEMENTS,
  PROJECT_USER_FOLLOWS,
  PROJECT_ACTIVITY,
  PROJECT_BOOKMARKS,
  PROJECT_LEADERBOARD,
  PROJECT_REFER_A_FRIEND,
  PROJECT_NOTIFICATIONS,
  PROJECT_INBOX,
  PROJECT_PROFILE,
  PROJECT_REWARDS,
  PROJECT_TEAMS,
  PROJECT_TEAM_CREATE,
  PROJECT_SEARCH,
  PROJECT_USER,
  PROJECT_USERS,
  PROJECT_LOGIN_HOME,
  PROJECT_LOGIN,
  PROJECT_POST_LOGIN_INTERCEPT,
  PROJECT_LOGIN_SSO,
  PROJECT_FRAME_LOGIN_SUCCESS,
  PROJECT_SSO_LOGIN_SUCCESS,
  PROJECT_REGISTER,
  PROJECT_RESET_PASSWORD,
  PROJECT_SETTINGS,
  PROJECT_TEAM,
  PROJECT_TEAM_INVITE,
  PROJECT_TEAM_UPDATE,
  PROJECT_ITEM,
  PROJECT_FRAME,
  /* Enquiry */
  CHALLENGE_ENQUIRY,
  PROJECT_USER_ENQUIRY,
  CLAIM_ENQUIRY,
  /* Pseudo-links */
  LOGOUT,
  PROJECT_LOGOUT,
  PROJECT_SEARCH_CHALLENGES,
  PROJECT_SELECT_CATEGORY,
  TOPIC_SELECT_CATEGORY_NO_ID,
  PROJECT_SHARE
};

/* For Gametize app layout */
let FEATURED_PROJECTS = "/featured",
  SEARCH_PROJECTS = "/searchprojects?search={0}",
  MY_PROJECTS = "/myprojects",
  GAME = "/game/{0}",
  /* Pseudo-links */
  PROJECT_REMOVE_GAME = "/project/{0}/removegame";

export {
  FEATURED_PROJECTS,
  SEARCH_PROJECTS,
  MY_PROJECTS,
  GAME,
  PROJECT_REMOVE_GAME
};
