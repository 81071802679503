import React, { Component } from "react";
import PropTypes from "prop-types";

import ChallengeCardContainer from "components/Topic/ChallengeCardContainer";
import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";

import { getChallengeType, getPoints } from "services/challengeServices";
import Loading from "components/shared/Loading";
// import localize from "lang/localize";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  bookmarks: PropTypes.array.isRequired,
  more: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  showBookmarkLoginDialog: PropTypes.bool.isRequired,
  handleOpenBookmarkLoginDialog: PropTypes.func.isRequired,
  handleCloseBookmarkLoginDialog: PropTypes.func.isRequired,
  showLikeLoginDialog: PropTypes.bool.isRequired,
  handleOpenLikeLoginDialog: PropTypes.func.isRequired,
  handleCloseLikeLoginDialog: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  language: PropTypes.string,
  contactEmail: PropTypes.string
};

class BookmarksPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("bookmarksPage")) {
      bodyDOM.className += " bookmarksPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("bookmarksPage")) {
      bodyDOM.classList.remove("bookmarksPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  getUnlocked(unlockable, locked) {
    return !unlockable || locked === false;
  }

  renderBookmarks(bookmarks) {
    return (
      <div>
        {bookmarks
          .filter(bookmark => bookmark.bookmarked)
          .map((bookmark, index) => (
            <div className="pure-u-1 pure-u-md-12-24" key={bookmark.id}>
              <div className="pure-u-sm-4-24 pure-u-md-1-24" />
              <div className="pure-u-sm-16-24 pure-u-md-22-24">
                <ChallengeCardContainer
                  id={bookmark.id}
                  unlocked={this.getUnlocked(
                    bookmark.unlockable,
                    bookmark.locked
                  )}
                  title={bookmark.title}
                  img={bookmark.imageSmall}
                  challengeType={getChallengeType(
                    bookmark.challengeTypeId,
                    bookmark.photoOnly,
                    bookmark.multiSelect,
                    bookmark.challengeType
                  )}
                  challengeTypeId={bookmark.challengeTypeId}
                  points={getPoints(bookmark.challengeTypeId, bookmark.points)}
                  challengeReferralLink={bookmark.challengeReferralLink}
                  isFlashcard={bookmark.challengeTypeId === 4}
                  likeNo={bookmark.likeNo}
                  completedNo={bookmark.claimNo}
                  isLiked={!!bookmark.ratedLike}
                  isBookmarked={!!bookmark.bookmarked}
                  isCompleted={!!bookmark.claimed}
                  isExpired={!!bookmark.expired}
                  handleLike={event =>
                    this.props.handleLike(event, bookmark.id)
                  }
                  handleBookmark={event =>
                    this.props.handleBookmark(
                      event,
                      bookmark.id,
                      bookmark.bookmarked
                    )
                  }
                  handleOpenBookmarkLoginDialog={
                    this.props.handleOpenBookmarkLoginDialog
                  }
                  handleOpenLikeLoginDialog={
                    this.props.handleOpenLikeLoginDialog
                  }
                  isLoggedIn={this.props.isLoggedIn}
                  language={this.props.language}
                  contactEmail={this.props.contactEmail}
                />
              </div>
              <div className="pure-u-sm-4-24 pure-u-md-1-24" />
            </div>
          ))}
      </div>
    );
  }

  renderBookmarkLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showBookmarkLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseBookmarkLoginDialog}
        type="bookmark"
      />
    );
  }

  renderLikeLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showLikeLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseLikeLoginDialog}
        type="like"
      />
    );
  }

  render() {
    return (
      <div className="container verticalpadding bottompadding-floating">
        <div className="innerblock">
          {this.renderBookmarks(this.props.bookmarks)}
          {this.props.isLoadingMore ? <Loading /> : null}
          {/* {this.props.more ? (
            <button
              className="button automargin"
              onClick={this.props.handleMore}
            >
              {localize("more_text", this.props.language)}
            </button>
          ) : null} */}
          {this.props.showBookmarkLoginDialog &&
            this.renderBookmarkLoginDialog()}
          {this.props.showLikeLoginDialog && this.renderLikeLoginDialog()}
        </div>
      </div>
    );
  }
}

BookmarksPage.propTypes = propTypes;

export default BookmarksPage;
