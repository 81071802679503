import React, { Component } from "react";
import PropTypes from "prop-types";

import { TEAM, PROJECT_TEAM } from "App/Routes";
import placeholder_team from "images/core/placeholders/placeholder_team.jpg";
import Link from "components/shared/Link/Link";
import localize from "lang/localize";

import { SINGLE_PROJECT_APP } from "config";

const propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  userNo: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  user: PropTypes.object,
  language: PropTypes.string,
  projectId: PropTypes.number,
  joined: PropTypes.bool.isRequired
};

const defaultProps = {
  img: placeholder_team
};

/**
 * Individual Team Object in Team List
 */
class Team extends Component {
  /**
   * Render user's profile image to represent team membership
   */
  renderUserImage() {
    if (this.props.joined) {
      return (
        <div className="team-current-user flex align-items-center justify-content-center topmargin-20">
          <span className="team-current-user-image square-image-wrapper ">
            <span className="square-image circle">
              <img
                src={this.props.user.photoLarge}
                alt={this.props.user.name}
              />
            </span>
          </span>
          {localize("status_team_membership", this.props.language)}
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Object render
   */
  render() {
    let img = this.props.img;
    let name = this.props.name;

    return (
      <div className="team-card-container">
        <Link
          to={
            this.props.projectId && !SINGLE_PROJECT_APP
              ? PROJECT_TEAM.format(this.props.projectId, this.props.id)
              : TEAM.format(this.props.id)
          }
          className="team-card team-card-bg pure-g"
        >
          <div className="flex justify-content-center bottommargin-10">
            <span className="square-image-wrapper">
              <span className="square-image circle">
                <img src={img} alt={name} />
              </span>
            </span>
          </div>
          <h6 className="title text-align-center">
            <div dangerouslySetInnerHTML={{ __html: name }} />
          </h6>
          {this.renderUserImage()}
        </Link>
      </div>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
