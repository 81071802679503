import React, { Component } from "react";
import PropTypes from "prop-types";

import CalendarDialog from "./CalendarDialog/CalendarDialog";

import localize from "lang/localize";

const propTypes = {
  onMFChangeDate: PropTypes.func.isRequired,
  keyIndex: PropTypes.number.isRequired,
  field: PropTypes.object,
  disabled: PropTypes.bool,
  handleDisabledClick: PropTypes.func,
  language: PropTypes.string
};

const defaultProps = {
  disabled: false
};

class DateInput extends Component {
  constructor() {
    super();

    this.state = {
      showDialog: false
    };

    this.handleOpenCalendarDialog = this.handleOpenCalendarDialog.bind(this);
    this.handleCloseCalendarDialog = this.handleCloseCalendarDialog.bind(this);
  }

  handleOpenCalendarDialog(e) {
    e && e.preventDefault();
    this.setState({
      showDialog: true
    });
  }

  handleCloseCalendarDialog() {
    this.setState({
      showDialog: false
    });
  }

  renderCalendarDialog(beginDate = null, endDate = null) {
    return (
      <CalendarDialog
        showModal={this.state.showDialog}
        handleCloseCalendarDialog={this.handleCloseCalendarDialog}
        onMFChangeDate={this.props.onMFChangeDate}
        beginDate={beginDate}
        endDate={endDate}
        keyIndex={this.props.keyIndex}
      />
    );
  }

  render() {
    return (
      <div>
        <button
          className={
            "button fullwidth dateinput" +
            (this.props.disabled ? " disabled cursor-not-allowed" : "")
          }
          onClick={
            this.props.disabled
              ? this.props.handleDisabledClick
              : this.handleOpenCalendarDialog
          }
        >
          {this.props.field.value ||
            localize("claim_calendar_placeholder", this.props.language)}
        </button>
        {this.state.showDialog &&
          this.renderCalendarDialog(
            this.props.field.beginDate,
            this.props.field.endDate
          )}
      </div>
    );
  }
}

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default DateInput;
