import React from "react";

import tour_screen_01 from "images/custom/tour/tour1.png";
import tour_screen_02 from "images/custom/tour/tour2.png";
import tour_screen_03 from "images/custom/tour/tour3.png";

import TourSlider from "components/shared/Tour/TourSlider";
import "./TourSlider.css";

import Slider from "react-slick";

class ASMTourSlider extends TourSlider {
  /**
   * Override for core render()
   */
  render() {
    const SETTINGS = this.getSliderSettings();
    /* No API support for tour screens yet, fall back to legacy tour screens implementation */
    return this.renderLegacyTourScreens(this, SETTINGS);
  }

  /**
   * Support for older tour screens
   *
   * Customization note for apps:
   * This method should be overwritten in a custom component to support older apps
   */
  renderLegacyTourScreens(slider, settings) {
    // --- Code example to render legacy tour screens ---
    // Define the number of slides here
    slider.slidesLength = 3;

    // Copy all code from <Slider> </Slider> from legacy implementations into here:
    // Ensure there are no traces of this.props.projectId because tour screens are now app-centric
    return (
      <Slider {...settings}>
        <div>
          <div className="pure-g bottommargin">
            <div className="pure-u-1-4 pure-u-md-1-3" />
            <div className="pure-u-1-2 pure-u-md-1-3">
              <img
                src={tour_screen_01}
                alt="We need you!"
                className="imgresponsive"
              />
            </div>
            <div className="pure-u-1-4 pure-u-md-1-3" />
          </div>
          <div className="pure-g">
            <div className="pure-u-1-12" />
            <div className="pure-u-5-6 asm-grey-overlay asm-overlay-desktop">
              <h3 className="textcenter">We need you!</h3>
              <p className="textcenter">
                Hackers are trying to breach our security system to access IP
                protected material. Our security system has gone on lock-down to
                prevent them.
              </p>
            </div>
            <div className="pure-u-1-12" />
          </div>
        </div>
        <div>
          <div className="pure-g bottommargin">
            <div className="pure-u-1-4 pure-u-md-1-3" />
            <div className="pure-u-1-2 pure-u-md-1-3">
              <img
                src={tour_screen_02}
                alt="Complete challenges and collect rewards"
                className="imgresponsive"
              />
            </div>
            <div className="pure-u-1-4 pure-u-md-1-3" />
          </div>
          <div className="pure-g">
            <div className="pure-u-1-12" />
            <div className="pure-u-5-6 asm-grey-overlay asm-overlay-desktop">
              <h3 className="textcenter">
                Complete challenges and collect rewards
              </h3>
              <p className="textcenter">
                Your mission is to complete the challenges presented here and
                help secure our intellectual property.
              </p>
            </div>
            <div className="pure-u-1-12" />
          </div>
        </div>
        <div>
          <div className="pure-g bottommargin">
            <div className="pure-u-1-4 pure-u-md-1-3" />
            <div className="pure-u-1-2 pure-u-md-1-3">
              <img
                src={tour_screen_03}
                alt="Find the hacker"
                className="imgresponsive"
              />
            </div>
            <div className="pure-u-1-4 pure-u-md-1-3" />
          </div>
          <div className="pure-g">
            <div className="pure-u-1-12" />
            <div className="pure-u-5-6 asm-grey-overlay asm-overlay-desktop">
              <h3 className="textcenter">Find the hacker</h3>
              <p className="textcenter">
                Hidden amongst the people on the leaderboard is the hacker.
                Prove you are a true ASMer to complete this challenge and save
                ASM's IP. Good luck!
              </p>
            </div>
            <div className="pure-u-1-12" />
          </div>
        </div>
      </Slider>
    );

    // If no legacy tour screens defined, redirect to main screen
    //slider.props.handleCloseClick();
    //return null;
  }
}

export default ASMTourSlider;
