import sessionStorageService from "./sessionStorageService";

const popupStorage = {
  initialize: function(projectId) {
    try {
      let popupState = [{ projectId: projectId, popupShown: false }];
      sessionStorageService.setItem("popupStates", JSON.stringify(popupState));
    } catch (err) {
      return undefined;
    }
  },

  checkIdExist: function(projectId) {
    try {
      let popupStatesArr = JSON.parse(
        sessionStorageService.getItem("popupStates") || "[]"
      );
      let idExists = popupStatesArr.some(function(element) {
        return projectId === element.projectId;
      });
      return idExists;
    } catch (err) {
      return undefined;
    }
  },

  addId: function(projectId) {
    try {
      let popupStatesArr = JSON.parse(
        sessionStorageService.getItem("popupStates") || "[]"
      );
      let popupState = { projectId: projectId, popupShown: false };
      popupStatesArr.push(popupState);
      sessionStorageService.setItem(
        "popupStates",
        JSON.stringify(popupStatesArr)
      );
    } catch (err) {
      return undefined;
    }
  },

  checkPopupShown: function(projectId) {
    try {
      let popupStatesArr = JSON.parse(
        sessionStorageService.getItem("popupStates") || "[]"
      );

      let popupShown = true;

      popupStatesArr.forEach(function(element) {
        if (element.projectId === projectId) {
          popupShown = element.popupShown;
        }
      });

      return popupShown;
    } catch (err) {
      return undefined;
    }
  },

  clearAll: function() {
    try {
      sessionStorageService.removeItem("popupStates");
    } catch (err) {
      return undefined;
    }
  },

  setHidePopup: function(projectId) {
    try {
      let arrIndex;
      let popupStatesArr = JSON.parse(
        sessionStorageService.getItem("popupStates") || "[]"
      );

      /* identify index of array with project ID */
      popupStatesArr.forEach(function(element, index) {
        if (element.projectId === projectId) {
          arrIndex = index;
        }
      });

      if (typeof arrIndex === "number") {
        popupStatesArr[arrIndex] = { projectId: projectId, popupShown: true };
        sessionStorageService.setItem(
          "popupStates",
          JSON.stringify(popupStatesArr)
        );
      }
    } catch (err) {
      return undefined;
    }
  }
};

export default popupStorage;
