import { connect } from "react-redux";

import getApiGenerator from "services/getApiGenerator";
import { GET_CHALLENGES } from "services/api";
import {
  TopicContainer,
  mapStateToProps,
  mapDispatchToProps
} from "components/Topic/TopicContainer";

import localize from "lang/localize";

export class ASMTopicContainer extends TopicContainer {
  getChallenges(page) {
    getApiGenerator(
      GET_CHALLENGES.format(this.state.id),
      {
        category_id: this.props.categoryId,
        page: page,
        /* CUSTOM FOR ASM (backend only allows max of 50, 0 and larger numbers will result in default of 10) */
        limit: 50
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body && res.body.game) {
          this.setState(
            {
              challenges: [],
              errorProject: res.body.game,
              isLoadingMore: false
            },
            () => {
              this.props.setProject(this.state.errorProject);
            }
          );
        } else {
          this.setState({ challenges: [], isLoadingMore: false });
        }
      } else {
        const CATEGORIES = res.body.categories
          ? res.body.categories.map(category => ({
              id: category.id,
              title: category.name,
              selected: category.selected,
              description: category.description,
              image: category.image
            }))
          : null;

        const challenges = this.state.challenges
          ? this.state.challenges.slice()
          : [];

        let compiledTopic = CATEGORIES
          ? Object.assign({}, res.body.quest, { categories: CATEGORIES })
          : res.body.quest;

        this.setState({
          project: res.body.game,
          topic: res.body.quest,
          achievements: res.body.wins ? res.body.wins : [],
          challenges: challenges.concat(res.body.data),
          more: res.body.more,
          page: this.state.page + 1,
          contactEmail: res.body.game.contactEmail,
          topicIdNext: res.body.topicIdNext,
          isLoadingMore: false
        });
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        this.props.setTopic(compiledTopic);

        if (CATEGORIES) {
          this.props.setChallengeCategory(
            CATEGORIES.filter(category => category.selected === true)[0]
          );
        }
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  componentDidMount() {
    /* Use hashing to show success alert for reset activity */
    if (window.location.hash.slice(0, 14) === "#resettedtopic") {
      this.props.showAlertWithTimeout({
        text: localize("alert_reset_activity_success", this.props.language),
        type: "success"
      });
    }
    this.getChallenges(this.state.page);
    /*CUSTOM FOR ASM: Do not activate scroll support*/
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ASMTopicContainer);
