import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

const defaultProps = {
  disabled: false,
  className: ""
};

const SelectInput = props => {
  let placeholderText = props.placeholder;

  return (
    <select
      name={props.name}
      value={props.selectedOption}
      onChange={props.onChange}
      disabled={props.disabled}
      className={(props.disabled ? "pseudo-readonly " : "") + props.className}
    >
      <option value="">{placeholderText}</option>
      {props.options.map(opt => {
        let optTitle = opt.title;

        return (
          <option key={opt.id} value={opt.id}>
            {optTitle}
          </option>
        );
      })}
    </select>
  );
};

SelectInput.propTypes = propTypes;
SelectInput.defaultProps = defaultProps;

export default SelectInput;
