import React, { Component } from "react";
import PropTypes from "prop-types";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "./ImageDialog.css";

import Modal from "react-modal";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseImageDialog: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Pop-up Modal to render a single image
 */
class ImageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal
    };
  }

  render() {
    let img = this.props.img;

    return (
      <Modal
        isOpen={this.state.showModal}
        contentLabel=""
        onRequestClose={this.props.handleCloseImageDialog}
        shouldCloseOnOverlayClick={true}
        className="dialog image-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-body">
            <button
              onClick={() => {
                this.props.handleCloseImageDialog();
              }}
              className="close-button-container"
            >
              <i className="fa close-button" aria-hidden="true" />
            </button>
            <TransformWrapper>
              {({ zoomIn, zoomOut }) => (
                <React.Fragment>
                  <div className="tools">
                    <button onClick={() => zoomIn()}>
                      <i className="fas fa-search-plus"></i>
                    </button>
                    <button onClick={() => zoomOut()}>
                      <i className="fas fa-search-minus"></i>
                    </button>
                  </div>
                  <TransformComponent>
                    <span className="square-image">
                      <img src={img} alt="" />
                    </span>
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
      </Modal>
    );
  }
}

ImageDialog.propTypes = propTypes;

export default ImageDialog;
