import React from "react";
import "./Loading.css";

const Loading = props =>
  <div className="loading">
    <div className="loading__dots">
      <div className="loading__dots__dot" />
      <div className="loading__dots__dot" />
      <div className="loading__dots__dot" />
    </div>
  </div>;

export default Loading;
