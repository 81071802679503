import React from "react";

import "./Profile.css";
import ProfilePage from "components/Profile/ProfilePage";
import ClaimResultContainer from "components/Project/Activity/ClaimResultContainer";
import Claim from "components/Project/Activity/Claim";
import Link from "components/shared/Link/Link";
import Loading from "components/shared/Loading";

import { getChallengeType } from "services/challengeServices";
import { PROJECT_REWARDS } from "App/Routes";
import localize from "lang/localize";

class ASMProfilePage extends ProfilePage {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("userProfilePage")) {
      bodyDOM.className += " userProfilePage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }

    if (!bodyDOM.classList.contains("asmUserProfilePage")) {
      bodyDOM.className += " asmUserProfilePage";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("userProfilePage")) {
      bodyDOM.classList.remove("userProfilePage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }

    if (bodyDOM.classList.contains("asmUserProfilePage")) {
      bodyDOM.classList.remove("asmUserProfilePage");
    }
  }

  renderProfile() {
    let img = this.props.img;
    let name = this.props.name;

    return (
      <div className="asm-user-profile-info pure-g">
        <div className="asm-user-profile-photo">
          <div
            className="card-img"
            style={{
              backgroundImage: "url(" + img + ")"
            }}
          ></div>
        </div>
        <div className="asm-user-profile-stats asm-user-profile-section">
          <div className="asm-user-profile-section-header">User Details</div>
          <div className="asm-user-profile-section-content">
            <div className="asm-user-profile-dataset">
              <span className="asm-user-profile-field">Name:</span>
              <span className="asm-user-profile-data">{name}</span>
            </div>
            <div className="asm-user-profile-dataset">
              <span className="asm-user-profile-field">
                {localize("points_just_text", this.props.language)}:
              </span>
              <span className="asm-user-profile-data">{this.props.points}</span>
            </div>
            <div className="asm-user-profile-dataset">
              <span className="asm-user-profile-field">
                {localize("completions_text", this.props.language)}:
              </span>
              <span className="asm-user-profile-data">
                {this.props.completions}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderProfileMobile() {
    let img = this.props.img;
    let name = this.props.name;

    return (
      <div className="asm-user-profile-info">
        <div className="asm-user-profile-stats asm-user-profile-section">
          <div className="asm-user-profile-section-header">User Details</div>
          <div className="asm-user-profile-section-content">
            <div className="asm-user-profile-photo">
              <div
                className="card-img"
                style={{
                  backgroundImage: "url(" + img + ")"
                }}
              ></div>
            </div>
            <div className="asm-user-profile-dataset-mobile">
              <div className="asm-user-profile-dataset">
                <span className="asm-user-profile-data">{name}</span>
              </div>
              <div className="asm-user-profile-dataset">
                <span className="asm-user-profile-data">
                  {this.props.points}
                </span>
                <span className="asm-user-profile-field">
                  {localize("points_just_text", this.props.language)}
                </span>
              </div>
              <div className="asm-user-profile-dataset">
                <span className="asm-user-profile-data">
                  {this.props.completions}
                </span>
                <span className="asm-user-profile-field">
                  {localize("completions_text", this.props.language)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAchievements() {
    const items = this.props.items;

    if (this.props.itemsLoading) {
      return (
        <div id="profileAchievements" className="asm-user-profile-achievements">
          <div className="asm-user-profile-section">
            <div className="asm-user-profile-section-header">Achievements</div>
            <div className="asm-user-profile-section-content">
              <Loading />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="profileAchievements" className="asm-user-profile-achievements">
          <div className="asm-user-profile-section">
            <div className="asm-user-profile-section-header">Achievements</div>
            <div className="asm-user-profile-section-content">
              <div className="asm-user-profile-achievement-list">
                {items && items.length > 0 ? (
                  <Link to={this.props.navLink}>
                    {items.map(item => {
                      let imageSmall = item.imageSmall;

                      return (
                        <div
                          className="pure-u-md-1-4 innerpadding asm-user-profile-achievement"
                          key={item.id}
                        >
                          <span className="square-image-wrapper">
                            <span className="square-image circle">
                              <img src={imageSmall} alt="" />
                              {!item.earned && (
                                <div className="lockoverlay circle" />
                              )}
                            </span>
                          </span>
                        </div>
                      );
                    })}
                  </Link>
                ) : (
                  <h6 className="fadeout">
                    {this.props.id === this.props.userId
                      ? localize("user_self_no_item_text", this.props.language)
                      : localize("user_no_item_text", this.props.language)}
                  </h6>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderActivity() {
    return (
      <div id="profileActivity" className="asm-user-profile-activity">
        <div className="asm-user-profile-section">
          <div className="asm-user-profile-section-header">Activity</div>
          <div className="asm-user-profile-section-content">
            <div className="asm-user-profile-activity-list">
              {this.props.claims && this.props.claims.length > 0 ? (
                this.props.claims.map((claim, index) => (
                  <div className="claimcard-line bottompadding" key={claim.id}>
                    <Claim
                      key={claim.id}
                      projectId={this.props.projectId}
                      claimUserId={claim.userId}
                      userId={this.props.userId}
                      userName={claim.userName}
                      userImage={claim.userPhotoSmall}
                      createdAtFormatted={claim.createdAtFormatted}
                      points={claim.points}
                      challengeId={claim.challenge.id}
                      challengeTitle={claim.challenge.title}
                      challengeType={getChallengeType(
                        claim.challenge.challengeTypeId,
                        claim.challenge.photoOnly,
                        claim.challenge.multiSelect,
                        claim.challenge.challengeType
                      )}
                      challengeTypeId={claim.challenge.challengeTypeId}
                      isConfirmationChallenge={
                        claim.challenge.challengeTypeId === 11 &&
                        claim.challenge.confirmation === true
                      }
                      correctAnswer={claim.correctAnswer}
                      challengePhotoOnly={claim.challenge.photoOnly}
                      privateClaim={claim.challenge.privateClaim}
                      language={this.props.language}
                    />
                    <ClaimResultContainer
                      id={claim.id}
                      claimUserId={claim.userId}
                      userId={this.props.userId}
                      type={this.props.type}
                      challengeTypeId={claim.challenge.challengeTypeId}
                      claimImage={claim.claimPhotoSmall}
                      entries={claim.entries}
                      claimMessage={claim.message}
                      likeNo={claim.likeNo}
                      commentNo={claim.commentNo}
                      ratedLike={claim.ratedLike}
                      handleLike={event =>
                        this.props.handleLike(event, claim.id)
                      }
                      correctAnswer={claim.correctAnswer}
                      removeClaim={this.props.removeClaim}
                      privateClaim={claim.challenge.privateClaim}
                      userName={claim.userName}
                      handleComments={this.props.handleComments}
                    />
                  </div>
                ))
              ) : (
                <h6 className="fadeout">
                  {localize("empty_claim_table_text", this.props.language)}
                </h6>
              )}
              {this.props.isLoadingMore ? <Loading /> : null}
              {this.props.more && (
                <button
                  className="button automargin"
                  onClick={this.props.handleMore}
                >
                  {localize("more_text", this.props.language)}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRewardStoreLink() {
    return (
      <div className="asm-user-profile-rewards">
        <div className="asm-user-profile-section">
          <div className="asm-user-profile-section-header">Reward Store</div>
          <div className="asm-user-profile-section-content">
            <div className="pure-g">
              <div className="pure-u-1-4">
                <div className="asm-user-profile-reward-icon">
                  <i className="fas fa-award"></i>
                </div>
              </div>
              <div className="pure-u-3-4">
                <div>Check out the latest rewards here.</div>

                <div className="asm-user-profile-reward-link">
                  <Link to={PROJECT_REWARDS}>
                    <button className="button automargin cta">Let's go!</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="profile">
        <div className="container">
          <div className="asm-user-profile profile-container pure-g hide-below-xl">
            <div className="pure-u-1-1 pure-u-md-17-24 asm-user-profile-left">
              {this.renderProfile()}
              {this.renderActivity()}
            </div>
            <div className="pure-u-1-1 pure-u-md-7-24 asm-user-profile-right">
              {this.renderAchievements()}
              {this.renderRewardStoreLink()}
            </div>
          </div>
          <div className="asm-user-profile profile-container pure-g hide-from-xl">
            <div className="asm-user-profile-mobile">
              {this.renderProfileMobile()}
              {this.renderActivity()}
              {this.renderAchievements()}
              {this.renderRewardStoreLink()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ASMProfilePage;
