import React, { Component } from "react";
import PropTypes from "prop-types";

const propTypes = {
  sessionKey: PropTypes.string,
  message: PropTypes.string
};

class UpdateDeviceTokenPage extends Component {
  setDisplayMessage() {
    if (!this.props.message) {
      return "Success!";
    } else {
      if (this.props.message.indexOf("update parameter") !== -1) {
        return "No update parameter is specified.";
      } else {
        return this.props.message;
      }
    }
  }

  render() {
    return (
      <div className="container nopadding">{this.setDisplayMessage()}</div>
    );
  }
}

UpdateDeviceTokenPage.propTypes = propTypes;

export default UpdateDeviceTokenPage;
