import React, { Component } from "react";
import { connect } from "react-redux";

//import RewardsPage from "./RewardsPage";
import RewardsPage from "custom-components/Project/Rewards/RewardsPage"; //CUSTOM FOR ASM
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import localize from "lang/localize";

import getApiGenerator from "services/getApiGenerator";
import pushApiGenerator from "services/pushApiGenerator";
import {
  GET_TOPICS,
  GET_REWARDS,
  GET_REDEEMED,
  PURCHASE_ITEM,
  COLLECT_ITEM
} from "services/api";
import {
  showAlertWithTimeout,
  setTopbar,
  setProfileDrawer,
  setProject,
  setButtons
} from "actions";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    setTopbar: info => {
      dispatch(setTopbar(info));
    },
    setProfileDrawer: info => {
      dispatch(setProfileDrawer(info));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

export class RewardsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: null,
      currentCategory: "",
      rewards: null,
      rewardsCategories: null,
      rewardsCategoryData: [],
      rewardDescriptionAfter: "",
      redeemed: null,
      points: 0,
      loggedIn: !!props.user.id,
      searchInput: "",
      beganSearch: false,
      topics: null,
      error: null,
      code: null
    };
  }

  componentDidMount() {
    // populate project in redux store
    if (this.props.projectId) {
      this.getProject();
    }

    this.getRewards();
    this.getRedeemed();
    this.setState({ current: "rewards" });
  }

  getProject() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            topics: [],
            code: 500,
            error: res.body.error
          });
        }
      } else {
        this.setState({
          topics: res.body.data,
          code: res.body.code,
          error: ""
        });

        const BUTTONS =
          res.body.buttons && res.body.buttons.length > 0
            ? res.body.buttons
            : null;

        this.props.setProject(res.body.game);
        this.props.setButtons(BUTTONS);
      }
    });
  }

  // pagination not supported
  getRewards(category_id = "", keywords = null) {
    getApiGenerator(
      GET_REWARDS.format(this.props.projectId),
      {
        category_id: category_id,
        keywords: keywords
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          if (category_id !== "") {
            this.setState({ rewardsCategoryData: [] });
          } else {
            this.setState({ rewards: [] });
          }
        }
      } else {
        const categories = res.body.categories
          ? res.body.categories.map(category => ({
              id: category.id,
              title: category.name,
              selected: category.selected
            }))
          : null;
        this.setState({
          rewardsCategories: categories,
          points: res.body.pointsAvailableInBundle
        });
        if (category_id === "") {
          this.setState({
            rewards: res.body.data
          });
        } else {
          this.setState({
            rewardsCategoryData: res.body.data
          });
        }
      }
    });
  }

  // pagination not supported
  getRedeemed() {
    getApiGenerator(
      GET_REDEEMED.format(this.props.user.id),
      {
        game_id: this.props.projectId
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({ redeemed: [] });
        }
      } else {
        this.setState({
          redeemed: res.body.data
        });
      }
    });
  }

  updateRewards(id) {
    const rewards = this.state.rewards;
    let reward = rewards.filter(reward => reward.id === id)[0];
    reward.ownedQuantity++;
    // eslint-disable-next-line
    reward.quantityAvailable ? reward.quantityAvailable-- : null;
    this.setState({ rewards: rewards });
  }

  handleToggle = event => {
    this.setState({ current: event.currentTarget.id });
    this.getRewards(this.state.currentCategory);
  };

  handleClick(event, id, optionId = 0) {
    if (this.state.current === "rewards") {
      this.setState({
        rewardDescriptionAfter: ""
      });
      this.handleRedeem(event, id, optionId);
    } else {
      this.handleCollect(event, id, optionId);
    }
  }

  handleRedeem(event, id, optionId = 0) {
    event.preventDefault();
    pushApiGenerator(
      PURCHASE_ITEM,
      {
        type_id: id,
        reward_option_id: optionId
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("buy_item_successful_text", this.props.language),
          type: "success"
        });
        this.setState({
          points: res.body.pointsAvailableInBundle,
          rewardDescriptionAfter: res.body.rewardDescriptionAfter
        });
        this.props.setTopbar({ points: res.body.pointsAvailableInBundle });
        this.updateRewards(id);
        this.getRedeemed();
      }
    });
  }

  handleCollect(event, id, optionId = 0) {
    event.preventDefault();
    getApiGenerator(
      COLLECT_ITEM,
      {
        type_id: id,
        rewardOptionId: optionId
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("redeem_item_successful_text", this.props.language),
          type: "success"
        });
        this.getRedeemed();
      }
    });
  }

  handleCategory(event) {
    this.setState({ currentCategory: event.target.value });
    this.getRewards(event.target.value);
  }

  handleSearchChange(event) {
    if (!this.state.beganSearch) {
      this.setState({ beganSearch: true });
    }

    this.setState({ searchInput: event.target.value }, () =>
      this.getRewards(this.state.currentCategory, this.state.searchInput)
    );
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    }
    // if rewards are returned
    // OR there is at least 1 reward in the list, but can't find searched rewards
    else if (
      (this.state.rewards && this.state.rewards.length !== 0) ||
      (this.state.rewards && this.state.beganSearch)
    ) {
      return (
        <RewardsPage
          current={this.state.current}
          handleToggle={this.handleToggle}
          categories={this.state.rewardsCategories}
          points={this.state.points}
          onCategoryChange={this.handleCategory.bind(this)}
          rewards={
            this.state.currentCategory === ""
              ? this.state.rewards
              : this.state.rewardsCategoryData
          }
          rewardDescriptionAfter={this.state.rewardDescriptionAfter}
          redeemed={this.state.redeemed}
          handleClick={this.handleClick.bind(this)}
          loggedIn={this.state.loggedIn}
          handleSearchChange={this.handleSearchChange.bind(this)}
          handleSubmit={this.handleSubmit.bind(this)}
          language={this.props.language}
          projectId={this.props.projectId}
          showAlertWithTimeout={this.props.showAlertWithTimeout}
        />
      );
    } else if (this.state.rewards) {
      return (
        <GenericErrorPage
          message={localize("empty_store_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardsContainer);
