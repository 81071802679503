import React, { Component, Fragment } from "react";
import { LoginDialogContainer } from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import QRModal from "components/Challenge/ClaimForm/QRModal/QRModal";
import pushApiGenerator from "services/pushApiGenerator";
import { POST_CLAIM } from "services/api";
import { connect } from "react-redux";
import {
  addProfileDrawerPoints,
  addTopbarPoints,
  showAlertWithTimeout,
  showChallengeCompleted,
  setShowQRModal
} from "actions";

export const mapStateToProps = state => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    showQRModal: state.floatingButtons.showQRModal
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    },
    addTopbarPoints: points => {
      dispatch(addTopbarPoints(points));
    },
    addProfileDrawerPoints: points => {
      dispatch(addProfileDrawerPoints(points));
    },
    showChallengeCompleted: challengeCompleted => {
      dispatch(showChallengeCompleted(challengeCompleted));
    },
    setShowQRModal: isShow => {
      dispatch(setShowQRModal(isShow));
    }
  };
};

class QRChallengeModalProjectHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClaimLoginDialog: false
    };
    this.handleCloseQRModal = this.handleCloseQRModal.bind(this);
    this.handleOpenClaimLoginDialog = this.handleOpenClaimLoginDialog.bind(
      this
    );
    this.handleCloseClaimLoginDialog = this.handleCloseClaimLoginDialog.bind(
      this
    );
  }
  postQRClaim(qrCode) {
    pushApiGenerator(
      POST_CLAIM,
      {
        comment: qrCode
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.handleCloseQRModal();
        this.props.showAlertWithTimeout({
          text: res.body.error,
          type: "error"
        });
      } else {
        this.props.addTopbarPoints(res.body.points);
        this.props.addProfileDrawerPoints(res.body.points);
        this.handleCloseQRModal();
        this.props.showChallengeCompleted(res.body);
      }
    });
  }

  handleCloseQRModal() {
    this.props.setShowQRModal(false);
  }

  handleOpenClaimLoginDialog() {
    this.setState({ showClaimLoginDialog: true });
  }

  handleCloseClaimLoginDialog() {
    this.setState({ showClaimLoginDialog: false });
  }

  renderClaimLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.state.showClaimLoginDialog}
        handleCloseLoginDialog={this.handleCloseClaimLoginDialog}
        type="claim"
      />
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderClaimLoginDialog()}
        <QRModal
          showQRModal={this.props.showQRModal}
          handleCloseQRModal={this.handleCloseQRModal}
          handleSubmit={this.postQRClaim.bind(this)}
          language={this.props.language}
        />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QRChallengeModalProjectHome);
