// eslint-disable-next-line
Number.prototype.pluralize = function(singleStr, pluralStr) {
  if (this.valueOf() === 1) {
    return singleStr.format(this);
  } else {
    return pluralStr.format(this);
  }
};

// eslint-disable-next-line
Number.prototype.abbreviateNumber = function() {
  let sigFigures = 3;

  // use (sigFigures + 1) to allow for better rounding
  // 2 decimal places => 100, 3 => 1000, etc
  let decPlaces = Math.pow(10, sigFigures + 1);

  let number = this;

  // Enumerate number abbreviations
  let abbrev = ["K", "M", "B", "T"];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    let size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round(number * decPlaces / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // turn number into string
      number = number.toString();

      // if decimal points exist
      if (number.indexOf(".") !== -1) {
        // get index
        let index = number.indexOf(".");

        // strip decimal dots
        number = number.split(".").join("");

        // determine number of times to divide by 10
        let count = number.length - sigFigures;

        for (let j = 0; j < count; j++) {
          // parseInt(number) to turn number string to number
          // parseInt(division) to remove decimal places
          let stringToNum = parseInt(number, 10);
          number = parseInt(stringToNum / 10, 10);
        }

        // verify the decimal numbers in string
        let finalDecimalStr = number.toString().slice(index);

        let dupFinalDecimalStr = finalDecimalStr;

        // slice trailing zeroes
        for (let k = dupFinalDecimalStr.length - 1; k >= 0; k--) {
          let comparedString = "0";
          let character = dupFinalDecimalStr.charAt(k);

          if (character.indexOf(comparedString) === -1) {
            break;
          }
          finalDecimalStr = finalDecimalStr.slice(0, -1);
        }

        // add decimal point only if there are decimal numbers
        // if not, return as is - whole numbers
        number =
          number.toString().slice(0, index) +
          (finalDecimalStr !== "" ? "." + finalDecimalStr : "");
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number.toString();
};
