import React from "react";

import ProjectPage from "components/Project/ProjectPage";
import TopicCard from "custom-components/Project/TopicCard";
import DesktopProjectPage from "custom-components/Project/DesktopProjectPage";
import MobileProjectPage from "custom-components/Project/MobileProjectPage";

import "./Project.css";

import localize from "lang/localize";

class ASMProjectPage extends ProjectPage {
  /*
    isMobile bool prop determines if these Topic cards
    are rendered in mobile or desktop view: for
    ScrollableAnchor library
  */
  renderTopics(topics, isMobile) {
    return topics.map((topic, index) => (
      <div key={topic.id}>
        <TopicCard
          sessionKey={this.props.sessionKey}
          projectId={this.props.projectId}
          isMobile={isMobile}
          allowReset={topic.allowReset || false}
          title={topic.title}
          description={topic.description}
          totalChallenges={topic.challengeNo}
          completedChallenges={topic.userChallengeCompletedNo || 0}
          img={topic.bannerImage}
          id={topic.id}
          started={topic.joined || false}
          locked={this.getTopicLock(topic.unlockable, topic.locked)}
          expired={typeof topic.expired === "boolean" ? topic.expired : false}
          completed={
            topic.challengeNo === topic.userChallengeCompletedNo || false
          }
          language={this.props.language}
        />
      </div>
    ));
  }

  render() {
    const FLAVOR_TEXT_ARR = [
      localize("showcase_suggestion_1", this.props.language),
      localize("showcase_suggestion_2", this.props.language),
      localize("showcase_suggestion_3", this.props.language)
    ];

    const showNextChallengePopup =
      this.props.checkpointChallengeId &&
      !this.props.showPopup &&
      localStorage.getItem("nextChallengePopupShown") !== "true";

    return (
      <div className="project container nopadding">
        {this.props.showPopup && this.renderPopupAlertDialog()}
        {showNextChallengePopup && this.renderNextChallengeDialog()}
        {this.props.showTopicsListDialog && this.renderTopicsListDialog()}
        <div className="desktop-project innerblock horizontalpadding pure-g bottompadding-floating hide-below-lg">
          {/* Render desktop Project page here */}
          <DesktopProjectPage
            isLoadingMore={this.props.isLoadingMore}
            categoryId={this.props.id}
            categories={this.props.categories}
            topics={this.props.topics}
            more={this.props.more}
            randomNumber={this.state.randomNumber}
            flavorTextArr={FLAVOR_TEXT_ARR}
            showcaseProject={this.props.showcaseProject}
            addAnchors={this.addAnchors}
            handleMore={this.props.handleMore}
            renderTopics={this.renderTopics}
            language={this.props.language}
          />
        </div>
        <div className="mobile-project horizontalpadding hide-from-lg">
          {/* Render mobile Project page here */}
          <MobileProjectPage
            project={this.props.project}
            projectId={this.props.projectId}
            projectImage={this.props.projectImage}
            buttons={this.props.buttons}
            topics={this.props.topics}
            user={this.props.user}
            team={this.props.team}
            categoryId={this.props.id}
            notifications={this.props.notifications}
            inboxUnread={this.props.inboxUnread}
            more={this.props.more}
            randomNumber={this.state.randomNumber}
            flavorTextArr={FLAVOR_TEXT_ARR}
            showcaseProject={this.props.showcaseProject}
            addAnchors={this.addAnchors}
            handleMore={this.props.handleMore}
            handleOpenShareGameDialog={this.props.handleOpenShareGameDialog}
            handleOpenTopicsListDialog={this.props.handleOpenTopicsListDialog}
            renderTopics={this.renderTopics}
            language={this.props.language}
            handleSubmit={this.props.handleSubmit}
            handleKeyPress={this.props.handleKeyPress}
            handleSearchChange={this.props.handleSearchChange}
          />
        </div>
      </div>
    );
  }
}

export default ASMProjectPage;
