import React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";

import "./GenericErrorPage.css";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

const propTypes = {
  routeUrl: PropTypes.string,
  routeName: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string /* Title */,
  message: PropTypes.string /* Message */,
  messageList: PropTypes.array /* Message list in point form */,
  endMessage: PropTypes.string /* Ending message */,
  isBackRoute: PropTypes.bool,
  showLogin: PropTypes.bool,
  language: PropTypes.string
};

const defaultProps = {
  isBackRoute: true,
  messageList: [],
  endMessage: ""
};

const GenericErrorPage = props => {
  // The equivalent of componentDidMount() for Functional Components
  useEffect(() => {
    let bodyDOM = document.body; // <body>

    // Set page identifier class
    if (!bodyDOM.classList.contains("errorPage")) {
      bodyDOM.className += " errorPage";
    }

    // The equivalent of componentWillUnmount() for Functional Components
    // Anything in here is fired on component unmount.
    return () => {
      // Remove page identifier class
      if (bodyDOM.classList.contains("errorPage")) {
        bodyDOM.classList.remove("errorPage");
      }
    };
  }, []);

  /**
   * Render title
   */
  const renderTitle = () => {
    if (typeof props.title === "string" && props.title.trim() !== "") {
      return <h4>{props.title}</h4>;
    } else {
      return null;
    }
  };

  /**
   * Render messages as a list
   */
  const renderMessageList = messageList => {
    if (Array.isArray(messageList) && messageList.length > 0) {
      return (
        <div className="flex justify-content-center topmargin-10">
          <ul className="ul-default list-style-position-inside">
            {messageList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   * Render button CTA with route URL if any
   */
  const renderRouteUrl = () => {
    if (props.routeUrl) {
      return (
        <div className="pure-u-g">
          <div className="pure-u-1-1 textcenter topmargin-30">
            <Link
              to={props.routeUrl}
              className="button cta inline-block button-error"
            >
              {/* Using div here for convenient access to display: block */}
              <div className="text-truncated">
                {props.isBackRoute
                  ? localize("back_button_text_start", props.language).format(
                      props.routeName
                    )
                  : props.routeName}
              </div>
            </Link>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   * Render closing message (to summarize the error, maybe?)
   */
  const renderEndMessage = () => {
    if (
      typeof props.endMessage === "string" &&
      props.endMessage.trim() !== ""
    ) {
      return <h5 className="topmargin-30">{props.endMessage}</h5>;
    } else {
      return null;
    }
  };

  /**
   * Render image icon
   */
  const renderImage = () => {
    if (props.image) {
      return (
        <div className="pure-u-g bottommargin-20">
          <div className="pure-u-1-1">
            <img
              className="circle iconlarge imgcenter"
              src={props.image}
              alt=""
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   * Render login form
   */
  // eslint-disable-next-line
  const renderLoginForm = () => {
    return null; // For now...
  };

  return (
    <div className="container verticalpadding">
      <div className="innerblock textcenter">
        {renderTitle()}
        {renderImage()}
        <h6>
          {props.message
            ? props.message
            : localize("unavailable_resource", props.language)}
        </h6>
        {renderMessageList(props.messageList)}
        {renderEndMessage()}
        {renderRouteUrl()}
      </div>
    </div>
  );
};

GenericErrorPage.propTypes = propTypes;
GenericErrorPage.defaultProps = defaultProps;

export default GenericErrorPage;
