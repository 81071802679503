import React from "react";
import RewardsPage from "components/Project/Rewards/RewardsPage";
import RewardsToggle from "components/Project/Rewards/RewardsToggle";
import "./Rewards.css";

import localize from "lang/localize";

/**
 * CUSTOM FOR ASM:
 * - Header
 * - Bold for points
 * - Scrollbar for reward list instead of whole page
 */
class ASMRewardsPage extends RewardsPage {
  render() {
    return (
      <React.Fragment>
        <div className="asm-reward-container">
          <div className="asm-page-header">REWARDS</div>
          <RewardsToggle
            current={this.props.current}
            points={this.props.points}
            handleToggle={this.props.handleToggle}
            s
            language={this.props.language}
          />
          <div className="asm-reward-list-container verticalpadding">
            <div className="innerblock pure-g">
              <div className="pure-u-md-2-24 pure-u-0-24" />
              <div className="pure-u-md-20-24 pure-u-1">
                {this.props.points !== undefined && (
                  <p className="textcenter">
                    <b>
                      {localize("points_text", this.props.language).format(
                        this.props.points
                      )}
                    </b>
                  </p>
                )}
              </div>
              <div className="pure-u-md-2-24 pure-u-0-24" />
            </div>
            <div className="innerblock pure-g">
              <div className="pure-u-md-2-24 pure-u-0-24" />
              <div className="pure-u-md-20-24 pure-u-1">
                <div className="rewards-filter-form pure-form pure-g">
                  {this.renderRewardsFilterForm()}
                </div>
              </div>
              <div className="pure-u-md-2-24 pure-u-0-24" />
            </div>
            <div className="asm-reward-list innerblock pure-g">
              <div className="pure-u-md-2-24 pure-u-0-24" />
              <div className="pure-u-md-20-24 pure-u-1">
                <div className="pure-g">
                  <div className="pure-u-1">
                    {this.props.current === "rewards"
                      ? this.renderRewards()
                      : this.renderRedeemed()}
                  </div>
                </div>
              </div>
              <div className="pure-u-md-2-24 pure-u-0-24" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ASMRewardsPage;
