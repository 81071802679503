import React from "react";

import TopbarMenu from "components/Topbar/TopbarMenu/TopbarMenu";
import Link from "components/shared/Link/Link";
import LogoutContainer from "components/Login/LogoutContainer";
import localize from "lang/localize";

import { slide as Menu } from "react-burger-menu";

import "../Topbar.css";

import {
  PROJECT_HOME,
  PROJECT_PROFILE,
  PROJECT_SETTINGS,
  PROJECT_INBOX,
  PROJECT_NOTIFICATIONS,
  PROJECT_LEADERBOARD,
  LOGIN_TOUR
} from "App/Routes";

class ASMTopbarMenu extends TopbarMenu {
  renderLink(className, iconClass, text, url) {
    return (
      <Link
        to={url.format(this.props.projectId)}
        className={"menu-item asm-menu-" + className}
        onClick={this.props.handleHideMenu}
      >

        {/* Navigation icon wrapper */}
        <span className={"asm-icon-container"}>
          {/* Hexagon outline */}
          <span className="asm-icon-hexagon"></span>

          {/* Menu icon */}
          <i className={"asm-icon fa asm-icon-" + className + " fa-solid " + iconClass} />
        </span>

        <span>{text}</span>
      </Link>
    );
  }

  renderLogoutItem() {
    if (this.props.loggedIn) {
      return (
        <LogoutContainer
          className="menu-item logout-link"
          handleHideMenu={this.props.handleHideMenu}
        >

          {/* Navigation icon wrapper */}
          <span className={"asm-icon-container"}>
            {/* Hexagon outline */}
            <span className="asm-icon-hexagon"></span>

            {/* Logout icon */}
            <i className="asm-icon fas fa-sign-out-alt" />
          </span>

          <span>{localize("icon_logout", this.props.language)}</span>
        </LogoutContainer>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <Menu
        isOpen={this.props.showMenu}
        handleHideMenu={this.props.handleHideMenu}
        onStateChange={this.toggleMenu}
        styles={this.setStyles()}
        right
      >
        {/* TODO: Implement necessary icons/styling in CSS */}
        {this.renderLink("home", "fa-home", "Home", PROJECT_HOME)}
        {this.renderLink("profile", "fa-user", "Profile", PROJECT_PROFILE)}
        {this.renderLink("leaderboard", "fa-medal", "Leaderboard", PROJECT_LEADERBOARD)}
        {this.renderLink("notifications", "fa-bell", "Notifications", PROJECT_NOTIFICATIONS)}
        {this.renderLink("inbox", "fa-inbox", "Inbox", PROJECT_INBOX)}
        {this.renderLink("settings", "fa-cog", "Settings", PROJECT_SETTINGS)}
        {this.renderLink("tour", "fa-info", "Mission Briefing", LOGIN_TOUR)}
        {this.renderLogoutItem()}
      </Menu>
    );
  }
}

export default ASMTopbarMenu;
