import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import Error404Container from "components/ErrorPages/Error404Container";
import PostLoginInterceptPage from "./PostLoginInterceptPage";

import loginServices from "services/loginServices";

const propTypes = {
  language: PropTypes.string
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    projectId: state.projectId,
    language: state.language
  };
};

class PostLoginInterceptContainer extends Component {
  constructor() {
    super();
    this.state = {
      interceptLoginUrl: null
    };
  }

  componentDidMount() {
    if (this.props.sessionKey && this.props.userId) {
      /*
        PostLoginIntercept houses the IFRAME platform page with the post login validation form.
        Once the form is completed, the IFRAME will re-route to the callback_url (directly, or
        based on the callback_id).

        The callback_url, which uses /frameloginsuccess (FrameLoginSuccessContainer.js) on ReactJS,
        stores the sessionKey to use for the "login". Through populating the Redux store with the
        new sessionKey / user information, the parent window here (not the IFRAME page) will receive
        the sessionKey and redirect accordingly.

        Thus, PostLoginInterceptContainer's session / user check (unlike FrameLoginSuccessContainer)
        focuses on the re-direction on the parent window, not the IFRAME window.

        We are going to assume visitors who reach this page are also new (player) accounts. Therefore,
        we will route them to the Tour screen, if it is enabled.

        Example usage to auto login using session key (e.g. API call to retrieve session key done separately):
        <REACTJS_DOMAIN>/postloginintercept?intercept_url=<REACTJS_DOMAIN>/frameloginsuccess?sessionKey=<SESSION_KEY>&callback_url=<REACTJS_PAGE_TO_FWD_TO>
      */
      loginServices.postLoginReplaceAndNavigate(
        this.props.projectId,
        null,
        true
      );
    } else {
      // Set page identifier class to .component-container
      let mainComponent = ReactDOM.findDOMNode(this).parentNode;
      if (!mainComponent.classList.contains("postLoginPage")) {
        mainComponent.className += " postLoginPage";
      }

      // Add other page classes to body DOM
      let bodyDOM = document.body; // <body> tag
      if (!bodyDOM.classList.contains("page-not-loggedin")) {
        bodyDOM.className += " page-not-loggedin";
      }

      this.setInterceptUrlParams();
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    // Remove page identifier class from .component-container
    let mainComponent = ReactDOM.findDOMNode(this).parentNode;
    if (mainComponent.classList.contains("postLoginPage")) {
      mainComponent.classList.remove("postLoginPage");
    }

    // Remove other page classes from body DOM
    let bodyDOM = document.body;
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }
  }

  /*
    Retrieve intercept_url params from window.location.search,
  */
  setInterceptUrlParams() {
    const PARAMS_STRING = new URLSearchParams(window.location.search);

    if (PARAMS_STRING.has("intercept_url")) {
      this.setState({
        interceptLoginUrl: PARAMS_STRING.get("intercept_url")
      });
    }
  }

  render() {
    if (typeof this.state.interceptLoginUrl === "string") {
      return (
        <PostLoginInterceptPage
          interceptLoginUrl={this.state.interceptLoginUrl}
          language={this.props.language}
        />
      );
    } else {
      return <Error404Container />;
    }
  }
}

PostLoginInterceptContainer.propTypes = propTypes;

export default connect(mapStateToProps)(PostLoginInterceptContainer);
