import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import localize from "lang/localize";

const propTypes = {
  showDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  videoInput: PropTypes.object.isRequired,
  videoDurationLimitFormatted: PropTypes.string,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Pop-up Modal to alert user about video duration limit
 */
class VideoDurationLimitDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: props.showDialog
    };
  }

  render() {
    return (
      <Modal
        isOpen={this.state.showDialog}
        contentLabel=""
        onRequestClose={this.props.handleCloseDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize("take_video_text", this.props.language)}
            </h5>
          </div>
          <div className="dialog-body">
            {localize(
              "claim_video_duration_reminder_text",
              this.props.language
            ).format(this.props.videoDurationLimitFormatted)}
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline cta"
                onClick={e => {
                  e.preventDefault();
                  this.props.handleCloseDialog();
                  this.props.videoInput.click();
                }}
              >
                {localize("web_confirm_okay_text", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

VideoDurationLimitDialog.propTypes = propTypes;

export default VideoDurationLimitDialog;
