import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import localize from "lang/localize";

import "./DeleteCommentDialog.css";

const propTypes = {
  showDialog: PropTypes.bool,
  commentId: PropTypes.number,
  handleCloseDialog: PropTypes.func,
  handleClickDelete: PropTypes.func,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class DeleteCommentDialog extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.showDialog}
        contentLabel={
          localize("delete_comment_text", this.props.language) + "?"
        }
        onRequestClose={this.props.handleCloseDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog delete-comment-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize("delete_comment_text", this.props.language) + "?"}
            </h5>
          </div>
          <div className="dialog-body">
            {localize("delete_comment_disclaimer", this.props.language)}
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseDialog}
                id="cancelButton"
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              <button
                className="button inline danger"
                onClick={this.props.handleClickDelete}
                id="deleteCommentButton"
              >
                {localize("web_confirm_yes_text", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

DeleteCommentDialog.propTypes = propTypes;

export default DeleteCommentDialog;
