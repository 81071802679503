import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import Dotdotdot from "react-dotdotdot";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import PropTypes from "prop-types";

import { CHALLENGE } from "App/Routes";
import Link from "components/shared/Link/Link";
import "./ChallengeCard.css";
import ChallengeCard from "components/Topic/ChallengeCard";

const propTypes = {
  sessionKey: PropTypes.string,
  id: PropTypes.number.isRequired,
  unlocked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  challengeType: PropTypes.string.isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  isFlashcard: PropTypes.bool.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  challengeReferralLink: PropTypes.string,
  likeNo: PropTypes.number.isRequired,
  completedNo: PropTypes.number.isRequired,
  isLiked: PropTypes.bool.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
  handleLike: PropTypes.func.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleOpenBookmarkLoginDialog: PropTypes.func,
  handleOpenLikeLoginDialog: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  language: PropTypes.string,
  contactEmail: PropTypes.string,
  handleChallengeTitleClick: PropTypes.func,
  /* CUSTOM FOR ASM */
  footnote: PropTypes.string
};

class ASMChallengeCard extends ChallengeCard {
  renderChallengeButtons() {
    return (
      <span id="challenge-buttons">
        <div className="asm-challenge-buttons">
          <button
            className={
              "button medium inline button-challenge-card" +
              (this.props.isCompleted || this.props.isExpired
                ? " inactive"
                : " cta")
            }
          >
            {this.renderActionButton()}
          </button>
        </div>
      </span>
    );
  }

  renderButtons() {
    if (this.props.isFlashcard) {
      return this.renderFlashcardButtons();
    } else {
      return this.renderChallengeButtons();
    }
  }
  renderCard(props) {
    let anchoredTitle = this.addAnchors(this.props.title);
    let footnoteAnchored = this.addAnchors(this.props.footnote);
    let img = this.props.img;

    return (
      <ScrollableAnchor id={"challenge" + this.props.id}>
        <div className="challenge-card box-sizing-border-box-all">
          <Link className="icons-wrap" to={CHALLENGE.format(props.id)}>
            <div className="icons">
              <div
                className={"challengeicon icon " + this.setChallengeTypeClass()}
              />
              <div
                className="asm-challenge-img"
                style={{
                  backgroundImage: "url(" + img + ")"
                }}
              ></div>
              {this.props.points !== 0 ? (
                <div className="challengeicon points-top">
                  <div className="points">
                    +{this.props.points.abbreviateNumber()}
                  </div>
                </div>
              ) : (
                <div className="points-top-none challengeicon" />
              )}
            </div>
          </Link>
          {/*
            Although the below uses <a> instead of <Link>,
            its onClick handler allows it to continue behaving
            as though the link is passed through a SPA router
           */}
          <a
            className="challengecard-description-wrap link"
            href={CHALLENGE.format(props.id)}
            onClick={this.props.handleChallengeTitleClick}
          >
            <div className="challengecard-description">
              <Dotdotdot clamp={3}>
                <span dangerouslySetInnerHTML={{ __html: anchoredTitle }} />
              </Dotdotdot>
            </div>
          </a>
          <Link to={CHALLENGE.format(props.id)}>
            {this.props.challengeTypeId !== 15 && (
              <div className="challengecard-footnote-wrap">
                <span
                  className="challengecard-footnote"
                  dangerouslySetInnerHTML={{ __html: footnoteAnchored }}
                />
              </div>
            )}
          </Link>
          <div className="challengecard-bottom">
            <Link to={CHALLENGE.format(props.id)}>
              <div className="button-wrap">{this.renderButtons()}</div>
            </Link>
          </div>
        </div>
      </ScrollableAnchor>
    );
  }
}

ASMChallengeCard.propTypes = propTypes;

export default ASMChallengeCard;
