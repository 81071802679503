import React, { Fragment } from "react";

import TopicPage from "components/Topic/TopicPage";
import "./TopicPage.css";
import { getChallengeType, getPoints } from "services/challengeServices";

import Loading from "components/shared/Loading";
import ChallengeCardContainer from "custom-components/Topic/ChallengeCardContainer";
import Slider from "react-slick";

class ASMTopicPage extends TopicPage {
  /**
   * Render list of challenge cards
   *
   * @param {object} challenges - JSON data object
   */
  renderChallenges(challenges) {
    const settings = {
      dots: false,
      adaptiveHeight: false,
      speed: 100,
      swipeToSlide: true,
      slidesToShow: 3,
      centerMode: false,
      infinite: false,
      centerPadding: "0",
      className: "topic-cards-slider",
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1
          }
        }
      ],
      beforeChange: (oldIndex, newIndex) => {
        if (this.props.more && newIndex + 3 === this.props.challenges.length) {
          document.documentElement.scrollTop =
            document.scrollingElement.scrollHeight;
          this.props.handleMore();
        }
      },
      afterChange: current => this.setState({ currentSlide: current })
    };

    return (
      <Slider ref="slider" {...settings}>
        {challenges.map((challenge, index) => (
          <div className="pure-u-1 pure-u-md-12-24" key={index}>
            <div className="pure-u-sm-1-24" />
            <div className="pure-u-sm-22-24">
              <ChallengeCardContainer
                id={challenge.id}
                unlocked={this.getUnlocked(
                  challenge.unlockable,
                  challenge.locked
                )}
                title={challenge.title}
                footnote={challenge.footnote}
                img={challenge.imageSmall}
                challengeType={getChallengeType(
                  challenge.challengeTypeId,
                  challenge.photoOnly,
                  challenge.multiSelect,
                  challenge.challengeType
                )}
                challengeTypeId={challenge.challengeTypeId}
                points={getPoints(challenge.challengeTypeId, challenge.points)}
                isFlashcard={challenge.challengeTypeId === 4}
                isConfirmationChallenge={
                  challenge.challengeTypeId === 11 &&
                  challenge.confirmation === true
                }
                challengeReferralLink={challenge.challengeReferralLink}
                likeNo={challenge.likeNo}
                completedNo={challenge.claimNo}
                isLiked={!!challenge.ratedLike}
                isBookmarked={!!challenge.bookmarked}
                isCompleted={!!challenge.claimed}
                isExpired={!!challenge.expired}
                handleLike={event => this.props.handleLike(event, challenge.id)}
                handleBookmark={event =>
                  this.props.handleBookmark(
                    event,
                    challenge.id,
                    challenge.bookmarked
                  )
                }
                handleOpenBookmarkLoginDialog={
                  this.props.handleOpenBookmarkLoginDialog
                }
                handleOpenLikeLoginDialog={this.props.handleOpenLikeLoginDialog}
                isLoggedIn={this.props.isLoggedIn}
                language={this.props.language}
                contactEmail={this.props.contactEmail}
              />
            </div>
            <div className="pure-u-sm-1-24" />
          </div>
        ))}
      </Slider>
    );
  }

  /**
   * Render view
   */
  render() {
    return (
      <Fragment>
        <div className="topic-container container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1 pure-u-sm-1-24" />
            <div className="pure-u-1 pure-u-sm-22-24">
              {this.props.showBookmarkLoginDialog &&
                this.renderBookmarkLoginDialog()}
              {this.props.showLikeLoginDialog && this.renderLikeLoginDialog()}
              {this.renderChallenges(this.props.challenges)}
              {this.props.isLoadingMore ? <Loading /> : null}
            </div>
            <div className="pure-u-1 pure-u-sm-1-24" />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ASMTopicPage;
