import { connect } from "react-redux";

import {
  ProfileContainer,
  mapStateToProps,
  mapDispatchToProps
} from "components/Profile/ProfileContainer";

export class ASMProfileContainer extends ProfileContainer {
  /*
   CUSTOM FOR ASM: Remove auto pagination as div is not at the bottom of the page and UX is awkward
   */
  componentDidMount() {
    if (!this.state.ownProfile || this.props.sessionKey) {
      /* handling for when achievements take a while to load */
      this.setState({ itemsLoading: true }, () => {
        this.getUserProfile();
      });
    }

    if (this.props.projectId) {
      this.getTopics();
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ASMProfileContainer);
