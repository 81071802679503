import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Rewards.css";
import RewardsToggle from "./RewardsToggle";
import RewardContainer from "./RewardContainer";
import RedeemedContainer from "./RedeemedContainer";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import SearchBar from "components/shared/SearchBar/SearchBar";
import SelectInput from "components/shared/Inputs/SelectInput";

import localize from "lang/localize";

const propTypes = {
  current: PropTypes.oneOf(["rewards", "redeemed"]).isRequired,
  handleToggle: PropTypes.func.isRequired,
  categories: PropTypes.array,
  points: PropTypes.number,
  onCategoryChange: PropTypes.func.isRequired,
  rewards: PropTypes.array.isRequired,
  rewardDescriptionAfter: PropTypes.string,
  redeemed: PropTypes.array,
  handleClick: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number,
  showAlertWithTimeout: PropTypes.func.isRequired
};

class RewardsPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("rewardsPage")) {
      bodyDOM.className += " rewardsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("rewardsPage")) {
      bodyDOM.classList.remove("rewardsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderRewardsSearch() {
    return (
      <div className="pure-u-1-1">
        <SearchBar
          handleSubmit={this.props.handleSubmit}
          handleSearchChange={this.props.handleSearchChange}
          placeholder={localize(
            "general_search_placeholder_text",
            this.props.language
          )}
          language={this.props.language}
        />
      </div>
    );
  }

  renderRewards() {
    if (this.props.rewards.length === 0) {
      return (
        <React.Fragment>
          <p className="textcenter">
            {localize("empty_store_table_text", this.props.language)}
          </p>
        </React.Fragment>
      );
    }

    return (
      <div>
        <div className="pure-g">
          <div className="pure-u-1">
            {this.props.rewards.map((reward, index) => (
              <RewardContainer
                key={index}
                id={reward.id}
                title={reward.name}
                description={reward.description}
                descriptionAfterRedeem={this.props.rewardDescriptionAfter}
                img={reward.imageSmall}
                points={reward.price}
                available={reward.quantityAvailable}
                owned={reward.ownedQuantity}
                ownedLimit={reward.ownedQuantityLimit}
                adminOnly={reward.adminToAward}
                options={reward.rewardOptions}
                type={reward.rewardType ? reward.rewardType : "standard"}
                handleClick={this.props.handleClick}
                language={this.props.language}
                projectId={this.props.projectId}
                loggedIn={this.props.loggedIn}
                userPoints={this.props.points}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderRedeemed() {
    if (!this.props.loggedIn) {
      return (
        <NotLoggedInContainer
          id="notloggedin"
          showLogin={false}
          language={this.props.language}
        />
      );
    }
    if (this.props.redeemed.length === 0) {
      return (
        <p className="textcenter">
          {localize("empty_inventory_table_text", this.props.language)}
        </p>
      );
    }
    return this.props.redeemed.map((redeemed, index) => (
      <RedeemedContainer
        key={index}
        id={redeemed.itemId}
        title={redeemed.name}
        description={redeemed.description}
        img={redeemed.imageSmall}
        descriptionAfterRedeem={redeemed.rewardDescriptionAfter}
        descriptionAfterCollect={redeemed.rewardDescriptionAfterCollect}
        option={redeemed.rewardOption}
        code={redeemed.rewardCode}
        type={redeemed.rewardType ? redeemed.rewardType : "standard"}
        usedAtFormatted={redeemed.usedAtFormatted}
        handleClick={event => this.props.handleClick(event, redeemed.id)}
        language={this.props.language}
        projectId={this.props.projectId}
        showAlertWithTimeout={this.props.showAlertWithTimeout}
      />
    ));
  }

  renderRewardsFilterForm() {
    if (this.props.categories) {
      if (this.props.current === "rewards") {
        return (
          <React.Fragment>
            <div className="rewards-category-select-container pure-u-1-1 pure-u-md-1-3">
              <SelectInput
                name="categories"
                options={this.props.categories}
                placeholder={localize("category_all_text", this.props.language)}
                onChange={this.props.onCategoryChange}
              />
            </div>
            <div className="pure-u-md-1-3" />
            <div className="pure-u-1-1 pure-u-md-1-3 rewards-search-container">
              {this.renderRewardsSearch()}
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div className="pure-u-md-7-24" />
            <div className="rewards-category-select-container pure-u-1-1 pure-u-md-5-12">
              <SelectInput
                name="categories"
                options={this.props.categories}
                placeholder={localize("icon_categories", this.props.language)}
                onChange={this.props.onCategoryChange}
              />
            </div>
            <div className="pure-u-md-7-24" />
          </React.Fragment>
        );
      }
    } else {
      if (this.props.current === "rewards") {
        return (
          <React.Fragment>
            <div className="pure-u-md-5-24" />
            <div className="pure-u-1-1 pure-u-md-7-12">
              {this.renderRewardsSearch()}
            </div>
            <div className="pure-u-md-5-24" />
          </React.Fragment>
        );
      } else {
        return null;
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <RewardsToggle
          current={this.props.current}
          points={this.props.points}
          handleToggle={this.props.handleToggle}
          s
          language={this.props.language}
        />
        <div className="container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-md-2-24 pure-u-0-24" />
            <div className="pure-u-md-20-24 pure-u-1">
              {this.props.points !== undefined && (
                <p className="textcenter">
                  {localize(
                    "store_available_points_text",
                    this.props.language
                  ).format(this.props.points)}
                </p>
              )}
            </div>
            <div className="pure-u-md-2-24 pure-u-0-24" />
          </div>
          <div className="innerblock pure-g">
            <div className="pure-u-md-2-24 pure-u-0-24" />
            <div className="pure-u-md-20-24 pure-u-1">
              <div className="rewards-filter-form pure-form pure-g">
                {this.renderRewardsFilterForm()}
              </div>
            </div>
            <div className="pure-u-md-2-24 pure-u-0-24" />
          </div>
          <div className="innerblock pure-g">
            <div className="pure-u-md-2-24 pure-u-0-24" />
            <div className="pure-u-md-20-24 pure-u-1">
              <div className="pure-g">
                <div className="pure-u-1">
                  {this.props.current === "rewards"
                    ? this.renderRewards()
                    : this.renderRedeemed()}
                </div>
              </div>
            </div>
            <div className="pure-u-md-2-24 pure-u-0-24" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

RewardsPage.propTypes = propTypes;

export default RewardsPage;
