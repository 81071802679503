import React, { Component } from "react";
import { connect } from "react-redux";

import getApiGenerator2 from "services/getApiGenerator2";
import { VALIDATE_PROJECT2 } from "services/api";

import Router from "router";
import { PROJECT_HOME } from "App/Routes";

import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language
  };
};

class GameContainer extends Component {
  constructor() {
    super();
    this.state = {
      errorCode: null
    };
  }

  componentWillMount() {
    this.validateProject(this.props.id);
  }

  /* Using API2 */
  validateProject(id) {
    /*
      replaces spaces and non-alphanumeric characters.
      Note: IE11 does not currently support RegExp constructor
      accepting the regex literal object. Thus, I assigned '/[\W_]+/g'
      instead of 'new RegExp(/[\W_]+/, "g")' to specialCharacters.
    */
    let specialCharacters = /[\W_]+/g;
    let idHasSpecialCharacters = specialCharacters.test(id);

    /* if id is valid */
    if (idHasSpecialCharacters === false) {
      getApiGenerator2(VALIDATE_PROJECT2.format(id), {}).end((err, res) => {
        if (err || res.body.code !== 200) {
          this.setState({ errorCode: res.body.errorCode });
        } else {
          Router.replaceAndNavigate(PROJECT_HOME.format(res.body.id));
        }
      });
    } else {
      /* for invalid id cases */
      this.setState({
        errorCode: "-1"
      }); /* setting error code to -1 on the front end if there are special characters */
    }
  }

  render() {
    if (!this.state.errorCode) {
      /* For cases where API is still being called */
      return <Loading />;
    } else if (this.state.errorCode === "-1") {
      /* For cases where there are special characters are in the Project Code */
      return (
        <GenericErrorPage
          message={localize(
            "gametize_project_project_code_invalid",
            this.props.language
          )}
          language={this.props.language}
        />
      );
    } else {
      /*
        For all other cases:
        Likely when error code is "API00020"
        and error message is "Invalid Project"
      */
      return (
        <GenericErrorPage
          message={localize(
            "gametize_project_project_code_not_found",
            this.props.language
          )}
          language={this.props.language}
        />
      );
    }
  }
}

export default connect(mapStateToProps)(GameContainer);
