import React from "react";
import PropTypes from "prop-types";

import "./SidebarLinks.css";
import {
  PROJECT_FRAME,
  PROJECT_USERS,
  PROJECT_TEAMS,
  PROJECT_ACTIVITY,
  PROJECT_LEADERBOARD,
  PROJECT_REWARDS,
  PROJECT_BOOKMARKS,
  PROJECT_ACHIEVEMENTS,
  TOPIC_CATEGORY,
  TOPIC,
  CHALLENGE
} from "App/Routes";
import Link from "components/shared/Link/Link";
import { connect } from "react-redux";
import { setShowQRModal } from "actions";

const mapDispatchToProps = dispatch => {
  return {
    setShowQRModal: isShow => {
      dispatch(setShowQRModal(isShow));
    }
  };
};

const propTypes = {
  projectId: PropTypes.number.isRequired,
  showTeams: PropTypes.bool,
  showIcons: PropTypes.bool,
  buttons: PropTypes.array,
  language: PropTypes.string
};

const defaultProps = {
  showTeams: false,
  showIcons: false
};

const SidebarLinks = props => (
  <div className="sidebar-links">
    {Array.isArray(props.buttons) && props.buttons.length > 0
      ? props.buttons.map(button => {
          switch (button.buttonType) {
            case "bookmarks":
              return (
                <div className="sidebar-link home-btn-bookmark" key={button.id}>
                  <Link to={PROJECT_BOOKMARKS.format(props.projectId)}>
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "rewards":
              return (
                <div className="sidebar-link home-btn-reward" key={button.id}>
                  <Link to={PROJECT_REWARDS.format(props.projectId)}>
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "leaderboard":
              return (
                <div
                  className="sidebar-link home-btn-leaderboard"
                  key={button.id}
                >
                  <Link to={PROJECT_LEADERBOARD.format(props.projectId)}>
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "activity":
              return (
                <div className="sidebar-link home-btn-feed" key={button.id}>
                  <Link to={PROJECT_ACTIVITY.format(props.projectId)}>
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "web-url":
              return (
                <div className="sidebar-link home-btn-url" key={button.id}>
                  <a
                    href={button.objectReference}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {button.buttonText}
                  </a>
                </div>
              );
            case "web-url-session":
              const ENCODED_LINK = encodeURIComponent(button.objectReference);
              return (
                <div className="sidebar-link home-btn-page" key={button.id}>
                  <Link
                    to={PROJECT_FRAME.format(props.projectId, ENCODED_LINK)}
                  >
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "challenge-profile":
              return (
                <div
                  className="sidebar-link home-btn-challenge"
                  key={button.id}
                >
                  <Link to={CHALLENGE.format(button.objectReference)}>
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "topic":
              return (
                <div className="sidebar-link home-btn-topic" key={button.id}>
                  <Link to={TOPIC.format(button.objectReference)}>
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "topic-category":
              return (
                <div className="sidebar-link home-btn-category" key={button.id}>
                  <Link
                    to={TOPIC_CATEGORY.format(
                      props.projectId,
                      button.objectReference
                    )}
                  >
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "teams":
              return (
                <div className="sidebar-link home-btn-team" key={button.id}>
                  <Link to={PROJECT_TEAMS.format(props.projectId)}>
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "players":
              return (
                <div className="sidebar-link home-btn-player" key={button.id}>
                  <Link to={PROJECT_USERS.format(props.projectId)}>
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "qr":
              return (
                <div className="sidebar-link home-btn-qr" key={button.id}>
                  <Link
                    onClick={() => {
                      props.setShowQRModal(true);
                    }}
                  >
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "achievements":
              return (
                <div
                  className="sidebar-link home-btn-achievement"
                  key={button.id}
                >
                  <Link to={PROJECT_ACHIEVEMENTS.format(props.projectId)}>
                    {button.buttonText}
                  </Link>
                </div>
              );
            case "support":
              return (
                <div className="sidebar-link home-btn-support" key={button.id}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${button.objectReference}`}
                  >
                    {button.buttonText}
                  </a>
                </div>
              );
            default:
              return null;
          }
        })
      : null}
  </div>
);

SidebarLinks.propTypes = propTypes;
SidebarLinks.defaultProps = defaultProps;

export default connect(null, mapDispatchToProps)(SidebarLinks);
