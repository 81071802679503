import React, { Component } from "react";
import { connect } from "react-redux";

import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import SettingsPage from "./SettingsPage";

import { setProject } from "actions";
import { GET_TOPICS } from "services/api";
import getApiGenerator from "services/getApiGenerator";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    projectId: state.projectId,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    }
  };
};

export class SettingsContainer extends Component {
  componentDidMount() {
    if (this.props.projectId) {
      this.getProject();
    }
  }

  getProject() {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: 1
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (!(err || res.body.code !== 200)) {
        this.props.setProject(res.body.game);
      }
    });
  }

  render() {
    if (!(this.props.userId && this.props.sessionKey)) {
      return (
        <NotLoggedInContainer
          language={this.props.language}
          showLogin={false}
        />
      );
    } else {
      return (
        <SettingsPage
          sessionKey={this.props.sessionKey}
          language={this.props.language}
        />
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
