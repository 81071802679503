/**
 * React Video Thumbnail Component
 * @author mike trieu
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import "./VideoThumbnail.css";
import Loading from "components/shared/Loading";

/**
 * Simple component that renders thumbnail url
 * @param {string} snapshot
 */
const ThumbnailImage = ({ snapshot }) => {
  return (
    <div className="react-thumbnail-generator">
      <img src={snapshot} alt="claim" />
    </div>
  );
};

const propTypes = {
  videoUrl: PropTypes.string.isRequired
};

class VideoThumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false, // boolean
      metadataLoaded: false, // boolean
      seeked: false, // boolean
      snapshot: false, // string thumbnail url || false
      suspended: false, // boolean
      // props
      videoUrl: props.videoUrl // string
    };
  }

  render() {
    const { snapshot, videoUrl } = this.state;
    if (!snapshot) {
      return (
        <React.Fragment>
          <Loading />
          <div className="react-thumbnail-generator">
            <canvas className="snapshot-generator" ref="canvas"></canvas>
            <video
              muted
              className="snapshot-generator"
              ref="videoEl"
              src={videoUrl}
              onLoadedMetadata={() => this.setState({ metadataLoaded: true })}
              onLoadedData={() => this.setState({ dataLoaded: true })}
              onSuspend={() => this.setState({ suspended: true })}
              onSeeked={() => this.setState({ seeked: true })}
            ></video>
          </div>
        </React.Fragment>
      );
    } else {
      return <ThumbnailImage snapshot={snapshot} />;
    }
  }

  componentDidMount() {
    this.refs.videoEl.setAttribute("crossOrigin", "Anonymous");
  }

  /**
   * (fires every time setState() gets called)
   */
  componentDidUpdate(prevProps, prevState) {
    if (!this.state.snapshot) {
      const {
        metadataLoaded,
        dataLoaded,
        suspended,
        seeked,
        snapshot
      } = this.state;

      // check if all 3 required events fired
      if (metadataLoaded && dataLoaded && suspended) {
        if (
          !this.refs.videoEl.currentTime ||
          this.refs.videoEl.currentTime < 2
        ) {
          this.refs.videoEl.currentTime = 2;
        }

        if (seeked && !snapshot) {
          // attempt to generate thumbnail
          this.getSnapShot();
        }
      }
    }
  }

  /**
   * Create a canvas and video element to "draw" the
   * image, then convert it to a data url
   */
  getSnapShot = () => {
    try {
      const video = this.refs.videoEl;
      const canvas = this.refs.canvas;
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;

      canvas.getContext("2d").drawImage(video, 0, 0);

      const thumbnail = canvas.toDataURL("image/png");

      // Remove video & canvas elements (no longer needed)
      video.src = ""; // setting to empty string stops video from loading
      video.remove();
      canvas.remove();

      this.setState({
        snapshot: thumbnail
      });
    } catch (e) {}
  };
}

VideoThumbnail.propTypes = propTypes;

export default VideoThumbnail;
