import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import "./SurveyResult.css";

const propTypes = {
  quizOptions: PropTypes.array,
  userName: PropTypes.string.isRequired,
  userPhoto: PropTypes.string
};

/**
 * Render breakdown of quiz/poll options and results
 */
class SurveyResult extends Component {
  /**
   * Render user's image next to the options they selected
   */
  renderResultIcons(option) {
    if (option.answered) {
      return (
        <img
          className="circle iconxs"
          alt={this.props.userName}
          src={this.props.userPhoto}
        />
      );
    } else {
      return null;
    }
  }

  /**
   * Render option bar with results
   */
  renderResultOptionBar(option) {
    let hasOptionImage = option.image && option.image !== "";
    let hasOptionAnswerNo = option.answerNo ? true : false;

    let renderCorrectAnswerHighlight = false;
    if (option.correct) {
      renderCorrectAnswerHighlight = true;
    }

    return (
      <div
        className={
          renderCorrectAnswerHighlight
            ? "quiz-option-bar pure-g flex align-items-center border-green"
            : "quiz-option-bar pure-g flex align-items-center"
        }
      >
        {hasOptionImage && (
          <Fragment>
            <div className="pure-u-8-24 pure-u-sm-5-24 flex justify-content-center align-items-center">
              <img
                className="survey-result-option-image"
                src={option.image}
                alt={option.title}
              />
            </div>
            <div className="pure-u-1-24" />
          </Fragment>
        )}
        {this.renderResultOptionBarTitle(
          option,
          hasOptionImage,
          hasOptionAnswerNo
        )}
        {hasOptionAnswerNo && (
          <Fragment>
            <div className="pure-u-1-24" />
            <div className="pure-u-4-24 text-align-right">
              <strong>{option.answerNo}</strong>
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  /**
   * Render quiz option title
   */
  renderResultOptionBarTitle(option, hasOptionImage, hasOptionAnswerNo) {
    if (hasOptionImage && hasOptionAnswerNo) {
      return <div className="pure-u-10-24 pure-u-sm-13-24">{option.title}</div>;
    } else if (hasOptionImage) {
      return <div className="pure-u-15-24 pure-u-sm-18-24">{option.title}</div>;
    } else if (hasOptionAnswerNo) {
      return <div className="pure-u-19-24">{option.title}</div>;
    } else {
      return <div className="pure-u-1-1">{option.title}</div>;
    }
  }

  /**
   * Render quiz option breakdown view
   */
  render() {
    return (
      <Fragment>
        <div className="container verticalpadding">
          <div className="innerblock">
            <div className="pure-u-1-24" />
            <div className="pure-u-22-24">
              {this.props.quizOptions.map((option, index) => (
                <div
                  className="pure-g survey-result flex align-items-center"
                  key={index}
                >
                  <div className="pure-u-2-24 pure-u-md-2-24 flex justify-content-center relative">
                    {this.renderResultIcons(option)}
                  </div>
                  <div className="pure-u-1-24 hide-from-md" />
                  <div className="pure-u-21-24 pure-u-md-20-24">
                    {this.renderResultOptionBar(option)}
                  </div>
                  <div className="pure-u-md-2-24" />
                </div>
              ))}
            </div>
            <div className="pure-u-1-24" />
          </div>
        </div>
      </Fragment>
    );
  }
}

SurveyResult.propTypes = propTypes;

export default SurveyResult;
