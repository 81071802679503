import React from "react";
import { connect } from "react-redux";

import { SINGLE_PROJECT_APP, PROJECT_ID } from "config";

import {
  SidebarContainer,
  mapStateToProps
} from "components/Sidebar/SidebarContainer";
import Sidebar from "./Sidebar";

export class ASMSidebarContainer extends SidebarContainer {
  /**
   * Custom - sidebar is always shown in single project setup
   */
  render() {
    const sidebar = this.props.sidebar;
    const SHOW_TOPIC_SIDEBAR =
      sidebar.state &&
      sidebar.state === "topic" &&
      typeof sidebar.topic === "object" &&
      Object.keys(sidebar.topic).length > 0 &&
      sidebar.project &&
      typeof sidebar.project === "object" &&
      Object.keys(sidebar.project).length > 0;

    const SHOW_PROJECT_SIDEBAR =
      sidebar.state &&
      (sidebar.state === "project" || sidebar.state === "project-secondary") &&
      sidebar.project &&
      typeof sidebar.project === "object" &&
      Object.keys(sidebar.project).length > 0;

    const IS_PROJECT_PAGE = sidebar.state === "project";

    if (SHOW_TOPIC_SIDEBAR || SHOW_PROJECT_SIDEBAR) {
      return (
        <Sidebar
          state={sidebar.state}
          projectId={sidebar.project.id}
          projectName={sidebar.project.title}
          projectDescription={sidebar.project.description}
          projectImage={sidebar.project.bannerImage}
          project={sidebar.project}
          showTeams={sidebar.project.teamEnabled}
          topicId={sidebar.topic ? sidebar.topic.id : null}
          topicName={sidebar.topic ? sidebar.topic.title : null}
          topicDescription={sidebar.topic ? sidebar.topic.description : null}
          topicImage={sidebar.topic ? sidebar.topic.bannerImage : null}
          totalChallenges={sidebar.topic ? sidebar.topic.challengeNo : null}
          completedChallenges={
            sidebar.topic ? sidebar.topic.userChallengeCompletedNo : null
          }
          isProjectPage={IS_PROJECT_PAGE}
          showShareGameDialog={this.state.showShareGameDialog}
          handleOpenShareGameDialog={this.handleOpenShareGameDialog}
          handleCloseShareGameDialog={this.handleCloseShareGameDialog}
          showTopicResetActivityDialog={this.state.showTopicResetActivityDialog}
          handleOpenResetTopicActivityDialog={
            this.handleOpenResetTopicActivityDialog
          }
          handleCloseResetTopicActivityDialog={
            this.handleCloseResetTopicActivityDialog
          }
          language={this.props.language}
        />
      );
    } else if (SINGLE_PROJECT_APP) {
      //CUSTOM FOR ASM: Show fixed sidebar
      return (
        <Sidebar
          state={sidebar.state}
          projectId={PROJECT_ID}
          isProjectPage={IS_PROJECT_PAGE}
          showShareGameDialog={this.state.showShareGameDialog}
          handleOpenShareGameDialog={this.handleOpenShareGameDialog}
          handleCloseShareGameDialog={this.handleCloseShareGameDialog}
          showTopicResetActivityDialog={this.state.showTopicResetActivityDialog}
          handleOpenResetTopicActivityDialog={
            this.handleOpenResetTopicActivityDialog
          }
          handleCloseResetTopicActivityDialog={
            this.handleCloseResetTopicActivityDialog
          }
          language={this.props.language}
        />
      );
    } else {
      return null;
    }
  }
}

export default connect(mapStateToProps)(ASMSidebarContainer);
