import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { HOME } from "App/Routes";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";
import ChallengeAFriendPage from "./ChallengeAFriendPage";

import {
  ACCEPT_CHALLENGE,
  GET_CHALLENGE,
  GET_PROJECT_BUTTONS
} from "services/api";
import sessionStorageService from "services/sessionStorageService";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

import {
  setProject,
  setButtons,
  setTopic,
  setChallenge,
  showAlertWithTimeout
} from "actions";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    topic: state.sidebar.topic,
    challengeCompleted: state.challengeCompleted,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    setTopic: topic => {
      dispatch(setTopic(topic));
    },
    setChallenge: challenge => {
      dispatch(setChallenge(challenge));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

const propTypes = {
  id: PropTypes.string.isRequired
};

export class ChallengeAFriendContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      errorTopic: null,
      isLiked: null,
      isBookmarked: null,
      likeNo: null,
      adminToClaim: false,
      locked: false,
      submitted: false,
      contactEmail: null
    };
  }

  componentDidMount() {
    /* get challenge */
    this.getChallenge();
  }

  getChallenge = () => {
    getApiGenerator(
      GET_CHALLENGE.format(this.props.id),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        /* locked challenge check */
        if (
          res.body.error &&
          res.body.error.indexOf("locked") !== -1 &&
          res.body.quest
        ) {
          this.setState({ data: res.body, locked: true });
        } else {
          this.setState({ data: false });
        }

        this.setState(
          {
            errorTopic: res.body.quest
          },
          () => {
            /* setting sidebar */
            if (this.state.errorTopic) {
              this.props.setTopic(this.state.errorTopic);
            }
            if (res.body.game) {
              this.props.setProject(res.body.game);
            }
          }
        );

        this.props.setChallenge(res.body);
      } else {
        this.setState({ data: res.body });
        // accepts if not already accepted and expired is false or undefined
        if (res.body.accepted === false && !res.body.expired) {
          this.acceptChallenge();
        }
        this.props.setProject(res.body.game);
        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }
        this.props.setTopic(res.body.quest);
        this.props.setChallenge(res.body);
      }
    });
  };

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
      }
    });
  }

  acceptChallenge = () => {
    getApiGenerator(
      ACCEPT_CHALLENGE,
      {
        type_id: this.state.data.id
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      }
    });
  };

  render() {
    const data = this.state.data;
    const UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_challenge_private_topic_loggedin",
        this.props.language
      ),
      localize(
        "unavailable_challenge_private_project_loggedin",
        this.props.language
      ),
      localize("unavailable_challenge_unpublished", this.props.language),
      localize("unavailable_challenge_not_exist", this.props.language)
    ];
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    if (!this.props.sessionKey) {
      /* Not logged in */
      return (
        <NotLoggedInContainer
          showLogin={false}
          language={this.props.language}
        />
      );
    }
    // challenge exists, available for referral
    else if (data && data.challengeReferralLink) {
      /*
        Setting a key prop for ChallengeAFriendPage will allow the interface to be reset,
        whenever you switch challenge ids
      */
      return (
        <ChallengeAFriendPage
          key={data.id}
          sessionKey={this.props.sessionKey}
          challengeReferralLink={data.challengeReferralLink}
          language={this.props.language}
        />
      );
    }
    // challenge exists, not available for referral
    else if (data) {
      return (
        <GenericErrorPage
          message={localize(
            "unavailable_challenge_referral",
            this.props.language
          )}
          language={this.props.language}
        />
      );
    }
    // challenge does not exist
    else if (data === false) {
      return (
        <GenericErrorPage
          routeUrl={IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_challenge_long", this.props.language)}
          messageList={UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

ChallengeAFriendContainer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeAFriendContainer);
