import React, { Component } from "react";
import { connect } from "react-redux";

import { LOGIN, LOGIN_HOME, PROJECT_HOME, SEARCH_PROJECTS } from "App/Routes";
import HomePage from "./HomePage";

import { ENABLE_LOGIN_HOME } from "config";
import { showAlertWithTimeout } from "actions";
import { VALIDATE_PROJECT } from "services/api";
import Router from "router";
import getApiGenerator from "services/getApiGenerator";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    user: state.user,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

export class HomeContainer extends Component {
  constructor() {
    super();
    this.state = {
      search: ""
    };
  }

  componentDidMount() {
    if (this.props.sessionKey === null) {
      if (ENABLE_LOGIN_HOME) {
        Router.replaceAndNavigate(LOGIN_HOME);
      } else {
        Router.replaceAndNavigate(LOGIN);
      }

      Router.check();
    }

    this.setFinalMinHeight();
  }

  componentWillUnmount() {
    this.resetFinalMinHeight();
  }

  handleSearchChange = event => {
    this.setState({ search: event.target.value });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.handleSubmit(event);
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    const SEARCH = this.state.search.trim();

    if (SEARCH !== "") {
      this.validateProject(SEARCH);
    }
  };

  validateProject(id) {
    getApiGenerator(VALIDATE_PROJECT.format(id), {}, this.props.sessionKey).end(
      (err, res) => {
        if (err) {
          /*
          This error shows when attempting to submit with only "!", or ";", or "/" etc. special characters.
          Unfortunately, API as of 30 June 2020 does not account for these characters sent, and it throws
          "Failed to receive response data", causing it not to allow res.body to be accessible. This
          returns the error message thrown by either the superagent library or the browser.
          */
          this.props.showAlertWithTimeout({
            text: err.message,
            type: "error"
          });
        } else if (err || res.body.code !== 200) {
          /*
          Regular project alias not found errors
          */
          if (res.body.code === 500 || res.body.code === 400) {
            Router.navigate(SEARCH_PROJECTS.format(id));
          } else {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error"
            });
          }
        } else {
          Router.navigate(PROJECT_HOME.format(res.body.id));
        }
      }
    );
  }

  setFinalMinHeight() {
    let minHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    document.documentElement.style.minHeight = minHeight + "px";
  }

  resetFinalMinHeight() {
    if (document.documentElement.style.removeProperty) {
      document.documentElement.style.removeProperty("min-height");
    } else {
      document.documentElement.style.removeAttribute("min-height");
    }
  }

  render() {
    return (
      <HomePage
        handleKeyPress={this.handleKeyPress}
        handleSearchChange={this.handleSearchChange}
        handleSubmit={this.handleSubmit}
        language={this.props.language}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
