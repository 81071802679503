import React from "react";

import "./Sidebar.css";
import Sidebar from "components/Sidebar/Sidebar";
import Link from "components/shared/Link/Link";
import LogoutContainer from "components/Login/LogoutContainer";

import menu_logo from "images/custom/logo.png";
import menu_collapsed_logo from "images/custom/menu_collapsed_logo.png";

import {
  PROJECT_HOME,
  PROJECT_PROFILE,
  PROJECT_SETTINGS,
  PROJECT_INBOX,
  PROJECT_NOTIFICATIONS,
  PROJECT_LEADERBOARD,
  LOGIN_TOUR
} from "App/Routes";
import { SINGLE_PROJECT_APP, PROJECT_ID } from "config";

import localize from "lang/localize";

class ASMSidebar extends Sidebar {
  renderLink(className, iconClass, text, url) {
    let projectId = this.props.projectId;

    if (SINGLE_PROJECT_APP) {
      projectId = PROJECT_ID;
    }

    return (
      <Link to={url.format(projectId)} className={"asm-menu-link"} title={text}>
        <div className={"asm-menu-" + className}>
          {/* Navigation icon wrapper */}
          <span className="asm-icon-container">
            {/* Hexagon outline */}
            <span className="asm-icon-hexagon"></span>

            {/* Menu icon */}
            <i
              className={
                "asm-icon fa asm-icon-" + className + " fa-solid " + iconClass
              }
            />
          </span>

          <span className="asm-menu-label">{text}</span>
        </div>
      </Link>
    );
  }

  renderLogoutItem() {
    return (
      <LogoutContainer
        className="menu-item logout-link asm-menu-link"
        handleHideMenu={this.props.handleHideMenu}
      >
        <div className={"asm-menu-logout"}>
          {/* Navigation icon wrapper */}
          <span className="asm-icon-container">
            {/* Hexagon outline */}
            <span className="asm-icon-hexagon"></span>

            {/* Logout icon */}
            <i className="asm-icon fa asm-icon-logout fas fa-sign-out-alt" />
          </span>

          <span className="asm-menu-label">
            {localize("icon_logout", this.props.language)}
          </span>
        </div>
      </LogoutContainer>
    );
  }

  /**
   * Render project information on sidebar
   */
  renderProjectMenu() {
    let customMenuClassName = "sidebar-expanded";
    //console.log("renderProjectMenu: this.props.state: " + this.props.state);

    if (!this.props.project) {
      customMenuClassName = "sidebar-collapsed";
    }

    switch (this.props.state) {
      /*
        CUSTOM FOR ASM:
        1) Collapsed side menu for "topic" and "project-secondary"
        2) Expanded side menu for "project"
      */
      case "topic":
      case "project-secondary":
        customMenuClassName = "sidebar-collapsed";
      /* falls through */
      case "project":
        return (
          <div id="project" className="pure-g asm-menu">
            <div className="pure-u-1">
              <div
                className={"asm-menu-links-container " + customMenuClassName}
              >
                <div className="asm-menu-logo">
                  {/* Logo */}
                  <div className="asm-menu-logo-expanded">
                    <img src={menu_logo} alt="ASM"></img>
                  </div>
                  <div className="asm-menu-logo-collapsed">
                    <img src={menu_collapsed_logo} alt="ASM"></img>
                  </div>
                </div>
                <div className="asm-menu-links">
                  {/* TODO: Implement necessary icons/styling in CSS */}
                  {this.renderLink("home", "fa-home", "Home", PROJECT_HOME)}
                  {this.renderLink(
                    "profile",
                    "fa-user",
                    "Profile",
                    PROJECT_PROFILE
                  )}
                  {this.renderLink(
                    "leaderboard",
                    "fa-medal",
                    "Leaderboard",
                    PROJECT_LEADERBOARD
                  )}
                  {this.renderLink(
                    "notifications",
                    "fa-bell",
                    "Notifications",
                    PROJECT_NOTIFICATIONS
                  )}
                  {this.renderLink("inbox", "fa-inbox", "Inbox", PROJECT_INBOX)}
                  {this.renderLink(
                    "settings",
                    "fa-cog",
                    "Settings",
                    PROJECT_SETTINGS
                  )}
                  {this.renderLink(
                    "tour",
                    "fa-info",
                    "Mission briefing",
                    LOGIN_TOUR
                  )}
                  {this.renderLogoutItem()}
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  /**
   * Render view
   */
  render() {
    //console.log("Sidebar - render: this.props.state: " + this.props.state);
    return (
      <div className={"sidebar-wrapper hide-below-lg"}>
        <div className="sidebar sidebarbg asm-menu-fixed">
          <div className="on-lg-horizontalpadding bottompadding-floating-extended">
            {this.renderProjectMenu()}
          </div>
        </div>
      </div>
    );
  }
}

export default ASMSidebar;
