import React, { Fragment } from "react";

import TopicCard from "components/Project/TopicCard";
import Dotdotdot from "react-dotdotdot";

import Link from "components/shared/Link/Link";
import { TOPIC } from "App/Routes";
import localize from "lang/localize";

class ASMTopicCard extends TopicCard {
  getActionButtonText() {
    if (this.props.completed && this.props.totalChallenges !== 0) {
      return localize("button_quest_completed", this.props.language);
    } else if (!this.props.started) {
      return "Start now...";
    } else {
      /* Show "Start now" for private topics that users auto-join due to content filtering by country */
      if (
        this.props.completedChallenges > 0 ||
        this.props.totalChallenges < 1
      ) {
        return "Continue...";
      } else {
        return "Start now...";
      }
    }
  }

  renderActionButton() {
    let buttonClass = "card-action button cta inline";
    let buttonTextKey = "";

    if (this.props.locked || this.props.expired) {
      buttonClass = "card-locked button disabled inline";

      if (this.props.locked) {
        buttonTextKey = "button_quest_locked";
      } else {
        buttonTextKey = "button_quest_expired";
      }

      return (
        <div className={buttonClass}>
          <div className="card-action-text">
            {localize(buttonTextKey, this.props.language)}
          </div>
        </div>
      );
    } else {
      return (
        <Link to={TOPIC.format(this.props.id)}>
          <div className={buttonClass}>
            <div className="card-action-text">{this.getActionButtonText()}</div>
          </div>
        </Link>
      );
    }
  }

  render() {
    let img = this.props.img;
    let title = this.props.title;

    return (
      <Fragment>
        <div className="asm-topic-card topic-card card">
          <div className="pure-g">
            <div className="pure-u-lg-7-24 asm-topic-card-img">
              <div
                className="card-img"
                style={{
                  backgroundImage: "url(" + img + ")"
                }}
              ></div>
            </div>
            <div className="pure-u-lg-17-24 asm-topic-card-info">
              <div className="pure-u-lg-7-24 asm-topic-card-img-mobile">
                <div
                  className="card-img"
                  style={{
                    backgroundImage: "url(" + img + ")"
                  }}
                ></div>
              </div>
              <div className="asm-topic-card-title">
                <Dotdotdot clamp={2}>{title}</Dotdotdot>
              </div>
              <div className="card-scroll">
                <p
                  className="card-desc"
                  dangerouslySetInnerHTML={{
                    __html: this.addAnchors(this.props.description)
                  }}
                />
              </div>
              {this.renderActionButton()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ASMTopicCard;
