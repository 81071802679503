import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import "intl";
import "intl/locale-data/jsonp/en.js";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import moment from "moment";

import "./CalendarDialog.css";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseCalendarDialog: PropTypes.func.isRequired,
  onMFChangeDate: PropTypes.func.isRequired,
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  keyIndex: PropTypes.number.isRequired
};

const defaultProps = {
  showModal: false
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class CalendarDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal
    };
  }

  render() {
    let dateProps = {};

    /* both */
    if (this.props.beginDate && this.props.endDate) {
      dateProps = {
        minDate: new Date(moment.utc(this.props.beginDate)),
        maxDate: new Date(moment.utc(this.props.endDate))
      };
    } else if (this.props.beginDate) {
      dateProps = {
        minDate: new Date(moment.utc(this.props.beginDate))
      };
    } else if (this.props.endDate) {
      dateProps = {
        maxDate: new Date(moment.utc(this.props.endDate))
      };
    }

    return (
      <Modal
        isOpen={this.state.showModal}
        contentLabel=""
        onRequestClose={this.props.handleCloseCalendarDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog calendar-dialog"
        overlayClassName="dialog-overlay"
      >
        <button
          onClick={() => {
            this.props.handleCloseCalendarDialog();
          }}
          className="close-button-container"
        >
          <i className="fa close-button" aria-hidden="true" />
        </button>
        <Calendar
          onClickDay={date => {
            this.props.onMFChangeDate(
              moment(date, "DD MMM YYYY"),
              this.props.keyIndex
            );
            this.props.handleCloseCalendarDialog();
          }}
          {...dateProps}
        />
      </Modal>
    );
  }
}

CalendarDialog.propTypes = propTypes;
CalendarDialog.defaultProps = defaultProps;

export default CalendarDialog;
