import React from "react";

import Link from "components/shared/Link/Link";
import TourPage from "components/shared/Tour/TourPage";

class ASMTourPage extends TourPage {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("tourPage")) {
      bodyDOM.className += " tourPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.className += " page-not-loggedin";
    }

    if (!bodyDOM.classList.contains("tourPageWrap")) {
      bodyDOM.className += " tourPageWrap";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("tourPage")) {
      bodyDOM.classList.remove("tourPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }

    if (bodyDOM.classList.contains("tourPageWrap")) {
      bodyDOM.classList.remove("tourPageWrap");
    }
  }

  render() {
    return (
      <div className="container verticalpadding relative tour-interface">
        <div className="innerblock flex flex-direction-column justify-content-center">
          <div className="pure-g">
            <div className="pure-u-1 pure-u-sm-1-12" />
            <div className="pure-u-1 pure-u-sm-5-6 bottommargin-30">
              {this.renderTourSlider()}
            </div>
            <div className="pure-u-1 pure-u-sm-1-12" />
          </div>
          <div className="pure-g tour-cta-container">
            <div className="pure-u-1 flex justify-content-center bottommargin-10">
              {this.props.completedTour ? (
                <Link
                  to={this.props.redirectUrl}
                  className="button cta uppercase"
                  id="tourLinkContinue"
                  onClick={e => this.props.handleCloseClick(e)}
                >
                  Start
                </Link>
              ) : (
                <Link
                  to={this.props.redirectUrl}
                  className="button uppercase"
                  id="tourLinkSkip"
                  onClick={e => this.props.handleCloseClick(e)}
                >
                  Skip tour
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ASMTourPage;
