import React from "react";
import PropTypes from "prop-types";

import ChallengePage from "components/Challenge/ChallengePage";
import ClaimForm from "components/Challenge/ClaimForm/ClaimForm";
import ChallengeResult from "components/Challenge/ChallengeResult/ChallengeResult";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import LocationMap from "components/Challenge/LocationMap";
import Link from "components/shared/Link/Link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Challenge.css";

import { CHALLENGE, TOPIC, PROJECT_LIST } from "App/Routes";

import { CONTACT_EMAIL_DEFAULT } from "config";
import localize from "lang/localize";

const propTypes = {
  user: PropTypes.object.isRequired,
  sessionKey: PropTypes.string,
  challengeTypeId: PropTypes.number.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  photoRequired: PropTypes.bool,
  noPhoto: PropTypes.bool,
  quizOptions: PropTypes.array,
  privateClaim: PropTypes.bool,
  fields: PropTypes.array,
  completionMessage: PropTypes.string,
  multiSelect: PropTypes.bool,
  claimed: PropTypes.bool,
  claimedBefore: PropTypes.bool,
  expired: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  videoDurationLimit: PropTypes.number,
  gridWidth: PropTypes.number,
  // location
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  addressFormatted: PropTypes.string,
  // challengeresult
  id: PropTypes.number.isRequired,
  correctAnswerNo: PropTypes.number,
  wrongAnswerNo: PropTypes.number,
  correctAnswerTitle: PropTypes.string,
  correctAnswer: PropTypes.bool,
  // image
  imageMedium: PropTypes.string,
  // embed
  medias: PropTypes.array,
  // text
  footnote: PropTypes.string,
  title: PropTypes.string,
  repeat: PropTypes.bool,
  repeatUntilCorrect: PropTypes.bool,
  repeatAtFormatted: PropTypes.string,
  videoDurationLimitFormatted: PropTypes.string,
  // stats
  commentNo: PropTypes.number,
  claimNo: PropTypes.number,
  previousChallengeId: PropTypes.number,
  nextChallengeId: PropTypes.number,
  challengeType: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  challengeReferralLink: PropTypes.string,
  isLiked: PropTypes.bool.isRequired,
  likeNo: PropTypes.number.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  postClaim: PropTypes.func.isRequired,
  postQRClaim: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  adminToClaim: PropTypes.bool.isRequired,
  // login dialogs
  showClaimLoginDialog: PropTypes.bool.isRequired,
  handleOpenClaimLoginDialog: PropTypes.func.isRequired,
  handleCloseClaimLoginDialog: PropTypes.func.isRequired,
  showBookmarkLoginDialog: PropTypes.bool.isRequired,
  handleOpenBookmarkLoginDialog: PropTypes.func.isRequired,
  handleCloseBookmarkLoginDialog: PropTypes.func.isRequired,
  showLikeLoginDialog: PropTypes.bool.isRequired,
  handleOpenLikeLoginDialog: PropTypes.func.isRequired,
  handleCloseLikeLoginDialog: PropTypes.func.isRequired,
  // image dialogs
  showImageDialog: PropTypes.bool.isRequired,
  handleOpenImageDialog: PropTypes.func.isRequired,
  handleCloseImageDialog: PropTypes.func.isRequired,
  // embed dialogs
  showEmbedDialog: PropTypes.bool.isRequired,
  handleOpenEmbedDialog: PropTypes.func.isRequired,
  handleCloseEmbedDialog: PropTypes.func.isRequired,
  // qr dialogs
  showQRModal: PropTypes.bool,
  handleOpenQRModal: PropTypes.func,
  handleCloseQRModal: PropTypes.func,
  // minigame dialogs
  showExternalChallengeDialog: PropTypes.bool,
  handleOpenExternalChallengeDialog: PropTypes.func,
  handleCloseExternalChallengeDialog: PropTypes.func,
  // language
  language: PropTypes.string,
  // contact email
  contactEmail: PropTypes.string,
  // locked content handling
  locked: PropTypes.bool,
  lockedChallengeTopicId: PropTypes.number,
  lockedChallengeTopicTitle: PropTypes.string,
  // project and topic information
  project: PropTypes.object,
  topic: PropTypes.object,
  topicIdNext: PropTypes.number,
  // minigame
  challengeMinigameTitle: PropTypes.string,
  // challenge time tracking
  challengeMinViewDuration: PropTypes.number
};

const defaultProps = {
  contactEmail: CONTACT_EMAIL_DEFAULT
};

class ASMChallengePage extends ChallengePage {
  /**
   * Render button to navigate to previous challenge
   */
  renderPreviousChallengeButton() {
    /* Previous Challenge exists, and previous Challenge is not locked */
    if (this.props.previousChallengeId) {
      return (
        <div className="asm-challenge-nav-btn asm-challenge-nav-btn-prev">
          <Link
            className="challenge-nav challenge-nav-prev"
            to={CHALLENGE.format(this.props.previousChallengeId)}
          >
            <i className="fas fa-caret-left"></i>
          </Link>
        </div>
      );
    } else {
      /* Previous Challenge does not exist */
      return null;
    }
  }

  /**
   * Render button to navigate to next challenge
   */
  renderNextChallengeButton() {
    /* Next Challenge exists, and next Challenge is not locked */
    if (this.props.nextChallengeId) {
      return (
        <div className="asm-challenge-nav-btn asm-challenge-nav-btn-next">
          <Link
            className="challenge-nav challenge-nav-next"
            to={CHALLENGE.format(this.props.nextChallengeId)}
          >
            <i className="fas fa-caret-right"></i>
          </Link>
        </div>
      );
    } else {
      /* Next Challenge does not exist */
      return null;
    }
  }

  /**
   * Render action buttons (bookmark/like, comments)
   * Displayed at the side of the challenge information segment
   */
  renderActionButtons() {
    return (
      <div className="asm-challenge-action-buttons">
        {this.props.challengeTypeId === 4 && this.renderLikeButton()}
        {this.renderChallengeCommentsButton()}
      </div>
    );
  }

  /**
   * Render challenge image
   */
  renderImg() {
    let imageMedium = this.props.imageMedium;

    return (
      <div>
        <div className="asm-challenge-image-wrapper">
          <button
            className="button icon"
            onClick={this.props.handleOpenImageDialog}
          >
            <span className="expand-icon" />
          </button>
          <span
            className="asm-challenge-image challenge-image"
            onClick={this.props.handleOpenImageDialog}
          >
            <div
              className="card-img"
              style={{
                backgroundImage: "url(" + imageMedium + ")"
              }}
            ></div>
          </span>
        </div>
        {this.props.showImageDialog && this.renderImageDialog()}
      </div>
    );
  }

  /* Attempt to add expand icon for Youtube - not working */
  /*renderYoutubeWrapper(embed) {
    return (
      <div>
        <span className="asm-challenge-image-wrapper">
          <button
            className="button icon"
            onClick={this.props.handleOpenEmbedDialog}
          >
            <span className="expand-icon" />
          </button>
          {this.renderYoutube(embed)}
        </span>
      </div>
    );
  }*/

  /* Wrapper for MP4 embed */
  renderVideoWrapper(embed) {
    //let displayUrl = embed.displayUrl;

    return (
      <div className="asm-embed-offset-top">
        {this.renderVideo(embed)}
        {/* Can't seem to get expand icon working */}
        {/*<div
          key={displayUrl}
          className={
            "iframe-container-challenge-profile bottommargin" +
            (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
          }
        >

          <button
            className="button icon"
            onClick={this.props.handleOpenEmbedDialog}
          >
            <span className="expand-icon" />
          </button>
          <PostIframe
            hiddenFields={{
              session_key: this.props.sessionKey
            }}
            url={embed.url}
            title={displayUrl}
            allowFullScreen={true}
          />
        </div>*/}
      </div>
    );
  }

  /* Attempt to add expand icon */
  /*renderPdfWrapper(embed) {
    //return this.renderOthers(embed);
    return this.renderPdf(embed);
  }*/

  /**
   * Render embedded media for challenge
   */
  renderEmbed() {
    let embeddable = this.getFirstEmbeddableItem();
    let embedContent;

    if (
      embeddable &&
      this.props.challengeTypeId !== 15 &&
      embeddable.url.indexOf("https://") === 0
    ) {
      switch (embeddable.type) {
        case "Video":
          embedContent = this.renderVideoWrapper(embeddable);
          break;
        case "PDF": //no popup support
          embedContent = this.renderPdf(embeddable);
          break;
        case "Youtube": //no popup support
          embedContent = this.renderYoutube(embeddable);
          break;
        case "Vimeo": //no popup support
          embedContent = this.renderYoutubeVimeo(embeddable);
          break;
        case "SoundCloud":
          /* SoundCloud embed turned off temporarily until client key is given */
          embedContent = this.renderImg();
          break;
        case "Others":
          embedContent = this.renderOthers(embeddable);
          break;
        default:
          return this.renderImg();
      }

      const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 100,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: this.state.currentSlide,
        afterChange: current => this.setState({ currentSlide: current }),
        className: "bottommargin-30 leftmargin-10 rightmargin-10"
      };

      return (
        <Slider ref={this.sliderRef} {...settings}>
          {this.renderImg()}
          {embedContent}
        </Slider>
      );
    } else {
      return this.renderImg();
    }
  }

  /**
   * Render challenge media (images/embeddable media)
   */
  renderMedia() {
    let hasEmbed = this.checkEmbed();

    return (
      <div className={hasEmbed ? "relative" : ""}>{this.renderEmbed()}</div>
    );
  }

  /**
   * Render challenge information segment
   */
  renderChallengeInfo() {
    if (this.props.locked) {
      return (
        <div className="pure-g innerblock">
          <div className="pure-u-sm-1-24" />
          <div className="pure-u-22-24 topmargin-30">
            <GenericErrorPage
              routeUrl={TOPIC.format(this.props.lockedChallengeTopicId)}
              routeName={"“" + this.props.lockedChallengeTopicTitle + "”"}
              message={localize(
                "locked_challenge_with_route",
                this.props.language
              ).format(this.props.lockedChallengeTopicTitle)}
              language={this.props.language}
            />
          </div>
          <div className="pure-u-sm-1-24" />
        </div>
      );
    } else {
      return (
        <div>
          {/* Render Challenge page as per normal */}
          <div className="pure-g innerblock display-block">
            <div className="pure-u-sm-1-24" />
            <div className="pure-u-22-24 asm-media-fit-width">
              {/* LEFT COLUMN */}
              <div className="pure-u-1 pure-u-md-10-24 bottommargin-20">
                {this.renderMedia()}
              </div>

              {/* RIGHT COLUMN */}
              <div className="pure-u-md-1-24" />
              <div className="pure-u-1 pure-u-md-13-24">
                {/* Challenge type icon/points */}
                <div className="challenge-icons-position bottommargin-20">
                  <div
                    className={
                      "challengeicon icon asm-challengeicon " +
                      (this.props.isConfirmationChallenge
                        ? "confirmation"
                        : this.props.challengeType)
                    }
                  />
                  {this.props.points !== 0 ? (
                    <div className="challengeicon asm-challengeicon ">
                      <div className="points">
                        +{this.props.points.abbreviateNumber()}
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* Challenge information */}
                <div className="asm-challenge-description">
                  {this.renderText()}
                </div>
                {this.renderStats()}
                {this.renderActionButtons()}
              </div>
            </div>
            <div className="pure-u-sm-1-24" />
          </div>
          <div className="challenge-page-divider">
            <hr />
          </div>
        </div>
      );
    }
  }

  /**
   * Render button to navigate to next topic (button only)
   * CUSTOM FOR ASM: Client wants this button to redirect to main screen instead.
   */
  renderNextTopicButton() {
    if (this.props.topicIdNext) {
      return (
        <Link
          to={PROJECT_LIST}
          className="button display-inline-block cta topic-next-button"
        >
          {localize("back_button_text_start", this.props.language).format(
            "Home"
          )}
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * CUSTOM - Challenge title is centered
   */
  renderText() {
    let footnoteAnchored = this.addAnchors(this.props.footnote);
    let titleAnchored = this.addAnchors(this.props.title);

    return (
      <div className="responsive-img-description">
        {this.props.challengeTypeId === 15 && (
          <p className="smalltext uppercase asm-challenge-text">
            {" "}
            {this.props.challengeMinigameTitle}{" "}
          </p>
        )}
        <h5
          className="asm-challenge-text"
          dangerouslySetInnerHTML={{ __html: titleAnchored }}
        />
        {!(this.props.challengeTypeId === 15) && (
          <p
            className="asm-challenge-text"
            dangerouslySetInnerHTML={{ __html: footnoteAnchored }}
          />
        )}
        <p className="smalltext topmargin-30">
          {this.getRepeatText(this.props.repeat)}
          {this.getVideoDurationLimitText()}
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="challenge">
        {this.renderChallengeNavigationButtons()}
        {this.renderNextTopicNavigation()}
        <div className="container toppadding">{this.renderChallengeInfo()}</div>
        <ClaimForm
          sessionKey={this.props.sessionKey}
          isLoggedIn={!!this.props.user.id}
          challengeTypeId={this.props.challengeTypeId}
          challengeId={this.props.id}
          title={this.props.title}
          isConfirmationChallenge={this.props.isConfirmationChallenge}
          photoRequired={this.props.photoOnly}
          noPhoto={this.props.noPhoto}
          quizOptions={this.props.quizOptions}
          fields={this.props.fields}
          completionMessage={this.props.completionMessage}
          multiSelect={this.props.multiSelect}
          claimed={this.props.claimed}
          claimedBefore={this.props.claimedBefore}
          postClaim={this.props.postClaim}
          postQRClaim={this.props.postQRClaim}
          gridWidth={this.props.gridWidth}
          showAlertWithTimeout={this.props.showAlertWithTimeout}
          adminToClaim={this.props.adminToClaim}
          expired={this.props.expired}
          submitted={this.props.submitted}
          videoDurationLimit={this.props.videoDurationLimit}
          videoDurationLimitFormatted={this.props.videoDurationLimitFormatted}
          externalEmbeddable={this.getFirstEmbeddableItem()}
          handleOpenClaimLoginDialog={this.props.handleOpenClaimLoginDialog}
          showQRModal={this.props.showQRModal}
          handleOpenQRModal={this.props.handleOpenQRModal}
          handleCloseQRModal={this.props.handleCloseQRModal}
          showExternalChallengeDialog={this.props.showExternalChallengeDialog}
          handleOpenExternalChallengeDialog={
            this.props.handleOpenExternalChallengeDialog
          }
          handleCloseExternalChallengeDialog={
            this.props.handleCloseExternalChallengeDialog
          }
          challengeMinigameTitle={this.props.challengeMinigameTitle}
          nextChallengeId={this.props.nextChallengeId}
          challengeMinViewDuration={this.props.challengeMinViewDuration}
          isTimerActive={this.props.isTimerActive}
          setTimerActive={this.props.setTimerActive}
          countdownTime={this.props.countdownTime}
          language={this.props.language}
          charLimit={this.props.charLimit}
          repeat={this.props.repeat}
          repeatAtFormatted={this.props.repeatAtFormatted}
          repeatUntilCorrect={this.props.repeatUntilCorrect}
          correctAnswer={this.props.correctAnswer}
          correctAnswerTitle={this.props.correctAnswerTitle}
        />
        {this.props.showClaimLoginDialog && this.renderClaimLoginDialog()}
        <LocationMap
          latitude={this.props.latitude}
          longitude={this.props.longitude}
          addressFormatted={this.props.addressFormatted}
          language={this.props.language}
        />
        <div className="bottompadding">
          <ChallengeResult
            id={this.props.id}
            userName={this.props.user.name}
            userPhoto={this.props.user.photoSmall}
            challengeTypeId={this.props.challengeTypeId}
            claimed={!!this.props.claimed}
            claimedBefore={!!this.props.claimedBefore}
            repeatUntilCorrect={this.props.repeatUntilCorrect}
            correctAnswerNo={this.props.correctAnswerNo}
            wrongAnswerNo={this.props.wrongAnswerNo}
            correctAnswerTitle={this.props.correctAnswerTitle}
            correctAnswer={this.props.correctAnswer}
            quizOptions={this.props.quizOptions}
            gridWidth={this.props.gridWidth}
            privateClaim={this.props.privateClaim}
            language={this.props.language}
          />
        </div>
      </div>
    );
  }
}

ASMChallengePage.propTypes = propTypes;
ASMChallengePage.defaultProps = defaultProps;

export default ASMChallengePage;
