import React, { Component } from "react";
import PropTypes from "prop-types";

import { ENABLE_LOGIN_HOME } from "config";
import {
  PROJECT_LOGIN,
  LOGIN,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME
} from "App/Routes";
import Modal from "react-modal";
import AlertContainer from "components/shared/Alert/AlertContainer";
import TextInput from "components/shared/Inputs/TextInput";

import localize from "lang/localize";

import "./GuestLoginDialog.css";

const propTypes = {
  projectId: PropTypes.number,
  username: PropTypes.string,
  showGuestLoginDialog: PropTypes.bool.isRequired,
  handleCloseGuestLoginDialog: PropTypes.func.isRequired,
  handleUsernameChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class GuestLoginDialog extends Component {
  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.props.handleSubmit(event);
    }
  };

  getLoginRoute() {
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showGuestLoginDialog}
        contentLabel={localize("dialog_login_guest_title", this.props.language)}
        onRequestClose={this.props.handleCloseGuestLoginDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog guest-login-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize("dialog_login_guest_title", this.props.language)}
            </h5>
          </div>
          <AlertContainer />
          <div className="dialog-body">
            <form className="pure-form">
              <TextInput
                id="emailInput"
                value={this.props.email}
                onChange={this.props.handleUsernameChange}
                onKeyPress={this.handleKeyPress}
                placeholder={localize(
                  "login_view_guest_username_placeholder_text",
                  this.props.language
                )}
              />
            </form>
            <p
              className="nomargin"
              dangerouslySetInnerHTML={{
                __html: localize(
                  "dialog_login_guest_disclaimer_text",
                  this.props.language
                ).format(
                  '<a className="link default" href="' +
                    this.getLoginRoute() +
                    '">' +
                    localize(
                      "dialog_login_guest_disclaimer_register_login_text",
                      this.props.language
                    ) +
                    "</a>"
                )
              }}
            />
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseGuestLoginDialog}
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              <button
                className="button inline cta capitalize"
                onClick={this.props.handleSubmit}
              >
                {localize("button_quest_continue", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

GuestLoginDialog.propTypes = propTypes;

export default GuestLoginDialog;
