import React from "react";
import PropTypes from "prop-types";

import "./AwardableActions.css";

const propTypes = {
  awardableActions: PropTypes.array.isRequired
};

const AwardableActions = props => {
  let displayAwardableAction = props.awardableActions.length > 0
    ? props.awardableActions[0]
    : null;
  return (
    <div
      className={
        "awardable-actions-wrapper " + (displayAwardableAction ? "visible" : "")
      }
    >
      {displayAwardableAction &&
        <p className="points-plus-text">
          {"+" + displayAwardableAction.awardableAction.numberStr}
        </p>}
      {displayAwardableAction &&
        <p className="points-text">
          {displayAwardableAction.awardableAction.unit}
        </p>}
    </div>
  );
};

AwardableActions.propTypes = propTypes;

export default AwardableActions;
