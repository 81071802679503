import React, { Component } from "react";
import PropTypes from "prop-types";

import "./VideoDialog.css";

import deviceServices from "services/deviceServices";

import Modal from "react-modal";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseVideoDialog: PropTypes.func.isRequired,
  claimVideo: PropTypes.string.isRequired,
  claimVideoContainer: PropTypes.string.isRequired
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

/**
 * Pop-up Modal to render a single video
 */
class VideoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal
    };
  }

  render() {
    let claimVideo = this.props.claimVideo;
    let claimVideoContainer = this.props.claimVideoContainer;

    return (
      <Modal
        isOpen={this.state.showModal}
        contentLabel=""
        onRequestClose={this.props.handleCloseVideoDialog}
        shouldCloseOnOverlayClick={true}
        className="dialog video-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-body">
            <button
              onClick={() => {
                this.props.handleCloseVideoDialog();
              }}
              className="close-button-container"
            >
              <i className="fa close-button" aria-hidden="true" />
            </button>
            <div className="video-dialog-iframe-container">
              <div
                className={
                  "iframe-container" +
                  (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
                }
              >
                <iframe
                  title={claimVideo}
                  src={claimVideoContainer}
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

VideoDialog.propTypes = propTypes;

export default VideoDialog;
