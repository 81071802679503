import React, { Component } from "react";
import PropTypes from "prop-types";

import "./PersonalityModal.css";

import Modal from "react-modal";

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  personality: PropTypes.object.isRequired
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class PersonalityModal extends Component {
  renderCloseButton() {
    return (
      <div>
        <button
          onClick={() => {
            this.props.onRequestClose();
          }}
          className="close-button"
        >
          <i className="fa close-icon" aria-hidden="true" />
        </button>
      </div>
    );
  }

  render() {
    let personalityTag = this.props.personality
      ? this.props.personality.tag
      : "";
    let personalityImage = this.props.personality
      ? this.props.personality.image
      : "";
    let personalityDescription = this.props.personality
      ? this.props.personality.description
      : "";

    return (
      <Modal
        isOpen={this.props.isOpen}
        contentLabel=""
        onRequestClose={this.props.onRequestClose}
        shouldCloseOnOverlayClick={false}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {this.renderCloseButton()}

        <div className="modal-body textcenter">
          {this.props.personality.tag && <h3>{personalityTag}</h3>}
          {this.props.personality.image && (
            <p>
              <img
                className="modal-personality-image"
                src={personalityImage}
                alt={personalityTag}
              />
            </p>
          )}
          {this.props.personality.description && (
            <p className="modal-personality-description">
              {personalityDescription}
            </p>
          )}
        </div>
      </Modal>
    );
  }
}

PersonalityModal.propTypes = propTypes;

export default PersonalityModal;
