import React, { Component } from "react";
import PropTypes from "prop-types";

import icon_more_circle from "images/core/placeholders/item_more_circle_large@2x.png";

const propTypes = {
  img: PropTypes.string.isRequired,
  earned: PropTypes.bool.isRequired,
  isFifthItem: PropTypes.bool.isRequired
};

class TopicAchievements extends Component {
  render() {
    let img = this.props.img;

    return (
      <div className="pure-u-1-5">
        <div className="pure-u-1-24" />
        <div className="pure-u-22-24">
          <span className="square-image-wrapper">
            <span
              className={
                "square-image" + (!this.props.isFifthItem ? " circle" : "")
              }
            >
              <img
                src={this.props.isFifthItem ? icon_more_circle : img}
                alt=""
              />
              {!this.props.earned && !this.props.isFifthItem && (
                <div className="circle lockoverlay" />
              )}
            </span>
          </span>
        </div>
        <div className="pure-u-1-24" />
      </div>
    );
  }
}

TopicAchievements.propTypes = propTypes;

export default TopicAchievements;
