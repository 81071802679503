import React, { Component } from "react";
import PropTypes from "prop-types";

import "./ProgressBar.css";

import localize from "lang/localize";

const propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  language: PropTypes.string
};

const defaultProps = {
  completed: 0
};

class ProgressBar extends Component {
  render() {
    const { completed, children, total, className, ...rest } = this.props;

    if (total === 0) {
      return null;
    }

    const percent = (completed / total > 1 ? 1 : completed / total) * 100;
    const style = {
      width: percent + "%"
    };

    return (
      <div
        className={"progressbar progressbarbg " + (className || "")}
        {...rest}
      >
        <div className="progress-block" style={style}>
          {children}
        </div>
        <p className="progress-text textcenter">
          {localize("game_progress_text", this.props.language).format(
            completed,
            total
          )}
        </p>
      </div>
    );
  }
}

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
