import React from "react";
import PropTypes from "prop-types";

import "./CharacterCounter.css";

const propTypes = {
  charLimit: PropTypes.number,
  input: PropTypes.string
};

const CharacterCounter = props => {
  // If charLimit exists, add the characterCounter
  // User mentions are excluded from the character limit
  const transformedInput = props.input.replace(/(\[uid:[0-9]+\])/gm, "");
  return (
    <div>
      {props.charLimit !== 0 ? (
        <span
          className={
            "char-counter text-align-right " +
            (transformedInput.length > props.charLimit ? "text-red" : "")
          }
        >
          {props.charLimit - transformedInput.length}
        </span>
      ) : (
        <span></span>
      )}
    </div>
  );
};

CharacterCounter.propTypes = propTypes;

export default CharacterCounter;
