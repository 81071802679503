import React, { Component } from "react";
import PropTypes from "prop-types";

import { PLAYER_URL } from "config";
import deviceServices from "services/deviceServices";

const propTypes = {
  id: PropTypes.string,
  user: PropTypes.object,
  contentId: PropTypes.number,
  contentTitle: PropTypes.string,
  project: PropTypes.object,
  topic: PropTypes.object
};

// Methods copied from https://stackoverflow.com/a/54921282
class YoutubePlayer extends Component {
  lastPlayerState = null;
  lastPlayerTime = null;
  youtubeUrlPrepended = "http://www.youtube.com/watch?v=";

  componentDidMount = () => {
    // On mount, check to see if the API script is already loaded
    if (!window.YT) {
      // If not, load the script asynchronously
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.loadVideo;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      // If script is already there, load the video directly
      this.loadVideo();
    }
  };

  loadVideo = () => {
    const { id } = this.props;

    // the Player object is created uniquely based on the id in props
    this.player = new window.YT.Player(`youtube-player-${id}`, {
      videoId: id,
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange
      }
    });
  };

  /* Does nothing right now */
  onPlayerReady = event => {};

  getActor() {
    return {
      name: this.props.user.name + " (Player ID: " + this.props.user.id + ")",
      account: {
        homePage: PLAYER_URL,
        name: this.props.user.name
      }
    };
  }

  render() {
    return (
      <div
        className={
          "iframe-container-challenge-profile" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <div id={`youtube-player-${this.props.id}`}></div>
      </div>
    );
  }
}

YoutubePlayer.propTypes = propTypes;

export default YoutubePlayer;
