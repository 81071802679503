import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Home.css";
import Link from "components/shared/Link/Link";
import SearchBar from "components/shared/SearchBar/SearchBar";
import gametize_logo from "images/core/home/apphome_gametizelogo.png";
import powered_by_gametize_image from "images/core/home/powered-by-gametize.png";

import {
  ENABLE_HOME_SEARCH,
  ENABLE_FEATURED_GAMES,
  ENABLE_MY_GAMES
} from "config";
import { FEATURED_PROJECTS, MY_PROJECTS } from "App/Routes";
import localize from "lang/localize";

const propTypes = {
  handleKeyPress: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  language: PropTypes.string
};

class HomePage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("homePage")) {
      bodyDOM.className += " homePage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("homePage")) {
      bodyDOM.classList.remove("homePage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderSearchbar() {
    if (ENABLE_HOME_SEARCH) {
      return (
        <div className="pure-u-1 flex justify-content-center">
          <div className="homepage-flex-width">
            <SearchBar
              handleSearchChange={this.props.handleSearchChange}
              handleKeyPress={this.props.handleKeyPress}
              handleSubmit={this.props.handleSubmit}
              placeholder={localize(
                "home_search_game_placeholder_text",
                this.props.language
              )}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderHomeButtons() {
    return (
      <div className="home-buttons">
        {this.renderFeaturedGamesButton()}
        {this.renderMyGamesButton()}
        {this.renderBottomButtons()}
      </div>
    );
  }

  renderFeaturedGamesButton() {
    if (ENABLE_FEATURED_GAMES) {
      return (
        <div className="pure-u-g">
          <div className="pure-u-1-8 pure-u-md-1-6 pure-u-lg-5-24 pure-u-xl-7-24" />
          <div className="pure-u-3-4 pure-u-md-2-3 pure-u-lg-7-12 pure-u-xl-5-12">
            <Link to={FEATURED_PROJECTS} className="button cta">
              {localize("button_allgames_gametize", this.props.language)}
            </Link>
          </div>
          <div className="pure-u-1-8 pure-u-md-1-6 pure-u-lg-5-24 pure-u-xl-7-24" />
        </div>
      );
    } else {
      return null;
    }
  }

  renderMyGamesButton() {
    if (ENABLE_MY_GAMES) {
      return (
        <div className="pure-u-g">
          <div className="pure-u-1-8 pure-u-md-1-6 pure-u-lg-5-24 pure-u-xl-7-24" />
          <div className="pure-u-3-4 pure-u-md-2-3 pure-u-lg-7-12 pure-u-xl-5-12">
            <Link to={MY_PROJECTS} className="button cta">
              {localize("button_mygames_gametize", this.props.language)}
            </Link>
          </div>
          <div className="pure-u-1-8 pure-u-md-1-6 pure-u-lg-5-24 pure-u-xl-7-24" />
        </div>
      );
    } else {
      return null;
    }
  }

  renderBottomButtons() {
    return (
      <div className="pure-u-g">
        <div className="pure-u-1-24 pure-u-md-1-12 pure-u-lg-1-6 pure-u-xl-1-4" />
        <div className="pure-u-11-12 pure-u-md-5-6 pure-u-lg-2-3 pure-u-xl-1-2 bottom-links">
          <div className="pure-u-1-2 horizontalpadding-5">
            <a
              className="button fullwidth cta align-items-center justify-content-center"
              href="https://www.facebook.com/Gametize"
              target="_blank"
              rel="noopener noreferrer"
            >
              {localize("button_facebook_gametize", this.props.language)}
            </a>
          </div>
          <div className="pure-u-1-2 horizontalpadding-5">
            <a
              href="https://twitter.com/gametize"
              target="_blank"
              rel="noopener noreferrer"
              className="button fullwidth cta align-items-center justify-content-center"
            >
              {localize("button_twitter_gametize", this.props.language)}
            </a>
          </div>
        </div>
        <div className="pure-u-1-24 pure-u-md-1-12 pure-u-lg-1-6 pure-u-xl-1-4" />
      </div>
    );
  }

  renderHomeLogo() {
    return (
      <div className="home-logo-container">
        <img className="imgresponsive" src={gametize_logo} alt="Gametize" />
      </div>
    );
  }

  /* To be rendered only when requested */
  renderPoweredByContainer() {
    return (
      <div className="pure-u-1 flex justify-content-center topmargin-30">
        <div className="home-powered-by-container">
          <a
            href="https://www.gametize.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="imgresponsive"
              src={powered_by_gametize_image}
              alt=""
            />
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="home container">
        <div className="innerblock pure-g textcenter">
          {this.renderSearchbar()}
          {this.renderHomeLogo()}
          {this.renderHomeButtons()}
        </div>
      </div>
    );
  }
}

HomePage.propTypes = propTypes;

export default HomePage;
