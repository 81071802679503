import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

const TextboxInput = props => {
  let placeholder = props.placeholder;

  return (
    <textarea placeholder={placeholder} onChange={props.onChange} {...props} />
  );
};

TextboxInput.propTypes = propTypes;

export default TextboxInput;
