import React, { Component } from "react";
import PropTypes from "prop-types";

import { PROJECT_USER, USER, PROJECT_PROFILE, PROFILE } from "App/Routes";
import Link from "components/shared/Link/Link";
import localize from "lang/localize";

import { ENABLE_FOLLOWS } from "config";

const propTypes = {
  projectId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  isFollowing: PropTypes.bool,
  handleFollow: PropTypes.func,
  error: PropTypes.string
};

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFollowing: this.props.isFollowing
    };
  }

  getLink() {
    if (this.props.userId === this.props.id && this.props.projectId) {
      return PROJECT_PROFILE.format(this.props.projectId);
    } else if (this.props.userId === this.props.id) {
      return PROFILE;
    } else if (this.props.projectId) {
      return PROJECT_USER.format(this.props.projectId, this.props.id);
    } else {
      return USER.format(this.props.id);
    }
  }

  /**
   * Render the follow button
   * Depending on if the follow request is successful,
   * follow button will change locally
   */
  renderFollowButton() {
    return (
      <button
        className={
          "button rightmargin follow-button " +
          (!this.state.isFollowing ? "cta" : "inactive")
        }
        onClick={async () => {
          await this.props.handleFollow(this.props.id);
          if (this.props.error === "") {
            this.setState({
              isFollowing: !this.state.isFollowing
            });
          }
        }}
      >
        {this.state.isFollowing
          ? localize("button_user_following")
          : localize("button_user_follow", this.props.language)}
      </button>
    );
  }

  render() {
    let img = this.props.img;
    let name = this.props.name;
    let link = this.getLink();
    const isOwnProfile = this.props.userId === this.props.id;

    return (
      <div className="pure-g bottompadding align-items-center">
        <div className="pure-u-16-24 pure-u-md-21-24">
          <Link to={link}>
            <div className="pure-g">
              <div className="pure-u-1-24 pure-u-md-1-24" />
              <div className="pure-u-6-24 pure-u-md-8-24">
                {/* //mobile */}
                <img
                  className="iconsmall circle imgcenter hide-from-md"
                  src={img}
                  alt={name}
                />
                {/* //desktop */}
                <img
                  className="iconlarge circle imgcenter hide-below-md"
                  src={img}
                  alt={name}
                />
              </div>
              <div className="pure-u-2-24 pure-u-md-1-24" />
              <div className="pure-u-14-24 pure-u-md-10-24 relative">
                <div className="center">
                  <strong>{name}</strong>
                </div>
              </div>
              <div className="pure-u-2-24 pure-u-md-4-24" />
            </div>
          </Link>
        </div>
        <div className="pure-u-1-24 pure-u-md-1-24" />
        <div className="pure-u-7-24 pure-u-md-1-24 relative">
          {ENABLE_FOLLOWS && !isOwnProfile && this.renderFollowButton()}
        </div>
        <div className="pure-u-1-24 pure-u-md-1-24" />
      </div>
    );
  }
}

Team.propTypes = propTypes;

export default Team;
