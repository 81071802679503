import React, { Component } from "react";
import PropTypes from "prop-types";

import Achievement from "components/Profile/Achievements/Achievement";
import RewardContainer from "components/Project/Rewards/RewardContainer";

const propTypes = {
  type: PropTypes.oneOf(["reward", "store"]).isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  imageSmall: PropTypes.string.isRequired,
  earned: PropTypes.bool,
  description: PropTypes.string,
  descriptionAfter: PropTypes.string,
  descriptionBefore: PropTypes.string,
  unlockedAtFormatted: PropTypes.string,
  price: PropTypes.number,
  quantityAvailable: PropTypes.number,
  ownedQuantity: PropTypes.number,
  ownedQuantityLimit: PropTypes.number,
  adminOnly: PropTypes.bool,
  handleClick: PropTypes.func,
  projectId: PropTypes.number,
  loggedIn: PropTypes.bool.isRequired
};

class ItemPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("itemPage")) {
      bodyDOM.className += " itemPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("itemPage")) {
      bodyDOM.classList.remove("itemPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderItem() {
    switch (this.props.type) {
      case "reward":
        return (
          <Achievement
            id={this.props.id}
            title={this.props.name}
            img={this.props.imageSmall}
            description={
              this.props.earned
                ? this.props.descriptionAfter
                : this.props.descriptionBefore
            }
            earned={this.props.earned}
            unlockedAt={
              this.props.earned ? this.props.unlockedAtFormatted : null
            }
            projectId={this.props.projectId}
          />
        );
      case "store":
        let reward = this.props;
        return (
          <RewardContainer
            id={reward.id}
            title={reward.name}
            description={reward.description}
            img={reward.imageSmall}
            points={reward.price}
            available={reward.quantityAvailable}
            owned={reward.ownedQuantity}
            ownedLimit={reward.ownedQuantityLimit}
            adminOnly={reward.adminOnly}
            handleClick={event => this.props.handleClick(event, reward.id)}
            projectId={this.props.projectId}
            loggedIn={this.props.loggedIn}
          />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="container verticalpadding">
        <div className="innerblock pure-g">
          <div className="pure-u-1 pure-u-md-16-24 automargin">
            {this.renderItem()}
          </div>
        </div>
      </div>
    );
  }
}

ItemPage.propTypes = propTypes;

export default ItemPage;
