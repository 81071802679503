import React from "react";
import { connect } from "react-redux";

import {
  mapStateToProps,
  mapDispatchToProps,
  TopbarContainer
} from "components/Topbar/TopbarContainer";

import Topbar from "custom-components/Topbar/Topbar"; //CUSTOM FOR ASM

import {
  /* For routing: getBackLink() */
  HOME,
  PROJECT_HOME,
  PROFILE
} from "App/Routes";

import { ENABLE_LOGIN_TOPBAR, ENABLE_TOUR_TOPBAR } from "config";

export class ASMTopbarContainer extends TopbarContainer {
  getCustomBackLink() {
    switch (this.props.state) {
      case "topic":
      case "topic-category":
        if (this.props.projectId) {
          if (this.props.topic && this.props.topic.id) {
            return (
              PROJECT_HOME.format(this.props.projectId) +
              "#topic" +
              this.props.topic.id
            );
          } else {
            return PROJECT_HOME.format(this.props.projectId);
          }
        } else {
          return HOME;
        }
      case "rewards":
        return PROFILE;
      case "profile":
        return "";
      default:
        return this.getBackLink();
    }
  }

  render() {
    //console.log("this.props.state: " + this.props.state);

    if (
      this.props.state === "no-topbar" ||
      (!ENABLE_LOGIN_TOPBAR &&
        (this.props.state === "login" || this.props.state === "login-home")) ||
      (!ENABLE_TOUR_TOPBAR && this.props.state === "login-tour")
    ) {
      return null;
    } else {
      return (
        <Topbar
          showMenu={this.state.showMenu}
          title={this.setTitle()}
          state={this.props.state}
          isMobileProjectPage={
            this.state.isMobilePage &&
            (this.props.state === "home-single" ||
              this.props.state === "projecthome")
          }
          isHome={this.isHome()}
          isEmbeddedProject={this.isEmbeddedProject()}
          loggedIn={this.isLoggedIn()}
          user={this.props.user}
          projectId={this.props.projectId}
          handleBack={this.handleBack}
          backLink={this.getCustomBackLink()}
          project={this.props.project}
          topicCategory={this.props.topicCategory}
          challengeCategory={this.props.challengeCategory}
          topic={this.props.topic}
          challenge={this.props.challenge}
          handleShowMenu={this.handleShowMenu}
          handleHideMenu={this.handleHideMenu}
          showSearchChallengeDialog={this.state.showSearchChallengeDialog}
          handleOpenSearchChallengeDialog={this.handleOpenSearchChallengeDialog}
          handleCloseSearchChallengeDialog={
            this.handleCloseSearchChallengeDialog
          }
          showShareGameDialog={this.state.showShareGameDialog}
          handleOpenShareGameDialog={this.handleOpenShareGameDialog}
          handleCloseShareGameDialog={this.handleCloseShareGameDialog}
          showSelectTopicCategoryDialog={
            this.state.showSelectTopicCategoryDialog
          }
          showTopicsListDialog={this.state.showTopicsListDialog}
          handleOpenSelectTopicCategoryDialog={
            this.handleOpenSelectTopicCategoryDialog
          }
          handleCloseSelectTopicCategoryDialog={
            this.handleCloseSelectTopicCategoryDialog
          }
          handleOpenTopicsListDialog={this.handleOpenTopicsListDialog}
          handleCloseTopicsListDialog={this.handleCloseTopicsListDialog}
          showSelectChallengeCategoryDialog={
            this.state.showSelectChallengeCategoryDialog
          }
          handleOpenSelectChallengeCategoryDialog={
            this.handleOpenSelectChallengeCategoryDialog
          }
          handleCloseSelectChallengeCategoryDialog={
            this.handleCloseSelectChallengeCategoryDialog
          }
          showLanguageSelectionDialog={this.state.showLanguageSelectionDialog}
          handleOpenLanguageSelectionDialog={
            this.handleOpenLanguageSelectionDialog
          }
          handleCloseLanguageSelectionDialog={
            this.handleCloseLanguageSelectionDialog
          }
          showTourModal={this.state.showTourModal}
          handleOpenTourModal={this.handleOpenTourModal}
          handleCloseTourModal={this.handleCloseTourModal}
          handleRefresh={this.handleRefresh}
          language={this.props.language}
          sessionKey={this.props.sessionKey}
        />
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ASMTopbarContainer);
