import React, { Component } from "react";
import PropTypes from "prop-types";

import { PROJECT_HOME } from "App/Routes";
import placeholder_project from "images/core/placeholders/placeholder_project.png";
import placeholder_topic from "images/core/placeholders/placeholder_topic.jpg";
import "./Sidebar.css";
import Link from "components/shared/Link/Link";
import ShareGameDialog from "components/shared/Dialogs/ShareGameDialog/ShareGameDialog";
import ResetActivityDialogContainer from "components/shared/Dialogs/ResetActivityDialog/ResetActivityDialogContainer";
import ProgressBar from "components/shared/ProgressBar/ProgressBar";
import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";

import sessionStorageService from "services/sessionStorageService";
import localize from "lang/localize";
import urlParse from "library/js/url";

const propTypes = {
  state: PropTypes.oneOf(["project", "project-secondary", "topic"]),
  projectId: PropTypes.number,
  projectName: PropTypes.string,
  projectDescription: PropTypes.string,
  projectImage: PropTypes.string,
  project: PropTypes.object,
  showTeams: PropTypes.bool,
  topicId: PropTypes.number,
  topicName: PropTypes.string,
  topicDescription: PropTypes.string,
  topicImage: PropTypes.string,
  totalChallenges: PropTypes.number,
  completedChallenges: PropTypes.number,
  isProjectPage: PropTypes.bool,
  showShareGameDialog: PropTypes.bool.isRequired,
  handleOpenShareGameDialog: PropTypes.func.isRequired,
  handleCloseShareGameDialog: PropTypes.func.isRequired,
  allowReset: PropTypes.bool,
  showTopicResetActivityDialog: PropTypes.bool.isRequired,
  handleOpenResetTopicActivityDialog: PropTypes.func.isRequired,
  handleCloseResetTopicActivityDialog: PropTypes.func.isRequired,
  sessionKey: PropTypes.string,
  language: PropTypes.string
};
const defaultProps = {
  projectImage: placeholder_project,
  topicImage: placeholder_topic
};

class Sidebar extends Component {
  /**
   * Add anchor text links
   *
   * @param {string} text - Text
   */
  addAnchors(text) {
    // add anchor tags to links
    return urlParse(text, true);
  }

  /**
   * Render project information on sidebar
   */
  renderProject() {
    let anchoredProjectName = this.addAnchors(this.props.projectName);
    let anchoredProjectDescription = this.addAnchors(
      this.props.projectDescription
    );

    let projectImage = this.props.projectImage;

    switch (this.props.state) {
      case "topic":
        if (this.props.topicId) {
          return (
            <Link
              id="project-small"
              to={PROJECT_HOME.format(this.props.projectId)}
            >
              <div className="topic-bar table">
                <img
                  className="iconsmall verticalmargin-5 horizontalmargin-10 alignmiddle tablecell"
                  src={projectImage}
                  alt={this.props.projectName}
                />
                <div className="tablecell alignmiddle">
                  {this.props.projectName}
                </div>
              </div>
            </Link>
          );
        }
      /*
          Include the "falls through" comment below so that jshint/eslint can tell
          you are expecting this case to include no break; or return;
        */
      /* falls through */
      case "project":
      case "project-secondary":
        return (
          <div id="project" className="pure-g toppadding">
            {this.renderSidebarShareButton()}
            {this.props.showShareGameDialog && this.renderShareGameDialog()}
            <div className="pure-u-1">
              <div className="pure-u-1-2 imgcenter">
                <span className="square-image-wrapper bottommargin-10">
                  <span className="square-image circle">
                    <img src={projectImage} alt={this.props.projectName} />
                  </span>
                </span>
              </div>
              <h5
                className="textcenter"
                dangerouslySetInnerHTML={{ __html: anchoredProjectName }}
              />
              <p
                className="textcenter smalltext"
                dangerouslySetInnerHTML={{ __html: anchoredProjectDescription }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  /**
   * Render topic information on sidebar
   */
  renderTopic() {
    let anchoredTopicName = this.addAnchors(this.props.topicName);
    let anchoredTopicDescription = this.addAnchors(this.props.topicDescription);
    let topicImage = this.props.topicImage;

    switch (this.props.state) {
      case "topic":
        if (this.props.topicId) {
          return (
            <div id="topic" className="pure-g horizontalpadding">
              <div className="pure-u-1">
                <div className="pure-u-1-2 imgcenter">
                  <span className="square-image-wrapper bottommargin-10">
                    <span className="square-image circle">
                      <img src={topicImage} alt={this.props.topicName} />
                    </span>
                  </span>
                </div>
                <h5
                  className="textcenter"
                  dangerouslySetInnerHTML={{ __html: anchoredTopicName }}
                />
                <p
                  className="textcenter smalltext"
                  dangerouslySetInnerHTML={{ __html: anchoredTopicDescription }}
                />

                {this.props.allowReset
                  ? this.renderTopicProgressBarWithTopicOptions()
                  : this.renderTopicProgressBar()}
                {this.props.showTopicResetActivityDialog &&
                  this.renderResetTopicActivityDialog()}
              </div>
            </div>
          );
        } else {
          return null;
        }
      case "project":
      case "project-secondary":
      default:
        return null;
    }
  }

  /**
   * Render topic progress bar
   */
  renderTopicProgressBar() {
    if (this.props.topic) {
      return (
        <div className="sidebar-progressbar">
          <ProgressBar
            id="progressbar"
            className="boxshadow"
            completed={this.props.topic.userChallengeCompletedNo}
            total={this.props.topic.challengeNo}
            language={this.props.language}
          />
        </div>
      );
    } else {
      return (
        <div className="sidebar-progressbar">
          <ProgressBar
            id="progressbar"
            className="boxshadow"
            completed={this.props.completedChallenges}
            total={this.props.totalChallenges}
            language={this.props.language}
          />
        </div>
      );
    }
  }

  /**
   * Render topic progress bar with inline topic options
   */
  renderTopicProgressBarWithTopicOptions() {
    return (
      <div className="flex align-items-center">
        <div className="pure-u-19-24">{this.renderTopicProgressBar()}</div>
        <div className="pure-u-5-24 justify-content-flexend">
          {this.renderTopicOptionsButton()}
        </div>
      </div>
    );
  }

  /**
   * Render topic options button
   */
  renderTopicOptionsButton() {
    if (this.props.allowReset && this.props.sessionKey) {
      return (
        <div className="dropdown-button-group sidebar-reset-topic floatright">
          <Dropdown
            dropup={true}
            onSelect={(eventKey, event) => {
              event.preventDefault();
              this.props.handleOpenResetTopicActivityDialog();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={e => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <MenuItem eventKey={1} onSelect={(eventKey, event) => {}}>
                {localize("reset_topic", this.props.language)}
              </MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  renderResetTopicActivityDialog() {
    return (
      <ResetActivityDialogContainer
        context="topic"
        showDialog={this.props.showTopicResetActivityDialog}
        handleCloseDialog={this.props.handleCloseResetTopicActivityDialog}
        topicTitle={this.props.topicTitle || ""}
        topicId={this.props.topicId}
        projectId={this.props.project.id}
      />
    );
  }

  /**
   * Render share buttons for sidebar
   */
  renderSidebarShareButton() {
    if (this.props.project && this.props.project.projectShareLink) {
      return (
        <button
          className="button icon info inline"
          onClick={this.props.handleOpenShareGameDialog}
        >
          <i className="fas fa-share-alt"></i>
        </button>
      );
    } else {
      return null;
    }
  }

  /**
   * Render popup dialog for share project
   */
  renderShareGameDialog() {
    return (
      <ShareGameDialog
        showDialog={this.props.showShareGameDialog}
        handleCloseDialog={this.props.handleCloseShareGameDialog}
        projectId={this.props.projectId}
        project={this.props.project}
        language={this.props.language}
      />
    );
  }

  /**
   * Retrieve hiding class name based on screen size
   *
   * @return {string} CSS class name
   */
  renderHideClasses() {
    const IS_640_DESKTOP_PROJECT =
      sessionStorageService.getItem("640_desktop_project") === "true"
        ? true
        : false;
    if (IS_640_DESKTOP_PROJECT && this.props.isProjectPage) {
      return " hide-below-md-640";
    } else if (this.props.isProjectPage) {
      return " hide-below-md";
    } else {
      return " hide-below-lg";
    }
  }

  /**
   * Render view
   */
  render() {
    return (
      <div
        className={"sidebar-wrapper" + this.renderHideClasses()}
        style={{ width: "25%" }}
      >
        <div className="sidebar sidebarbg">
          <div className="horizontalpadding-10 on-lg-horizontalpadding bottompadding">
            {this.renderProject()}
            {this.renderTopic()}
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
