import React, { Component } from "react";
import PropTypes from "prop-types";

import MainButton from "./MainButton";
import ChildButton from "./ChildButton";

import "library/font-awesome/all.min.css";
import "./mfb.css";

let Children = React.Children;
let classnames = require("classnames");

const propTypes = {
  effect: PropTypes.oneOf(["zoomin", "slidein", "slidein-spring", "fountain"])
    .isRequired,
  position: PropTypes.oneOf(["tl", "tr", "bl", "br"]).isRequired,
  children: childrenValidator
};

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  toggleMenu(evt) {
    evt.preventDefault();

    if (this.props.method === "hover") {
      return;
    }
    // flip the state from open to close and viceversa
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleClickOutside = evt => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    let classes = getClasses(this.props);
    let buttons = getChildren(this.props.children);

    let main =
      buttons.main &&
      React.cloneElement(buttons.main, {
        onClick: this.toggleMenu,
        handleClickOutside: this.handleClickOutside
      });

    return (
      <ul
        className={classes}
        data-mfb-toggle={this.props.method}
        data-mfb-state={this.state.isOpen ? "open" : "closed"}
      >
        <li className="mfb-component__wrap">
          {main}
          <ul className="mfb-component__list">{buttons.child}</ul>
        </li>
      </ul>
    );
  }
}

function getChildren(children) {
  let buttons = {
    main: null,
    child: []
  };

  Children.forEach(children, function(child) {
    if (child) {
      if (child.type === MainButton) {
        buttons.main = child;
        return;
      }
      buttons.child.push(child);
    }
  });

  return buttons;
}

function getClasses(props) {
  return classnames(
    {
      "mfb-zoomin": props.effect === "zoomin",
      "mfb-slidein": props.effect === "slidein",
      "mfb-slidein-spring": props.effect === "slidein-spring",
      "mfb-fountain": props.effect === "fountain",
      "mfb-component--tl": props.position === "tl",
      "mfb-component--tr": props.position === "tr",
      "mfb-component--bl": props.position === "bl",
      "mfb-component--br": props.position === "br"
    },
    props.className
  );
}

function childrenValidator(props, propName, componentName) {
  let children = props[propName];
  let mainButtonCount = 0;
  let childButtonCount = 0;
  let otherCount = 0;
  let msg;
  Children.forEach(children, function(child) {
    if (child) {
      if (child.type === MainButton) {
        return mainButtonCount++;
      }
      if (child.type === ChildButton) {
        return childButtonCount++;
      }
      otherCount++;
    }
  });
  if (mainButtonCount === 0) {
    msg =
      "Prop `children` must contain a MainButton component in `" +
      componentName +
      "`.";
    return new Error(msg);
  }
  if (mainButtonCount > 1) {
    msg =
      "Prop `children` must contain only 1 MainButton component in `" +
      componentName +
      "`, but " +
      mainButtonCount +
      " exist.";
    return new Error(msg);
  }
  if (otherCount) {
    msg =
      "Prop `children` contains elements other than MainButton and ChildButton " +
      "components in `" +
      componentName +
      "`.";
    return new Error(msg);
  }
}

Menu.propTypes = propTypes;

export default Menu;
