import React, { Component } from "react";
import PropTypes from "prop-types";

import "./RedeemRewardDialog.css";

import Modal from "react-modal";

import localize from "lang/localize";

const propTypes = {
  showRedeemRewardDialog: PropTypes.bool.isRequired,
  handleCloseRedeemRewardDialog: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  rewardTitle: PropTypes.string.isRequired,
  rewardPoints: PropTypes.number.isRequired,
  rewardId: PropTypes.number.isRequired,
  rewardImg: PropTypes.string.isRequired,
  rewardDescription: PropTypes.string.isRequired,
  rewardDescriptionAfterRedeem: PropTypes.string,
  rewardOwned: PropTypes.number.isRequired,
  rewardOptions: PropTypes.array,
  rewardType: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class RedeemRewardDialog extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showRedeemRewardDialog,
      showDialogType: 1 // 0: no dialog, 1: redemption dialog, 2: post-redemption dialog
    };
  }

  /**
   * Informs React if this component’s output is not affected by the current change in state or props
   * @param {*} nextProps
   * @param {*} nextState
   * @returns
   */
  shouldComponentUpdate(nextProps, nextState) {
    // Determine if popup with post-redemption message should show
    // Note: rewardDescriptionAfterRedeem is only available after redemption, and loaded asynchronously
    if (
      this.props.rewardDescriptionAfterRedeem !==
      nextProps.rewardDescriptionAfterRedeem
    ) {
      if (nextProps.rewardDescriptionAfterRedeem) {
        this.setState({
          showDialogType: 2
        });
      }
    }

    // showModal value should also update if showDialogType changes
    if (this.state.showDialogType !== nextState.showDialogType) {
      if (nextState.showDialogType > 0) {
        this.setState({ showModal: true });
      } else {
        this.setState({ showModal: false });
      }
    }
    return true;
  }

  /**
   * Show post-redemption dialog.
   */
  toggleShowPostRedemptionDialog() {
    let renderPostRedeemDialog = false;

    if (this.props.rewardType === "code") {
      // Render to show collection message
      renderPostRedeemDialog = true;
    }
    // Note: Post-redemption message is handled by shouldComponentUpdate.

    if (renderPostRedeemDialog) {
      this.setState({ showDialogType: 2 });
    } else {
      this.setState({ showDialogType: 0 });
    }
  }

  /**
   * Renders reward options for redemption.
   */
  renderRewardOptions() {
    return (
      <div className="reward-options-container">
        {this.props.rewardOptions.map((option, index) => (
          <div key={index} className="reward-options-content">
            <div>
              <strong>{option.rewardOptionName}</strong>
              <p>
                {" "}
                {localize("reward_left", this.props.language).format(
                  option.rewardOptionQuantity
                )}{" "}
              </p>
            </div>
            {this.props.buttonClass.includes("redeem") &&
            !!option.rewardOptionQuantity ? (
              <button
                className={this.props.buttonClass}
                onClick={e => {
                  e.preventDefault();
                  this.props.handleClick(
                    e,
                    this.props.rewardId,
                    option.rewardOptionId
                  );
                  this.toggleShowPostRedemptionDialog();
                }}
              >
                {localize("button_reward_redeem", this.props.language)}
              </button>
            ) : (
              <button
                className={
                  !!option.rewardOptionQuantity
                    ? this.props.buttonClass
                    : "button inactive soldout"
                }
              >
                {!!option.rewardOptionQuantity
                  ? localize("insufficient_points", this.props.language)
                  : localize(
                      "buy_not_available_text_button",
                      this.props.language
                    )}
              </button>
            )}
          </div>
        ))}
      </div>
    );
  }

  /**
   * Renders reward redemption dialog box.
   */
  renderRedemptionDialog() {
    return (
      <div className="dialog-content">
        <div className="dialog-header">
          <h5 className="dialog-title">{this.props.rewardTitle}</h5>
          <button
            onClick={this.handleCloseDialog}
            className="close-button-container"
          >
            <i className="fa close-button" aria-hidden="true" />
          </button>
        </div>
        <div className="dialog-body">
          <div className="item-owned-container">
            <i className="fas fa-shopping-bag"></i>
            <p>
              {" "}
              {localize("item_owned_text", this.props.language).format(
                this.props.rewardOwned
              )}{" "}
            </p>
          </div>
          <img className="imgresponsive" src={this.props.rewardImg} alt="" />
          <div
            className="bottommargin-10"
            dangerouslySetInnerHTML={{ __html: this.props.rewardDescription }}
          />
          {this.props.rewardOptions && this.renderRewardOptions()}
          {!this.props.buttonClass.includes("redeem") && (
            <div className="info-text textcenter">
              <i className="fas fa-exclamation-triangle textcenter"></i>
              {this.props.buttonClass.includes("soldout") &&
                localize("buy_not_available_text", this.props.language)}
              {this.props.buttonClass.includes("limit") &&
                localize("buy_limit_text", this.props.language)}
              {this.props.buttonClass.includes("collected") &&
                localize("item_used_at_text", this.props.language).format(
                  this.props.usedAtFormatted
                )}
              {this.props.buttonClass.includes("adminOnly") &&
                localize("non_redeemable_item_text", this.props.language)}
              {this.props.buttonClass.includes("insufficientPoints") &&
                localize("insufficient_points", this.props.language)}
            </div>
          )}
        </div>
        {this.props.buttonClass.includes("redeem") &&
          !this.props.rewardOptions && (
            <div className="dialog-footer-button textcenter horizontalpadding-10 verticalpadding-10">
              <button
                className="button inline cta fullwidth"
                onClick={e => {
                  e.preventDefault();
                  this.props.handleClick(e, this.props.rewardId);
                  this.toggleShowPostRedemptionDialog();
                }}
              >
                {localize(
                  "button_reward_redeem_confirm",
                  this.props.language
                ).format(this.props.rewardPoints)}
              </button>
            </div>
          )}
      </div>
    );
  }

  /**
   * Renders post-reward redemption dialog box.
   * This appears only when there is post-redemption messages or
   * instructions for the user.
   */
  renderPostRedemptionDialog() {
    return (
      <div className="dialog-content">
        <div className="dialog-header">
          <h5 className="dialog-title">
            {localize("icon_inventory", this.props.language)}!
          </h5>
          <button
            onClick={this.handleCloseDialog}
            className="close-button-container"
          >
            <i className="fa close-button" aria-hidden="true" />
          </button>
        </div>
        <div className="dialog-body">
          <img className="imgresponsive" src={this.props.rewardImg} alt="" />
          {this.props.rewardDescriptionAfterRedeem && (
            <div
              className="bottommargin-10"
              dangerouslySetInnerHTML={{
                __html: this.props.rewardDescriptionAfterRedeem
              }}
            />
          )}
          {this.props.rewardType === "code" && (
            <div className="info-text">
              <i className="fas fa-info-circle"></i>
              <p>{localize("use_item_code_text", this.props.language)}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  /**
   * Render dialog type
   */
  renderDialogType() {
    switch (this.state.showDialogType) {
      case 1:
        return this.renderRedemptionDialog();
      case 2:
        return this.renderPostRedemptionDialog();
      default:
        return null;
    }
  }

  /**
   * Handle close dialog
   */
  handleCloseDialog = () => {
    this.props.handleCloseRedeemRewardDialog();
    this.setState({ showDialogType: 0 });
  };

  /**
   * Renders the modal with dialog box.
   */
  render() {
    return (
      <Modal
        isOpen={this.state.showModal}
        contentLabel={localize("button_reward_redeem", this.props.language)}
        onRequestClose={this.handleCloseDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog collect-redeemed-dialog"
        overlayClassName="dialog-overlay"
      >
        {this.renderDialogType()}
      </Modal>
    );
  }
}

RedeemRewardDialog.propTypes = propTypes;

export default RedeemRewardDialog;
