import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ChallengeCard from "./ChallengeCard";
import {
  ChallengeCardContainer,
  mapStateToProps
} from "components/Topic/ChallengeCardContainer";

const propTypes = {
  id: PropTypes.number.isRequired,
  unlocked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  challengeType: PropTypes.string.isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  isFlashcard: PropTypes.bool.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  challengeReferralLink: PropTypes.string,
  likeNo: PropTypes.number.isRequired,
  completedNo: PropTypes.number.isRequired,
  isLiked: PropTypes.bool.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
  handleLike: PropTypes.func.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleOpenBookmarkLoginDialog: PropTypes.func,
  handleOpenLikeLoginDialog: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  language: PropTypes.string,
  contactEmail: PropTypes.string
};

export class ASMChallengeCardContainer extends ChallengeCardContainer {
  render() {
    return (
      <ChallengeCard
        sessionKey={this.props.sessionKey}
        id={this.props.id}
        unlocked={this.props.unlocked}
        title={this.props.title}
        footnote={this.props.footnote}
        img={this.props.img}
        challengeType={this.props.challengeType}
        challengeTypeId={this.props.challengeTypeId}
        points={this.props.points}
        isFlashcard={this.props.isFlashcard}
        isConfirmationChallenge={this.props.isConfirmationChallenge}
        challengeReferralLink={this.props.challengeReferralLink}
        likeNo={this.props.likeNo}
        completedNo={this.props.completedNo}
        isLiked={this.props.isLiked}
        isBookmarked={this.props.isBookmarked}
        isCompleted={this.props.isCompleted}
        isExpired={this.props.isExpired}
        handleLike={this.props.handleLike}
        handleBookmark={this.props.handleBookmark}
        handleOpenBookmarkLoginDialog={this.props.handleOpenBookmarkLoginDialog}
        handleOpenLikeLoginDialog={this.props.handleOpenLikeLoginDialog}
        isLoggedIn={this.props.isLoggedIn}
        language={this.props.language}
        contactEmail={this.props.contactEmail}
        handleChallengeTitleClick={this.handleChallengeTitleClick}
      />
    );
  }
}

ASMChallengeCardContainer.propTypes = propTypes;

export default connect(mapStateToProps)(ASMChallengeCardContainer);
