export const SET_SESSIONKEY = "SET_SESSIONKEY";
export const SET_USER = "SET_USER";
export const SET_USER_ID = "SET_USER_ID";
export const SET_TEAM = "SET_TEAM";
export const SET_PROJECT_ID = "SET_PROJECT_ID";

export const SET_PROJECT = "SET_PROJECT";
export const SET_SHOW_QR_MODAL = "SET_SHOW_QR_MODAL";
export const SET_TOPIC_CATEGORY = "SET_TOPIC_CATEGORY";
export const SET_TOPIC = "SET_TOPIC";
export const SET_CHALLENGE_CATEGORY = "SET_CHALLENGE_CATEGORY";
export const SET_CHALLENGE = "SET_CHALLENGE";
export const SET_SIDEBAR_STATE = "SET_SIDEBAR_STATE";
export const SET_TOPBAR = "SET_TOPBAR";
export const ADD_TOPBAR_POINTS = "ADD_TOPBAR_POINTS";

export const SET_PROFILEDRAWER = "SET_PROFILEDRAWER";
export const ADD_PROFILEDRAWER_POINTS = "ADD_PROFILEDRAWER_POINTS";

export const SET_ALERT = "SET_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const SET_CHALLENGE_COMPLETED = "SET_CHALLENGE_COMPLETED";
export const CLEAR_CHALLENGE_COMPLETED = "CLEAR_CHALLENGE_COMPLETED";

export const SET_ACHIEVEMENTS = "SET_ACHIEVEMENTS";
export const CLEAR_ACHIEVEMENTS = "CLEAR_ACHIEVEMENTS";

export const SET_FLOATINGBUTTONS_STATE = "SET_FLOATINGBUTTONS_STATE";
export const SET_BUTTONS = "SET_BUTTONS";

export const SET_AWARDABLE_ACTION = "SET_AWARDABLE_ACTION";
export const CLEAR_AWARDABLE_ACTION = "CLEAR_AWARDABLE_ACTION";

export const SET_LANGUAGE = "SET_LANGUAGE";

// action creator
export const setSessionKey = sessionKey => {
  return {
    type: SET_SESSIONKEY,
    sessionKey
  };
};

export const setUser = user => {
  return {
    type: SET_USER,
    user
  };
};

export const setUserId = userId => {
  return {
    type: SET_USER_ID,
    userId
  };
};

export const setProjectId = projectId => {
  return {
    type: SET_PROJECT_ID,
    projectId
  };
};

export const setTeam = team => {
  return {
    type: SET_TEAM,
    team
  };
};

/* Sidebar */
export const setProject = project => {
  return {
    type: SET_PROJECT,
    project
  };
};

export const setTopicCategory = topicCategory => {
  return {
    type: SET_TOPIC_CATEGORY,
    topicCategory
  };
};

export const setTopic = topic => {
  return {
    type: SET_TOPIC,
    topic
  };
};

export const setChallengeCategory = challengeCategory => {
  return {
    type: SET_CHALLENGE_CATEGORY,
    challengeCategory
  };
};

export const setChallenge = challenge => {
  return {
    type: SET_CHALLENGE,
    challenge
  };
};

export const setSidebarState = state => {
  return {
    type: SET_SIDEBAR_STATE,
    state
  };
};

/* Topbar */
/* info holds the following information
  state
  title
*/
export const setTopbar = info => {
  return {
    type: SET_TOPBAR,
    info
  };
};

export const addTopbarPoints = points => {
  return {
    type: ADD_TOPBAR_POINTS,
    points
  };
};

/* Profile Drawer */
/* Currently only stores notifications, points */
export const setProfileDrawer = info => {
  return {
    type: SET_PROFILEDRAWER,
    info
  };
};

export const addProfileDrawerPoints = points => {
  return {
    type: ADD_PROFILEDRAWER_POINTS,
    points
  };
};

/* Alert */
export const setAlert = (alert, id) => {
  return {
    type: SET_ALERT,
    id: id,
    alert
  };
};

export const clearAlert = id => {
  return {
    type: CLEAR_ALERT,
    id: id
  };
};

let alertId = 1;
export const showAlertWithTimeout = alert => {
  return dispatch => {
    const id = alertId++;
    dispatch(setAlert(alert, id));
    setTimeout(() => {
      dispatch(clearAlert(id));
    }, 5000);
  };
};

/* Set completed challenge */
export const setChallengeCompleted = (challengeCompleted, id) => {
  return {
    type: SET_CHALLENGE_COMPLETED,
    id: id,
    challengeCompleted
  };
};

/* Clear completed challenge */
export const clearChallengeCompleted = id => {
  return {
    type: CLEAR_CHALLENGE_COMPLETED,
    id: id
  };
};

/* Show completed challenge */
let challengeNo = 1;
export const showChallengeCompleted = challengeCompleted => {
  return dispatch => {
    const id = challengeNo++;
    dispatch(setChallengeCompleted(challengeCompleted, id));
  };
};

/* Set achievement */
export const setAchievements = (achievements, id) => {
  return {
    type: SET_ACHIEVEMENTS,
    id: id,
    achievements
  };
};

/* Clear completed challenge */
export const clearAchievements = id => {
  return {
    type: CLEAR_ACHIEVEMENTS,
    id: id
  };
};

/* Show completed challenge */
let achievementNo = 1;
export const showAchievements = achievements => {
  return dispatch => {
    const id = achievementNo++;
    dispatch(setAchievements(achievements, id));
  };
};

/* Floating buttons */
export const setFloatingButtonsState = state => {
  return {
    type: SET_FLOATINGBUTTONS_STATE,
    state
  };
};

export const setButtons = buttons => {
  return {
    type: SET_BUTTONS,
    buttons
  };
};

export const setShowQRModal = isShow => {
  return {
    type: SET_SHOW_QR_MODAL,
    isShow
  };
};

/* Awardable Actions */
export const setAwardableAction = (awardableAction, id) => {
  return {
    type: SET_AWARDABLE_ACTION,
    id: id,
    awardableAction
  };
};

export const clearAwardableAction = id => {
  return {
    type: CLEAR_AWARDABLE_ACTION,
    id: id
  };
};

let awardableActionId = 1;
export const showAwardableActionWithTimeout = awardableAction => {
  return dispatch => {
    const id = awardableActionId++;
    dispatch(setAwardableAction(awardableAction, id));
    setTimeout(() => {
      dispatch(clearAwardableAction(id));
    }, 1500);
  };
};

/* Language */
export const setLanguage = language => {
  return {
    type: SET_LANGUAGE,
    language
  };
};
