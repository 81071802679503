import React, { Fragment } from "react";

import LoginPage from "components/Login/LoginPage";

import TextInput from "components/shared/Inputs/TextInput";
import Link from "components/shared/Link/Link";
import PasswordInput from "components/shared/Inputs/PasswordInput";

import {
  ENABLE_RESEND_ACTIVATION_LINK,
  RESEND_ACTIVATION_LINK,
  ENABLE_EMAIL_LOGIN,
  ENABLE_LOGIN_TOPBAR,
  USE_LOGIN_HOME_TEMPLATE_1,
  ENABLE_LOGIN_HOME,
  ENABLE_REGISTER_PAGES
} from "config";

import {
  PROJECT_REGISTER,
  REGISTER,
  PROJECT_RESET_PASSWORD,
  RESET_PASSWORD
} from "App/Routes";
import localize from "lang/localize";

import "./Login.css";
import login_logo from "images/custom/logo.png";

class ASMLoginPage extends LoginPage {
  renderRegisterSection() {
    if (
      !ENABLE_LOGIN_HOME ||
      (ENABLE_LOGIN_HOME && USE_LOGIN_HOME_TEMPLATE_1)
    ) {
      return (
        <Fragment>
          <br />
          <p className="textcenter bottommargin-10">
            <Link
              to={
                this.props.projectId
                  ? PROJECT_REGISTER.format(this.props.projectId)
                  : REGISTER
              }
              className="default"
            >
              {localize("login_view_signup_text", this.props.language)}
            </Link>
          </p>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  renderResetPasswordOrResendActivation() {
    if (ENABLE_RESEND_ACTIVATION_LINK) {
      return (
        <div className="pure-g bottommargin">
          <div className="pure-u-1-2">
            <p className="nomargin">
              <Link
                to={
                  this.props.projectId
                    ? PROJECT_RESET_PASSWORD.format(this.props.projectId)
                    : RESET_PASSWORD
                }
                className="default"
              >
                {localize(
                  "login_view_forgetpassword_text",
                  this.props.language
                )}
              </Link>
            </p>
          </div>
          <div className="pure-u-1-2">
            <p className="nomargin flex justify-content-flexend">
              <a
                href={RESEND_ACTIVATION_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="default"
              >
                {localize(
                  "login_view_resend_activation_text",
                  this.props.language
                )}
              </a>
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pure-g bottommargin">
          <div className="pure-u-1-1">
            <p className="nomargin">
              <Link
                to={
                  this.props.projectId
                    ? PROJECT_RESET_PASSWORD.format(this.props.projectId)
                    : RESET_PASSWORD
                }
                className="default"
              >
                {localize(
                  "login_view_forgetpassword_text",
                  this.props.language
                )}
              </Link>
            </p>
          </div>
        </div>
      );
    }
  }

  renderLoginFields() {
    if (ENABLE_EMAIL_LOGIN) {
      return (
        <Fragment>
          <h1 className="login-title">Welcome to</h1>
          <h4 className="login-title">Discovering ASM</h4>
          <p className="login-sub-title">
            DRIVE INNOVATION ● DELIVER EXCELLENCE
          </p>
          <TextInput
            id="emailInput"
            className="login-input"
            value={this.props.email}
            onChange={this.props.handleEmailChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_email_placeholder_text",
              this.props.language
            )}
          />
          <PasswordInput
            id="passwordInput"
            value={this.props.password}
            onChange={this.props.handlePasswordChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_password_placeholder_text",
              this.props.language
            )}
          />
          <div className="pure-g bottommargin topmargin-10">
            <div className="pure-u-1-1 pure-u-sm-1-4" />
            <div className="pure-u-1-1 pure-u-sm-1-2">
              <button
                id="loginButton"
                className={
                  "button fullwidth" +
                  (!!this.props.isSubmitLoading ? " disabled" : " cta")
                }
                type="submit"
                disabled={!!this.props.isSubmitLoading}
              >
                {!!this.props.isSubmitLoading
                  ? localize(
                      "nav_bar_title_generic_loading",
                      this.props.language
                    )
                  : localize("button_login", this.props.language)}
              </button>
            </div>
            <div className="pure-u-1-1 pure-u-sm-1-12" />
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="login-register-container container verticalpadding flex align-items-center">
        {this.renderTopbarlessBackButton()}
        <div className="innerblock">
          <div
            className={
              "pure-g align-items-center login-wrapper" +
              (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR ? " topmargin-20" : "")
            }
          >
            <div className="pure-u-1 pure-u-lg-1-2">
              <div className="pure-u-1 pure-u-lg-1-6" />
              <div className="pure-u-1 pure-u-lg-5-6">
                <form
                  className="asm-grey-overlay asm-overlay-desktop pure-form textcenter"
                  onSubmit={this.handlePreSubmit.bind(this)}
                >
                  {this.renderLoginFields()}
                  {ENABLE_REGISTER_PAGES && this.renderRegisterSection()}
                  <div className="textcenter">
                    {this.renderResetPasswordOrResendActivation()}
                  </div>
                </form>
              </div>
            </div>
            <div className="pure-u-1 pure-u-lg-1-2 textcenter">
              <div className="pure-u-1 pure-u-lg-1-12" />
              <div className="pure-u-1 pure-u-lg-2-3">
                <img src={login_logo} alt="Logo" className="logo-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ASMLoginPage;
