import { connect } from "react-redux";

import {
  mapStateToProps,
  mapDispatchToProps,
  FrameLoginContainer
} from "components/Login/FrameLoginSuccessContainer";

import loginServices from "services/loginServices";

export class ASMFrameLoginContainer extends FrameLoginContainer {
  componentDidMount() {
    // Add other page classes to body DOM
    // CUSTOM: Prevent awkward flashing between backgrounds by using background for login/tour pages
    let bodyDOM = document.body; // <body> tag
    if (!bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.className += " page-not-loggedin";
    }

    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }

    if (!bodyDOM.classList.contains("frameLoginPage")) {
      bodyDOM.className += " frameLoginPage";
    }

    let parametersString = this.props.id;

    /*
      Route out if there is already an existing sessionKey and userId
    */
    if (this.props.sessionKey && this.props.userId) {
      /*
        FrameLoginSuccess, unlike PostLoginIntercept (/postloginintercept or PostLoginInterceptContainer.js),
        refers to the page housed in the IFRAME. Thus, it is likely a child page.

        However, this component can also be re-used as a parent page itself, eg. not actually being housed
        in any IFRAME. Thus, we are still include re-routes here should this component be used as a parent
        page.

        If the page is framed, the re-routing will likely happen in PostLoginIntercept (much faster)
        than FrameLoginSuccess. This means that from the visitor's perspective, it will seem like
        the parent window does the re-routing. From the developer's perspective, PostLoginIntercept
        will receive the props from the Redux store and re-route, ignoring whatever happens in its IFRAME.

        Thus, the re-routing logic below is only a fallback for cases where FrameLoginSuccess is its
        own parent page.
      */
      loginServices.postLoginReplaceAndNavigate(
        this.props.projectId,
        null,
        true
      );
    } else {
      if (!!this.props.id && this.props.id.indexOf("/?") === 0) {
        this.retrieveSessionKeyAndUserId(parametersString.slice(2));
      } else if (!!this.props.id && this.props.id.indexOf("?") === 0) {
        this.retrieveSessionKeyAndUserId(parametersString.slice(1));
      } else {
        this.setState({
          sessionKey: false,
          userId: false
        });
      }
    }
  }

  componentWillUnmount() {
    // Remove other page classes from body DOM
    let bodyDOM = document.body;
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }

    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }

    if (bodyDOM.classList.contains("frameLoginPage")) {
      bodyDOM.classList.remove("frameLoginPage");
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ASMFrameLoginContainer);
