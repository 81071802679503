import React, { Component } from "react";
import PropTypes from "prop-types";

import QuizInput from "components/shared/Inputs/QuizInput";
import CountdownButton from "components/shared/Countdown/CountdownButton";
import localize from "lang/localize";

const propTypes = {
  isConfirmationChallenge: PropTypes.bool,
  options: PropTypes.array.isRequired,
  multiSelect: PropTypes.bool,
  gridWidth: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  handleOpenClaimLoginDialog: PropTypes.func.isRequired,
  challengeMinViewDuration: PropTypes.number,
  isTimerActive: PropTypes.bool,
  setTimerActive: PropTypes.func,
  countdownTime: PropTypes.number,
  language: PropTypes.string
};

const defaultProps = {
  multiSelect: false
};

/**
 * Render challenge claim form for QUIZ challenge types
 * - Single/multi-select quiz
 * - Poll
 * - Confirmation challenge
 * - "Spot what's wrong" quiz
 */
class QuizChallenge extends Component {
  constructor() {
    super();
    this.state = {
      selected: []
    };
  }

  /**
   * Determines quiz layout and renders quiz options accordingly
   */
  renderOptions() {
    if (this.props.gridWidth === undefined || this.props.gridWidth === 0) {
      return this.renderDefaultQuizOptions();
    } else {
      return this.renderGridQuizOptions();
    }
  }

  /**
   * Render quiz options
   * - Single/multi-select quiz
   * - Poll
   * - Confirmation challenge
   */
  renderDefaultQuizOptions() {
    return this.props.options.map(option => (
      <QuizInput
        key={option.id}
        id={option.id}
        selected={this.state.selected.indexOf(option.id) !== -1}
        image={option.image}
        title={option.title}
        onClick={
          this.props.isLoggedIn
            ? id => this.handleClick(id)
            : this.props.handleOpenClaimLoginDialog
        }
        disabled={!this.props.isLoggedIn}
      />
    ));
  }

  /**
   * Render options with grid size
   * - "Spot what's wrong" quiz
   */
  renderGridQuizOptions() {
    return (
      <div
        className={`grid-quiz-board-container ${
          this.props.gridWidth > 2 ? "scrollable-map-activated" : ""
        }`}
      >
        <div className="grid-quiz-board-scrollable-instruction">
          <span className="far fa-hand-pointer"></span> &nbsp; Swipe to move
          around the map.
        </div>
        <div className="grid-quiz-board-scrollable-map bottommargin-20">
          <div className="grid-quiz-board-wrap">
            <table id="gridQuizBoard" className="grid-quiz-board">
              <tbody>
                {this.props.options.map((option, index) => {
                  if (index % this.props.gridWidth === 0 || index === 0) {
                    return this.renderSingleGridRow(index);
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders a row of grid options
   * @param {number} startIndex - The starting index
   **/
  renderSingleGridRow(startIndex) {
    // Temporary array to store the current row
    let rowOptionsArray = this.props.options.slice(
      startIndex,
      startIndex + this.props.gridWidth
    );

    return (
      <tr key={startIndex}>
        {rowOptionsArray.map(option => {
          return this.renderSingleGridOption(option);
        })}
      </tr>
    );
  }

  /**
   * Render a single grid option
   * @param {*} option
   * @returns
   */
  renderSingleGridOption(option) {
    let isGrid = true;

    return (
      <td key={option.id}>
        <QuizInput
          key={option.id}
          id={option.id}
          selected={this.state.selected.indexOf(option.id) !== -1}
          image={option.image}
          title={option.title}
          isGrid={isGrid}
          onClick={
            this.props.isLoggedIn
              ? id => this.handleClick(id)
              : this.props.handleOpenClaimLoginDialog
          }
          disabled={!this.props.isLoggedIn}
        />
      </td>
    );
  }

  /**
   * Handle click event on a quiz option.
   * @param {int} id - ID of the option
   */
  handleClick(id) {
    if (this.props.multiSelect) {
      let idx = this.state.selected.indexOf(id);
      let selectedcopy = this.state.selected.slice();
      if (idx === -1) {
        selectedcopy.push(id);
      } else {
        selectedcopy.splice(idx, 1);
      }
      this.setState({ selected: selectedcopy });
    } else {
      if (this.state.selected[0] === id) {
        this.setState({ selected: [] });
      } else {
        this.setState({ selected: [id] });
      }
    }
  }

  /**
   * Handle click event on a confirmation challenge button.
   * This will submit the form at the same time.
   *
   * @param {int} id - ID of the option
   */
  handleConfirmationClick(id) {
    this.setState({ selected: [id] }, function() {
      let query = { quiz_option_id: this.state.selected[0] };
      this.props.handleSubmit(query);
      this.setState({ selected: [] });
    });
  }

  /**
   * Handle the form submission event
   * @param {Object} event - The event
   */
  handleSubmit(event) {
    event.preventDefault();

    if (this.state.selected.length === 0) {
      this.props.showAlertWithTimeout({
        text: localize("choose_option_to_claim_text", this.props.language),
        type: "error"
      });
      return;
    }
    let query;
    if (this.props.multiSelect) {
      let quiz_option_id_list = this.state.selected.map(entry => ({
        id: entry
      }));
      query = { quiz_option_id_list: JSON.stringify(quiz_option_id_list) };
    } else {
      query = { quiz_option_id: this.state.selected[0] };
    }
    this.props.handleSubmit(query);
    this.setState({ selected: [] });
  }

  /**
   * Renders the challenge claim form.
   */
  render() {
    /* Confirmation Challenge */
    if (this.props.isConfirmationChallenge) {
      return (
        <div className="pure-g">
          <div className="pure-u-sm-4-24" />
          <div className="pure-u-1 pure-u-sm-16-24 textcenter">
            {this.props.isLoggedIn ? (
              this.props.options.map(option => (
                <QuizInput
                  key={option.id}
                  id={option.id}
                  selected={this.state.selected.indexOf(option.id) !== -1}
                  image={option.image}
                  title={option.title}
                  onClick={id => {
                    this.handleConfirmationClick(id);
                  }}
                  isConfirmationChallenge={this.props.isConfirmationChallenge}
                />
              ))
            ) : (
              <div>
                {this.props.options.map(option => (
                  <QuizInput
                    key={option.id}
                    id={option.id}
                    selected={this.state.selected.indexOf(option.id) !== -1}
                    image={option.image}
                    title={option.title}
                    onClick={this.props.handleOpenClaimLoginDialog}
                    isConfirmationChallenge={this.props.isConfirmationChallenge}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="pure-u-sm-4-24" />
        </div>
      );
      /* Not Confirmation Challenge */
    } else {
      return (
        <div className="pure-g">
          <div className="pure-u-sm-4-24" />
          <div className="pure-u-1 pure-u-sm-16-24">
            {this.renderOptions()}
            {this.props.isLoggedIn ? (
              <button
                className={
                  "button automargin " +
                  (this.props.submitted ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive)
                    ? "inactive"
                    : "cta")
                }
                onClick={this.handleSubmit.bind(this)}
                disabled={
                  this.props.submitted ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive)
                }
                id="submitCompletionButton"
              >
                {this.props.challengeMinViewDuration &&
                this.props.countdownTime &&
                this.props.isTimerActive ? (
                  <CountdownButton
                    countdownTime={this.props.countdownTime}
                    setTimerActive={this.props.setTimerActive}
                    language={this.props.language}
                  />
                ) : this.props.submitted ? (
                  localize("button_claim_submitting", this.props.language)
                ) : (
                  localize("button_claim_get_points", this.props.language)
                )}
              </button>
            ) : (
              <button
                className={
                  "button automargin " +
                  (this.props.submitted ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive)
                    ? "inactive"
                    : "cta")
                }
                onClick={this.props.handleOpenClaimLoginDialog}
                disabled={
                  this.props.submitted ||
                  (this.props.challengeMinViewDuration &&
                    this.props.isTimerActive)
                }
                id="loginBeforeSubmitCompletionButton"
              >
                {this.props.challengeMinViewDuration &&
                this.props.countdownTime &&
                this.props.isTimerActive ? (
                  <CountdownButton
                    countdownTime={this.props.countdownTime}
                    setTimerActive={this.props.setTimerActive}
                    language={this.props.language}
                  />
                ) : this.props.submitted ? (
                  localize("button_claim_submitting", this.props.language)
                ) : (
                  localize("button_claim_get_points", this.props.language)
                )}
              </button>
            )}
          </div>
          <div className="pure-u-sm-4-24" />
        </div>
      );
    }
  }
}

QuizChallenge.propTypes = propTypes;
QuizChallenge.defaultProps = defaultProps;

export default QuizChallenge;
