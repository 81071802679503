import React from "react";
import PropTypes from "prop-types";

import deviceServices from "services/deviceServices";
import localize from "lang/localize";

const propTypes = {
  interceptLoginUrl: PropTypes.string.isRequired,
  language: PropTypes.string
};

const PostLoginInterceptPage = props => (
  <div
    className={
      "fullscreen-frame-wrap" +
      (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
    }
  >
    <iframe
      src={props.interceptLoginUrl}
      title={localize("login_recover_password_web_view_title", props.language)}
      frameBorder="0"
      className="fullscreen-frame"
    />
  </div>
);

PostLoginInterceptPage.propTypes = propTypes;

export default PostLoginInterceptPage;
