import React from "react";
import PropTypes from "prop-types";

import "./LoginDivider.css";

const propTypes = {
  language: PropTypes.string
};

const LoginDivider = props => (
  <div className="pure-g toppadding-10 bottommargin-10 login-divider" />
);

LoginDivider.propTypes = propTypes;

export default LoginDivider;
