import React, { Component } from "react";
import PropTypes from "prop-types";

import "./ImageInput.css";

import localize from "lang/localize";

const propTypes = {
  imagename: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  resetFiles: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  handleDisabledClick: PropTypes.func,
  language: PropTypes.string
};

const defaultProps = {
  disabled: false
};

class ImageInput extends Component {
  constructor() {
    super();
    this.state = {
      preview: null
    };
  }

  createErrorAlert(text) {
    this.props.showAlertWithTimeout({ text: text, type: "error" });
  }

  checkFiles(event, file) {
    if (["image/png", "image/gif", "image/jpeg"].indexOf(file.type) === -1) {
      this.props.resetFiles(); /* reset files in props */
      this.refs.imageInputForm.reset(); /* reset filename in <input type="file"> */
      this.createErrorAlert(
        localize("alert_image_type_fail", this.props.language)
      );
    } else if (file.size > 8500000) {
      this.props.resetFiles(); /* reset files in props */
      this.refs.imageInputForm.reset(); /* reset filename in <input type="file"> */
      this.createErrorAlert(
        localize("alert_image_size_fail", this.props.language)
      );
    } else {
      this.props.onDrop(event.target.files);
      this.readPreviewImage(event.target.files[0]);
    }
  }

  readPreviewImage(input) {
    const reader = new FileReader();
    reader.onload = () => this.setState({ preview: reader.result });
    reader.readAsDataURL(input);
  }

  render() {
    return (
      <form ref="imageInputForm">
        <div className="image-input-label-container">
          <label
            htmlFor="imageInput"
            className={
              "image-input-label" +
              (this.props.disabled
                ? " cursor-not-allowed disabled"
                : " cursor-pointer")
            }
            onClick={
              this.props.disabled ? this.props.handleDisabledClick : () => {}
            }
          >
            {this.state.preview ? (
              <div className="image-preview-container">
                <img
                  className="image-preview-img"
                  src={this.state.preview}
                  alt={this.props.imagename}
                />
              </div>
            ) : (
              <i className="fas fa-camera" />
            )}
            <p
              className={
                "image-input-label-text " +
                (this.props.imagename ? "text-truncated" : "")
              }
            >
              {this.props.imagename
                ? this.props.imagename
                : localize(
                    "button_claim_challenge_upload_photo",
                    this.props.language
                  )}
            </p>
          </label>
        </div>
        <input
          className="image-input"
          type="file"
          onChange={e => {
            if (e.target.files[0]) {
              this.checkFiles(e, e.target.files[0]);
            }
          }}
          id="imageInput"
          accept="image/png, image/gif, image/jpeg"
          disabled={this.props.disabled}
        />
      </form>
    );
  }
}

ImageInput.propTypes = propTypes;
ImageInput.defaultProps = defaultProps;

export default ImageInput;
