import React, { Component } from "react";
import PropTypes from "prop-types";

import Router from "router";
import sessionStorageService from "services/sessionStorageService";
import urlServices from "services/urlServices";

const propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  vars: PropTypes.any
};

class Link extends Component {
  // returns true if router navigates
  handleClick = event => {
    if (this.props.onClick && this.props.vars) {
      this.props.onClick(this.props.vars);
    } else if (this.props.onClick) {
      this.props.onClick(event);
    }

    if (event.button !== 0 /* left click */) {
      return false;
    }

    if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) {
      return false;
    }

    if (event.defaultPrevented === true) {
      return false;
    }

    event.preventDefault();

    if (this.props.to === undefined || this.props.to === null) {
      return false;
    }

    /*
      TO PREVENT SAME-PAGE ROUTING, BECAUSE WE RELY A LOT ON window.history.back() NAVIGATION:
      for same page routing, it is not sufficient to simply use window.location.pathname.
      window.location.search is included because we also used parameterised URLs for cases like
      searching for challenges. window.location.search will provide you with the string of the
      parameterised parts of the URL.
      In the following lines, assume urlServices.defaultPath() = window.location.pathname +
      window.location.search
    */
    if (this.props.to === urlServices.defaultPath()) {
      return false;
    }

    // saves redirect URL if new route is LOGIN or REGISTER or LOGIN_TOUR (or PROJECT_ equivalents), and
    // current pathname is not REGISTER nor LOGIN nor LOGIN_TOUR (nor PROJECT_ equivalents)
    if (
      urlServices.isLoginRedirectionPage(this.props.to) &&
      !urlServices.isLoginRedirectionPage()
    ) {
      sessionStorageService.setItem(
        "loginRedirectUrl",
        urlServices.defaultPath()
      );
    }

    Router.navigate(this.props.to);
    return true;
  };

  render() {
    const { to, vars, className, children, redirectUrl, ...props } = this.props;
    return (
      <a
        className={"link" + (className ? " " + className : "")}
        href={to}
        {...props}
        onClick={this.handleClick}
      >
        {children}
      </a>
    );
  }
}

Link.propTypes = propTypes;

export default Link;
