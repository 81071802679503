import React, { Fragment } from "react";

import Loading from "components/shared/Loading";
import localize from "lang/localize";

import MobileProjectPage from "components/Project/MobileProjectPage";

class ASMMobileProjectPage extends MobileProjectPage {
  render() {
    return (
      <Fragment>
        <div className="asm-topic-card-container">
          {this.props.topics.length !== 0 ? (
            /* isMobile is false */
            this.props.renderTopics(this.props.topics, true)
          ) : (
            <p className="textcenter verticalpadding">
              {localize("empty_game_table_text", this.props.language)}
            </p>
          )}
          {this.props.isLoadingMore ? <Loading /> : null}
        </div>
      </Fragment>
    );
  }
}

export default ASMMobileProjectPage;
