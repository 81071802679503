import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";
import SearchBar from "components/shared/SearchBar/SearchBar";

import localize from "lang/localize";

import "./SearchChallengeDialog.css";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseSearchChallengeDialog: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class SearchChallengeDialog extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        contentLabel={localize(
          "general_search_placeholder_text",
          this.props.language
        )}
        onRequestClose={this.props.handleCloseSearchChallengeDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog search-challenge-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize(
                "home_search_challenge_placeholder_text",
                this.props.language
              )}
            </h5>
          </div>
          <div className="dialog-body">
            <SearchBar
              id="searchbar"
              handleKeyPress={this.props.handleKeyPress}
              handleSearchChange={this.props.handleSearchChange}
              handleSubmit={event => {
                this.props.handleSubmit(event);
              }}
              button={false}
              autoFocus={true}
              language={this.props.language}
            />
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseSearchChallengeDialog}
                id="cancelButton"
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              <button
                className="button inline cta"
                id="searchChallengeButton"
                onClick={event => {
                  this.props.handleSubmit(event);
                }}
              >
                {localize(
                  "general_search_placeholder_text",
                  this.props.language
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

SearchChallengeDialog.propTypes = propTypes;

export default SearchChallengeDialog;
