import React from "react";
import PropTypes from "prop-types";

import "./ImageInput.css";
import placeholder_video from "images/core/placeholders/placeholder_video.png";
import VideoDurationLimitDialog from "components/shared/Dialogs/VideoDurationLimitDialog/VideoDurationLimitDialog";
import ImageInput from "./ImageInput";

import localize from "lang/localize";

const propTypes = {
  videoname: PropTypes.string,
  videoDurationLimit: PropTypes.number,
  videoDurationLimitFormatted: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  resetFiles: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  handleDisabledClick: PropTypes.func,
  language: PropTypes.string
};

const defaultProps = {
  disabled: false
};

class VideoInput extends ImageInput {
  constructor() {
    super();
    this.state = {
      preview: null,
      showVideoDurationLimitDialog: false
    };

    /* Video Duration Limit Dialog */
    this.handleOpenVideoDurationLimitDialog = this.handleOpenVideoDurationLimitDialog.bind(
      this
    );
    this.handleCloseVideoDurationLimitDialog = this.handleCloseVideoDurationLimitDialog.bind(
      this
    );
  }

  checkFiles(event, file) {
    let files = event.target.files;

    if (["video/mp4"].indexOf(file.type) === -1) {
      this.props.resetFiles(); /* reset files in props */
      this.refs.videoInputForm.reset(); /* reset filename in <input type="file"> */
      this.createErrorAlert(
        localize("alert_video_type_fail", this.props.language)
      );
    } else {
      let fileUrl = URL.createObjectURL(file);
      let video = document.createElement("video");

      video.src = fileUrl;

      video.addEventListener("durationchange", () => {
        this.checkFilesDurationAndSize(video, file, files);
      });
    }
  }

  checkFilesDurationAndSize(video, file, files) {
    if (video.duration > this.props.videoDurationLimit) {
      this.props.resetFiles(); /* reset files in props */
      this.refs.videoInputForm.reset(); /* reset filename in <input type="file"> */
      this.createErrorAlert(
        localize("alert_video_duration_fail", this.props.language).format(
          this.props.videoDurationLimit
        )
      );
    } else if (file.size > 70000000) {
      this.props.resetFiles(); /* reset files in props */
      this.refs.videoInputForm.reset(); /* reset filename in <input type="file"> */
      this.createErrorAlert(
        localize("alert_video_size_fail", this.props.language)
      );
    } else {
      this.props.onDrop(files);
    }
  }

  handleOpenVideoDurationLimitDialog() {
    this.setState({
      showVideoDurationLimitDialog: true
    });
  }

  handleCloseVideoDurationLimitDialog() {
    this.setState({
      showVideoDurationLimitDialog: false
    });
  }

  renderVideoDurationLimitDialog() {
    return (
      <VideoDurationLimitDialog
        showDialog={this.state.showVideoDurationLimitDialog}
        handleCloseDialog={this.handleCloseVideoDurationLimitDialog}
        videoInput={this.videoInput}
        videoDurationLimitFormatted={this.props.videoDurationLimitFormatted}
        language={this.props.language}
      />
    );
  }

  render() {
    return (
      <form ref="videoInputForm">
        <div className="image-input-label-container">
          <div
            className={
              "image-input-label" +
              (this.props.disabled
                ? " cursor-not-allowed disabled"
                : " cursor-pointer")
            }
            onClick={
              this.props.disabled
                ? this.props.handleDisabledClick
                : this.handleOpenVideoDurationLimitDialog
            }
          >
            {this.props.videoname ? (
              <div className="image-preview-container">
                <img
                  className="image-preview-img"
                  src={placeholder_video}
                  alt={this.props.videoname}
                />
              </div>
            ) : (
              <i className="fas fa-video" />
            )}
            <p
              className={
                "image-input-label-text " +
                (this.props.videoname ? "truncated" : "")
              }
            >
              {this.props.videoname
                ? this.props.videoname
                : localize("upload_video_label_text", this.props.language)}
            </p>
          </div>
        </div>
        {this.state.showVideoDurationLimitDialog &&
          this.renderVideoDurationLimitDialog()}
        <input
          className="image-input"
          type="file"
          ref={input => (this.videoInput = input)}
          onChange={e => {
            if (e.target.files[0]) {
              this.checkFiles(e, e.target.files[0]);
            }
          }}
          id="videoInput"
          accept="video/mp4"
          disabled={this.props.disabled}
        />
      </form>
    );
  }
}

VideoInput.propTypes = propTypes;
VideoInput.defaultProps = defaultProps;

export default VideoInput;
