const listenerServices = {
  addListener: function(event, handler) {
    let eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    let eventer = window[eventMethod];
    let listenerEvent = eventMethod === "attachEvent" ? "on" + event : event;

    eventer(listenerEvent, handler, false);
  },

  removeListener: function(event, handler) {
    let eventMethod = window.removeEventListener
      ? "removeEventListener"
      : "detachEvent";
    let eventer = window[eventMethod];
    let listenerEvent = eventMethod === "detachEvent" ? "on" + event : event;

    eventer(listenerEvent, handler, false);
  },

  isAtScrollThreshold: function() {
    // current vertical scroll position of the bottom of the visible area
    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop + 1;

    // height of the entire scrollable area (visible + hidden) * 0.8
    const threshold = document.scrollingElement.scrollHeight * 0.8;

    return scrollPosition >= threshold;
  }
};

export default listenerServices;
