import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { PROJECT_HOME } from "App/Routes";
import "./DesktopProject.css";
import Link from "components/shared/Link/Link";
import Loading from "components/shared/Loading";

import { SINGLE_PROJECT_APP, SHOW_TOPIC_CATEGORIES } from "config";
import localize from "lang/localize";

const propTypes = {
  categoryId: PropTypes.string,
  categories: PropTypes.array,
  topics: PropTypes.array.isRequired,
  more: PropTypes.bool,
  randomNumber: PropTypes.number.isRequired,
  flavorTextArr: PropTypes.array.isRequired,
  showcaseProject: PropTypes.object,
  addAnchors: PropTypes.func,
  handleMore: PropTypes.func,
  renderTopics: PropTypes.func,
  language: PropTypes.string,
  isLoadingMore: PropTypes.bool
};

class DesktopProjectPage extends Component {
  renderCategory() {
    let category = this.props.categories.filter(
      category =>
        category.hasOwnProperty("selected") && category.selected === true
    )[0];

    let categoryTitle = category ? category.title : "";
    let anchoredCategoryTitle =
      categoryTitle !== "" ? this.props.addAnchors(categoryTitle) : "";

    let categoryDescription = category ? category.description : "";
    let anchoredCategoryDescription =
      categoryDescription !== ""
        ? this.props.addAnchors(categoryDescription)
        : "";

    if (this.props.categoryId) {
      return (
        <div className={"pure-u-1 " + (category ? "category-container" : "")}>
          <div className="category-title-icon">
            {/* Commented out the below until Topic Category icons are supported */}
            {/*{category &&
              category.image &&
              this.renderCategoryImage(category.image)}
            {category && category.image && (
              <div className="pure-u-1 pure-u-sm-1-12" />
            )}
            <div
              className={
                "pure-u-1 " +
                (category && category.image
                  ? "pure-u-sm-19-24 "
                  : "pure-u-sm-1-1 ")
              }
            >*/}

            {/* When Topic Category icons are supported, remove the 3 lines below and uncomment the lines above */}
            <div className="pure-u-1 pure-u-sm-1-1">
              <h3
                className="category-title"
                dangerouslySetInnerHTML={{ __html: anchoredCategoryTitle }}
              />
            </div>
          </div>
          <div className="category-description">
            <p
              dangerouslySetInnerHTML={{ __html: anchoredCategoryDescription }}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderCategoryImage(img) {
    return (
      <div className="pure-u-1 pure-u-sm-1-8">
        <div
          className="category-img"
          style={{
            backgroundImage: "url(" + img + ")",
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat"
          }}
        />
      </div>
    );
  }

  renderProjectShowcaseCard() {
    let flavorText = this.props.flavorTextArr[this.props.randomNumber];
    let showcaseImage = this.props.showcaseProject.showcaseImage;
    let showcaseTitle = this.props.showcaseProject.showcaseTagline;
    let showcaseDescription = this.props.showcaseProject.showcaseDescription;

    return (
      <div className="pure-u-1 pure-u-md-12-24" key={"projectshowcase"}>
        <div className="card featured-project-card featured-project-card-bg">
          <div className="card-height">
            <div className="inner-card">
              <h4 className="text-pink-gametize">{flavorText}</h4>
              <img
                className="circle iconlarge imgcenter padding"
                src={showcaseImage}
                alt={showcaseTitle}
              />
              <h5 dangerouslySetInnerHTML={{ __html: showcaseTitle }} />
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: showcaseDescription }}
              />
            </div>
            {this.renderProjectShowcaseLink()}
          </div>
        </div>
      </div>
    );
  }

  renderProjectShowcaseLink() {
    let showcaseProjectId = this.props.showcaseProject.projectId;

    /*
      We do not allow routing to different projects on single-project apps.
      Thus, open a new window to the app.gametize.com project instead
    */
    if (SINGLE_PROJECT_APP) {
      return (
        <a
          className="link"
          href={"https://app.gametize.com/project/" + showcaseProjectId}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="card-action">
            <strong className="card-action-text lighttext">
              {localize("showcase_try_now", this.props.language)}
            </strong>
          </div>
        </a>
      );
    } else {
      return (
        <Link to={PROJECT_HOME.format(showcaseProjectId)}>
          <div className="card-action">
            <strong className="card-action-text lighttext">
              {localize("showcase_try_now", this.props.language)}
            </strong>
          </div>
        </Link>
      );
    }
  }

  render() {
    return (
      <Fragment>
        <div className="pure-u-1-1 pure-u-lg-2-24" />
        <div className="pure-u-1-1 pure-u-lg-20-24">
          {SHOW_TOPIC_CATEGORIES &&
            this.props.categories &&
            this.renderCategory()}
          {this.props.topics.length !== 0 ? (
            /* isMobile is false */
            this.props.renderTopics(this.props.topics, false)
          ) : (
            <p className="textcenter verticalpadding">
              {localize("empty_game_table_text", this.props.language)}
            </p>
          )}
          {this.props.topics.length !== 0 &&
            !this.props.more &&
            !!this.props.showcaseProject &&
            this.renderProjectShowcaseCard()}
          {/* {this.props.more && (
            <button
              className="button automargin"
              onClick={this.props.handleMore}
            >
              {localize("more_text", this.props.language)}
            </button>
          )} */}
          {this.props.isLoadingMore ? <Loading /> : null}
        </div>
        <div className="pure-u-1-1 pure-u-lg-2-24" />
      </Fragment>
    );
  }
}

DesktopProjectPage.propTypes = propTypes;

export default DesktopProjectPage;
