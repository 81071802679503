import React, { Component } from "react";
import PropTypes from "prop-types";

import Redeemed from "./Redeemed";

const propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  descriptionAfterRedeem: PropTypes.string,
  descriptionAfterCollect: PropTypes.string,
  option: PropTypes.string,
  usedAtFormatted: PropTypes.string,
  code: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  language: PropTypes.string,
  projectId: PropTypes.number,
  showAlertWithTimeout: PropTypes.func.isRequired
};

class RedeemedContainer extends Component {
  constructor() {
    super();
    this.state = {
      showCollectRedeemedDialog: false
    };

    this.handleOpenCollectRedeemedDialog = this.handleOpenCollectRedeemedDialog.bind(
      this
    );
    this.handleCloseCollectRedeemedDialog = this.handleCloseCollectRedeemedDialog.bind(
      this
    );
  }

  handleOpenCollectRedeemedDialog() {
    this.setState({
      showCollectRedeemedDialog: true
    });
  }

  handleCloseCollectRedeemedDialog() {
    this.setState({
      showCollectRedeemedDialog: false
    });
  }

  render() {
    return (
      <Redeemed
        id={this.props.id}
        title={this.props.title}
        description={this.props.description}
        img={this.props.img}
        descriptionAfterRedeem={this.props.descriptionAfterRedeem}
        descriptionAfterCollect={this.props.descriptionAfterCollect}
        option={this.props.option}
        usedAtFormatted={this.props.usedAtFormatted}
        code={this.props.code}
        type={this.props.type}
        handleClick={this.props.handleClick}
        language={this.props.language}
        projectId={this.props.projectId}
        showAlertWithTimeout={this.props.showAlertWithTimeout}
        // collect redeemed dialog
        showCollectRedeemedDialog={this.state.showCollectRedeemedDialog}
        handleOpenCollectRedeemedDialog={this.handleOpenCollectRedeemedDialog}
        handleCloseCollectRedeemedDialog={this.handleCloseCollectRedeemedDialog}
      />
    );
  }
}

RedeemedContainer.propTypes = propTypes;

export default RedeemedContainer;
