import React, { Component } from "react";
import PropTypes from "prop-types";

import { PROJECT_FRAME } from "App/Routes";
import placeholder_team from "images/core/placeholders/placeholder_team.jpg";
/* For incorporating routing in child buttons */
import Link from "components/shared/Link/Link";

let classnames = require("classnames");

const propTypes = {
  bgImg: PropTypes.string,
  yourTeamButton: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  isWebUrlSession: PropTypes.bool,
  projectId: PropTypes.number,
  icon: PropTypes.string,
  buttonTypeClass: PropTypes.string
};

const defaultProps = {
  yourTeamButton: false,
  isExternalLink: false,
  isWebUrlSession: false
};

class ChildButton extends Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    if (this.props.disabled === true) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    let className = classnames(
        "mfb-component__button--child",
        this.props.className,
        this.props.buttonTypeClass,
        { yourTeamButton: this.props.yourTeamButton },
        { "mfb-component__button--disabled": this.props.disabled }
      ),
      /* Mfb default: Mfb icons */
      // iconClass = classnames('mfb-component__child-icon', this.props.icon),

      /* New: Font-awesome icons */
      iconClass =
        this.props.icon.indexOf("fa") !== -1
          ? classnames("fas", this.props.icon)
          : classnames("gamicons", this.props.icon),
      style = {};

    /* Background images for 'Your Team' */
    if (this.props.yourTeamButton) {
      if (this.props.bgImg) {
        let bgImg = this.props.bgImg;

        style = {
          background: "url(" + bgImg + ") 0% 0% / cover transparent"
        };
      } else {
        // cases where user hasn't logged in, or no team profile image
        style = {
          background: "url(" + placeholder_team + ") 0% 0% / cover transparent"
        };
      }
    }

    let label = this.props.label;

    if (this.props.isExternalLink) {
      return (
        <li>
          <a
            href={this.props.href}
            data-mfb-label={label}
            target="_blank"
            rel="noopener noreferrer"
            style={style}
            className={className}
          >
            <i className={iconClass} />
          </a>
        </li>
      );
    } else if (this.props.isWebUrlSession) {
      const ENCODED_LINK = encodeURIComponent(this.props.href);
      return (
        <li>
          <Link
            to={PROJECT_FRAME.format(this.props.projectId, ENCODED_LINK)}
            data-mfb-label={label}
            onClick={this.handleOnClick}
            style={style}
            className={className}
          >
            <i className={iconClass} />
          </Link>
        </li>
      );
    } else {
      return (
        <li>
          <Link
            to={this.props.href}
            data-mfb-label={label}
            onClick={this.handleOnClick}
            style={style}
            className={className}
          >
            <i className={iconClass} />
          </Link>
        </li>
      );
    }
  }
}

ChildButton.propTypes = propTypes;
ChildButton.defaultProps = defaultProps;

export default ChildButton;
