import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import getApiGenerator from "services/getApiGenerator";
import { GET_PROJECT_CHALLENGES } from "services/api";
import Router from "router";
import { PROJECT_SEARCH } from "App/Routes";

import { showAlertWithTimeout } from "actions";

import SearchChallengeDialog from "./SearchChallengeDialog";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseSearchChallengeDialog: PropTypes.func.isRequired
};

const defaultProps = {
  showModal: false
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

class SearchChallengeDialogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal,
      search: ""
    };
  }

  handleSearchChange = event => {
    this.setState({ search: event.target.value });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.handleSubmit(event);
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.state.search) {
      getApiGenerator(
        GET_PROJECT_CHALLENGES.format(this.props.projectId),
        { keywords: this.state.search },
        this.props.sessionKey
      ).end((err, res) => {
        this.props.handleCloseSearchChallengeDialog();

        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error"
            });
          }
        } else {
          Router.navigate(
            PROJECT_SEARCH.format(this.props.projectId, this.state.search)
          );
        }
        this.setState({ search: "" });
      });
    }
  };

  render() {
    return (
      <SearchChallengeDialog
        showModal={this.props.showModal}
        handleCloseSearchChallengeDialog={
          this.props.handleCloseSearchChallengeDialog
        }
        handleKeyPress={this.handleKeyPress}
        handleSearchChange={this.handleSearchChange}
        handleSubmit={this.handleSubmit}
        search={this.state.search}
        language={this.props.language}
      />
    );
  }
}

SearchChallengeDialogContainer.propTypes = propTypes;
SearchChallengeDialogContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchChallengeDialogContainer);
