import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { TopicContainer } from "./TopicContainer";
import TopicPage from "./TopicPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import getApiGenerator from "services/getApiGenerator";
import { LOAD_MORE_COUNT } from "config";
import { setProject, setButtons, showAlertWithTimeout } from "actions";
import { GET_PROJECT_BUTTONS } from "services/api";
import localize from "lang/localize";

const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    challengeSearchEnabled:
      state.project && Object.entries(state.project).length > 0
        ? state.project.challengeSearchEnabled
        : false,
    user: state.user,
    language: state.language
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

const propTypes = {
  search: PropTypes.string.isRequired
};

class SearchChallengeContainer extends TopicContainer {
  getChallenges(page) {
    getApiGenerator(
      "bundles/" + this.props.projectId + "/challenges.json",
      {
        page: page,
        keywords: this.props.search,
        limit: LOAD_MORE_COUNT
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.setState({ challenges: [] });
      } else {
        const challenges = this.state.challenges
          ? this.state.challenges.slice()
          : [];
        this.setState({
          project: res.body.game,
          challenges: challenges.concat(res.body.data),
          more: res.body.more,
          page: this.state.page + 1,
          contactEmail: res.body.game.contactEmail
        });
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }
      }
    });
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
      }
    });
  }

  render() {
    if (this.props.challengeSearchEnabled === false) {
      return (
        <GenericErrorPage
          message={localize("error_404", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges && this.state.challenges.length !== 0) {
      return (
        <TopicPage
          sessionKey={this.props.sessionKey}
          project={this.state.project}
          challenges={this.state.challenges}
          /* searching challenges should not retrieve category ID from URL */
          categoryId={null}
          more={this.state.more}
          /* achievements prop unnecessary */
          handleMore={this.handleMore.bind(this)}
          handleBookmark={this.handleBookmark.bind(this)}
          handleLike={this.handleLike.bind(this)}
          /* totalChallenges prop unnecessary */
          /* completedChallenges prop unnecessary */
          /* personality prop unnecessary */
          allowReset={false}
          isSearch={true}
          showTopicChallengesCompleted={this.state.showTopicChallengesCompleted}
          showTopicAchievements={this.state.showTopicAchievements}
          handleOpenCommentsModal={this.handleOpenCommentsModal}
          handleCloseCommentsModal={this.handleCloseCommentsModal}
          showCommentsModal={this.state.showCommentsModal}
          /* topicTitle prop unnecessary */
          /* topicId prop unnecessary */
          showBookmarkLoginDialog={this.state.showBookmarkLoginDialog}
          handleOpenBookmarkLoginDialog={this.handleOpenBookmarkLoginDialog}
          handleCloseBookmarkLoginDialog={this.handleCloseBookmarkLoginDialog}
          showLikeLoginDialog={this.state.showLikeLoginDialog}
          handleOpenLikeLoginDialog={this.handleOpenLikeLoginDialog}
          handleCloseLikeLoginDialog={this.handleCloseLikeLoginDialog}
          showResetActivityDialog={this.state.showResetActivityDialog}
          handleOpenResetActivityDialog={this.handleOpenResetActivityDialog}
          handleCloseResetActivityDialog={this.handleCloseResetActivityDialog}
          isLoggedIn={!!this.props.user.id}
          language={this.props.language}
          contactEmail={this.state.contactEmail}
        />
      );
    } else if (this.state.challenges) {
      return (
        <GenericErrorPage
          message={localize("empty_challenge_table_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

SearchChallengeContainer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchChallengeContainer);
