import React, { Component } from "react";
import PropTypes from "prop-types";

import "./ButtonGroupInput.css";

const propTypes = {
  options: PropTypes.array.isRequired,
  onMFChangeButtonOptions: PropTypes.func,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  handleDisabledClick: PropTypes.func
};

const defaultProps = {
  disabled: false
};

class ButtonGroupInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedId: null
    };

    this.handleOptionClick = this.handleOptionClick.bind(this);
  }

  handleOptionClick(evt, id) {
    evt.preventDefault();

    if (this.state.selectedId !== id) {
      this.setState({ selectedId: id });
    }

    if (this.props.onMFChangeButtonOptions) {
      this.props.onMFChangeButtonOptions(this.props.index, id);
    }
  }

  render() {
    return (
      <div className="button-input-group">
        {this.props.options.map(opt => {
          let optTitle = opt.title;

          return (
            <button
              key={opt.id}
              className={
                "button fullwidth quizinput" +
                (this.state.selectedId === opt.id ? " selected" : "") +
                (this.props.disabled
                  ? " pseudo-readonly cursor-not-allowed"
                  : "")
              }
              onClick={
                this.props.disabled
                  ? this.props.handleDisabledClick
                  : evt => this.handleOptionClick(evt, opt.id)
              }
            >
              {optTitle}
            </button>
          );
        })}
      </div>
    );
  }
}

ButtonGroupInput.propTypes = propTypes;
ButtonGroupInput.defaultProps = defaultProps;

export default ButtonGroupInput;
