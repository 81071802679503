import React, { Component } from "react";
import PropTypes from "prop-types";

import localize from "lang/localize";

const propTypes = {
  language: PropTypes.string
};

class Error404Page extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("error404Page")) {
      bodyDOM.className += " error404Page";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("error404Page")) {
      bodyDOM.classList.remove("error404Page");
    }
  }

  render() {
    return (
      <div className="container verticalpadding">
        <div className="innerblock textcenter">
          <h6>{localize("error_404", propTypes.language)}</h6>
        </div>
      </div>
    );
  }
}

Error404Page.propTypes = propTypes;

export default Error404Page;
