import React, { Fragment } from "react";

import Loading from "components/shared/Loading";
import localize from "lang/localize";

import DesktopProjectPage from "components/Project/DesktopProjectPage";

class ASMDesktopProjectPage extends DesktopProjectPage {
  render() {
    return (
      <Fragment>
        <div className="pure-u-1-1 asm-topic-card-container">
          {this.props.topics.length !== 0 ? (
            /* isMobile is false */
            this.props.renderTopics(this.props.topics, false)
          ) : (
            <p className="textcenter verticalpadding">
              {localize("empty_game_table_text", this.props.language)}
            </p>
          )}
          {this.props.isLoadingMore ? <Loading /> : null}
        </div>
      </Fragment>
    );
  }
}

export default ASMDesktopProjectPage;
