import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  autoFocus: PropTypes.bool
};

const defaultProps = {
  autoFocus: false
};

const TextInput = props => {
  let placeholder = props.placeholder;

  return (
    <input
      type="text"
      placeholder={placeholder}
      onChange={props.onChange}
      autoFocus={props.autoFocus}
      onKeyPress={props.onKeyPress}
      {...props}
    />
  );
};

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default TextInput;
