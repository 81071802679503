import getApiGenerator from "./getApiGenerator";
import {
  LIKE_ACTION,
  BOOKMARK_ACTION,
  START_CHALLENGE_VIEW,
  END_CHALLENGE_VIEW
} from "./api";
import {
  CHALLENGE,
  CHALLENGE_COMMENTS,
  CHALLENGE_ACTIVITY,
  CHALLENGE_MY_ACTIVITY
} from "App/Routes";

/*
 * challenge icons and points
 */

function getChallengeType(
  challengeTypeId,
  photoOnly,
  multiSelect,
  challengeType
) {
  if (challengeTypeId === 1 && photoOnly) {
    return "photo";
  } else if (challengeTypeId === 11) {
    if (multiSelect === true) {
      return "survey-multi";
    } else if (multiSelect === false) {
      return "survey-single";
    } else {
      /* Current workaround, because certain APIs do not return "multiSelect" flag */
      return "survey";
    }
  } else if (challengeType !== undefined) {
    return challengeType;
  } else {
    return "normal";
  }
}

function getPoints(challengeTypeId: number, points: number) {
  if (challengeTypeId === 4) {
    return 0;
  } else if (points !== undefined) {
    // no points for flashcard
    return points;
  } else {
    return 10;
  } // default if points not specified
}

/*
 * GET actions (bookmark, like)
 */

function likeChallenge(event, id, sessionKey) {
  event.preventDefault();
  let query = { type: "challenge", type_id: id };
  return getApiGenerator(LIKE_ACTION, query, sessionKey);
}

function bookmarkChallenge(event, id, isBookmarked, sessionKey) {
  event.preventDefault();
  let query = { type_id: id, flag: !isBookmarked };
  return getApiGenerator(BOOKMARK_ACTION, query, sessionKey);
}

function likeClaim(event, id, sessionKey) {
  event.preventDefault();
  let query = { type: "claim", type_id: id };
  return getApiGenerator(LIKE_ACTION, query, sessionKey);
}

function startChallengeTimeTracking(id, sessionKey, showAlertWithTimeout) {
  getApiGenerator(START_CHALLENGE_VIEW.format(id), {}, sessionKey).end(
    (err, res) => {
      /* API failure */
      if (err || res.body.code !== 200) {
        showAlertWithTimeout({
          text: res.body.error,
          type: "error"
        });
      }
    }
  );
}

function endChallengeTimeTracking(id, sessionKey, showAlertWithTimeout, path) {
  /* end challenge time tracking if user is not navigating to challenge/comments/activity page */
  const excludedUrl = [
    CHALLENGE.format(id),
    CHALLENGE_COMMENTS.format(id),
    CHALLENGE_ACTIVITY.format(id),
    CHALLENGE_MY_ACTIVITY.format(id)
  ];
  if (!excludedUrl.some(url => path.includes(url))) {
    getApiGenerator(END_CHALLENGE_VIEW.format(id), {}, sessionKey).end(
      (err, res) => {
        /* API failure */
        if (err || res.body.code !== 200) {
          showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      }
    );
  }
}

export {
  getChallengeType,
  getPoints,
  likeChallenge,
  bookmarkChallenge,
  likeClaim,
  startChallengeTimeTracking,
  endChallengeTimeTracking
};
