import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DeleteCommentDialog from "./DeleteCommentDialog";

import { DELETE_COMMENT, DELETE_SUBCOMMENT } from "services/api";
import { showAlertWithTimeout } from "actions";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

const propTypes = {
  showDialog: PropTypes.bool,
  commentId: PropTypes.number,
  isSubComment: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  removeComment: PropTypes.func,
  type: PropTypes.string
};

const defaultProps = {
  showDialog: false,
  isSubComment: false
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

class DeleteCommentDialogContainer extends Component {
  constructor(props) {
    super(props);

    this.handleClickDelete = this.handleClickDelete.bind(this);
  }

  handleClickDelete() {
    this.deleteComment(this.props.commentId, this.props.type);
    this.props.removeComment(this.props.commentId);
    this.props.handleCloseDialog();
  }

  getApi2Type(type) {
    switch (type) {
      case "quest":
        return "topic";
      case "claim":
        return "completion";
      case "challenge":
      default:
        return type;
    }
  }

  deleteComment(type_id, type) {
    getApiGenerator(
      this.props.isSubComment ? DELETE_SUBCOMMENT : DELETE_COMMENT,
      {
        type_id: type_id,
        type: this.getApi2Type(type)
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("comment_delete_success", this.props.language),
          type: "success"
        });
      }
    });
  }

  render() {
    return (
      <DeleteCommentDialog
        showDialog={this.props.showDialog}
        commentId={this.props.commentId}
        handleCloseDialog={this.props.handleCloseDialog}
        handleClickDelete={this.handleClickDelete}
        language={this.props.language}
      />
    );
  }
}

DeleteCommentDialogContainer.propTypes = propTypes;
DeleteCommentDialogContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCommentDialogContainer);
