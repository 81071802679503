import React, { Component } from "react";
import { connect } from "react-redux";

import {
  PROJECT_LOGIN,
  LOGIN,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME
} from "App/Routes";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import Loading from "components/shared/Loading";

import { ENABLE_LOGIN_HOME, SECRET_KEY } from "config";
import { GET_OAUTH_SESSION_KEY } from "services/api";
import { setSessionKey, setUserId } from "actions";
import localStorageService from "services/localStorageService";
import sessionStorageService from "services/sessionStorageService";
import pushApiGenerator from "services/pushApiGenerator";
import loginServices from "services/loginServices";
import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    userId: state.user ? state.user.id : null,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setSessionKey: sessionKey => {
      dispatch(setSessionKey(sessionKey));
    },
    setUserId: user => {
      dispatch(setUserId(user));
    }
  };
};

export class SSOLoginSuccessContainer extends Component {
  constructor() {
    super();
    this.state = {
      sessionKey: null,
      userId: null
    };
  }

  componentDidMount() {
    let parametersString = this.props.id;

    /* Route out if there is already an existing sessionKey and userId */
    if (this.props.sessionKey && this.props.userId) {
      loginServices.postLoginReplaceAndNavigate(
        this.props.projectId,
        null,
        false
      );
    } else {
      if (
        this.props.id &&
        typeof this.props.id === "string" &&
        this.props.id.indexOf("/?") === 0
      ) {
        this.setParameters(parametersString, 2);
      } else if (
        this.props.id &&
        typeof this.props.id === "string" &&
        this.props.id.indexOf("?") === 0
      ) {
        this.setParameters(parametersString, 1);
      } else {
        this.setState({
          sessionKey: false,
          userId: false
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sessionKey !== this.state.sessionKey ||
      prevState.userId !== this.state.userId
    ) {
      if (
        typeof this.state.sessionKey === "string" &&
        typeof this.state.userId === "number"
      ) {
        this.handleLogin();
      }
    }
  }

  handleOAuthToken(token) {
    /*
      Retrieve session key using API call
      End Point: /api/oauth/tokenlogin.json?token=<token>&api_key=<api_key>
    */

    let data = {
      token: token,
      /* API key is appended for all API calls as "secret_key" but this specific end point uses "api_key" */
      api_key: SECRET_KEY
    };

    pushApiGenerator(GET_OAUTH_SESSION_KEY, data).end((err, res) => {
      if (err || res.body.code !== 200) {
        // No need for error handling here since it is handled in render()
      } else if (res.body.sessionKey) {
        this.setState({
          userId: res.body.userId,
          sessionKey: res.body.sessionKey
        });
      }
    });
  }

  handleLogin() {
    localStorageService.saveState({
      user_id: this.state.userId,
      sessionKey: this.state.sessionKey
    });
    this.props.setUserId(this.state.userId);
    this.props.setSessionKey(this.state.sessionKey);

    let isFirstLogin = false;

    // Check local storage of browser if this account has been used before for SSO login.
    // 1. If user has never logged in to any account in this browser, show tour screen
    // 2. If previous login is for a different account, show tour screen
    // 3. If previous login is for the same account, do not show tour screen (even if the account has been used in this browser in the past before)
    const lastUserId = localStorageService.getItem("last_user_id");

    if (!lastUserId) {
      // First time logging in to this app in current browser
      isFirstLogin = true;
    } else if (parseInt(lastUserId) !== this.state.userId) {
      // Check if previous login in current browser is for the same account
      isFirstLogin = true;
    }

    localStorageService.setItem("last_user_id", this.state.userId);

    // loads loginRedirectUrl from sessionStorage (if available)
    // eventually loginRedirectUrl will be dumped when new page is loaded
    // (not REGISTER nor LOGIN nor RESET_PASSWORD)
    // for the aforementioned dumping, see SingleProjectApp.js
    let loginRedirectUrl = sessionStorageService.getItem("loginRedirectUrl");

    loginServices.postLoginReplaceAndNavigate(
      this.props.projectId,
      loginRedirectUrl,
      isFirstLogin
    );
  }

  setParameters(slicedUrl, index) {
    const PARAMETERS = this.renderParametersInArray(slicedUrl, index);

    if (
      slicedUrl.indexOf("data=") > 0 &&
      slicedUrl.indexOf("sessionKey=") < 0
    ) {
      // Do not combine with setSessionKeyAndUserId as the error page will appear before state is updated
      PARAMETERS.forEach(this.getSessionKeyFromToken);
    } else {
      PARAMETERS.forEach(this.setSessionKeyAndUserId);
    }
  }

  getSessionKeyFromToken = (item, index) => {
    if (item.indexOf("data=") === 0) {
      this.handleOAuthToken(item.slice(5));
    }
  };

  setSessionKeyAndUserId = (item, index) => {
    /*
      set session key and user id to false,
      before reverifying by checking parameters
    */
    this.setState(
      {
        userId: false,
        sessionKey: false
      },
      () => {
        if (item.indexOf("sessionKey=") === 0) {
          this.setState({
            sessionKey: item.slice(11)
          });
        } else if (item.indexOf("userId=") === 0) {
          this.setState({
            userId: isNaN(parseInt(item.slice(7), 10))
              ? false
              : parseInt(item.slice(7), 10)
          });
        }
      }
    );
  };

  renderParametersInArray(slicedUrl, index) {
    return slicedUrl.slice(index).split("&");
  }

  getLoginRoute() {
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  render() {
    /*
      When sessionKey and userId are not yet set, they will be null.
      When sessionKey and userId are WRONGFULLY set, they will be false.
    */
    if (this.state.sessionKey === false || this.state.userId === false) {
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login_page", this.props.language)}
          message={localize("login_failed_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SSOLoginSuccessContainer);
