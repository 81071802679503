/*
  navigator.userAgentData still does not display correct
  information for ios devices as it is experimental, so
  it will not be used as default for now.
*/
const deviceServices = {
  isMobile: function() {
    if (navigator.userAgent) {
      return (
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        /Android/.test(navigator.userAgent)
      );
    } else {
      return navigator.userAgentData && navigator.userAgentData.mobile;
    }
  },
  isAndroid: function() {
    if (navigator.userAgent) {
      return /Android/.test(navigator.userAgent);
    } else {
      return (
        navigator.userAgentData &&
        navigator.userAgentData.platform === "Android"
      );
    }
  },
  isIOS: function() {
    if (navigator.userAgent) {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    } else {
      return (
        navigator.userAgentData && navigator.userAgentData.platform === "macOS"
      );
    }
  },
  isSafari: function() {
    if (navigator.userAgent) {
      return (
        !!window.navigator.userAgent.match(/WebKit/i) &&
        !window.navigator.userAgent.match(/CriOS/i) &&
        !window.navigator.userAgent.match(/FxiOS/i) &&
        !window.navigator.userAgent.match(/EdgiOS/i)
      );
    } else {
      navigator.userAgentData &&
        navigator.userAgentData.brands &&
        navigator.userAgentData.brands.some(brand => brand.brand === "safari");
    }
  },
  isWebView: function() {
    if (navigator.userAgent) {
      /* Detection is based on WebView for Android only. Unsure of implementation for iOS */
      return (
        /Dalvik/.test(navigator.userAgent) &&
        /Android/.test(navigator.userAgent)
      );
    } else {
      /* Only works for Android */
      return (
        navigator.userAgentData &&
        navigator.userAgentData.mobile &&
        navigator.userAgentData.mobile.endsWith("/webview")
      );
    }
  }
};

export default deviceServices;
