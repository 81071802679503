import React, { Component } from "react";
import PropTypes from "prop-types";

import CollectRedeemedDialog from "./CollectRedeemedDialog/CollectRedeemedDialog";

import localize from "lang/localize";

const propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  descriptionAfterRedeem: PropTypes.string,
  descriptionAfterCollect: PropTypes.string,
  option: PropTypes.string,
  usedAtFormatted: PropTypes.string,
  code: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  language: PropTypes.string,
  projectId: PropTypes.number,
  showAlertWithTimeout: PropTypes.func.isRequired,
  showCollectRedeemedDialog: PropTypes.bool.isRequired,
  handleOpenCollectRedeemedDialog: PropTypes.func.isRequired,
  handleCloseCollectRedeemedDialog: PropTypes.func.isRequired
};

const defaultProps = {
  usedAtFormatted: null
};

class Reward extends Component {
  renderButton() {
    if (this.props.usedAtFormatted) {
      return (
        <div>
          <div
            className="last-used-text smalltext flex align-items-center bottommargin-10"
            id="unlockedAt"
          >
            <i className="fas fa-history rightmargin-5"></i>
            {this.props.usedAtFormatted}
          </div>
          <div className="topmargin-10">
            <button
              className="button inactive"
              onClick={e => {
                e.preventDefault();
                this.props.handleOpenCollectRedeemedDialog();
              }}
            >
              {localize("item_used_at_text", this.props.language)}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="topmargin-10">
          <button
            className="button cta"
            onClick={e => {
              e.preventDefault();
              this.props.handleOpenCollectRedeemedDialog();
            }}
          >
            {localize("button_reward_collect", this.props.language)}
          </button>
        </div>
      );
    }
  }

  renderCollectRedeemedDialog() {
    return (
      <CollectRedeemedDialog
        showCollectRedeemedDialog={this.props.showCollectRedeemedDialog}
        itemTitle={this.props.title}
        itemId={this.props.id}
        itemImg={this.props.img}
        itemDescriptionAfterRedeem={this.props.descriptionAfterRedeem}
        itemDescriptionAfterCollect={this.props.descriptionAfterCollect}
        itemOption={this.props.option}
        itemUsedAtFormatted={this.props.usedAtFormatted}
        itemCode={this.props.code}
        itemType={this.props.type}
        showAlertWithTimeout={this.props.showAlertWithTimeout}
        handleCloseCollectRedeemedDialog={
          this.props.handleCloseCollectRedeemedDialog
        }
        handleClick={this.props.handleClick}
        language={this.props.language}
      />
    );
  }

  render() {
    let img = this.props.img;
    let title = this.props.title;

    return (
      <div className="bottompadding">
        <div className="pure-g">
          <div className="pure-u-6-24">
            <span className="square-image-wrapper">
              <span className="square-image circle">
                <img src={img} alt={title} />
              </span>
            </span>
          </div>
          <div className="pure-u-3-24" />
          <div className="pure-u-15-24">
            <p className="nomargin">
              <b>{title}</b>
            </p>
            {this.renderButton()}
          </div>
          <div className="pure-u-1-24" />
        </div>
        {this.props.showCollectRedeemedDialog &&
          this.renderCollectRedeemedDialog()}
      </div>
    );
  }
}

Reward.propTypes = propTypes;
Reward.defaultProps = defaultProps;

export default Reward;
