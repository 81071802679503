import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import {
  PROJECT_HOME,
  HOME,
  PROJECT_LOGIN,
  LOGIN,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME
} from "App/Routes";
//import TopicPage from "./TopicPage";
import TopicPage from "custom-components/Topic/TopicPage"; //CUSTOM FOR ASM
import {
  setProject,
  setButtons,
  setTopic,
  setChallengeCategory,
  showAlertWithTimeout,
  addProfileDrawerPoints,
  showAwardableActionWithTimeout,
  showAchievements
} from "actions";
import getApiGenerator from "services/getApiGenerator";
import sessionStorageService from "services/sessionStorageService";
import { GET_CHALLENGES, GET_PROJECT_BUTTONS } from "services/api";
import { likeChallenge, bookmarkChallenge } from "services/challengeServices";
import listenerServices from "services/listenerServices";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import {
  LOAD_MORE_COUNT,
  SHOW_TOPIC_CHALLENGES_COMPLETED,
  SHOW_TOPIC_ACHIEVEMENTS,
  ENABLE_LOGIN_HOME
} from "config";

import localize from "lang/localize";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    user: state.user,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    setTopic: topic => {
      dispatch(setTopic(topic));
    },
    setChallengeCategory: challengeCategory => {
      dispatch(setChallengeCategory(challengeCategory));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    },
    addProfileDrawerPoints: points => {
      dispatch(addProfileDrawerPoints(points));
    },
    showAwardableActionWithTimeout: awardableAction => {
      dispatch(showAwardableActionWithTimeout(awardableAction));
    },
    showAchievements: achievements => {
      dispatch(showAchievements(achievements));
    }
  };
};

const propTypes = {
  id: PropTypes.string.isRequired
};

export class TopicContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      errorProject: null,
      topic: null,
      challenges: null,
      achievements: null,
      more: false,
      page: 1,
      id: props.id,
      contactEmail: null,
      showTopicChallengesCompleted: SHOW_TOPIC_CHALLENGES_COMPLETED,
      showTopicAchievements: SHOW_TOPIC_ACHIEVEMENTS,
      showBookmarkLoginDialog: false,
      showLikeLoginDialog: false,
      showResetActivityDialog: false,
      topicIdNext: null,
      isLoadingMore: false
    };

    this.handleOpenBookmarkLoginDialog = this.handleOpenBookmarkLoginDialog.bind(
      this
    );
    this.handleCloseBookmarkLoginDialog = this.handleCloseBookmarkLoginDialog.bind(
      this
    );
    this.handleOpenLikeLoginDialog = this.handleOpenLikeLoginDialog.bind(this);
    this.handleCloseLikeLoginDialog = this.handleCloseLikeLoginDialog.bind(
      this
    );

    this.handleOpenResetActivityDialog = this.handleOpenResetActivityDialog.bind(
      this
    );
    this.handleCloseResetActivityDialog = this.handleCloseResetActivityDialog.bind(
      this
    );

    this.getChallenges = this.getChallenges.bind(this);
    this.handleMore = this.handleMore.bind(this);
  }

  getChallenges(page) {
    getApiGenerator(
      GET_CHALLENGES.format(this.state.id),
      {
        category_id: this.props.categoryId,
        page: page,
        limit: LOAD_MORE_COUNT
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body && res.body.game) {
          this.setState(
            {
              challenges: [],
              errorProject: res.body.game,
              isLoadingMore: false
            },
            () => {
              this.props.setProject(this.state.errorProject);
            }
          );
        } else {
          this.setState({ challenges: [], isLoadingMore: false });
        }
      } else {
        const CATEGORIES = res.body.categories
          ? res.body.categories.map(category => ({
              id: category.id,
              title: category.name,
              selected: category.selected,
              description: category.description,
              image: category.image
            }))
          : null;

        const challenges = this.state.challenges
          ? this.state.challenges.slice()
          : [];

        let compiledTopic = CATEGORIES
          ? Object.assign({}, res.body.quest, { categories: CATEGORIES })
          : res.body.quest;

        this.setState({
          project: res.body.game,
          topic: res.body.quest,
          achievements: res.body.wins ? res.body.wins : [],
          challenges: challenges.concat(res.body.data),
          more: res.body.more,
          page: this.state.page + 1,
          contactEmail: res.body.game.contactEmail,
          topicIdNext: res.body.topicIdNext,
          isLoadingMore: false
        });
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        this.props.setTopic(compiledTopic);

        if (CATEGORIES) {
          this.props.setChallengeCategory(
            CATEGORIES.filter(category => category.selected === true)[0]
          );
        }
      }
    });
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    /*
      resolves API call problem:
      (1) checks if prevState.challenges is null, and not array (see constructor)
      (2) then checks if this.state.challenges is array
          (ie getChallenges called for first time, but did not save challenge list)
      (3) then checks if !this.state.challenges[0] (ie it is an empty array)
      (4) if conditions met, re-call API
    */
    if (
      !prevState.challenges &&
      !Array.isArray(prevState.challenges) &&
      Array.isArray(this.state.challenges) &&
      !this.state.challenges[0]
    ) {
      this.getChallenges(this.state.page);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleMore);
  }

  handleLike = (event, id) => {
    likeChallenge(event, id, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        if (res.body.actionPoints && res.body.actionPoints > 0) {
          this.props.addProfileDrawerPoints(res.body.actionPoints);
          this.props.showAwardableActionWithTimeout({
            numberStr: "" + res.body.actionPoints.abbreviateNumber(),
            unit: localize("points_just_text", this.props.language)
          });
        }
        if (res.body.items) {
          this.props.showAchievements(res.body.items);
        }
        const challenges = this.state.challenges.slice();
        const challenge = challenges.filter(
          challenge => challenge.id === id
        )[0];
        challenge.ratedLike = res.body.rated;
        challenge.likeNo = res.body.likeNo;
        this.setState({
          challenges: challenges
        });
      }
    });
  };

  handleBookmark = (event, id, bookmarked) => {
    bookmarkChallenge(event, id, bookmarked, this.props.sessionKey).end(
      (err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error"
            });
          }
        } else {
          const challenges = this.state.challenges.slice();
          challenges.filter(challenge => challenge.id === id)[0].bookmarked =
            res.body.bookmarked;
          this.setState({
            challenges: challenges
          });
        }
      }
    );
  };

  componentDidMount() {
    /* Use hashing to show success alert for reset activity */
    if (window.location.hash.slice(0, 14) === "#resettedtopic") {
      this.props.showAlertWithTimeout({
        text: localize("alert_reset_activity_success", this.props.language),
        type: "success"
      });
    }
    this.getChallenges(this.state.page);
    window.addEventListener("scroll", this.handleMore);
  }

  handleMore = debounce(() => {
    const {
      getChallenges,
      state: { more, isLoadingMore, page }
    } = this;
    if (!more) {
      return;
    } else if (!isLoadingMore && more) {
      if (listenerServices.isAtScrollThreshold()) {
        this.setState(() => ({
          isLoadingMore: true
        }));
        getChallenges(page);
      }
    }
  }, 100);

  handleOpenBookmarkLoginDialog() {
    this.setState({
      showBookmarkLoginDialog: true
    });
  }

  handleCloseBookmarkLoginDialog() {
    this.setState({
      showBookmarkLoginDialog: false
    });
  }

  handleOpenLikeLoginDialog() {
    this.setState({
      showLikeLoginDialog: true
    });
  }

  handleCloseLikeLoginDialog() {
    this.setState({
      showLikeLoginDialog: false
    });
  }

  handleOpenResetActivityDialog() {
    this.setState({
      showResetActivityDialog: true
    });
  }

  handleCloseResetActivityDialog() {
    this.setState({
      showResetActivityDialog: false
    });
  }

  getLoginRoute() {
    /* Embedded project tests not necessary here, because loginRedirectUrl is saved */
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  render() {
    const UNAVAILABLE_TOPIC_ERROR_LIST = [
      localize("unavailable_topic_locked_private", this.props.language),
      localize("unavailable_topic_private_project", this.props.language),
      localize("unavailable_topic_unpublished", this.props.language),
      localize("unavailable_topic_no_challenges", this.props.language),
      localize("unavailable_topic_not_exist", this.props.language)
    ];
    const UNAVAILABLE_TOPIC_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_topic_locked_private_loggedin",
        this.props.language
      ),
      localize(
        "unavailable_topic_private_project_loggedin",
        this.props.language
      ),
      localize("unavailable_topic_unpublished", this.props.language),
      localize("unavailable_topic_no_challenges", this.props.language),
      localize("unavailable_topic_not_exist", this.props.language)
    ];
    const UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST = [
      localize("unavailable_topic_locked_private", this.props.language),
      localize("unavailable_topic_no_challenges", this.props.language)
    ];
    const UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_topic_locked_private_loggedin",
        this.props.language
      ),
      localize("unavailable_topic_no_challenges", this.props.language)
    ];
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    if (this.state.challenges && this.state.challenges.length !== 0) {
      return (
        <TopicPage
          isLoadingMore={this.state.isLoadingMore}
          topicIdNext={this.state.topicIdNext}
          sessionKey={this.props.sessionKey}
          project={this.state.project}
          challenges={this.state.challenges}
          categoryId={this.props.categoryId}
          more={this.state.more}
          achievements={this.state.achievements}
          handleMore={this.handleMore}
          handleBookmark={this.handleBookmark.bind(this)}
          handleLike={this.handleLike.bind(this)}
          totalChallenges={this.state.topic.challengeNo}
          completedChallenges={this.state.topic.userChallengeCompletedNo}
          personality={this.state.topic.tag}
          allowReset={this.state.topic.allowReset || false}
          isSearch={false}
          showTopicChallengesCompleted={this.state.showTopicChallengesCompleted}
          showTopicAchievements={this.state.showTopicAchievements}
          topicTitle={this.state.topic.title}
          topicId={this.state.topic.id}
          showBookmarkLoginDialog={this.state.showBookmarkLoginDialog}
          handleOpenBookmarkLoginDialog={this.handleOpenBookmarkLoginDialog}
          handleCloseBookmarkLoginDialog={this.handleCloseBookmarkLoginDialog}
          showLikeLoginDialog={this.state.showLikeLoginDialog}
          handleOpenLikeLoginDialog={this.handleOpenLikeLoginDialog}
          handleCloseLikeLoginDialog={this.handleCloseLikeLoginDialog}
          showResetActivityDialog={this.state.showResetActivityDialog}
          handleOpenResetActivityDialog={this.handleOpenResetActivityDialog}
          handleCloseResetActivityDialog={this.handleCloseResetActivityDialog}
          isLoggedIn={!!this.props.user.id}
          language={this.props.language}
          contactEmail={this.state.contactEmail}
        />
      );
    } else if (
      this.state.challenges &&
      this.state.errorProject &&
      !this.props.sessionKey
    ) {
      /* Project information is returned, but not logged in */
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST}
          endMessage={localize(
            "unavailable_topic_only_login_prompt",
            this.props.language
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges && this.state.errorProject) {
      /* Project information is returned, but logged in */
      return (
        <GenericErrorPage
          routeUrl={PROJECT_HOME.format(this.state.errorProject.id)}
          routeName={"“" + this.state.errorProject.title + "”"}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_AVAILABLE_PROJECT_ERROR_LIST_LOGGEDIN}
          endMessage={localize(
            "unavailable_topic_return_project_prompt",
            this.props.language
          ).format(this.state.errorProject.title)}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges && !this.props.sessionKey) {
      /* No information is returned, but not logged in */
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_ERROR_LIST}
          endMessage={localize(
            "unavailable_topic_login_prompt",
            this.props.language
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (this.state.challenges) {
      /* No information is returned, but logged in */
      return (
        <GenericErrorPage
          routeUrl={IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_topic_long", this.props.language)}
          messageList={UNAVAILABLE_TOPIC_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

TopicContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(TopicContainer);
