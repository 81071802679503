import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { CLAIM } from "App/Routes";
import ClaimResult from "./ClaimResult";

import Router from "router";
import getApiGenerator from "services/getApiGenerator";
import { GET_TOPICS } from "services/api";

const propTypes = {
  id: PropTypes.number.isRequired,
  claimUserId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  type: PropTypes.oneOf(["project", "challenge", "claim"]).isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  claimImage: PropTypes.string,
  claimVideo: PropTypes.string,
  claimVideoContainer: PropTypes.string,
  claimVideoThumbnail: PropTypes.string,
  entries: PropTypes.array,
  claimMessage: PropTypes.string,
  likeNo: PropTypes.number.isRequired,
  commentNo: PropTypes.number.isRequired,
  ratedLike: PropTypes.bool,
  handleLike: PropTypes.func.isRequired,
  removeClaim: PropTypes.func,
  privateClaim: PropTypes.bool,
  userName: PropTypes.string.isRequired,
  handleComments: PropTypes.func.isRequired
};

const defaultProps = {
  ratedLike: false
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    projectId: state.projectId,
    language: state.language
  };
};

export class ClaimResultContainer extends Component {
  constructor() {
    super();

    this.state = {
      showDeleteCompletionDialog: false,
      showLikeLoginDialog: false,
      contactEmail: null,
      showVideoDialog: false
    };

    this.handleClaimClick = this.handleClaimClick.bind(this);
    this.handleOpenDeleteCompletionDialog = this.handleOpenDeleteCompletionDialog.bind(
      this
    );
    this.handleCloseDeleteCompletionDialog = this.handleCloseDeleteCompletionDialog.bind(
      this
    );
    this.handleOpenLikeLoginDialog = this.handleOpenLikeLoginDialog.bind(this);
    this.handleCloseLikeLoginDialog = this.handleCloseLikeLoginDialog.bind(
      this
    );
    /* Video Dialogs */
    this.handleOpenVideoDialog = this.handleOpenVideoDialog.bind(this);
    this.handleCloseVideoDialog = this.handleCloseVideoDialog.bind(this);
  }

  componentDidMount() {
    if (this.props.projectId) {
      this.getSupportEmail();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.projectId !== prevProps.projectId) {
      this.getSupportEmail();
    }
  }

  handleClaimClick(event) {
    if (!event.target.classList.contains("secondaryLink")) {
      event.preventDefault();
      Router.navigate(CLAIM.format(this.props.id));
    }
  }

  handleOpenDeleteCompletionDialog() {
    this.setState({
      showDeleteCompletionDialog: true
    });
  }

  handleCloseDeleteCompletionDialog() {
    this.setState({
      showDeleteCompletionDialog: false
    });
  }

  handleOpenLikeLoginDialog() {
    this.setState({
      showLikeLoginDialog: true
    });
  }

  handleCloseLikeLoginDialog() {
    this.setState({
      showLikeLoginDialog: false
    });
  }

  handleOpenVideoDialog() {
    this.setState({
      showVideoDialog: true
    });
  }

  handleCloseVideoDialog() {
    this.setState({
      showVideoDialog: false
    });
  }

  getSupportEmail(page = 1, category_id = "") {
    getApiGenerator(
      GET_TOPICS.format(this.props.projectId),
      {
        page: page,
        category_id: category_id
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
        }
      } else {
        this.setState({ contactEmail: res.body.game.contactEmail });
      }
    });
  }

  render() {
    return (
      <ClaimResult
        sessionKey={this.props.sessionKey}
        id={this.props.id}
        claimUserId={this.props.claimUserId}
        userId={this.props.userId}
        type={this.props.type}
        challengeTypeId={this.props.challengeTypeId}
        claimImage={this.props.claimImage}
        claimVideo={this.props.claimVideo}
        claimVideoContainer={this.props.claimVideoContainer}
        claimVideoThumbnail={this.props.claimVideoThumbnail}
        entries={this.props.entries}
        claimMessage={this.props.claimMessage}
        likeNo={this.props.likeNo}
        commentNo={this.props.commentNo}
        ratedLike={this.props.ratedLike}
        handleLike={this.props.handleLike}
        removeClaim={this.props.removeClaim}
        privateClaim={this.props.privateClaim}
        showDeleteCompletionDialog={this.state.showDeleteCompletionDialog}
        handleOpenDeleteCompletionDialog={this.handleOpenDeleteCompletionDialog}
        handleCloseDeleteCompletionDialog={
          this.handleCloseDeleteCompletionDialog
        }
        userName={this.props.userName}
        handleComments={this.props.handleComments}
        showLikeLoginDialog={this.state.showLikeLoginDialog}
        handleOpenLikeLoginDialog={this.handleOpenLikeLoginDialog}
        handleCloseLikeLoginDialog={this.handleCloseLikeLoginDialog}
        isLoggedIn={!!this.props.userId}
        language={this.props.language}
        contactEmail={this.state.contactEmail}
        handleClaimClick={this.handleClaimClick}
        // video dialogs
        showVideoDialog={this.state.showVideoDialog}
        handleOpenVideoDialog={this.handleOpenVideoDialog}
        handleCloseVideoDialog={this.handleCloseVideoDialog}
      />
    );
  }
}

ClaimResultContainer.propTypes = propTypes;
ClaimResultContainer.defaultProps = defaultProps;

export default connect(mapStateToProps)(ClaimResultContainer);
