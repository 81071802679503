import React, { Component } from "react";
import { connect } from "react-redux";

import AchievementsModal from "./AchievementsModal";

import { showAchievements, clearAchievements } from "actions";

export const mapStateToProps = (state, ownProps) => {
  return {
    achievements: state.achievements
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAchievements: achievements => {
      dispatch(showAchievements(achievements));
    },
    clearAchievements: id => {
      dispatch(clearAchievements(id));
    }
  };
};

/*
  Use AchievementsModalContainer if you intend to retrieve Achievements
  from Redux store. Otherwise, just use AchievementsModal.
*/
export class AchievementsModalContainer extends Component {
  constructor() {
    super();
    this.state = {
      showAchievementsModal: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    /*
      Opens achievement modal if there's a set of achievements,
      and previous set of achievements !== current set of achievements
    */
    if (
      !!this.props.achievements[0] &&
      this.props.achievements[0] !== prevProps.achievements[0]
    ) {
      this.handleOpenAchievementsModal();
    }
  }

  handleOpenAchievementsModal() {
    this.setState({ showAchievementsModal: true });
  }

  handleCloseAchievementsModal(id) {
    this.setState({ showAchievementsModal: false }, () => {
      this.props.clearAchievements(id);
    });
  }

  render() {
    if (this.state.showAchievementsModal) {
      return (
        <AchievementsModal
          showModal={this.state.showAchievementsModal}
          handleCloseModal={() =>
            this.handleCloseAchievementsModal(this.props.achievements[0].id)
          }
          achievements={this.props.achievements[0].achievements}
        />
      );
    } else {
      return null;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AchievementsModalContainer);
