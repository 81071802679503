import React, { Component } from "react";
import { connect } from "react-redux";

import AwardableActions from "./AwardableActions";

import { clearAwardableAction } from "actions";

const mapStateToProps = (state, ownProps) => {
  return {
    awardableActions: state.awardableActions
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearAlert: id => {
      dispatch(clearAwardableAction(id));
    }
  };
};

class AwardableActionsContainer extends Component {
  render() {
    return (
      <AwardableActions
        awardableActions={this.props.awardableActions}
        clearAlert={this.props.clearAwardableAction}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AwardableActionsContainer
);
