import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  PROJECT_PROFILE,
  PROFILE,
  PROJECT_USER,
  USER,
  CHALLENGE
} from "App/Routes";
import Link from "components/shared/Link/Link";

import localize from "lang/localize";

const propTypes = {
  projectId: PropTypes.number.isRequired,
  claimUserId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  userName: PropTypes.string.isRequired,
  userImage: PropTypes.string.isRequired,
  userRankStatus: PropTypes.string,
  userRankStatusImage: PropTypes.string,
  createdAtFormatted: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  challengeId: PropTypes.number.isRequired,
  challengeTitle: PropTypes.string.isRequired,
  challengeType: PropTypes.string.isRequired,
  challengeTypeId: PropTypes.number.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  challengePhotoOnly: PropTypes.bool.isRequired,
  correctAnswer: PropTypes.bool,
  privateClaim: PropTypes.bool,
  language: PropTypes.string
};

// should use default pic
class Claim extends Component {
  getProfileLink() {
    if (this.props.claimUserId === this.props.userId && this.props.projectId) {
      return PROJECT_PROFILE.format(this.props.projectId);
    } else if (this.props.claimUserId === this.props.userId) {
      return PROFILE;
    } else if (this.props.projectId) {
      return PROJECT_USER.format(this.props.projectId, this.props.claimUserId);
    } else {
      return USER.format(this.props.claimUserId);
    }
  }

  renderCompletionText(challengeTitle) {
    if (this.props.challengeTypeId === 2 || this.props.challengeTypeId === 10) {
      if (
        this.props.privateClaim &&
        this.props.userId !== this.props.claimUserId
      ) {
        return localize("claim_cell_claimed_text", this.props.language).format(
          challengeTitle
        );
      } else if (this.props.correctAnswer) {
        return localize(
          "claim_cell_claimed_correct_text",
          this.props.language
        ).format(challengeTitle);
      } else {
        return localize(
          "claim_cell_claimed_wrong_text",
          this.props.language
        ).format(challengeTitle);
      }
    } else {
      return localize("claim_cell_claimed_text", this.props.language).format(
        challengeTitle
      );
    }
  }

  renderPrivateCompletionMarker() {
    if (
      this.props.privateClaim &&
      this.props.userId !== this.props.claimUserId
    ) {
      return (
        <div className="topmargin-5">
          <strong>
            {localize(
              "challenge_profile_private_claim_text",
              this.props.language
            )}
          </strong>
        </div>
      );
    }

    return null;
  }

  render() {
    let userName = this.props.userName;
    let userImage = this.props.userImage;
    let challengeTitle = this.props.challengeTitle;
    let profileLink = this.getProfileLink();
    let completionMessage = this.renderCompletionText(challengeTitle);

    return (
      <div>
        <div className="pure-g bottompadding-5">
          <div className="pure-u-3-24 pure-u-md-1-24">
            <Link to={profileLink}>
              <img className="challengeicon" alt={userName} src={userImage} />
              {this.props.userRankStatusImage && (
                <div id="rankStatus" className="photo-icon-wrap xs-icon-wrap">
                  <img
                    className="rank-status"
                    src={this.props.userRankStatusImage}
                    alt={this.props.userRankStatus}
                  />
                </div>
              )}
            </Link>
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-17-24 pure-u-md-19-24">
            <strong>
              <Link to={profileLink} className="underline-on-hover">
                {userName}
              </Link>
            </strong>
            <div className="smalltext">{this.props.createdAtFormatted}</div>
          </div>
          <div className="pure-u-3-24">
            <div className="textright topmargin-5">
              <strong>
                {this.props.points !== 0 &&
                  "+ " + this.props.points.abbreviateNumber()}
              </strong>
            </div>
          </div>
        </div>

        <Link
          to={CHALLENGE.format(this.props.challengeId)}
          className="pure-g bottompadding-5"
        >
          <div className="pure-u-3-24 pure-u-md-1-24">
            <div
              className={
                "challengeicon icon " +
                (this.props.isConfirmationChallenge
                  ? "confirmation"
                  : this.props.challengeType)
              }
            />
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-19-24 pure-u-md-21-24">
            <div
              className="topmargin-5"
              dangerouslySetInnerHTML={{ __html: completionMessage }}
            />
            {this.renderPrivateCompletionMarker()}
          </div>
          <div className="pure-u-1-24 textright">
            <span className="right-caret alignmiddle topmargin-5 rightmargin-5" />
          </div>
        </Link>
      </div>
    );
  }
}

Claim.propTypes = propTypes;

export default Claim;
