import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";

import TextInput from "components/shared/Inputs/TextInput";
import Link from "components/shared/Link/Link";
import LoginDivider from "./LoginDivider";
import GuestLoginDialogContainer from "./GuestLoginDialog/GuestLoginDialogContainer";
import TourModalContainer from "components/shared/Tour/TourModal/TourModalContainer";

import ReCAPTCHA from "react-google-recaptcha";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import {
  ENABLE_RESEND_ACTIVATION_LINK,
  RESEND_ACTIVATION_LINK,
  GOOGLE_RECAPTCHA_SITE_KEY,
  FACEBOOK_APP_ID,
  GOOGLE_APP_ID,
  ENABLE_EMAIL_LOGIN,
  ENABLE_LOGIN_TOPBAR,
  USE_LOGIN_HOME_TEMPLATE_1,
  USE_LOGIN_HOME_TEMPLATE_2,
  ENABLE_LOGIN_HOME,
  ENABLE_REGISTER_PAGES,
  ENABLE_FACEBOOK_LOGIN,
  ENABLE_GOOGLE_LOGIN,
  ENABLE_MICROSOFT_LOGIN,
  ENABLE_OAUTH_LOGIN,
  PRIVACY_PAGE_LINK
} from "config";

import {
  PROJECT_REGISTER,
  REGISTER,
  PROJECT_RESET_PASSWORD,
  RESET_PASSWORD
} from "App/Routes";
import localize from "lang/localize";

import "./Login.css";
import PasswordInput from "../shared/Inputs/PasswordInput";
import { ENABLE_TOUR_PUBLIC } from "config";
import { LOGIN_TOUR } from "App/UrlVariables";
import { ENABLE_TOUR } from "config";
import urlServices from "services/urlServices";
import deviceServices from "services/deviceServices";

const propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  handleUpdateMarketingConsent: PropTypes.func.isRequired,
  handleUpdatePrivacyAgreed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handlePrivacySubmit: PropTypes.func.isRequired,
  showPrivacyAgreement: PropTypes.bool,
  marketingConsent: PropTypes.bool,
  privacyAgreed: PropTypes.bool.isRequired,
  projectId: PropTypes.number,
  topbarlessBackButtonRoute: PropTypes.string,
  language: PropTypes.string,
  /* Guest Login Dialog */
  guestEnabled: PropTypes.bool.isRequired,
  showGuestLoginDialog: PropTypes.bool.isRequired,
  handleOpenGuestLoginDialog: PropTypes.func.isRequired,
  handleCloseGuestLoginDialog: PropTypes.func.isRequired,
  /* Show Recaptcha */
  userRecaptchaActivate: PropTypes.bool,
  /* OAuth */
  responseFacebook: PropTypes.func.isRequired,
  responseFacebookError: PropTypes.func.isRequired,
  responseGoogle: PropTypes.func.isRequired,
  responseGoogleError: PropTypes.func.isRequired,
  responseMicrosoft: PropTypes.func.isRequired,
  /* Loading indicators */
  isSubmitLoading: PropTypes.bool,
  setSubmitLoading: PropTypes.func
};

class LoginPage extends Component {
  constructor() {
    super();
    this.recaptchaRef = React.createRef();
    this.state = {
      showTourModal: false
    };

    this.handleOpenTourModal = this.handleOpenTourModal.bind(this);
    this.handleCloseTourModal = this.handleCloseTourModal.bind(this);
  }

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.handlePreSubmit(event);
    }
  };

  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("loginPage")) {
      bodyDOM.className += " loginPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.className += " page-not-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("loginPage")) {
      bodyDOM.classList.remove("loginPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-not-loggedin")) {
      bodyDOM.classList.remove("page-not-loggedin");
    }
  }

  /*
    Instead of directly calling this.props.handleSubmit, we use handlePreSubmit()
    in LoginPage to determine if a reCAPTCHA token has to be passed into the
    backend. Then we will call this.props.handleSubmit with an optional parameter.
  */
  handlePreSubmit(e) {
    e.preventDefault();

    this.props.setSubmitLoading(true);

    if (this.props.userRecaptchaActivate) {
      this.props.handleSubmit(
        this.recaptchaRef,
        this.recaptchaRef.current.getValue()
      );
    } else {
      this.props.handleSubmit(this.recaptchaRef);
    }
  }

  renderGuestLoginSection() {
    if (
      this.props.projectId &&
      this.props.guestEnabled &&
      ((ENABLE_LOGIN_HOME && !USE_LOGIN_HOME_TEMPLATE_2) || !ENABLE_LOGIN_HOME)
    ) {
      return (
        <Fragment>
          <LoginDivider />
          <br />
          <div className="pure-g">
            <div className="pure-u-1-12" />
            <div className="pure-u-5-6">
              <button
                className="button button-login-guest fullwidth cta"
                onClick={this.props.handleOpenGuestLoginDialog}
              >
                {localize("dialog_login_guest_title", this.props.language)}
              </button>
              {this.props.showGuestLoginDialog && this.renderGuestLoginDialog()}
            </div>
            <div className="pure-u-1-12" />
          </div>
          <br />
        </Fragment>
      );
    } else {
      return null;
    }
  }

  renderGuestLoginDialog() {
    return (
      <GuestLoginDialogContainer
        showGuestLoginDialog={this.props.showGuestLoginDialog}
        handleCloseGuestLoginDialog={this.props.handleCloseGuestLoginDialog}
      />
    );
  }

  renderRegisterSection() {
    if (
      !ENABLE_LOGIN_HOME ||
      (ENABLE_LOGIN_HOME && USE_LOGIN_HOME_TEMPLATE_1)
    ) {
      return (
        <Fragment>
          <br />
          <div className="textcenter">
            <strong>
              {localize("login_view_register_start", this.props.language) + " "}
            </strong>
            <Link
              to={
                this.props.projectId
                  ? PROJECT_REGISTER.format(this.props.projectId)
                  : REGISTER
              }
              className="default"
            >
              {localize("login_view_signup_text", this.props.language)}
            </Link>
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  renderRecaptcha() {
    if (this.props.userRecaptchaActivate) {
      return (
        <div className="pure-g topmargin-20 bottommargin-10">
          <div className="pure-u-1-1 flex justify-content-flexend">
            <ReCAPTCHA
              sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
              ref={this.recaptchaRef}
              onExpired={() => {
                this.props.handleRecaptchaExpired(this.recaptchaRef);
              }}
              onErrored={() => {
                this.props.handleRecaptchaError(this.recaptchaRef);
              }}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderSSOLoginSection() {
    if (ENABLE_OAUTH_LOGIN && !ENABLE_LOGIN_HOME) {
      return (
        <div className="pure-g">
          <div className="pure-u-1-1 bottommargin-10 box-sizing-border-box-all">
            <LoginDivider />
          </div>
          <div className="pure-u-1-1">
            <p className="bottommargin textcenter box-sizing-border-box-all">
              <strong>
                {localize("login_view_sign_in_start", this.props.language)}
              </strong>
            </p>
          </div>
          <div className="button-group-social-login">
            {this.renderFacebookLoginButton()}
            {this.renderGoogleLoginButton()}
            {this.renderMicrosoftLoginButton()}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderFacebookLoginButton() {
    if (ENABLE_FACEBOOK_LOGIN && !ENABLE_LOGIN_HOME) {
      return (
        <div className="pure-u-1-1 horizontalpadding-5 box-sizing-border-box-all">
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            scope="email"
            callback={this.props.responseFacebook}
            onFailure={this.props.responseFacebookError}
            cssClass={"button button-fb"}
            textButton={""}
            icon="fas fa-facebook"
            isMobile={false}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderGoogleLoginButton() {
    if (ENABLE_GOOGLE_LOGIN && !ENABLE_LOGIN_HOME) {
      return (
        <div className="pure-u-1-1 horizontalpadding-5">
          <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
            <GoogleLogin
              onSuccess={this.props.responseGoogle}
              onError={this.props.responseGoogleError}
              type="icon"
              shape="circle"
            />
          </GoogleOAuthProvider>
        </div>
      );
    } else {
      return null;
    }
  }

  renderMicrosoftLoginButton() {
    if (ENABLE_MICROSOFT_LOGIN && !ENABLE_LOGIN_HOME) {
      return (
        <div className="pure-u-1-1 horizontalpadding-5 box-sizing-border-box-all">
          <button
            id="msLoginButton"
            className="button button-microsoft"
            type="button"
            onClick={this.props.responseMicrosoft}
          >
            <i className="button-microsoft-icon" />
          </button>
        </div>
      );
    } else {
      return null;
    }
  }

  renderTopbarlessBackButton() {
    if (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR) {
      return (
        <div className="pure-u-g">
          <div className="pure-u-1">
            <Link
              className="topbarless-back-button"
              to={this.props.topbarlessBackButtonRoute}
            >
              <i className="fas fa-arrow-left" />
            </Link>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderResetPasswordOrResendActivation() {
    if (ENABLE_RESEND_ACTIVATION_LINK) {
      return (
        <div className="pure-g bottommargin">
          <div className="pure-u-1-2">
            <p className="smalltext nomargin">
              <Link
                to={
                  this.props.projectId
                    ? PROJECT_RESET_PASSWORD.format(this.props.projectId)
                    : RESET_PASSWORD
                }
                className="default"
              >
                {localize(
                  "login_view_forgetpassword_text",
                  this.props.language
                )}
              </Link>
            </p>
          </div>
          <div className="pure-u-1-2">
            <p className="smalltext nomargin flex justify-content-flexend">
              <a
                href={RESEND_ACTIVATION_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="default"
              >
                {localize(
                  "login_view_resend_activation_text",
                  this.props.language
                )}
              </a>
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pure-g bottommargin">
          <div className="pure-u-1-1">
            <p className="smalltext nomargin">
              <Link
                to={
                  this.props.projectId
                    ? PROJECT_RESET_PASSWORD.format(this.props.projectId)
                    : RESET_PASSWORD
                }
                className="default"
              >
                {localize(
                  "login_view_forgetpassword_text",
                  this.props.language
                )}
              </Link>
            </p>
          </div>
        </div>
      );
    }
  }

  renderLoginFields() {
    if (ENABLE_EMAIL_LOGIN) {
      return (
        <Fragment>
          <h4>{localize("alert_login_text", this.props.language)}</h4>
          <TextInput
            id="emailInput"
            value={this.props.email}
            onChange={this.props.handleEmailChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_email_placeholder_text",
              this.props.language
            )}
          />
          <PasswordInput
            id="passwordInput"
            value={this.props.password}
            onChange={this.props.handlePasswordChange}
            onKeyPress={this.handleKeyPress}
            placeholder={localize(
              "login_view_password_placeholder_text",
              this.props.language
            )}
          />
          {this.renderResetPasswordOrResendActivation()}
          {this.renderRecaptcha()}
          <div className="pure-g bottommargin">
            <div className="pure-u-1-1 pure-u-sm-1-12" />
            <div className="pure-u-1-1 pure-u-sm-5-6">
              <button
                id="loginButton"
                className={
                  "button fullwidth" +
                  (!!this.props.isSubmitLoading ? " disabled" : " cta")
                }
                type="submit"
                disabled={!!this.props.isSubmitLoading}
              >
                {!!this.props.isSubmitLoading
                  ? localize(
                      "nav_bar_title_generic_loading",
                      this.props.language
                    )
                  : localize("button_login", this.props.language)}
              </button>
            </div>
            <div className="pure-u-1-1 pure-u-sm-1-12" />
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  }

  /**
   * Renders the tour link (to open the tour modal)
   */
  renderTourLink() {
    if (ENABLE_TOUR) {
      return (
        <Fragment>
          <div className="textcenter">
            <a
              className="default"
              onClick={e => {
                this.handleOpenTourModal(e);
              }}
              href={LOGIN_TOUR}
            >
              {localize("icon_tour", this.props.language)}
            </a>
          </div>
        </Fragment>
      );
    }
  }

  /**
   * Renders the tour modal container
   */
  renderTourModal() {
    return (
      <TourModalContainer
        showModal={this.state.showTourModal}
        handleCloseModal={this.handleCloseTourModal}
        language={this.props.language}
      />
    );
  }

  /**
   * Opens the tour modal
   * @param {*} event
   */
  handleOpenTourModal(event) {
    event.preventDefault();

    this.setState({
      showTourModal: true
    });
  }

  /**
   * Closes the tour modal
   */
  handleCloseTourModal() {
    this.setState({
      showTourModal: false
    });
  }

  render() {
    if (
      /* checking type is important: showPrivacyAgreement is initialised as null */
      typeof this.props.showPrivacyAgreement === "boolean" &&
      this.props.showPrivacyAgreement === true
    ) {
      return (
        <div className="login-register-container container verticalpadding">
          {this.renderTopbarlessBackButton()}
          <div
            className={
              "innerblock pure-g" +
              (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR ? " topmargin-20" : "")
            }
          >
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
            <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2">
              <h5>
                {localize(
                  "login_view_privacy_policy_updated_title",
                  this.props.language
                )}
              </h5>
              <p>
                {localize("login_agree_to_new_terms_text", this.props.language)}
              </p>
              <div className="bottommargin-30">
                <p className="smalltext">
                  <input
                    type="checkbox"
                    onChange={this.props.handleUpdatePrivacyAgreed}
                    checked={this.props.privacyAgreed}
                  />{" "}
                  {localize(
                    "register_agree_to_gametize_text",
                    this.props.language
                  )}
                  <a
                    className="link default"
                    href={PRIVACY_PAGE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {localize(
                      "register_privacy_policy_text",
                      this.props.language
                    )}
                  </a>
                  .
                </p>
                <p className="smalltext">
                  <input
                    type="checkbox"
                    onChange={this.props.handleUpdateMarketingConsent}
                    checked={this.props.marketingConsent}
                  />{" "}
                  {localize(
                    "register_marketing_communications_text",
                    this.props.language
                  )}
                </p>
              </div>
              <div className="pure-g bottommargin">
                <div className="pure-u-11-24 pure-u-sm-14-24 pure-u-lg-2-3" />
                <div className="pure-u-13-24 pure-u-sm-10-24 pure-u-lg-1-3">
                  <button
                    className={
                      "button fullwidth " +
                      (this.props.privacyAgreed ? "cta" : "inactive")
                    }
                    onClick={this.props.handlePrivacySubmit}
                    disabled={!this.props.privacyAgreed}
                  >
                    {localize("button_quest_continue", this.props.language)}
                  </button>
                </div>
              </div>
            </div>
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="login-register-container container verticalpadding">
          {this.renderTopbarlessBackButton()}
          <div
            className={
              "innerblock pure-g" +
              (ENABLE_LOGIN_HOME && !ENABLE_LOGIN_TOPBAR ? " topmargin-20" : "")
            }
          >
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
            <div className="pure-u-1 pure-u-sm-16-24 pure-u-md-1-2">
              <form
                className="pure-form"
                onSubmit={this.handlePreSubmit.bind(this)}
              >
                {this.renderLoginFields()}
                {this.renderGuestLoginSection()}
                {/* Add hidesso=true to query string in order to hide sso login, e.g. /login?hidesso=true */}
                {urlServices.getUrlParamValueByKey("hidesso") !== "true" &&
                  !deviceServices.isWebView() &&
                  this.renderSSOLoginSection()}
                {ENABLE_REGISTER_PAGES && this.renderRegisterSection()}
                {ENABLE_TOUR_PUBLIC && this.renderTourLink()}
              </form>
            </div>
            <div className="pure-u-sm-4-24 pure-u-md-6-24" />
          </div>
          {this.state.showTourModal && this.renderTourModal()}
        </div>
      );
    }
  }
}

LoginPage.propTypes = propTypes;

export default LoginPage;
