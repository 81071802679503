import React, { Component } from "react";
import PropTypes from "prop-types";

import Team from "./Team";
import Member from "./Member";
import SearchBar from "components/shared/SearchBar/SearchBar";

import localize from "lang/localize";

import "./Team.css";
import Loading from "components/shared/Loading";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  teamId: PropTypes.number.isRequired,
  teamName: PropTypes.string.isRequired,
  teamDescription: PropTypes.string,
  teamImage: PropTypes.string,
  members: PropTypes.array.isRequired,
  points: PropTypes.number.isRequired,
  more: PropTypes.bool.isRequired,
  joined: PropTypes.bool.isRequired,
  teamCreator: PropTypes.bool.isRequired,
  teamCreateEnabled: PropTypes.bool.isRequired,
  teamJoinEnabled: PropTypes.bool.isRequired,
  teamJoinedInProject: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleJoinTeam: PropTypes.func.isRequired,
  handleLeaveTeam: PropTypes.func.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number,
  user: PropTypes.object,
  userId: PropTypes.number
};

class TeamPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("teamPage")) {
      bodyDOM.className += " teamPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("teamPage")) {
      bodyDOM.classList.remove("teamPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  renderMembers() {
    if (this.props.members.length > 0) {
      return (
        <div className="pure-g">
          {this.props.members.map((member, index) => (
            <Member
              key={index}
              id={member.id}
              name={member.name}
              image={member.photoSmall}
              userRankStatus={member.userRankStatus}
              userRankStatusImage={member.userRankStatusImage}
              points={member.points}
              language={this.props.language}
              projectId={this.props.projectId}
              userId={this.props.userId}
            />
          ))}
        </div>
      );
    } else {
      return (
        <p className="text-align-center nomargin">
          {localize("empty_players_table_text", this.props.language)}
        </p>
      );
    }
  }

  renderPlayerSearch() {
    return (
      <div className="pure-u-1-1">
        <div className="pure-u-3-24 pure-u-md-7-24" />
        <div className="pure-u-18-24 pure-u-md-10-24 automargin">
          <div className="toppadding" />
          <SearchBar
            handleSubmit={this.props.handleSubmit}
            handleSearchChange={this.props.handleSearchChange}
            placeholder={localize(
              "general_search_placeholder_text",
              this.props.language
            )}
            language={this.props.language}
          />
        </div>
        <div className="pure-u-3-24 pure-u-md-7-24" />
      </div>
    );
  }

  render() {
    return (
      <div className="team">
        <div className="container verticalpadding darkbg">
          <div className="innerblock">
            <Team
              projectId={this.props.projectId}
              id={this.props.teamId}
              name={this.props.teamName}
              description={this.props.teamDescription || ""}
              image={this.props.teamImage}
              joined={this.props.joined}
              teamCreator={this.props.teamCreator}
              teamCreateEnabled={this.props.teamCreateEnabled}
              teamJoinEnabled={this.props.teamJoinEnabled}
              teamJoinedInProject={this.props.teamJoinedInProject}
              user={this.props.user}
              points={this.props.points}
              members={this.props.members}
              handleJoinTeam={this.props.handleJoinTeam}
              handleLeaveTeam={this.props.handleLeaveTeam}
              language={this.props.language}
            />
          </div>
        </div>
        {this.renderPlayerSearch()}
        <div className="container verticalpadding">
          <div className="innerblock bottompadding-floating">
            {this.renderMembers()}
            {this.props.isLoadingMore ? <Loading /> : null}
            {/* {this.props.more ? (
              <button
                className="button button-more automargin"
                onClick={this.props.handleMore.bind(this)}
              >
                {localize("more_text", this.props.language)}
              </button>
            ) : null} */}
          </div>
        </div>
      </div>
    );
  }
}

TeamPage.propTypes = propTypes;

export default TeamPage;
