import React, { Component } from "react";
import PropTypes from "prop-types";

import SearchBar from "components/shared/SearchBar/SearchBar";
import Follows from "./Follows";
import localize from "lang/localize";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

const propTypes = {
  projectId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  players: PropTypes.array.isRequired,
  more: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleFollow: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["following", "followers"]).isRequired,
  searchedPlayersExist: PropTypes.bool,
  language: PropTypes.string,
  isLoadingMore: PropTypes.bool
};

const defaultProps = {
  searchedPlayersExist: true
};

class FollowsPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("followsPage")) {
      bodyDOM.className += " followsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("followsPage")) {
      bodyDOM.classList.remove("followsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  /**
   * Render list of following/followers
   */
  renderPlayers() {
    return this.props.players.map(player => (
      <Follows
        projectId={this.props.projectId}
        userId={this.props.userId}
        key={player.id}
        id={player.id}
        name={player.name}
        img={player.photoLarge}
        type={this.props.type}
        userFriended={player.userFriended}
        userFollowed={player.followed}
        userIsFollowedBy={player.userIsFollowedBy}
        handleFollow={this.props.handleFollow}
      />
    ));
  }

  /**
   * Render view
   */
  render() {
    if (
      this.props.players &&
      this.props.players.length === 0 &&
      this.props.searchedPlayersExist
    ) {
      return (
        <GenericErrorPage
          message={
            this.props.type === "following"
              ? localize("empty_following_text")
              : localize("empty_followers_text", this.props.language)
          }
          language={this.props.language}
        />
      );
    } else {
      return (
        <div className="container verticalpadding bottompadding-floating">
          <div className="innerblock pure-g">
            <div className="pure-u-1 pure-u-md-20-24 automargin">
              <SearchBar
                handleSubmit={this.props.handleSubmit}
                handleSearchChange={this.props.handleSearchChange}
                placeholder={localize(
                  "general_search_placeholder_text",
                  this.props.language
                )}
                language={this.props.language}
              />
              <div className="verticalpadding" />
              <div />
              {!this.props.searchedPlayersExist && (
                <p className="textcenter">
                  {localize("empty_users_search_text", this.props.language)}
                </p>
              )}
              {this.renderPlayers()}
              {this.props.isLoadingMore ? <Loading /> : null}
            </div>
          </div>
        </div>
      );
    }
  }
}

FollowsPage.propTypes = propTypes;
FollowsPage.defaultProps = defaultProps;

export default FollowsPage;
