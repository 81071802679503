import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";
import LanguageSelectInput from "./LanguageSelectInput";

import localize from "lang/localize";

import "./LanguageSelectionDialog.css";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseLanguageSelectionDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  onLanguageChange: PropTypes.func.isRequired,
  language: PropTypes.string
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

class LanguageSelectionDialog extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        contentLabel={localize(
          "dialog_language_selection_title",
          this.props.language
        )}
        onRequestClose={this.props.handleCloseLanguageSelectionDialog}
        shouldCloseOnOverlayClick={false}
        className="dialog language-selection-dialog"
        overlayClassName="dialog-overlay"
      >
        <div className="dialog-content">
          <div className="dialog-header">
            <h5 className="dialog-title">
              {localize("dialog_language_selection_title", this.props.language)}
            </h5>
          </div>
          <div className="dialog-body">
            <LanguageSelectInput
              name="languagesSelect"
              placeholder={localize(
                "dialog_language_selection_placeholder",
                this.props.language
              )}
              onChange={this.props.onLanguageChange}
              selectedValue={this.props.selectedValue}
              language={this.props.language}
            />
          </div>
          <div className="dialog-footer">
            <div className="textright">
              <button
                className="button inline"
                onClick={this.props.handleCloseLanguageSelectionDialog}
                id="cancelButton"
              >
                {localize("web_confirm_cancel_text", this.props.language)}
              </button>
              <button
                className="button inline cta"
                id="languageSelectionButton"
                onClick={event => {
                  this.props.handleCloseLanguageSelectionDialog();
                  this.props.handleSubmit(event);
                }}
              >
                {localize("web_confirm_okay_text", this.props.language)}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

LanguageSelectionDialog.propTypes = propTypes;

export default LanguageSelectionDialog;
