import React from "react";
import PropTypes from "prop-types";
import TextInput from "./TextInput";
import "./PasswordInput.scss";

const propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool
};

const defaultProps = {
  autoFocus: false
};

const PasswordInput = props => {
  let placeholder = props.placeholder;
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = value => {
    setShowPassword(value);
  };
  return (
    <div className="input-group flex align-items-center textcenter">
      <input
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        autoFocus={props.autoFocus}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
      />
      <div className="input-group-addon">
        {showPassword ? (
          <i
            className="fas fa-eye-slash"
            onClick={() => toggleShowPassword(false)}
            aria-hidden="true"
          />
        ) : (
          <i
            className="fas fa-eye"
            aria-hidden="true"
            onClick={() => toggleShowPassword(true)}
          />
        )}
      </div>
    </div>
  );
};

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default PasswordInput;
