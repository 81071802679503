import React, { Component } from "react";
import PropTypes from "prop-types";

/* Floating buttons */
import MainButton from "./MainButton";
import ChildButton from "./ChildButton";
import Menu from "./Menu";

import {
  PROJECT_TEAMS,
  PROJECT_ACTIVITY,
  PROJECT_LEADERBOARD,
  PROJECT_REWARDS,
  PROJECT_BOOKMARKS,
  TOPIC_CATEGORY,
  TOPIC,
  CHALLENGE,
  PROJECT_USERS,
  PROJECT_ACHIEVEMENTS
} from "App/Routes";
import { setShowQRModal } from "actions";
import { connect } from "react-redux";

const mapDispatchToProps = dispatch => {
  return {
    setShowQRModal: isShow => {
      dispatch(setShowQRModal(isShow));
    }
  };
};

const propTypes = {
  buttons: PropTypes.array,
  projectId: PropTypes.number,
  language: PropTypes.string
};

export class FloatingButtons extends Component {
  render() {
    return (
      <Menu effect="slidein-spring" method="click" position="br">
        <MainButton />
        {this.props.buttons
          .slice(0)
          .reverse()
          .map(button => {
            switch (button.buttonType) {
              case "bookmarks":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={PROJECT_BOOKMARKS.format(this.props.projectId)}
                    icon="fa-bookmark"
                    buttonTypeClass="home-btn-bookmark"
                  />
                );
              case "rewards":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={PROJECT_REWARDS.format(this.props.projectId)}
                    icon="fa-gift"
                    buttonTypeClass="home-btn-reward"
                  />
                );
              case "leaderboard":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={PROJECT_LEADERBOARD.format(this.props.projectId)}
                    icon="fa-trophy"
                    buttonTypeClass="home-btn-leaderboard"
                  />
                );
              case "activity":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={PROJECT_ACTIVITY.format(this.props.projectId)}
                    icon="fa-list-alt"
                    buttonTypeClass="home-btn-feed"
                  />
                );
              case "web-url":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={button.objectReference}
                    isExternalLink={true}
                    icon="fa-external-link-alt"
                    buttonTypeClass="home-btn-url"
                  />
                );
              case "web-url-session":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={button.objectReference}
                    isWebUrlSession={true}
                    projectId={this.props.projectId}
                    icon="fa-link"
                    buttonTypeClass="home-btn-page"
                  />
                );
              case "challenge-profile":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={CHALLENGE.format(button.objectReference)}
                    icon="icon-challenge-placeholder"
                    buttonTypeClass="home-btn-challenge"
                  />
                );
              case "topic":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={TOPIC.format(button.objectReference)}
                    icon="icon-topics"
                    buttonTypeClass="home-btn-topic"
                  />
                );
              case "topic-category":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={TOPIC_CATEGORY.format(
                      this.props.projectId,
                      button.objectReference
                    )}
                    icon="fa-layer-group"
                    buttonTypeClass="home-btn-category"
                  />
                );
              case "teams":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={PROJECT_TEAMS.format(this.props.projectId)}
                    icon="fa-users"
                    buttonTypeClass="home-btn-team"
                  />
                );
              case "players":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={PROJECT_USERS.format(this.props.projectId)}
                    icon="fa-user"
                    buttonTypeClass="home-btn-player"
                  />
                );
              case "qr":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    onClick={() => {
                      this.props.setShowQRModal(true);
                    }}
                    icon="fa-qrcode"
                    buttonTypeClass="home-btn-qr"
                  />
                );
              case "achievements":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={PROJECT_ACHIEVEMENTS.format(this.props.projectId)}
                    icon="fa-medal"
                    buttonTypeClass="home-btn-achievement"
                  />
                );
              case "support":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={`mailto:${button.objectReference}${
                      button.emailTitle
                        ? `?subject=${encodeURIComponent(button.emailTitle)}`
                        : ""
                    }`}
                    isExternalLink={true}
                    icon="fa-question-circle"
                    buttonTypeClass="home-btn-support"
                  />
                );
              case "photos":
                return (
                  <ChildButton
                    key={button.id}
                    label={button.buttonText}
                    href={
                      PROJECT_ACTIVITY.format(this.props.projectId) +
                      "?filter=image"
                    }
                    icon="fa-photo-video"
                    buttonTypeClass="home-btn-photos"
                  />
                );
              default:
                return null;
            }
          })}
      </Menu>
    );
  }
}

FloatingButtons.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(FloatingButtons);
