import React, { Component } from "react";
import { connect } from "react-redux";

import Alert from "./Alert";

import { clearAlert } from "actions";

const mapStateToProps = (state, ownProps) => {
  return {
    alerts: state.alerts
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearAlert: id => {
      dispatch(clearAlert(id));
    }
  };
};

class AlertContainer extends Component {
  render() {
    return (
      <Alert alerts={this.props.alerts} clearAlert={this.props.clearAlert} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
