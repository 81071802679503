import request from "superagent";
import { ENDPOINT } from "config";

const getApiGenerator = (route, query = {}, sessionKey) =>
  request
    .get(ENDPOINT + route)
    .query(query)
    .set("Authorization", `Bearer ${sessionKey}`);

export default getApiGenerator;
