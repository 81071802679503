import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import Dropdown from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";
import ReactSoundCloud from "react-soundcloud-embedded";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import {
  CHALLENGE,
  CHALLENGE_ENQUIRY,
  CHALLENGE_COMMENTS,
  CHALLENGE_A_FRIEND,
  TOPIC
} from "App/Routes";
import LoginDialogContainer from "components/shared/Dialogs/LoginDialog/LoginDialogContainer";
import ImageDialog from "components/shared/Dialogs/ImageDialog/ImageDialog";
import EmbedDialog from "components/shared/Dialogs/EmbedDialog/EmbedDialog";
import ClaimForm from "./ClaimForm/ClaimForm";
import ChallengeResult from "./ChallengeResult/ChallengeResult";
import LocationMap from "./LocationMap";
import Link from "components/shared/Link/Link";
import ProgressBar from "components/shared/ProgressBar/ProgressBar";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import YoutubePlayer from "components/shared/YoutubePlayer/YoutubePlayer";
import "./Challenge.css";

import {
  ENABLE_CONTENT_ENQUIRY,
  USE_ENQUIRY_PAGE,
  CONTACT_EMAIL_DEFAULT,
  CONTACT_EMAIL_TITLE_DEFAULT,
  ENABLE_CHALLENGE_COMMENTS,
  ENABLE_CHALLENGE_BOOKMARK_BUTTON,
  ENABLE_CHALLENGE_LIKES
} from "config";
import deviceServices from "services/deviceServices";
import localize from "lang/localize";
import PostIframe from "../shared/PostIframe/PostIframe";
import urlParse from "library/js/url";

const propTypes = {
  user: PropTypes.object.isRequired,
  sessionKey: PropTypes.string,
  challengeTypeId: PropTypes.number.isRequired,
  isConfirmationChallenge: PropTypes.bool,
  photoRequired: PropTypes.bool,
  noPhoto: PropTypes.bool,
  quizOptions: PropTypes.array,
  privateClaim: PropTypes.bool,
  fields: PropTypes.array,
  completionMessage: PropTypes.string,
  multiSelect: PropTypes.bool,
  claimed: PropTypes.bool,
  claimedBefore: PropTypes.bool,
  expired: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  videoDurationLimit: PropTypes.number,
  gridWidth: PropTypes.number,
  // location
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  addressFormatted: PropTypes.string,
  // challengeresult
  id: PropTypes.number.isRequired,
  correctAnswerNo: PropTypes.number,
  wrongAnswerNo: PropTypes.number,
  correctAnswerTitle: PropTypes.string,
  correctAnswer: PropTypes.bool,
  // image
  imageMedium: PropTypes.string,
  // embed
  medias: PropTypes.array,
  // text
  footnote: PropTypes.string,
  title: PropTypes.string,
  repeat: PropTypes.bool,
  repeatUntilCorrect: PropTypes.bool,
  repeatAtFormatted: PropTypes.string,
  videoDurationLimitFormatted: PropTypes.string,
  // stats
  commentNo: PropTypes.number,
  claimNo: PropTypes.number,
  previousChallengeId: PropTypes.number,
  nextChallengeId: PropTypes.number,
  challengeType: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  challengeReferralLink: PropTypes.string,
  isLiked: PropTypes.bool.isRequired,
  likeNo: PropTypes.number.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  handleBookmark: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  postClaim: PropTypes.func.isRequired,
  postQRClaim: PropTypes.func.isRequired,
  showAlertWithTimeout: PropTypes.func,
  adminToClaim: PropTypes.bool.isRequired,
  // login dialogs
  showClaimLoginDialog: PropTypes.bool.isRequired,
  handleOpenClaimLoginDialog: PropTypes.func.isRequired,
  handleCloseClaimLoginDialog: PropTypes.func.isRequired,
  showBookmarkLoginDialog: PropTypes.bool.isRequired,
  handleOpenBookmarkLoginDialog: PropTypes.func.isRequired,
  handleCloseBookmarkLoginDialog: PropTypes.func.isRequired,
  showLikeLoginDialog: PropTypes.bool.isRequired,
  handleOpenLikeLoginDialog: PropTypes.func.isRequired,
  handleCloseLikeLoginDialog: PropTypes.func.isRequired,
  // image dialogs
  showImageDialog: PropTypes.bool.isRequired,
  handleOpenImageDialog: PropTypes.func.isRequired,
  handleCloseImageDialog: PropTypes.func.isRequired,
  // embed dialogs
  showEmbedDialog: PropTypes.bool.isRequired,
  handleOpenEmbedDialog: PropTypes.func.isRequired,
  handleCloseEmbedDialog: PropTypes.func.isRequired,
  // qr dialogs
  showQRModal: PropTypes.bool,
  handleOpenQRModal: PropTypes.func,
  handleCloseQRModal: PropTypes.func,
  // minigame dialogs
  showExternalChallengeDialog: PropTypes.bool,
  handleOpenExternalChallengeDialog: PropTypes.func,
  handleCloseExternalChallengeDialog: PropTypes.func,
  // language
  language: PropTypes.string,
  // contact email
  contactEmail: PropTypes.string,
  // locked content handling
  locked: PropTypes.bool,
  lockedChallengeTopicId: PropTypes.number,
  lockedChallengeTopicTitle: PropTypes.string,
  // project and topic information
  project: PropTypes.object,
  topic: PropTypes.object,
  topicIdNext: PropTypes.number,
  // minigame
  challengeMinigameTitle: PropTypes.string,
  // challenge time tracking
  challengeMinViewDuration: PropTypes.number,
  // character limit
  charLimit: PropTypes.number
};

const defaultProps = {
  contactEmail: CONTACT_EMAIL_DEFAULT
};

class ChallengePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 1
    };
    this.sliderRef = React.createRef();
  }

  getFirstEmbeddableItem() {
    return this.props.medias.filter(media => media.source === "Embed")[0];
  }

  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("challengePage")) {
      bodyDOM.className += " challengePage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("challengePage")) {
      bodyDOM.classList.remove("challengePage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  /**
   * Render challenge information segment
   */
  renderChallengeInfo() {
    if (this.props.locked) {
      return (
        <div className="pure-g innerblock">
          <div className="pure-u-sm-1-24" />
          <div className="pure-u-sm-22-24 topmargin-30">
            <GenericErrorPage
              routeUrl={TOPIC.format(this.props.lockedChallengeTopicId)}
              routeName={"“" + this.props.lockedChallengeTopicTitle + "”"}
              message={localize(
                "locked_challenge_with_route",
                this.props.language
              ).format(this.props.lockedChallengeTopicTitle)}
              language={this.props.language}
            />
          </div>
          <div className="pure-u-sm-1-24" />
        </div>
      );
    } else {
      return (
        <div>
          <div className="pure-g innerblock display-block">
            <div className="pure-u-sm-1-24" />
            <div className="pure-u-1-1 pure-u-sm-20-24">
              <div className="pure-u-sm-8-24 relative">
                {this.renderMedia()}
              </div>
              <div className="pure-u-sm-1-24" />
              <div className="pure-u-sm-15-24 topmargin-10">
                {this.renderText()}
                {this.renderStats()}
                {this.renderButtons()}
              </div>
            </div>
            <div className="pure-u-sm-2-24 relative">
              {this.renderSideButtons()}
            </div>
            <div className="pure-u-sm-1-24" />
          </div>
        </div>
      );
    }
  }

  /**
   * Render challenge navigation buttons (previous/next challenge)
   */
  renderChallengeNavigationButtons() {
    return (
      <div className="position-sticky-offset-topbar">
        {/* Challenge navigation (prev/next challenge) on desktop views */}
        <div className="hide-below-lg">
          <div className="challenge-nav-desktop-wrap">
            {this.renderPreviousChallengeButton()}
            {this.renderNextChallengeButton()}
          </div>
        </div>

        {/* Challenge navigation (prev/next challenge) on mobile views */}
        {this.renderChallengeNavigationButtonsForMobile()}
      </div>
    );
  }

  /**
   * Render challenge navigation buttons for mobile view (previous/next challenge)
   */
  renderChallengeNavigationButtonsForMobile() {
    if (this.props.nextChallengeId) {
      return (
        <div className="pure-u-lg-0 horizontalpadding-5">
          <div className="pure-g">
            <div className="pure-u-2-24 textcenter">
              {this.renderPreviousChallengeButton()}
            </div>
            <div className="pure-u-20-24 box-sizing-border-box-all verticalpadding-5 horizontalpadding-10 progress-bar-height">
              {this.renderTopicProgressBarForChallenge()}
            </div>
            <div className="pure-u-2-24 textcenter">
              {this.renderNextChallengeButton()}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="pure-u-lg-0 horizontalpadding-5">
          <div className="pure-g">
            <div className="pure-u-2-24 textcenter">
              {this.renderPreviousChallengeButton()}
            </div>
            <div className="pure-u-22-24 box-sizing-border-box-all verticalpadding-5 horizontalpadding-10 progress-bar-height">
              {this.renderTopicProgressBarForChallenge()}
            </div>
          </div>
        </div>
      );
    }
  }

  /**
   * Render button to navigate to previous challenge
   */
  renderPreviousChallengeButton() {
    /* Previous Challenge exists, and previous Challenge is not locked */
    if (this.props.previousChallengeId) {
      return (
        <Link
          className="challenge-nav challenge-nav-prev"
          to={CHALLENGE.format(this.props.previousChallengeId)}
        >
          <i className="fas fa-chevron-circle-left"></i>
        </Link>
      );
    } else {
      /* Previous Challenge does not exist */
      return null;
    }
  }

  /**
   * Render button to navigate to next challenge
   */
  renderNextChallengeButton() {
    /* Next Challenge exists, and next Challenge is not locked */
    if (this.props.nextChallengeId) {
      return (
        <Link
          className="challenge-nav challenge-nav-next"
          to={CHALLENGE.format(this.props.nextChallengeId)}
        >
          <i className="fas fa-chevron-circle-right"></i>
        </Link>
      );
    } else {
      /* Next Challenge does not exist */
      return null;
    }
  }

  /**
   * Render navigation elements for next topic (button container with button)
   */
  renderNextTopicNavigation() {
    if (this.props.topicIdNext) {
      return (
        <div className="container toppadding rightmargin-30 leftmargin-30">
          <div className="pure-g innerblock display-block">
            <div className="challenge-topic-nav innerpadding">
              <div className="flex-from-sm justify-content-space-between align-items-center justify-content-sm-center text-align-sm-center">
                <div>
                  {localize(
                    "challenge_profile_topic_end_text",
                    this.props.language
                  )}
                </div>
                <div className="justify-content-flexend">
                  {this.renderNextTopicButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render button to navigate to next topic (button only)
   */
  renderNextTopicButton() {
    if (this.props.topicIdNext) {
      return (
        <Link
          to={TOPIC.format(this.props.topicIdNext)}
          className="button display-inline-block cta topic-next-button"
        >
          {localize("button_next_topic", this.props.language)}
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render topic progress bar
   */
  renderTopicProgressBarForChallenge() {
    if (this.props.topic) {
      return (
        <ProgressBar
          id="progressbar"
          className="bottommargin boxshadow"
          completed={this.props.topic.userChallengeCompletedNo}
          total={this.props.topic.challengeNo}
          language={this.props.language}
        />
      );
    } else {
      return null;
    }
  }

  /**
   * Render challenge media (images/embeddable media)
   */
  renderMedia() {
    let hasEmbed = this.checkEmbed();

    return (
      <div className={hasEmbed ? "topmargin-40 relative" : ""}>
        <div
          className={"challenge-icons-position " + (hasEmbed && "has-embed")}
        >
          <div
            className={
              "challengeicon icon " +
              (this.props.isConfirmationChallenge
                ? "confirmation"
                : this.props.challengeType)
            }
          />
          {this.props.points !== 0 ? (
            <div className="challengeicon">
              <div className="points">
                +{this.props.points.abbreviateNumber()}
              </div>
            </div>
          ) : null}
        </div>
        {this.renderEmbed()}
      </div>
    );
  }

  /**
   * Check if challenge has any embeddable media
   */
  checkEmbed() {
    let embeddable = this.getFirstEmbeddableItem();

    /*
      For external challenges (challengeTypeId 15),
      we won't be rendering the embedded files like
      typical PDF or video embeds.
    */
    if (embeddable && this.props.challengeTypeId !== 15) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Render embedded media for challenge
   */
  renderEmbed() {
    let embeddable = this.getFirstEmbeddableItem();
    let embedContent;

    if (
      embeddable &&
      this.props.challengeTypeId !== 15 &&
      embeddable.url.indexOf("https://") === 0
    ) {
      switch (embeddable.type) {
        case "Video":
          embedContent = this.renderVideo(embeddable);
          break;
        case "PDF":
          embedContent = this.renderPdf(embeddable);
          break;
        case "Youtube":
          embedContent = this.renderYoutube(embeddable);
          break;
        case "Vimeo":
          embedContent = this.renderYoutubeVimeo(embeddable);
          break;
        case "SoundCloud":
          embedContent = this.renderSoundCloud(embeddable);
          break;
        case "Others":
          embedContent = this.renderOthers(embeddable);
          break;
        default:
          return this.renderImg();
      }

      const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 100,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: this.state.currentSlide,
        afterChange: current => this.setState({ currentSlide: current }),
        className: "bottommargin-30 leftmargin-10 rightmargin-10"
      };

      return (
        <Slider ref={this.sliderRef} {...settings}>
          {this.renderImg()}
          {embedContent}
        </Slider>
      );
    } else {
      return this.renderImg();
    }
  }

  /**
   * Render challenge image
   */
  renderSoundCloud(embed) {
    return <ReactSoundCloud url={embed.url} height="100%" />;
  }

  /**
   * Render challenge image
   */
  renderImg() {
    let imageMedium = this.props.imageMedium;

    return (
      <div>
        <span className="square-image-wrapper">
          <span
            className="square-image challenge-image"
            onClick={this.props.handleOpenImageDialog}
          >
            <img src={imageMedium} alt="" />
          </span>
        </span>
        {this.props.showImageDialog && this.renderImageDialog()}
      </div>
    );
  }

  /**
   * Render challenge video
   *
   * @param {object} embed - The embeddable media object
   */
  renderVideo(embed) {
    let displayUrl = embed.displayUrl;

    return (
      <div
        key={displayUrl}
        className={
          "iframe-container-challenge-profile iframe-container-challenge-profile-center bottommargin" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <PostIframe
          hiddenFields={{
            session_key: this.props.sessionKey
          }}
          url={embed.url}
          title={displayUrl}
          allowFullScreen={true}
        />
      </div>
    );
  }

  /**
   * Render challenge Youtube video
   *
   * @param {object} embed - The embeddable media object
   */
  renderYoutube(embed) {
    const YOUTUBE_ID = embed.url.match(
      new RegExp("https://www.youtube.com/embed/(.*)\\?rel=0")
    )[1];

    return (
      <YoutubePlayer
        id={YOUTUBE_ID}
        user={this.props.user}
        contentId={this.props.id}
        contentTitle={this.props.title}
        project={this.props.project}
        topic={this.props.topic}
      />
    );
  }

  /**
   * Render challenge Youtube or Vimeo video
   *
   * @param {object} embed - The embeddable media object
   */
  renderYoutubeVimeo(embed) {
    let displayUrl = embed.displayUrl;
    let url = embed.url;

    return (
      <div
        className={
          "iframe-container-challenge-profile" +
          (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
        }
      >
        <iframe
          title={displayUrl}
          src={url} // use url for Youtube and Vimeo to avoid X-Frame-Options: sameorigin problems
          allowFullScreen
        />
      </div>
    );
  }

  /**
   * Render challenge PDF
   *
   * @param {object} embed - The embeddable media object
   */
  renderPdf(embed) {
    let displayUrl = embed.displayUrl;
    let url = embed.url;

    /*
      in the past, we use the "?.pdf" hack appended at the back of links
      to render websites when website embed wasn't available. to allow such
      embeds to continue being loaded seamlessly, we catch them by checking for "?.pdf"
      filetypes and forcing it into this.renderOthers();
    */
    if (embed.url.indexOf("?.pdf") !== -1) {
      return this.renderOthers(embed);
    } else {
      return (
        <div
          className={
            "iframe-container-challenge-profile" +
            (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
          }
        >
          <iframe
            src={"https://docs.google.com/viewer?url=" + url + "&embedded=true"}
            title={displayUrl}
            allowFullScreen
            width="100%"
          />
        </div>
      );
    }
  }

  /**
   * Render other types of media
   *
   * @param {object} embed - The embeddable media object
   */
  renderOthers(embed) {
    let displayUrl = embed.displayUrl;
    let url = embed.url;

    /* hides iframe, replace with black background */
    if (this.props.showEmbedDialog) {
      return (
        <div
          className={
            "iframe-container-challenge-profile" +
            (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
          }
        >
          {this.renderEmbedDialog(embed.url)}
        </div>
      );
    } else {
      /* shows iframe in challenge profile */
      return (
        <div
          className={
            "iframe-container-challenge-profile" +
            (deviceServices.isIOS() ? " iframe-scroll-wrapper" : "")
          }
        >
          <button
            className="button icon"
            onClick={this.props.handleOpenEmbedDialog}
          >
            <span className="expand-icon" />
          </button>
          <iframe
            title={displayUrl}
            src={url}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          />
        </div>
      );
    }
  }

  /**
   * Add anchor text links
   *
   * @param {string} text - Text
   */
  addAnchors(text) {
    // add anchor tags to links
    return urlParse(text, true);
  }

  /**
   * Render challenge text (title/description)
   */
  renderText() {
    let footnoteAnchored = this.addAnchors(this.props.footnote);
    let titleAnchored = this.addAnchors(this.props.title);

    return (
      <div className="responsive-img-description">
        {this.props.challengeTypeId === 15 && (
          <p className="smalltext uppercase">
            {" "}
            {this.props.challengeMinigameTitle}{" "}
          </p>
        )}
        <h5 dangerouslySetInnerHTML={{ __html: titleAnchored }} />
        {!(this.props.challengeTypeId === 15) && (
          <p dangerouslySetInnerHTML={{ __html: footnoteAnchored }} />
        )}
        <p className="smalltext topmargin-30">
          {this.getRepeatText(this.props.repeat)}
          {this.getVideoDurationLimitText()}
        </p>
      </div>
    );
  }

  /**
   * Render challenge repeatable message
   *
   * @param {boolean} repeat - Whether the challenge is repeatable or not
   */
  getRepeatText(repeat: boolean) {
    if (this.props.challengeTypeId === 4) {
      return null;
    }

    /* passcode/fixed answer challenge */
    if (this.props.challengeTypeId === 9) {
      return localize(
        "challenge_profile_repeat_once_text",
        this.props.language
      );
    }

    if (this.props.repeatUntilCorrect) {
      return localize(
        "challenge_profile_repeat_again_text",
        this.props.language
      );
    } else if (!repeat) {
      return localize(
        "challenge_profile_repeat_once_text",
        this.props.language
      );
    } else if (this.props.repeatAtFormatted) {
      return localize(
        "challenge_profile_repeat_again_text_with_duration",
        this.props.language
      ).format(this.props.repeatAtFormatted);
    } else {
      return localize(
        "challenge_profile_repeat_again_text",
        this.props.language
      );
    }
  }

  /**
   * Render allowable video duration for video challenges
   */
  getVideoDurationLimitText() {
    if (this.props.videoDurationLimitFormatted) {
      return (
        <Fragment>
          <br />
          {localize(
            "challenge_profile_max_duration_text",
            this.props.language
          ).format(this.props.videoDurationLimitFormatted)}
        </Fragment>
      );
    }
  }

  /**
   * Render challenge statistics (comments/like count... etc)
   */
  renderStats() {
    if (
      this.props.challengeTypeId === 4 &&
      (ENABLE_CHALLENGE_LIKES || ENABLE_CHALLENGE_COMMENTS)
    ) {
      return (
        <p className="smalltext">
          {ENABLE_CHALLENGE_COMMENTS
            ? this.props.commentNo.pluralize(
                localize("comment_text", this.props.language),
                localize("comments_text", this.props.language)
              )
            : null}
          {/* rendering spaces below, &nbsp; doesn't render well with JSX */}
          {ENABLE_CHALLENGE_COMMENTS & ENABLE_CHALLENGE_LIKES
            ? "\u00A0\u00A0\u00A0"
            : null}
          {ENABLE_CHALLENGE_LIKES
            ? this.props.likeNo.pluralize(
                localize("like_text", this.props.language),
                localize("likes_text", this.props.language)
              )
            : null}
        </p>
      );
    } else if (this.props.challengeTypeId === 4) {
      return null;
    } else if (ENABLE_CHALLENGE_COMMENTS) {
      return (
        <p className="smalltext">
          {this.props.commentNo.pluralize(
            localize("comment_text", this.props.language),
            localize("comments_text", this.props.language)
          )}
          &nbsp;&nbsp;&nbsp;
          {localize("challenge_completed_text", this.props.language).format(
            this.props.claimNo
          )}
        </p>
      );
    } else {
      return (
        <p className="smalltext">
          {localize("challenge_completed_text", this.props.language).format(
            this.props.claimNo
          )}
        </p>
      );
    }
  }

  /**
   * Render comment button
   */
  renderChallengeCommentsButton() {
    if (ENABLE_CHALLENGE_COMMENTS) {
      return (
        <Link
          to={CHALLENGE_COMMENTS.format(this.props.id)}
          id="commentButton"
          className="button icon inline-block"
        >
          <span className="comment-icon" />
        </Link>
      );
    } else {
      return null;
    }
  }

  /**
   * Render all action buttons
   */
  renderButtons() {
    return (
      <div className="challenge-action-buttons-bottom">
        {this.renderBookmarkButton()}
        {this.props.challengeTypeId === 4 && this.renderLikeButton()}
        {this.renderChallengeCommentsButton()}
        {this.renderMenuDropdownButton()}
      </div>
    );
  }

  /**
   * Render action buttons (bookmark/like, comments)
   * Displayed at the side of the challenge information segment
   */
  renderSideButtons() {
    return (
      <div className="challenge-action-buttons-side">
        {this.renderBookmarkButton()}
        {this.props.challengeTypeId === 4 && this.renderLikeButton()}
        {this.renderChallengeCommentsButton()}
        {this.renderMenuDropdownButton()}
      </div>
    );
  }

  /**
   * Render menu dropdown buttons
   */
  renderMenuDropdownButton() {
    if (
      this.props.sessionKey &&
      (ENABLE_CONTENT_ENQUIRY || this.props.challengeReferralLink)
    ) {
      return (
        <div className="dropdown-button-group">
          <Dropdown
            dropup={true}
            onSelect={(eventKey, event) => {
              event.preventDefault();
            }}
            pullRight={true}
          >
            <Dropdown.Toggle
              btnStyle="flat"
              noCaret={true}
              onClick={e => {
                e.preventDefault();
              }}
            >
              <span className="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {this.props.challengeReferralLink ? (
                <Link to={CHALLENGE_A_FRIEND.format(this.props.id)}>
                  {localize("challenge_referral_text", this.props.language)}
                </Link>
              ) : null}
              {ENABLE_CONTENT_ENQUIRY ? (
                USE_ENQUIRY_PAGE ? (
                  <Link to={CHALLENGE_ENQUIRY.format(this.props.id)}>
                    {localize("report_challenge", this.props.language)}
                  </Link>
                ) : (
                  <a
                    href={
                      "mailto:" +
                      this.props.contactEmail +
                      "?subject=" +
                      (CONTACT_EMAIL_TITLE_DEFAULT
                        ? CONTACT_EMAIL_TITLE_DEFAULT
                        : "I want to make a report on Gametize (Challenge ID: " +
                          this.props.id +
                          ")")
                    }
                  >
                    {localize("report_challenge", this.props.language)}
                  </a>
                )
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Render bookmark button
   */
  renderBookmarkButton() {
    if (!ENABLE_CHALLENGE_BOOKMARK_BUTTON) {
      return null;
    } else if (!!this.props.user.id) {
      return (
        <button
          id="bookmarkButton"
          className={
            "button icon inline" +
            (this.props.isBookmarked ? " bookmarked" : "")
          }
          onClick={event =>
            this.props.handleBookmark(
              event,
              this.props.id,
              this.props.isBookmarked
            )
          }
        >
          <span className="bookmark-icon" />
        </button>
      );
    } else {
      return (
        <div className="inline">
          <button
            id="loginBeforeBookmarkButton"
            className={
              "button icon inline" +
              (this.props.isBookmarked ? " bookmarked" : "")
            }
            onClick={this.props.handleOpenBookmarkLoginDialog}
          >
            <span className="bookmark-icon" />
          </button>
          {this.props.showBookmarkLoginDialog &&
            this.renderBookmarkLoginDialog()}
        </div>
      );
    }
  }

  /**
   * Render like button
   */
  renderLikeButton() {
    if (!ENABLE_CHALLENGE_LIKES) {
      return null;
    } else if (!!this.props.user.id) {
      return (
        <button
          id="likeButton"
          className={
            "button icon inline" + (this.props.isLiked ? " liked" : "")
          }
          onClick={this.props.handleLike}
        >
          <span className="like-icon" />
        </button>
      );
    } else {
      return (
        <div className="inline">
          <button
            id="loginBeforeLikeButton"
            className={
              "button icon inline" + (this.props.isLiked ? " liked" : "")
            }
            onClick={this.props.handleOpenLikeLoginDialog}
          >
            <span className="like-icon" />
          </button>
          {this.props.showLikeLoginDialog && this.renderLikeLoginDialog()}
        </div>
      );
    }
  }

  /**
   * Render prompt login popup dialog for bookmark button
   */
  renderBookmarkLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showBookmarkLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseBookmarkLoginDialog}
        type="bookmark"
      />
    );
  }

  /**
   * Render prompt login popup dialog for like button
   */
  renderLikeLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showLikeLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseLikeLoginDialog}
        type="like"
      />
    );
  }

  /**
   * Render prompt login popup dialog for completing a challenge
   */
  renderClaimLoginDialog() {
    return (
      <LoginDialogContainer
        showModal={this.props.showClaimLoginDialog}
        handleCloseLoginDialog={this.props.handleCloseClaimLoginDialog}
        type="claim"
      />
    );
  }

  /**
   * Render popup dialog for challenge image
   */
  renderImageDialog() {
    return (
      <ImageDialog
        showModal={this.props.showImageDialog}
        handleCloseImageDialog={this.props.handleCloseImageDialog}
        img={this.props.imageMedium}
      />
    );
  }

  /**
   * Render popup dialog for other embeddable challenge media
   *
   * @param {string} url - URL to embed
   */
  renderEmbedDialog(url) {
    return (
      <EmbedDialog
        showDialog={this.props.showEmbedDialog}
        handleCloseEmbedDialog={this.props.handleCloseEmbedDialog}
        url={url}
        title={this.props.title}
      />
    );
  }

  /**
   * Render view
   */
  render() {
    return (
      <div className="challenge">
        {this.renderChallengeNavigationButtons()}
        {this.renderNextTopicNavigation()}
        <div className="container verticalpadding">
          {this.renderChallengeInfo()}
        </div>
        <ClaimForm
          sessionKey={this.props.sessionKey}
          isLoggedIn={!!this.props.user.id}
          challengeTypeId={this.props.challengeTypeId}
          challengeId={this.props.id}
          title={this.props.title}
          isConfirmationChallenge={this.props.isConfirmationChallenge}
          photoRequired={this.props.photoOnly}
          noPhoto={this.props.noPhoto}
          quizOptions={this.props.quizOptions}
          fields={this.props.fields}
          completionMessage={this.props.completionMessage}
          multiSelect={this.props.multiSelect}
          claimed={this.props.claimed}
          claimedBefore={this.props.claimedBefore}
          postClaim={this.props.postClaim}
          postQRClaim={this.props.postQRClaim}
          gridWidth={this.props.gridWidth}
          showAlertWithTimeout={this.props.showAlertWithTimeout}
          adminToClaim={this.props.adminToClaim}
          expired={this.props.expired}
          submitted={this.props.submitted}
          videoDurationLimit={this.props.videoDurationLimit}
          videoDurationLimitFormatted={this.props.videoDurationLimitFormatted}
          externalEmbeddable={this.getFirstEmbeddableItem()}
          handleOpenClaimLoginDialog={this.props.handleOpenClaimLoginDialog}
          showQRModal={this.props.showQRModal}
          handleOpenQRModal={this.props.handleOpenQRModal}
          handleCloseQRModal={this.props.handleCloseQRModal}
          showExternalChallengeDialog={this.props.showExternalChallengeDialog}
          handleOpenExternalChallengeDialog={
            this.props.handleOpenExternalChallengeDialog
          }
          handleCloseExternalChallengeDialog={
            this.props.handleCloseExternalChallengeDialog
          }
          challengeMinigameTitle={this.props.challengeMinigameTitle}
          nextChallengeId={this.props.nextChallengeId}
          challengeMinViewDuration={this.props.challengeMinViewDuration}
          isTimerActive={this.props.isTimerActive}
          setTimerActive={this.props.setTimerActive}
          countdownTime={this.props.countdownTime}
          language={this.props.language}
          charLimit={this.props.charLimit}
          repeat={this.props.repeat}
          repeatAtFormatted={this.props.repeatAtFormatted}
          repeatUntilCorrect={this.props.repeatUntilCorrect}
          correctAnswer={this.props.correctAnswer}
          correctAnswerTitle={this.props.correctAnswerTitle}
        />
        {this.props.showClaimLoginDialog && this.renderClaimLoginDialog()}
        <LocationMap
          latitude={this.props.latitude}
          longitude={this.props.longitude}
          addressFormatted={this.props.addressFormatted}
          language={this.props.language}
        />
        <ChallengeResult
          id={this.props.id}
          userName={this.props.user.name}
          userPhoto={this.props.user.photoSmall}
          challengeTypeId={this.props.challengeTypeId}
          claimed={!!this.props.claimed}
          claimedBefore={!!this.props.claimedBefore}
          repeatUntilCorrect={this.props.repeatUntilCorrect}
          correctAnswerNo={this.props.correctAnswerNo}
          wrongAnswerNo={this.props.wrongAnswerNo}
          correctAnswerTitle={this.props.correctAnswerTitle}
          correctAnswer={this.props.correctAnswer}
          quizOptions={this.props.quizOptions}
          gridWidth={this.props.gridWidth}
          privateClaim={this.props.privateClaim}
          language={this.props.language}
        />
        {/* Added padding for floating buttons */}
        <div className="container bottompadding-floating" />
      </div>
    );
  }
}

ChallengePage.propTypes = propTypes;
ChallengePage.defaultProps = defaultProps;

export default ChallengePage;
