import React, { Fragment } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";

import mentionsClass from "../mentions.module.css";
import { MentionsInput, Mention } from "react-mentions";
import Comment from "components/Comments/Comment";
import AchievementsModal from "components/shared/Achievements/AchievementsModal/AchievementsModal";
import CharacterCounter from "components/shared/Inputs/CharacterCounter";

import localize from "lang/localize";
import getApiGenerator from "services/getApiGenerator";
import { SEARCH_PROJECT } from "services/api";
import { MENTIONS_LIMIT } from "config";
import Loading from "components/shared/Loading";

const propTypes = {
  isLoadingMore: PropTypes.bool,
  comment: PropTypes.object,
  subComments: PropTypes.array,
  more: PropTypes.bool,
  showInput: PropTypes.bool,
  input: PropTypes.string,
  handleMore: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
  typeId: PropTypes.string,
  language: PropTypes.string,
  projectId: PropTypes.number,
  userId: PropTypes.number,
  removeSubComment: PropTypes.func,
  showAchievements: PropTypes.bool,
  achievements: PropTypes.array,
  setHideAchievements: PropTypes.func,
  setAchievements: PropTypes.func
};

const MainComment = props => {
  if (props.comment) {
    return (
      <div>
        <Comment
          id={props.comment.id}
          commentUserName={props.comment.userName}
          commentUserPhoto={props.comment.photoSmall}
          commentUserId={props.comment.userId}
          userId={props.userId}
          userRankStatus={props.comment.userRankStatus}
          userRankStatusImage={props.comment.userRankStatusImage}
          message={props.comment.message}
          messageAttributed={props.comment.commentMessageAttributed}
          isMainCommentInThreadPage={true}
          createdAtFormatted={props.comment.createdAtFormatted}
          projectId={props.projectId}
          removeComment={props.removeComment}
          type={props.type}
          typeId={props.typeId}
          commentThreadCount={props.comment.commentThreadCount}
        />
      </div>
    );
  } else {
    return null;
  }
};

const SubComments = props => {
  if (
    props.subComments &&
    Array.isArray(props.subComments) &&
    props.subComments.length > 0
  ) {
    return (
      <div className="topmargin-30">
        {props.subComments.map((comment, index) => (
          <Fragment key={comment.id}>
            <Comment
              id={comment.id}
              commentUserName={comment.userName}
              commentUserPhoto={comment.photoSmall}
              commentUserId={comment.userId}
              userId={props.userId}
              userRankStatus={comment.userRankStatus}
              userRankStatusImage={comment.userRankStatusImage}
              message={comment.message}
              messageAttributed={comment.commentMessageAttributed}
              isSubComment={true}
              createdAtFormatted={comment.createdAtFormatted}
              projectId={props.projectId}
              removeComment={props.removeSubComment}
              type={props.type}
              typeId={props.id}
              commentThreadCount={comment.commentThreadCount}
            />
          </Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <p className="topmargin-30 text-align-center">
        {localize("empty_comment_table_text", props.language)}
      </p>
    );
  }
};

const CommentInput = props => {
  const [mentionsUserList, setMentionsUserList] = React.useState([]);
  const [storedUserList, setStoredUserList] = React.useState([]);
  const [numberOfMentions, setNumberOfMentions] = React.useState(0);

  const getPlayers = debounce((search, callback) => {
    getApiGenerator(
      SEARCH_PROJECT.format(props.projectId),
      {
        type: "user",
        keywords: search
      },
      props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          return [];
        }
      } else {
        const formattedPlayers = res.body.data.map(user => ({
          id: user.id,
          display: user.userName,
          userImage: user.userImage
        }));
        setMentionsUserList(formattedPlayers);
        callback(formattedPlayers);
      }
    });
  }, 500);

  const onAdd = search => {
    const foundUser = mentionsUserList.find(
      user => user.id === parseInt(search)
    );
    setStoredUserList([...storedUserList, foundUser]);
  };

  const resetNumberOfMentions = () => {
    setNumberOfMentions(0);
  };

  // checks input against regex to count accurate number of mentions
  const checkInput = event => {
    const regexp = /(\[uid:[0-9]+\])/gm;
    let mentionsInInput = event.target.value.match(regexp);
    if (mentionsInInput !== null) {
      setNumberOfMentions(event.target.value.match(regexp).length);
    } else {
      setNumberOfMentions(0);
    }
    props.handleInputChange(event);
  };

  if (!!props.showInput) {
    const transformedInput = props.input.replace(/(\[uid:[0-9]+\])/gm, "");
    return (
      <div id="commentInput" className="bottompadding">
        <form className="pure-form" onSubmit={props.handleSubmit}>
          <div className="pure-u-1 relative">
            <MentionsInput
              value={props.input}
              placeholder={localize("comment_placeholder_text", props.language)}
              onChange={checkInput}
              classNames={mentionsClass}
              allowSpaceInQuery={true}
              a11ySuggestionsListLabel="Suggested mentions"
            >
              <Mention
                trigger="@"
                data={getPlayers}
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div className="flex align-items-center">
                    <div className="rightmargin-20 suggestion-image-container">
                      <img
                        className="suggestion-image"
                        src={suggestion.userImage}
                        alt="pfp"
                      />
                    </div>
                    {suggestion.display}
                  </div>
                )}
                displayTransform={id => {
                  const foundUser = mentionsUserList.find(
                    user => user.id === parseInt(id)
                  );
                  const storedUser = storedUserList.find(
                    user => user.id === parseInt(id)
                  );
                  return foundUser
                    ? `@${foundUser.display}`
                    : `@${storedUser.display}`;
                }}
                onAdd={onAdd}
                markup="[uid:__id__]"
                className={mentionsClass.mentions__mention}
              />
            </MentionsInput>
            <CharacterCounter input={props.input} charLimit={props.charLimit} />
          </div>
          <div className="pure-u-1">
            {numberOfMentions > MENTIONS_LIMIT && (
              <span className="text-red">
                {localize(
                  "alert_exceeded_mentions_limit",
                  props.language
                ).format(MENTIONS_LIMIT)}
              </span>
            )}
            <button
              type="submit"
              onClick={resetNumberOfMentions}
              disabled={
                props.input.trim() === "" ||
                numberOfMentions > MENTIONS_LIMIT ||
                (props.charLimit !== 0 &&
                  transformedInput.length > props.charLimit)
                  ? true
                  : false
              }
              className={
                "button nomargin floatright " +
                (props.input.trim() === "" ||
                numberOfMentions > MENTIONS_LIMIT ||
                (props.charLimit !== 0 &&
                  transformedInput.length > props.charLimit)
                  ? "disabled"
                  : "cta")
              }
            >
              {localize("nav_bar_title_comments_text", props.language)}
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return null;
  }
};

// const MoreButtonContainer = props => {
//   if (props.more) {
//     return (
//       <div className="more-container flex justify-content-center">
//         <button id="pagination" className="button" onClick={props.handleMore}>
//           {localize("more_text", props.language)}
//         </button>
//       </div>
//     );
//   } else {
//     return null;
//   }
// };

const CommentsThreadPage = props => {
  return (
    <Fragment>
      <div className="comments-container container verticalpadding bottompadding-floating-extended">
        <div className="innerblock">
          <MainComment
            comment={props.comment}
            userId={props.userId}
            projectId={props.projectId}
            removeComment={props.removeComment}
            type={props.type}
            typeId={props.typeId}
          />
          <CommentInput
            projectId={props.projectId}
            sessionKey={props.sessionKey}
            showInput={props.showInput}
            input={props.input}
            handleSubmit={props.handleSubmit}
            handleInputChange={props.handleInputChange}
            language={props.language}
            charLimit={props.charLimit}
          />
          <hr />
          <SubComments
            subComments={props.subComments}
            userId={props.userId}
            projectId={props.projectId}
            removeSubComment={props.removeSubComment}
            type={props.type}
            id={props.id}
            language={props.language}
          />
          <AchievementsModal
            showModal={props.showAchievements}
            handleCloseModal={props.setHideAchievements}
            achievements={props.achievements}
          />
          {props.isLoadingMore ? <Loading /> : null}
          {/* <MoreButtonContainer
            more={props.more}
            handleMore={props.handleMore}
            language={props.language}
          /> */}
        </div>
      </div>
    </Fragment>
  );
};

CommentsThreadPage.propTypes = propTypes;

export default CommentsThreadPage;
