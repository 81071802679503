import { LOAD_MORE_COUNT } from "config";
import getApiGenerator from "services/getApiGenerator";
import {
  ActivityContainer,
  mapDispatchToProps,
  mapStateToProps
} from "./ActivityContainer";
import { connect } from "react-redux";

export class MyActivityContainer extends ActivityContainer {
  getActivity(page) {
    const url = this.getURL(
      this.props.type,
      this.props.id,
      this.props.projectId
    );
    let query = {
      user_id: this.props.userId,
      page: page,
      limit: LOAD_MORE_COUNT
    };
    getApiGenerator(url, query, this.props.sessionKey).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.code === 500) {
          this.setState({
            claims: [],
            more: false,
            page: 1,
            isLoadingMore: false
          });
        }
      } else {
        if (this.props.type === "claim") {
          const claims = [res.body];
          this.setState({
            claims: claims,
            isLoadingMore: false
          });
        } else {
          this.setState({
            claims: this.state.claims
              ? this.state.claims.slice().concat(res.body.data)
              : res.body.data,
            page: this.state.page + 1,
            more: res.body.more,
            isLoadingMore: false
          });
        }
        this.props.setProject(res.body.game);

        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }

        if (res.body.quest) {
          this.props.setTopic(res.body.quest);
        }
      }
    });
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyActivityContainer);
