import React, { Component } from "react";
import PropTypes from "prop-types";

const propTypes = {
  quizOptions: PropTypes.array.isRequired,
  gridWidth: PropTypes.number.isRequired
};

/**
 * Renders a grid view for quiz options with correct answers displayed.
 */
class QuizGrid extends Component {
  /**
   * Renders a row of grid options
   * @param {number} startIndex - The starting index
   **/
  renderSingleGridRow(startIndex) {
    // Temporary array to store the current row
    let rowOptionsArray = this.props.quizOptions.slice(
      startIndex,
      startIndex + this.props.gridWidth
    );

    return (
      <tr key={startIndex}>
        {rowOptionsArray.map(option => {
          return this.renderSingleGridOption(option);
        })}
      </tr>
    );
  }

  /**
   * Render a single grid option
   * @param {*} option
   * @returns
   */
  renderSingleGridOption(option) {
    return (
      <td key={option.id}>
        <div
          className={
            "button fullwidth gridquizinput cursor-not-allowed" +
            (option.correct ? " selected" : "")
          }
        >
          {option.image ? (
            <div className="square-image-wrapper">
              <div className="square-image">
                <img src={option.image} alt={option.title} />
              </div>
            </div>
          ) : (
            <div className="padding textcenter">{option.title}</div>
          )}

          <span className="gridquizinput-selected correct"></span>
        </div>
      </td>
    );
  }

  render() {
    return (
      <div
        className={`grid-quiz-board-container ${
          this.props.gridWidth > 2 ? "scrollable-map-activated" : ""
        }`}
      >
        <div className="grid-quiz-board-scrollable-instruction">
          <span className="far fa-hand-pointer"></span> &nbsp; Swipe to move
          around the map.
        </div>
        <div className="grid-quiz-board-scrollable-map bottommargin-20">
          <div className="grid-quiz-board-wrap">
            <table id="gridQuizBoard" className="grid-quiz-board">
              <tbody>
                {this.props.quizOptions.map((option, index) => {
                  if (index % this.props.gridWidth === 0 || index === 0) {
                    return this.renderSingleGridRow(index);
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

QuizGrid.propTypes = propTypes;

export default QuizGrid;
