import React from "react";
import PropTypes from "prop-types";

import { GMAPS_API_KEY } from "config";
import localize from "lang/localize";

const propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  addressFormatted: PropTypes.string,
  language: PropTypes.string
};

const LocationMap = props => {
  if (props.latitude && props.longitude) {
    return (
      <React.Fragment>
        <div className="challenge-page-divider" />
        <div className="container verticalpadding">
          <div className="innerblock pure-g">
            <div className="pure-u-2-24" />
            <div className="pure-u-20-24">
              <div className="pure-g">
                <div className="pure-u-1-1 pure-u-md-12-24">
                  <h3>
                    {localize("nav_bar_title_map_view_text", props.language)}
                  </h3>
                  {props.addressFormatted}
                </div>
                <div className="pure-u-1-1 pure-u-md-12-24">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://www.google.com/maps/search/" +
                      props.latitude +
                      "," +
                      props.longitude
                    }
                  >
                    <img
                      className="pure-img"
                      alt={props.addressFormatted}
                      src={
                        "https://maps.google.com/maps/api/staticmap?&markers=" +
                        props.latitude +
                        "," +
                        props.longitude +
                        "&zoom=14&size=640x200&key=" +
                        GMAPS_API_KEY
                      }
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="pure-u-2-24" />
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

LocationMap.propTypes = propTypes;

export default LocationMap;
