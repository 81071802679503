import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import localize from "lang/localize";

import getApiGenerator from "services/getApiGenerator";
import { DELETE_CLAIM } from "services/api";

import { showAlertWithTimeout } from "actions";

import DeleteCompletionDialog from "./DeleteCompletionDialog";

const propTypes = {
  showModal: PropTypes.bool.isRequired,
  claimId: PropTypes.number.isRequired,
  handleCloseDeleteCompletionDialog: PropTypes.func.isRequired,
  removeClaim: PropTypes.func.isRequired
};

const defaultProps = {
  showModal: false
};

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey,
    userId: state.user ? state.user.id : null,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    }
  };
};

class DeleteCompletionDialogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showModal
    };

    this.handleClickDelete = this.handleClickDelete.bind(this);
  }

  deleteCompletion(type_id) {
    getApiGenerator(
      DELETE_CLAIM,
      {
        type_id: type_id
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      } else {
        this.props.showAlertWithTimeout({
          text: localize("claim_cell_delete_success", this.props.language),
          type: "success"
        });
      }
    });
  }

  handleClickDelete() {
    this.deleteCompletion(this.props.claimId);
    this.props.removeClaim(this.props.claimId);
    this.props.handleCloseDeleteCompletionDialog();
  }

  render() {
    return (
      <DeleteCompletionDialog
        showModal={this.props.showModal}
        claimId={this.props.claimId}
        handleCloseDeleteCompletionDialog={
          this.props.handleCloseDeleteCompletionDialog
        }
        handleClickDelete={this.handleClickDelete}
        language={this.props.language}
      />
    );
  }
}

DeleteCompletionDialogContainer.propTypes = propTypes;
DeleteCompletionDialogContainer.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCompletionDialogContainer);
