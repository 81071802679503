import React, { Component } from "react";
import { connect } from "react-redux";
import UpdateDeviceTokenPage from "./UpdateDeviceTokenPage";
import pushApiGenerator from "services/pushApiGenerator";
import { UPDATE_USER } from "services/api";
import Router from "router";
import { HOME } from "App/Routes";

export const mapStateToProps = (state, ownProps) => {
  return {
    sessionKey: state.sessionKey
  };
};

export class UpdateDeviceTokenContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null
    };
  }

  componentDidMount() {
    this.validateDeviceToken();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.deviceToken !== prevProps.deviceToken ||
      this.props.sessionKey !== prevProps.sessionKey
    ) {
      this.validateDeviceToken();
    }
  }

  validateDeviceToken() {
    let query = {
      device_token: this.props.deviceToken
    };

    let req = pushApiGenerator(UPDATE_USER, query, this.props.sessionKey);

    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        this.setState({ message: res.body.error });
      }

      Router.replaceAndNavigate(HOME);
    });
  }

  render() {
    return (
      <UpdateDeviceTokenPage
        sessionKey={this.props.sessionKey}
        message={this.state.message}
      />
    );
  }
}

export default connect(mapStateToProps)(UpdateDeviceTokenContainer);
