import { ENABLE_LOGIN_HOME } from "config";
import {
  LOGIN_HOME,
  PROJECT_LOGIN_HOME,
  LOGIN,
  PROJECT_LOGIN
} from "App/Routes";
import Router from "router";
import popupStorage from "services/popupStorage";
import localStorageService from "services/localStorageService";
import urlServices from "services/urlServices";
import sessionStorageService from "services/sessionStorageService";

import localize from "lang/localize";

const logoutServices = {
  /* logging out upon session invalidation */
  sessionLogout: function(
    projectId,
    language,
    setSessionKey,
    setUser,
    showAlertWithTimeout
  ) {
    /*
      Login redirect URLs are usually only set through routing to login pages, or whenever REQUIRE_LOGIN is true
      and the user is not yet logged in (when trying to access any page). Thus, or sessionLogout() instances, we
      want to save the URL they are at before routing them out. This allows the visitors to continue with their
      regular flow after logging in (and validating their session).

      However, we will not save if they are currently in a page meant to redirect users to pages they were in
      before logging in, like the login tour page. This is to prevent users from being stuck in a loop.
    */
    if (!urlServices.isLoginRedirectionPage()) {
      sessionStorageService.setItem(
        "loginRedirectUrl",
        urlServices.defaultPath()
      );
    }

    /*
      Theoretically, it makes more sense to make navigation the last action conducted upon logout.
      This is because *LoginContainer will re-route to app's Home page when sessionKey and userId
      still exist. However, we will make it the first action for now. This is because alerts are
      broken (they show, but disappear in a split second) when routing is moved to the last action.
    */
    if (projectId && ENABLE_LOGIN_HOME) {
      Router.replaceAndNavigate(PROJECT_LOGIN_HOME.format(projectId));
    } else if (projectId) {
      Router.replaceAndNavigate(PROJECT_LOGIN.format(projectId));
    } else if (ENABLE_LOGIN_HOME) {
      Router.replaceAndNavigate(LOGIN_HOME);
    } else {
      Router.replaceAndNavigate(LOGIN);
    }
    showAlertWithTimeout({
      text: localize("session_invalid_text", language),
      type: "error"
    });
    localStorageService.saveState({
      user_id: null,
      sessionKey: null
    });
    setSessionKey(null);
    setUser({ id: null });
    /*
      Unlike *logoutContainers, we won't be removing loginRedirectUrl from sessionStorageService here.
      This is because we want the users to continue with their regular flow, without starting all over
      again from the app's home page.
    */
    popupStorage.clearAll();
  }
};

export default logoutServices;
