import React from "react";
import { connect } from "react-redux";

import {
  mapStateToProps,
  mapDispatchToProps,
  LeaderboardContainer
} from "components/Project/Leaderboard/LeaderboardContainer";

import LeaderboardPage from "./LeaderboardPage";
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";
import NotLoggedInContainer from "components/shared/NotLoggedIn/NotLoggedInContainer";

import {
  GET_LEADERBOARD,
  GET_LEADERBOARD_OVERVIEW,
  GET_TOPICS
} from "services/api";
import { ENABLE_FULL_LEADERBOARD } from "config";
import getApiGenerator from "services/getApiGenerator";
import localize from "lang/localize";

const GET_COUNTRIES = "bundles/{0}/countries.json";

export class ASMLeaderboardContainer extends LeaderboardContainer {
  constructor() {
    super();
    this.state = {
      requests: [],
      indivReqStatus: [],
      teamReqStatus: [],
      currentBoard: "individual",
      type: "overall",
      individual: {
        overall: null,
        monthly: null,
        daily: null
      },
      team: {
        overall: null,
        monthly: null,
        daily: null
      },
      topics: null,
      topicLeaderboards: null,
      countryLeaderboards: null,
      selectedTopicLeaderboard: 0,
      selectedCountryLeaderboard: 0,
      error: null,
      code: null,
      overview: true,
      loadingData: false
    };

    this.handleFetchData = this.handleFetchData.bind(this);
  }

  async componentDidMount() {
    if (this.props.sessionKey) {
      this.getPageData();
    }

    // populate project in redux store
    if (this.props.projectId) {
      await this.getProject();

      if (this.props.project.teamEnabled) {
        //set default leaderboard to team if enabled
        this.setState({ currentBoard: "team" }, () => {
          this.getPageTeamData();
        });
      }
    }
  }

  async handleFetchData() {
    if (this.state.selectedCountryLeaderboard === 0) {
      return this.getPageData(undefined, this.state.type);
    } else {
      return this.getPageData("country", this.state.type);
    }
  }

  handleToggleOverview = event => {
    let tmpState = this.state;
    tmpState.overview = !tmpState.overview;
    tmpState[tmpState.currentBoard][tmpState.type] = {
      page: 1,
      more: true
    };
    this.setState(tmpState, () => {
      if (this.state.selectedCountryLeaderboard === 0) {
        this.getPageData(undefined, this.state.type);
      } else {
        this.getPageData("country", this.state.type);
      }
    });
  };

  async getProject() {
    // Load Project Information using topic list API (codes from core)
    await new Promise((resolve, reject) => {
      getApiGenerator(
        GET_TOPICS.format(this.props.projectId),
        {
          page: 1
        },
        this.props.sessionKey
      ).end((err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.code === 500) {
            this.setState({
              topics: [],
              code: 500,
              error: res.body.error
            });
          }
          reject(err);
        } else {
          const BUTTONS =
            res.body.buttons && res.body.buttons.length > 0
              ? res.body.buttons
              : null;

          this.props.setProject(res.body.game);
          this.props.setButtons(BUTTONS);
          resolve();
        }
      });
    });

    // Load country list (custom)
    await new Promise((resolve, reject) => {
      getApiGenerator(
        GET_COUNTRIES.format(this.props.projectId),
        {
          page: 1
        },
        this.props.sessionKey
      ).end((err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.code === 500) {
            this.setState({
              countryLeaderboards: [],
              code: 500,
              error: res.body.error
            });
          }
          reject(err);
        } else {
          this.setState({ countryLeaderboards: res.body.data });
          resolve();
        }
      });
    });
  }

  getTeamLeaderboard(type, teamID, team = true) {
    let dateFilterType = type;

    if (type === "monthly") {
      //replace monthly filter with 30 day filter
      dateFilterType = "30days";
    }

    let query = { team_id: teamID, type: dateFilterType, team: team };
    if (this.state.overview) {
      return getApiGenerator(
        GET_LEADERBOARD_OVERVIEW.format(this.props.projectId),
        query,
        this.props.sessionKey
      ).end((err, res) => {
        let reqStatus = this.state.teamReqStatus;
        reqStatus.push(type);
        this.setState({ teamReqStatus: reqStatus });

        if (err || res.body.code !== 200) {
          /* Returns "No one scored yet" */
        } else {
          /* Returns leaderboard */
          this.setTeamLeaderboardData(type, res);
        }
      });
    } else if (this.state.team[type].more) {
      query.page = this.state.team[type].page;
      return getApiGenerator(
        GET_LEADERBOARD.format(this.props.projectId),
        query,
        this.props.sessionKey
      ).end((err, res) => {
        if (
          (err || res.body.code !== 200) &&
          res.body.error !== "Data is not available"
        ) {
          /* Returns "No one scored yet" */
        } else {
          /* Returns leaderboard */
          this.setTeamLeaderboardData(type, res);
        }
      });
    }
  }

  getLeaderboard(type) {
    let dateFilterType = type;

    if (type === "monthly") {
      //replace monthly filter with 30 day filter
      dateFilterType = "30days";
    }

    let query = { user_id: this.props.user.id, type: dateFilterType };
    if (this.state.overview) {
      return getApiGenerator(
        GET_LEADERBOARD_OVERVIEW.format(this.props.projectId),
        query,
        this.props.sessionKey
      ).end((err, res) => {
        let reqStatus = this.state.indivReqStatus;
        reqStatus.push(type);
        this.setState({ indivReqStatus: reqStatus });

        if (err || res.body.code !== 200) {
          /* Returns "No one scored yet" */
        } else {
          /* Returns leaderboard */
          this.setLeaderboardData(type, res);
        }
      });
    } else if (this.state.individual[type].more) {
      query.page = this.state.individual[type].page;
      return getApiGenerator(
        GET_LEADERBOARD.format(this.props.projectId),
        query,
        this.props.sessionKey
      ).end((err, res) => {
        if (
          (err || res.body.code !== 200) &&
          res.body.error !== "Data is not available"
        ) {
          /* Returns "No one scored yet" */
        } else {
          /* Returns leaderboard */
          this.setLeaderboardData(type, res);
        }
      });
    }
  }

  getCountryLeaderboard(type) {
    let dateFilterType = type;

    if (type === "monthly") {
      //replace monthly filter with 30 day filter
      dateFilterType = "30days";
    }

    let query = { user_id: this.props.user.id, type: dateFilterType };

    if (
      this.state.selectedCountryLeaderboard != null &&
      this.state.selectedCountryLeaderboard !== 0
    ) {
      query = {
        user_id: this.props.user.id,
        type: dateFilterType,
        filter: "country",
        category_id: this.state.selectedCountryLeaderboard
      };
    }

    if (this.state.overview) {
      return getApiGenerator(
        GET_LEADERBOARD_OVERVIEW.format(this.props.projectId),
        query,
        this.props.sessionKey
      ).end((err, res) => {
        let reqStatus = this.state.indivReqStatus;
        reqStatus.push(type);
        this.setState({ indivReqStatus: reqStatus });

        if (err || res.body.code !== 200) {
          /* Returns "No one scored yet" */
        } else {
          /* Returns leaderboard */
          this.setLeaderboardData(type, res);
        }
      });
    } else if (this.state.individual[type].more) {
      query.page = this.state.individual[type].page;
      return getApiGenerator(
        GET_LEADERBOARD.format(this.props.projectId),
        query,
        this.props.sessionKey
      ).end((err, res) => {
        if (
          (err || res.body.code !== 200) &&
          res.body.error !== "Data is not available"
        ) {
          /* Returns "No one scored yet" */
        } else {
          /* Returns leaderboard */
          this.setLeaderboardData(type, res);
        }
      });
    }
  }

  getPageData(type = "project", time = "overall") {
    let requests = this.state.requests;

    if (this.props.project.teamEnabled && this.state.currentBoard === "team") {
      this.getPageTeamData(type, time);
    } else {
      if (type === "country") {
        const req = this.getCountryLeaderboard(time);
        requests.push(req);
      } else {
        const req = this.getLeaderboard(time);
        requests.push(req);
      }

      this.setState({ requests: requests });
    }
  }

  handleToggleCountry = event => {
    const TARGET_VALUE = Number(event.target.value);
    this.state.requests.forEach(req => {
      if (req) {
        req.abort();
      }
    });
    if (TARGET_VALUE !== this.state.selectedCountryLeaderboard) {
      this.setState(
        {
          selectedCountryLeaderboard: TARGET_VALUE,
          requests: []
        },
        () => {
          let tmpState = this.state;
          tmpState.individual = tmpState.team = {
            overall: null,
            monthly: null,
            daily: null
          };
          tmpState[tmpState.currentBoard][tmpState.type] = {
            page: 1,
            more: true
          };
          this.setState(tmpState, () => {
            if (this.state.selectedCountryLeaderboard === 0) {
              this.getPageData(undefined, this.state.type);
            } else {
              this.getPageData("country", this.state.type);
            }
          });
        }
      );
    }
  };

  handleToggleType = event => {
    let tmpState = this.state;
    tmpState.type = event.currentTarget.id;
    if (!tmpState[tmpState.currentBoard][tmpState.type]) {
      tmpState[tmpState.currentBoard][tmpState.type] = {
        page: 1,
        more: true
      };
    }
    this.setState(tmpState, () => {
      if (this.state.selectedCountryLeaderboard === 0) {
        this.getPageData(undefined, this.state.type);
      } else {
        this.getPageData("country", this.state.type);
      }
    });
  };

  render() {
    if (!this.props.user.id) {
      return (
        <NotLoggedInContainer
          showLogin={false}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project is private */
      this.props.projectId &&
      this.state.code === 500 &&
      this.state.error.indexOf("private") !== -1
    ) {
      return (
        <GenericErrorPage
          message={localize(
            "bundle_private_logged_in_text",
            this.props.language
          )}
          language={this.props.language}
        />
      );
    } else if (
      /* Project ID is available, but Project does not exist */
      this.props.projectId &&
      Array.isArray(this.state.topics) &&
      (this.state.error.indexOf("not exist") !== -1 ||
        this.state.error.indexOf("not published") !== -1)
    ) {
      return (
        <GenericErrorPage
          message={localize("bundle_not_found_text", this.props.language)}
          language={this.props.language}
        />
      );
    } else if (
      this.state.indivReqStatus.indexOf("overall") >= 0 &&
      this.props.project.teamEnabled !== undefined
    ) {
      /* Retrieving individual/player overall leaderboard is the fastest */
      /* Default view is also individual/player overall leaderboard */
      /* If can't even retrieve it, send to loading screen */
      return (
        <LeaderboardPage
          projectId={this.props.projectId}
          mainBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].mainBoard
              : null
          }
          userBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].userBoard
              : null
          }
          moreBoardData={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type]
                  .moreBoardData
              : null
          }
          fullBoard={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].data
              : null
          }
          more={
            this.state[this.state.currentBoard][this.state.type]
              ? this.state[this.state.currentBoard][this.state.type].more
              : null
          }
          topicLeaderboards={this.state.topicLeaderboards}
          countryLeaderboards={this.state.countryLeaderboards}
          type={this.state.type}
          board={this.state.currentBoard}
          teamAvailable={this.props.project.teamEnabled}
          handleToggleType={this.handleToggleType}
          handleToggleBoard={this.handleToggleBoard}
          handleToggleTopic={this.handleToggleTopic}
          handleToggleOverview={
            ENABLE_FULL_LEADERBOARD ? this.handleToggleOverview : () => {}
          }
          handleFetchData={this.handleFetchData}
          overview={this.state.overview}
          handleToggleCountry={this.handleToggleCountry}
          userId={this.props.user.id}
          teamId={this.props.team.id}
          indivReqStatus={this.state.indivReqStatus}
          teamReqStatus={this.state.indivReqStatus}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ASMLeaderboardContainer);
