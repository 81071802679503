import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { PROJECT_TEAM_CREATE } from "App/Routes";
import SearchBar from "components/shared/SearchBar/SearchBar";
import Team from "./Team";
import Link from "components/shared/Link/Link";
import localize from "lang/localize";

import "./Teams.css";
import Loading from "components/shared/Loading";

const propTypes = {
  teams: PropTypes.array.isRequired,
  teamCreateEnabled: PropTypes.bool.isRequired,
  teamCreatedInProject: PropTypes.bool.isRequired,
  user: PropTypes.object,
  more: PropTypes.bool.isRequired,
  handleMore: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  language: PropTypes.string,
  projectId: PropTypes.number,
  isLoadingMore: PropTypes.bool
};

/**
 * Team Page - Displays list of teams.
 */
class TeamsPage extends Component {
  /**
   * Set page identifier classes
   * (To specifically target this page for styling/customizations)
   */
  componentDidMount() {
    let bodyDOM = document.body; // <body> tag

    // Set page identifier class to body DOM
    if (!bodyDOM.classList.contains("teamsPage")) {
      bodyDOM.className += " teamsPage";
    }

    // Add other page classes to body DOM
    if (!bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.className += " page-loggedin";
    }
  }

  /**
   * Remove page identifier classes
   */
  componentWillUnmount() {
    let bodyDOM = document.body; // <body> tag

    // Remove page identifier class from body DOM
    if (bodyDOM.classList.contains("teamsPage")) {
      bodyDOM.classList.remove("teamsPage");
    }

    // Remove other page classes from body DOM
    if (bodyDOM.classList.contains("page-loggedin")) {
      bodyDOM.classList.remove("page-loggedin");
    }
  }

  /**
   * Render button to create team
   */
  renderCreateTeamButton() {
    if (this.props.teamCreateEnabled) {
      // Existing user has not created a team before
      if (!this.props.teamCreatedInProject) {
        return (
          <Fragment>
            <div className="row">
              <div className="hide-below-sm pure-u-sm-3-4" />
              <div className="pure-u-1-1 pure-u-sm-1-4">
                <Link
                  to={PROJECT_TEAM_CREATE.format(this.props.projectId)}
                  className="button cta"
                >
                  <span className="fas fa-plus" />
                  &nbsp;&nbsp;
                  {localize("new_team_text", this.props.language)}
                </Link>
              </div>
            </div>
          </Fragment>
        );
      } else {
        return (
          // Show greyed out button button
          <Fragment>
            <div className="row">
              <div className="hide-below-sm pure-u-sm-2-3" />
              <div className="pure-u-1-1 pure-u-sm-1-3">
                <div className="button">
                  <span className="fas fa-plus" />
                  &nbsp;&nbsp;
                  {localize("new_team_text", this.props.language)}
                  <br />
                  <small>
                    <em>
                      ({localize("new_team_disabled_text", this.props.language)}
                      )
                    </em>
                  </small>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }
    } else {
      // Don't show anything if setting is disabled
      return null;
    }
  }

  /**
   * Render Team search bar
   */
  renderSearchBar() {
    return (
      <Fragment>
        <div className="verticalpadding-5" />
        <SearchBar
          handleSubmit={this.props.handleSubmit}
          handleSearchChange={this.props.handleSearchChange}
          placeholder={localize(
            "general_search_placeholder_text",
            this.props.language
          )}
          language={this.props.language}
        />
      </Fragment>
    );
  }

  /**
   * Render list of Teams
   */
  renderTeams() {
    if (this.props.teams.length >= 1) {
      return this.props.teams.map(team => (
        <Team
          key={team.id}
          id={team.id}
          name={team.name}
          userNo={team.userNo}
          img={team.image}
          user={this.props.user}
          language={this.props.language}
          projectId={this.props.projectId}
          joined={!!team.joined}
        />
      ));
    } else {
      return (
        <p className="textcenter">
          {localize("empty_teams_text", this.props.language)}
        </p>
      );
    }
  }

  /**
   * Page render
   */
  render() {
    return (
      <div className="teams container verticalpadding">
        <div className="innerblock pure-g bottompadding-floating">
          <div className="pure-u-md-2-24 pure-u-0-24" />
          <div className="pure-u-md-20-24 pure-u-1">
            {this.renderCreateTeamButton()}
            {this.renderSearchBar()}
            {this.renderTeams()}
            {this.props.isLoadingMore ? <Loading /> : null}
            {/* {this.props.more && (
              <button
                className="button automargin"
                onClick={this.props.handleMore}
              >
                {localize("more_text", this.props.language)}
              </button>
            )} */}
          </div>
          <div className="pure-u-md-2-24 pure-u-0-24" />
        </div>
      </div>
    );
  }
}

TeamsPage.propTypes = propTypes;

export default TeamsPage;
