import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  HOME,
  PROJECT_LOGIN,
  LOGIN,
  PROJECT_LOGIN_HOME,
  LOGIN_HOME,
  CHALLENGE,
  CHALLENGE_COMMENTS,
  CHALLENGE_ACTIVITY,
  CHALLENGE_MY_ACTIVITY
} from "App/Routes";
//import ChallengePage from "./ChallengePage";
import ChallengePage from "custom-components/Challenge/ChallengePage"; //CUSTOM FOR ASM
import Loading from "components/shared/Loading";
import GenericErrorPage from "components/ErrorPages/GenericError/GenericErrorPage";

import { ENABLE_LOGIN_HOME } from "config";
import {
  ACCEPT_CHALLENGE,
  GET_CHALLENGE,
  GET_PROJECT_BUTTONS,
  POST_CLAIM,
  GET_USER_WIDGET
} from "services/api";
import sessionStorageService from "services/sessionStorageService";
import getApiGenerator from "services/getApiGenerator";
import pushApiGenerator from "services/pushApiGenerator";
import listenerServices from "services/listenerServices";
import localStorageService from "services/localStorageService";
import localize from "lang/localize";

import {
  getChallengeType,
  getPoints,
  likeChallenge,
  bookmarkChallenge,
  startChallengeTimeTracking,
  endChallengeTimeTracking
} from "services/challengeServices";
import {
  setProject,
  setButtons,
  setTopic,
  setChallenge,
  showAlertWithTimeout,
  addTopbarPoints,
  setProfileDrawer,
  addProfileDrawerPoints,
  showChallengeCompleted,
  showAchievements,
  showAwardableActionWithTimeout
} from "actions";

export const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    sessionKey: state.sessionKey,
    topic: state.sidebar.topic,
    challengeCompleted: state.challengeCompleted,
    language: state.language
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setProject: project => {
      dispatch(setProject(project));
    },
    setButtons: buttons => {
      dispatch(setButtons(buttons));
    },
    setTopic: topic => {
      dispatch(setTopic(topic));
    },
    setChallenge: challenge => {
      dispatch(setChallenge(challenge));
    },
    showAlertWithTimeout: alert => {
      dispatch(showAlertWithTimeout(alert));
    },
    addTopbarPoints: points => {
      dispatch(addTopbarPoints(points));
    },
    setProfileDrawer: info => {
      dispatch(setProfileDrawer(info));
    },
    addProfileDrawerPoints: points => {
      dispatch(addProfileDrawerPoints(points));
    },
    showChallengeCompleted: challengeCompleted => {
      dispatch(showChallengeCompleted(challengeCompleted));
    },
    showAchievements: achievements => {
      dispatch(showAchievements(achievements));
    },
    showAwardableActionWithTimeout: awardableAction => {
      dispatch(showAwardableActionWithTimeout(awardableAction));
    }
  };
};

const propTypes = {
  id: PropTypes.string.isRequired
};

export class ChallengeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      errorTopic: null,
      isLiked: null,
      isBookmarked: null,
      likeNo: null,
      adminToClaim: false,
      locked: false,
      submitted: false,
      showClaimLoginDialog: false,
      showBookmarkLoginDialog: false,
      showLikeLoginDialog: false,
      showImageDialog: false,
      showEmbedDialog: false,
      showQRModal: false,
      showExternalChallengeDialog: false,
      contactEmail: null,
      isTimerActive: false,
      countdownTime: 0,
      // character limit
      charLimit: 0
    };

    /* Login Dialogs */
    this.handleOpenClaimLoginDialog = this.handleOpenClaimLoginDialog.bind(
      this
    );
    this.handleCloseClaimLoginDialog = this.handleCloseClaimLoginDialog.bind(
      this
    );
    this.handleOpenBookmarkLoginDialog = this.handleOpenBookmarkLoginDialog.bind(
      this
    );
    this.handleCloseBookmarkLoginDialog = this.handleCloseBookmarkLoginDialog.bind(
      this
    );
    this.handleOpenLikeLoginDialog = this.handleOpenLikeLoginDialog.bind(this);
    this.handleCloseLikeLoginDialog = this.handleCloseLikeLoginDialog.bind(
      this
    );
    /* Image Dialogs */
    this.handleOpenImageDialog = this.handleOpenImageDialog.bind(this);
    this.handleCloseImageDialog = this.handleCloseImageDialog.bind(this);
    /* Embed Dialogs */
    this.handleOpenEmbedDialog = this.handleOpenEmbedDialog.bind(this);
    this.handleCloseEmbedDialog = this.handleCloseEmbedDialog.bind(this);
    /* QR Modal */
    this.handleOpenQRModal = this.handleOpenQRModal.bind(this);
    this.handleCloseQRModal = this.handleCloseQRModal.bind(this);
    /* Minigame Dialogs */
    this.handleOpenExternalChallengeDialog = this.handleOpenExternalChallengeDialog.bind(
      this
    );
    this.handleCloseExternalChallengeDialog = this.handleCloseExternalChallengeDialog.bind(
      this
    );

    this.setTimerActive = this.setTimerActive.bind(this);
  }

  componentDidMount() {
    /* Listening to messages for completion error handling for External Challenges */
    listenerServices.addListener("message", this.handleReceiveMessage);

    /* initialising getChallengeAgainTimeout variable, which will not be used in props or react states */
    /* getChallengeAgainTimeout will eventually be assigned to a setTimeout() function */
    this.getChallengeAgainTimeout = null;

    /* get challenge */
    this.getChallenge();

    /* start challenge time tracking */
    if (this.props.sessionKey) {
      startChallengeTimeTracking(
        this.props.id,
        this.props.sessionKey,
        this.props.showAlertWithTimeout
      );
    }
  }

  componentWillUnmount() {
    /* Unmount message listener for External Challenges */
    listenerServices.removeListener("message", this.handleReceiveMessage);

    /*
      clears timeout on unmount for this.getChallengeAgainTimeout,
      if calling getChallengeAgain() was ever necessary
    */
    clearTimeout(this.getChallengeAgainTimeout);

    if (this.props.sessionKey) {
      endChallengeTimeTracking(
        this.props.id,
        this.props.sessionKey,
        this.props.showAlertWithTimeout,
        window.location.pathname
      );
    }

    // challenge min view duration
    const excludedUrl = [
      CHALLENGE.format(this.props.id),
      CHALLENGE_COMMENTS.format(this.props.id),
      CHALLENGE_ACTIVITY.format(this.props.id),
      CHALLENGE_MY_ACTIVITY.format(this.props.id)
    ];
    const exclude = excludedUrl.some(url =>
      window.location.pathname.includes(url)
    );

    if (
      exclude &&
      this.state.data.challengeMinViewDuration &&
      this.state.isTimerActive
    ) {
      localStorageService.setItem("challengeId", this.props.id);
      localStorageService.setItem("currentTime", Date.now());
      localStorageService.setItem("currentCountdown", this.state.countdownTime);
    } else if (
      !exclude &&
      localStorageService.getItem("challengeId") === this.props.id &&
      localStorageService.getItem("currentTime") !== null &&
      localStorageService.getItem("currentCountdown") !== null
    ) {
      localStorageService.removeItem("challengeId");
      localStorageService.removeItem("currentTime");
      localStorageService.removeItem("currentCountdown");
    }
  }

  handleReceiveMessage = e => {
    let messageData = e.data;

    if (
      this.state.data &&
      this.state.data.challengeTypeId === 15 &&
      e.origin === "https://ext.gametize.com"
    ) {
      this.handleExternalClaim(messageData);
    }
  };

  setTimerActive = isTimerActive => {
    this.setState({ isTimerActive: isTimerActive });
  };

  setCountdownTime = (challengeMinViewDuration, id) => {
    const challengeId = parseInt(localStorageService.getItem("challengeId"));
    const currentTime = localStorageService.getItem("currentTime");
    const currentCountdown = localStorageService.getItem("currentCountdown");

    if (challengeId === id && currentTime && currentCountdown) {
      let newCountdownTime = currentCountdown - (Date.now() - currentTime);
      if (newCountdownTime > 0) {
        this.setState({
          countdownTime: newCountdownTime,
          isTimerActive: true
        });
      }
    } else {
      this.setState({
        countdownTime: challengeMinViewDuration * 1000,
        isTimerActive: true
      });
    }
  };

  /*
    Function for calling getChallenge once more
    For this, we only want to update this.state.data, which contains the 'claimed' key-value pair.
    getChallengeAgain() should be housed within a 1 second setTimeout(), because repeatable (anytime)
    challenges take about a second to change from "claimed === true" to "claim === false" upon completion.
  */
  getChallengeAgain = () => {
    getApiGenerator(
      GET_CHALLENGE.format(this.props.id),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      /* API success */
      if (!(err || res.body.code !== 200)) {
        this.setState({
          data: res.body,
          isLiked: res.body.ratedLike,
          isBookmarked: res.body.bookmarked,
          likeNo: res.body.likeNo,
          adminToClaim: res.body.adminToClaim,
          locked: res.body.locked,
          contactEmail: res.body.game.contactEmail
        });

        if (res.body.previousChallengeId) {
          this.getPrevChallengeUnlocked(res.body.previousChallengeId);
        }
        if (res.body.nextChallengeId) {
          this.getNextChallengeUnlocked(res.body.nextChallengeId);
        }
        // accepts if not already accepted and expired is false or undefined
        if (res.body.accepted === false && !res.body.expired) {
          this.acceptChallenge();
        }
        this.props.setProject(res.body.game);
        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }
        this.props.setTopic(res.body.quest);
        this.props.setChallenge(res.body);

        if (res.body.challengeMinViewDuration) {
          this.setCountdownTime(res.body.challengeMinViewDuration, res.body.id);
        }
      }
    });
  };

  getChallenge = () => {
    getApiGenerator(
      GET_CHALLENGE.format(this.props.id),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        /* locked challenge check */
        if (
          res.body.error &&
          res.body.error.indexOf("locked") !== -1 &&
          res.body.quest
        ) {
          this.setState({ data: res.body, locked: true });
        } else {
          this.setState({ data: false });
        }

        this.setState(
          {
            errorTopic: res.body.quest
          },
          () => {
            /* setting sidebar */
            if (this.state.errorTopic) {
              this.props.setTopic(this.state.errorTopic);
            }
            if (res.body.game) {
              this.props.setProject(res.body.game);
            }
          }
        );

        this.props.setChallenge(res.body);
      } else {
        this.setState(
          {
            data: res.body,
            isLiked: res.body.ratedLike,
            isBookmarked: res.body.bookmarked,
            likeNo: res.body.likeNo,
            adminToClaim: res.body.adminToClaim,
            contactEmail: res.body.game.contactEmail
          },
          () => {
            /*
            Once this.state.data is updated, check if 'claimed' exists and if it's true.
            If it's true, do a second check to make sure it remains true (because
            repeatable (anytime) challenges will switch to true for a second before
            switching to false)
          */
            if (this.state.data.claimed && this.state.data.claimed === true) {
              this.getChallengeAgainTimeout = setTimeout(
                () => this.getChallengeAgain(),
                1000
              );
            }
          }
        );
        // accepts if not already accepted and expired is false or undefined
        if (res.body.accepted === false && !res.body.expired) {
          this.acceptChallenge();
        }
        this.props.setProject(res.body.game);
        if (res.body.game && res.body.game.id) {
          this.getProjectButtons(res.body.game.id);
        }
        this.props.setTopic(res.body.quest);
        this.props.setChallenge(res.body);

        if (res.body.challengeMinViewDuration) {
          this.setCountdownTime(res.body.challengeMinViewDuration, res.body.id);
        }
      }
    });
  };

  getUserPoints(project_id) {
    if (this.props.user.id === undefined) {
      return false;
    }
    getApiGenerator(
      GET_USER_WIDGET.format(this.props.user.id),
      { bundle_id: project_id },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
      } else {
        this.props.setProfileDrawer({ points: res.body.points });
      }
    });
  }

  getProjectButtons(projectId) {
    getApiGenerator(
      GET_PROJECT_BUTTONS.format(projectId),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.props.setButtons(null);
      } else {
        const BUTTONS =
          res.body.data && res.body.data.length > 0 ? res.body.data : null;

        this.props.setButtons(BUTTONS);
      }
    });
  }

  getPrevChallengeUnlocked(challengeId) {
    getApiGenerator(
      GET_CHALLENGE.format(challengeId),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error === "Challenge is locked") {
          this.setState({ prevChallengeLocked: true });
        }
      } else {
        if (res.body.locked) {
          this.setState({ prevChallengeLocked: true });
        } else {
          this.setState({ prevChallengeLocked: false });
        }
      }
    });
  }

  getNextChallengeUnlocked(challengeId) {
    getApiGenerator(
      GET_CHALLENGE.format(challengeId),
      {},
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error === "Challenge is locked") {
          this.setState({ nextChallengeLocked: true });
        }
      } else {
        if (res.body.locked) {
          this.setState({ nextChallengeLocked: true });
        } else {
          this.setState({ nextChallengeLocked: false });
        }
      }
    });
  }

  postClaim = (query, image = null, imagename = null) => {
    // only standard and multifield challenges have image upload
    this.setState({ submitted: true });
    let req = pushApiGenerator(POST_CLAIM, query, this.props.sessionKey);
    req.query({ challenge_id: this.state.data.id });
    if (image !== null && imagename !== null) {
      req.field("file", image);
      req.field("file_name", imagename);
    }

    req.end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }

        this.setState({ submitted: false });
      } else {
        this.getChallenge();
        this.props.addTopbarPoints(res.body.points);
        this.props.addProfileDrawerPoints(res.body.points);
        this.setState({ submitted: false });
        if (this.state.data.challengeMinViewDuration) {
          this.setState({
            isTimerActive: true,
            countdownTime: this.state.data.challengeMinViewDuration * 1000
          });
        }
        this.showResultAlerts(res);
      }
    });
  };

  postQRClaim = decodedQR => {
    this.setState({ submitted: true });
    pushApiGenerator(
      POST_CLAIM,
      {
        qr_challenge_id: this.state.data.id == null ? 0 : this.state.data.id,
        comment: decodedQR
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        this.handleCloseQRModal();
        this.props.showAlertWithTimeout({
          text: res.body.error,
          type: "error"
        });
        this.setState({ submitted: false });
      } else {
        this.getChallenge();
        this.props.addTopbarPoints(res.body.points);
        this.props.addProfileDrawerPoints(res.body.points);
        this.setState({ submitted: false });
        this.handleCloseQRModal();
        this.showResultAlerts(res);
      }
    });
  };

  handleExternalClaim = data => {
    if (data.code !== 200) {
      if (data.error && data.error.indexOf("session key") !== -1) {
        this.handleOpenClaimLoginDialog();
      } else if (data.error) {
        this.props.showAlertWithTimeout({
          text: data.error,
          type: "error"
        });
      }

      this.setState({ submitted: false });
    } else {
      let res = {
        body: data
      };
      this.getChallenge();
      this.props.addTopbarPoints(data.points);
      this.props.addProfileDrawerPoints(data.points);
      this.setState({ submitted: false });
      this.showResultAlerts(res);
    }
  };

  showResultAlerts = res => {
    this.props.showChallengeCompleted(res.body);
  };

  acceptChallenge = () => {
    getApiGenerator(
      ACCEPT_CHALLENGE,
      {
        type_id: this.state.data.id
      },
      this.props.sessionKey
    ).end((err, res) => {
      if (err || res.body.code !== 200) {
        if (res.body.error) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        }
      }
    });
  };

  handleBookmark = (event, id, bookmarked) => {
    bookmarkChallenge(event, id, bookmarked, this.props.sessionKey).end(
      (err, res) => {
        if (err || res.body.code !== 200) {
          if (res.body.error) {
            this.props.showAlertWithTimeout({
              text: res.body.error,
              type: "error"
            });
          }
        } else {
          this.setState({ isBookmarked: res.body.bookmarked });
        }
      }
    );
  };

  handleLike = event => {
    likeChallenge(event, this.state.data.id, this.props.sessionKey).end(
      (err, res) => {
        if (err || res.body.code !== 200) {
          this.props.showAlertWithTimeout({
            text: res.body.error,
            type: "error"
          });
        } else {
          if (res.body.actionPoints && res.body.actionPoints > 0) {
            this.props.addProfileDrawerPoints(res.body.actionPoints);
            this.props.showAwardableActionWithTimeout({
              numberStr: "" + res.body.actionPoints.abbreviateNumber(),
              unit: localize("points_just_text", this.props.language)
            });
          }
          if (res.body.items) {
            this.props.showAchievements(res.body.items);
          }
          this.setState({
            isLiked: res.body.rated,
            likeNo: res.body.likeNo
          });
        }
      }
    );
  };

  handleOpenClaimLoginDialog() {
    this.setState({
      showClaimLoginDialog: true
    });
  }

  handleCloseClaimLoginDialog() {
    this.setState({
      showClaimLoginDialog: false
    });
  }

  handleOpenBookmarkLoginDialog() {
    this.setState({
      showBookmarkLoginDialog: true
    });
  }

  handleCloseBookmarkLoginDialog() {
    this.setState({
      showBookmarkLoginDialog: false
    });
  }

  handleOpenLikeLoginDialog() {
    this.setState({
      showLikeLoginDialog: true
    });
  }

  handleCloseLikeLoginDialog() {
    this.setState({
      showLikeLoginDialog: false
    });
  }

  handleOpenImageDialog() {
    this.setState({
      showImageDialog: true
    });
  }

  handleCloseImageDialog() {
    this.setState({
      showImageDialog: false
    });
  }

  handleOpenEmbedDialog() {
    this.setState({
      showEmbedDialog: true
    });
  }

  handleCloseEmbedDialog() {
    this.setState({
      showEmbedDialog: false
    });
  }

  handleOpenQRModal() {
    this.setState({
      showQRModal: true
    });
  }

  handleCloseQRModal() {
    this.setState({
      showQRModal: false
    });
  }

  handleOpenExternalChallengeDialog() {
    this.setState({
      showExternalChallengeDialog: true
    });
  }

  handleCloseExternalChallengeDialog() {
    this.setState(
      {
        showExternalChallengeDialog: false
      },
      () => {
        this.getChallenge();
        this.getUserPoints(this.state.data.game.id);
      }
    );
  }

  getLoginRoute() {
    /* Embedded project tests not necessary here, because loginRedirectUrl is saved */
    if (this.props.projectId && ENABLE_LOGIN_HOME) {
      return PROJECT_LOGIN_HOME.format(this.props.projectId);
    } else if (this.props.projectId) {
      return PROJECT_LOGIN.format(this.props.projectId);
    } else if (ENABLE_LOGIN_HOME) {
      return LOGIN_HOME;
    } else {
      return LOGIN;
    }
  }

  render() {
    const data = this.state.data;
    const errorTopic = this.state.errorTopic;
    const UNAVAILABLE_CHALLENGE_ERROR_LIST = [
      localize("unavailable_challenge_private_topic", this.props.language),
      localize("unavailable_challenge_private_project", this.props.language),
      localize("unavailable_challenge_unpublished", this.props.language),
      localize("unavailable_challenge_not_exist", this.props.language)
    ];
    const UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN = [
      localize(
        "unavailable_challenge_private_topic_loggedin",
        this.props.language
      ),
      localize(
        "unavailable_challenge_private_project_loggedin",
        this.props.language
      ),
      localize("unavailable_challenge_unpublished", this.props.language),
      localize("unavailable_challenge_not_exist", this.props.language)
    ];
    const IS_EMBEDDED_PROJECT =
      sessionStorageService.getItem("embedded_project") === "true"
        ? true
        : false;

    if (data) {
      return (
        <ChallengePage
          key={data.id}
          user={this.props.user}
          sessionKey={this.props.sessionKey}
          // claimform
          challengeTypeId={data.challengeTypeId}
          isConfirmationChallenge={
            data.challengeTypeId === 11 && data.confirmation === true
          }
          photoOnly={data.photoOnly}
          noPhoto={data.noPhoto}
          quizOptions={data.quizOptions}
          privateClaim={data.privateClaim}
          fields={data.fields}
          completionMessage={data.completionMessage}
          multiSelect={!!data.multiSelect}
          gridWidth={data.gridWidth ? data.gridWidth : 0}
          claimed={data.claimed}
          claimedBefore={data.claimedBefore}
          expired={!!data.expired}
          submitted={this.state.submitted}
          videoDurationLimit={data.videoDurationLimit}
          // location
          latitude={data.latitude}
          longitude={data.longitude}
          addressFormatted={data.addressFormatted}
          // challengeresult
          id={data.id}
          correctAnswerNo={data.correctAnswerNo}
          wrongAnswerNo={data.wrongAnswerNo}
          correctAnswerTitle={data.correctAnswerTitle}
          correctAnswer={data.correctAnswer}
          // image
          imageMedium={data.imageMedium}
          // embed
          medias={data.medias ? data.medias : []}
          // text
          footnote={data.footnote}
          title={data.title}
          repeat={data.repeat}
          repeatUntilCorrect={!!data.repeatUntilCorrect}
          repeatAtFormatted={data.repeatAtFormatted}
          videoDurationLimitFormatted={data.videoDurationLimitFormatted}
          // stats
          commentNo={data.commentNo}
          claimNo={data.claimNo}
          previousChallengeId={data.previousChallengeId}
          nextChallengeId={data.nextChallengeId}
          challengeType={getChallengeType(
            data.challengeTypeId,
            data.photoOnly,
            data.multiSelect,
            data.challengeType
          )}
          points={getPoints(data.challengeTypeId, data.points)}
          challengeReferralLink={data.challengeReferralLink}
          isLiked={!!this.state.isLiked}
          likeNo={this.state.likeNo}
          isBookmarked={!!this.state.isBookmarked}
          handleBookmark={this.handleBookmark.bind(this)}
          handleLike={this.handleLike}
          postClaim={this.postClaim}
          postQRClaim={this.postQRClaim}
          showAlertWithTimeout={this.props.showAlertWithTimeout}
          adminToClaim={this.state.adminToClaim}
          // login dialogs
          showClaimLoginDialog={this.state.showClaimLoginDialog}
          handleOpenClaimLoginDialog={this.handleOpenClaimLoginDialog}
          handleCloseClaimLoginDialog={this.handleCloseClaimLoginDialog}
          showBookmarkLoginDialog={this.state.showBookmarkLoginDialog}
          handleOpenBookmarkLoginDialog={this.handleOpenBookmarkLoginDialog}
          handleCloseBookmarkLoginDialog={this.handleCloseBookmarkLoginDialog}
          showLikeLoginDialog={this.state.showLikeLoginDialog}
          handleOpenLikeLoginDialog={this.handleOpenLikeLoginDialog}
          handleCloseLikeLoginDialog={this.handleCloseLikeLoginDialog}
          // image dialogs
          showImageDialog={this.state.showImageDialog}
          handleOpenImageDialog={this.handleOpenImageDialog}
          handleCloseImageDialog={this.handleCloseImageDialog}
          // embed dialogs
          showEmbedDialog={this.state.showEmbedDialog}
          handleOpenEmbedDialog={this.handleOpenEmbedDialog}
          handleCloseEmbedDialog={this.handleCloseEmbedDialog}
          // qr dialogs
          showQRModal={this.state.showQRModal}
          handleOpenQRModal={this.handleOpenQRModal}
          handleCloseQRModal={this.handleCloseQRModal}
          // minigame dialogs
          showExternalChallengeDialog={this.state.showExternalChallengeDialog}
          handleOpenExternalChallengeDialog={
            this.handleOpenExternalChallengeDialog
          }
          handleCloseExternalChallengeDialog={
            this.handleCloseExternalChallengeDialog
          }
          // language
          language={this.props.language}
          // contact email
          contactEmail={this.state.contactEmail}
          // locked content handling
          locked={this.state.locked}
          lockedChallengeTopicId={errorTopic ? errorTopic.id : null}
          lockedChallengeTopicTitle={errorTopic ? errorTopic.title : ""}
          // project and topic information
          project={data.game}
          topic={data.quest}
          topicIdNext={data.topicIdNext}
          // minigame
          challengeMinigameTitle={data.challengeMinigameTitle}
          // challenge time tracking
          challengeMinViewDuration={data.challengeMinViewDuration}
          isTimerActive={this.state.isTimerActive}
          setTimerActive={this.setTimerActive}
          countdownTime={this.state.countdownTime}
          // character limit
          charLimit={data.charLimit !== undefined ? data.charLimit : 0}
        />
      );
    } else if (data === false && !this.props.sessionKey) {
      return (
        <GenericErrorPage
          routeUrl={this.getLoginRoute()}
          routeName={localize("button_login", this.props.language)}
          message={localize("unavailable_challenge_long", this.props.language)}
          messageList={UNAVAILABLE_CHALLENGE_ERROR_LIST}
          endMessage={localize(
            "unavailable_challenge_login_prompt",
            this.props.language
          )}
          isBackRoute={false}
          language={this.props.language}
        />
      );
    } else if (data === false) {
      return (
        <GenericErrorPage
          routeUrl={IS_EMBEDDED_PROJECT ? null : HOME}
          routeName={localize("icon_home", this.props.language)}
          message={localize("unavailable_challenge_long", this.props.language)}
          messageList={UNAVAILABLE_CHALLENGE_ERROR_LIST_LOGGEDIN}
          language={this.props.language}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

ChallengeContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeContainer);
