import request from "superagent";
import { ENDPOINT, SECRET_KEY } from "config";

const pushApiGenerator = (route, data, sessionKey) =>
  request
    .post(ENDPOINT + route)
    .accept("application/json")
    .set("Authorization", `Bearer ${Boolean(sessionKey) ? sessionKey : ""}`)
    .field({ secret_key: Boolean(SECRET_KEY) ? SECRET_KEY : "" })
    .field(data);

export default pushApiGenerator;
