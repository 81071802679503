import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import localize from "lang/localize";
import { DOWNLOAD_APP_APPLE, DOWNLOAD_APP_ANDROID } from "config";
import deviceServices from "services/deviceServices";
import localStorageService from "services/localStorageService";

const COOKIE_NAME = "downloadAppAlertShown";

const propTypes = {
  language: PropTypes.string,
  imageComponent: PropTypes.element
};

const DownloadAppAlert = props => {
  const [showDownloadAlert, setShowDownloadAlert] = useState(false);
  const downloadLink = useRef(null);

  useEffect(() => {
    const hasSeenDownloadAppAlert = localStorageService.getItem(COOKIE_NAME);

    if (!hasSeenDownloadAppAlert && window.innerWidth <= 768) {
      window.setTimeout(() => {
        if (deviceServices.isIOS() && !!DOWNLOAD_APP_APPLE) {
          setShowDownloadAlert(true);
          downloadLink.current = DOWNLOAD_APP_APPLE;
        } else if (
          deviceServices.isAndroid() &&
          !deviceServices.isWebView() &&
          !!DOWNLOAD_APP_ANDROID
        ) {
          setShowDownloadAlert(true);
          downloadLink.current = DOWNLOAD_APP_ANDROID;
        }
      }, 500);
    }
  }, []);

  const handleDownload = () => {
    window.open(downloadLink.current, "_blank");
  };

  const setCookie = () => {
    localStorageService.setItem(COOKIE_NAME, true);
  };

  return (
    <div>
      {showDownloadAlert && (
        <div className="dim-background">
          <div className="download-modal">
            <strong className="leftmargin-10 bottommargin-20">
              {localize("alert_download_app_text", props.language)}
            </strong>
            <div className="bottommargin-10 fullwidth">
              <button
                className="button inline cta fullwidth"
                onClick={() => {
                  handleDownload();
                  setShowDownloadAlert(false);
                  setCookie();
                }}
              >
                {props.imageComponent ?? (
                  <i className="fas fa-mobile-alt rightmargin-10" />
                )}
                <span>{localize("alert_open_app_text", props.language)}</span>
              </button>
              <button
                className="button inline fullwidth"
                onClick={() => {
                  setShowDownloadAlert(false);
                  setCookie();
                }}
              >
                <i className="fas fa-globe rightmargin-10" />
                <span>
                  {localize("alert_continue_browser_text", props.language)}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

DownloadAppAlert.propTypes = propTypes;

export default DownloadAppAlert;
