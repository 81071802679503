import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Loading from "../Loading";

const propTypes = {
  hiddenFields: PropTypes.object,
  url: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  allowFullScreen: PropTypes.bool
};

const defaultProps = {
  title: "Test",
  name: "post_iframe",
  allowFullScreen: false
};

class PostIframe extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = { renderedIframe: false };
  }
  componentDidMount() {
    this.formRef.current.submit();
    this.setState({ renderedIframe: true });
  }

  render() {
    return (
      <Fragment>
        {this.state.renderedIframe ? (
          ""
        ) : (
          <form
            action={this.props.url}
            method="post"
            target={this.props.name}
            ref={this.formRef}
          >
            <Loading />
            {Object.keys(this.props.hiddenFields).map(key => {
              return (
                <input
                  key={key}
                  type="hidden"
                  name={key}
                  value={this.props.hiddenFields[key]}
                />
              );
            })}
          </form>
        )}
        <iframe
          name={this.props.name}
          title={this.props.title}
          frameBorder="0"
          className="fullscreen-frame"
          allowFullScreen={this.props.allowFullScreen}
        />
      </Fragment>
    );
  }
}

PostIframe.propTypes = propTypes;
PostIframe.defaultProps = defaultProps;

export default PostIframe;
