import React from "react";

import Topbar from "components/Topbar/Topbar";

import "./Topbar.css";
import Link from "components/shared/Link/Link";

import {
  SHOW_TOPIC_CATEGORIES,
  SHOW_CHALLENGE_CATEGORIES,
  ENABLE_LOGIN_HOME
} from "config";
import localize from "lang/localize";

class ASMTopbar extends Topbar {
  renderCustomBackText() {
    switch (this.props.state) {
      case "challenge":
      case "challenge-category":
        return localize("back_button_back_text", this.props.language); //Back to Topic
      //return "Back to Topic Page"; //awkward switching of text in mobile
      case "rewards":
        return "Back to Profile Page";
      default:
        return this.renderBackText();
    }
  }

  renderBackTextWrap() {
    return (
      <span className="asm-back-text-wrap">{this.renderCustomBackText()}</span>
    );
  }

  renderBack() {
    /* CUSTOM FOR ASM: No back button for user profile page */
    if (this.props.state === "profile") {
      return null;
    }

    /* Special case: at topic page - you are a project administrator, but not a project player */
    if (
      (this.props.state === "topic" || this.props.state === "topic-category") &&
      !this.props.projectId
    ) {
      return null;
    } else if (
      this.props.state === "home-single" ||
      this.props.state === "home-multi" ||
      this.props.state === "login-tour" ||
      this.props.state === "home"
    ) {
      /* There's no reason for a back button in the Player home page */
      return null;
    } else if (
      this.props.state === "projecthome" &&
      this.props.isEmbeddedProject
    ) {
      /* For embedded projects, no way to go back home */
      return null;
    } else if (this.props.backLink !== "") {
      /*
      For cases that involve routing
    */
      let hideLinkClass = "";

      //Hide back button for topic page (challenge list) in desktop mode
      if (
        this.props.state === "topic" ||
        this.props.state === "topic-category"
      ) {
        hideLinkClass = " hide-from-lg";
      }

      return (
        <div className={"back-button-wrap" + hideLinkClass}>
          {/* Use Back with no copywriting for mobile sizes */}
          <Link to={this.props.backLink} className="hide-from-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-caret-left" aria-hidden="true" />
              {localize("back_button_back_text", this.props.language)}
            </span>
          </Link>
          {/* Use renderBackText() for desktop sizes */}
          <Link to={this.props.backLink} className="hide-below-md">
            <span className="back-button-text text-truncated">
              <i className="fas fa-caret-left" aria-hidden="true" />
              {this.renderBackTextWrap()}
            </span>
          </Link>
        </div>
      );
    } else {
      /*
      For cases that involve simulated browser back button
    */

      if (window.history.length <= 1) {
        return null; // Remove back button if no history
      }

      return (
        <div className="back-button-wrap">
          <span
            className="back-button-text text-truncated back-button-history-text"
            onClick={this.props.handleBack}
          >
            <i className="fas fa-caret-left" aria-hidden="true" />
            {this.renderBackTextWrap()}
          </span>
        </div>
      );
    }
  }

  renderTopbar() {
    /* No back button */
    if (
      (ENABLE_LOGIN_HOME && this.props.state === "login-home") ||
      (!ENABLE_LOGIN_HOME && this.props.state === "login")
    ) {
      return (
        <div className="topbar topbarbg pure-g fixed fullwidth">
          <div className="pure-u-sm-7-24" />
          <div className="pure-u-sm-1-24" />
          <div className="pure-u-13-24 pure-u-sm-8-24">
            <div
              className="title-wrap text-truncated-parent not-logged-in"
              dangerouslySetInnerHTML={{ __html: this.renderTopbarTitle() }}
            />
          </div>
          <div className="pure-u-1-24 hide-from-sm" />
          <div className="pure-u-10-24 pure-u-sm-8-24">
            {this.renderRightTopbar()}
          </div>
        </div>
      );
    } else {
      /* With back button */
      return (
        <div className="topbar topbarbg pure-g fixed fullwidth">
          <div className="pure-u-7-24 pure-u-sm-4-24 pure-u-md-8-24 relative">
            {this.renderBack()}
          </div>
          <div className="pure-u-1-24" />
          <div className="pure-u-8-24 pure-u-sm-14-24 pure-u-md-10-24">
            <div
              className="title-wrap text-truncated-parent"
              dangerouslySetInnerHTML={{ __html: this.renderTopbarTitle() }}
            />
          </div>
          <div className="pure-u-8-24 pure-u-sm-5-24 pure-u-md-5-24">
            {this.renderRightTopbar()}
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <nav
        className={
          "topbar-wrap" +
          (this.props.loggedIn ? " logged-in" : "") +
          (this.props.isMobileProjectPage ? " mobile-project-topbar-wrap" : "")
        }
      >
        {this.renderTopbar()}
        {this.props.showSearchChallengeDialog &&
          this.renderSearchChallengeDialog()}
        {this.props.showShareGameDialog && this.renderShareGameDialog()}
        {SHOW_TOPIC_CATEGORIES &&
          this.props.showSelectTopicCategoryDialog &&
          this.renderSelectTopicCategoryDialog()}
        {SHOW_CHALLENGE_CATEGORIES &&
          this.props.showSelectChallengeCategoryDialog &&
          this.renderSelectChallengeCategoryDialog()}
        {this.props.showLanguageSelectionDialog &&
          this.renderLanguageSelectionDialog()}
        {this.props.showTourModal && this.renderTourModal()}
      </nav>
    );
  }
}

export default ASMTopbar;
